/* eslint-disable */

export default function DatasetExtender(name, requiredDimensions) {
    this.name = name;
    this.requiredDimensions = requiredDimensions;

    return {
        name: this.name,
        meetsDimensionExpectations: function(dimensions) {
            return this.requiredDimensions.filter(d => dimensions.indexOf(d) != -1).length ===
                this.requiredDimensions.length;
        }.bind(this),
        extend: function(value, dataset) {
            if (this.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }.bind(this)
    };
}