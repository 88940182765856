import { NonNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';

const segmentKey = 'segment';

class Segment extends NonNumericDimension {
    /**
     * Describes a geological segment
     */
    constructor() {
        super();

        this.displayName = 'Segment';
        this.name = this.shortName = segmentKey;
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.Segment;
}

const segment = new Segment();

export default segment;