define("ava-saturation/controllers/cp-import", ["exports", "ava-import/controllers/cp-import"], function (_exports, _cpImport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _cpImport.default;
    }
  });
});
