import { action, computed } from '@ember/object';
import Component from '@ember/component';
import facies from 'ava-saturation/classes/dimensions/facies';
import { faciesLogReferenceType } from 'ava-saturation/classes/facies-log-reference-type';
import { IWellContextCollection, WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import CalculationSetGenerator from 'ava-saturation/classes/widgets/calculation-set-generator';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import IDimension from 'ava-saturation/interfaces/dimension';
import IWellLog, { IWellLogFilter } from 'ava-saturation/interfaces/well-log-reference';
import IWell from 'ava-saturation/interfaces/well-reference';

export default abstract class WidgetInputItem extends Component {
    discreteKeys: string[] = [facies.shortName];

    // inputs
    wellContext: IWell;
    exploredDimension: IDimension | null = null;
    filter: boolean;
    wellContextCollection: IWellContextCollection;
    wellModelingContextCollection: WellModelingContextCollection;

    onCalculationSetsChanged: (action: any) => void;

    @computed('wellModelingContextCollection.byMoniker', 'wellContext')
    get inputDictionary() {
        return this.wellModelingContextCollection.byMoniker[this.wellContext.moniker.string];
    }

    @computed('wellContextCollection.faciesLogs.[]', 'wellContextCollection.faciesLogFilters.[]', 'wellContext')
    get faciesLogs() {
        const filters = this.wellContextCollection.faciesLogFilters;
        return this.wellContextCollection.faciesLogs.filter((l: IWellLog) =>
            l.wellMoniker.string === this.wellContext.moniker.string &&
            filters.filter((filter: IWellLogFilter) => filter.predicate(l)).length === filters.length
        );
    }

    get faciesDimension() {
        return facies;
    }

    get faciesLogType() {
        return faciesLogReferenceType;
    }

    abstract get targetCollections(): { dimension: any, logs: any, type: any }[];

    @computed('filter', 'inputDictionary.byDimensionKey')
    get targetDiscreteCollections(): { dimension: any, logs: any, type: any }[] {
        return Object.keys(this.filter ? this.inputDictionary.byDimensionKey : {
            [this.faciesDimension.shortName]: null
        }).filter(key => this.discreteKeys.indexOf(key) !== -1).map(key => {
            return {
                // @ts-ignore
                dimension: this.get(`${key}Dimension`),
                // @ts-ignore
                logs: this.get(`${key}Logs`),
                // @ts-ignore
                type: this.get(`${key}LogType`)
            };
        });
    }

    @action
    inputUpdated(logs: IWellLog[], dimension: IDimension, add: boolean) {
        // add a collection for this well
        this.wellModelingContextCollection.tryAdd(this.wellContext);
        const inputDictionary = this.wellModelingContextCollection.byMoniker[this.wellContext.moniker.string];

        // add a dimension for the well collection, if there are no logs provided, reset the dimension.
        const logCountBeforeUpdate = inputDictionary.tryAdd(dimension, logs.length === 0);

        // retrieve the dimension collection
        const monikerCollection = inputDictionary.byDimensionKey[dimension.shortName];
        const collectionUpdate = add ? monikerCollection.tryAdd : monikerCollection.delete;

        // perform target operation, either adding logs or removing logs
        const logCountAfterUpdate = collectionUpdate.call(monikerCollection, logs);

        if (logCountBeforeUpdate === 0 || logCountAfterUpdate === 0) {
            let updates = CalculationSetGenerator.generateSetsFromState(inputDictionary,
                {
                    calculationSets: [[CalculationSetGenerator.trajectoryCalculationSet(this.wellContext)]],
                    dimension: null,
                    wellContext: this.wellContext
                }).filter(set => set.length > 1);

            if (logCountAfterUpdate === 0) {
                inputDictionary.delete(dimension);
            }

            if (updates.length === 0) {
                this.wellModelingContextCollection.delete(this.wellContext);
            }

            const refreshAllDatasetsAction = {
                updates,
                // remove all datasets
                filter: (calculationSets: CalculationSet[]) => {
                    return calculationSets.any(set => {
                        const wellMoniker = set.monikerDictionary.wellMoniker || {};

                        return this.wellContext.moniker.string === wellMoniker.string;
                    });
                }
            };

            this.onCalculationSetsChanged(refreshAllDatasetsAction);
            return;
        }

        if (add) {
            const addDatasetsAction = {
                updates: CalculationSetGenerator.produceCalculationSets(
                    {
                        logMonikers: logs.map(l => l.moniker),
                        dimension,
                        wellContext: this.wellContext,
                        inputDictionary
                    }),
                // ignore all datasets
                // @ts-ignore
                filter: (calculationSets: CalculationSet[]) => false
            };

            this.onCalculationSetsChanged(addDatasetsAction);
            return;
        }

        const logMonikers = logs.map(log => log.moniker);

        const removeDatasetsAction = {
            updates: [],
            filter: (calculationSets: CalculationSet[]) => {
                return calculationSets.any(set => {
                    const wellLogMoniker = set.monikerDictionary.wellLogMoniker || {};

                    return logMonikers.any(moniker => wellLogMoniker.string === moniker.string);
                });
            }
        };
        this.onCalculationSetsChanged(removeDatasetsAction);
    }
}
