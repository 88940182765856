import IMoniker from 'ava-saturation/interfaces/moniker';
import IReference from 'ava-saturation/interfaces/reference';
import IDimension from 'ava-saturation/interfaces/dimension';
import IReferenceType from 'ava-saturation/interfaces/reference-type';

export interface IWellLogReferenceType extends IReferenceType {
    primaryDimension: IDimension;
    dimensions: Array<IDimension>;

    toPlainObject(): Record<string, any>;
}

export default interface IWellLog extends IReference {
    type: IWellLogReferenceType;
    wellMoniker: IMoniker;
    templateMoniker: IMoniker;
    isSynthetic: boolean;
}

export const toPlainObject = (wellLog: IWellLog) => {
    return {
        ...wellLog,
        type: wellLog.type.toPlainObject()
    };
};

export interface IWellLogFilter {
    predicate(wellLog: IWellLog): boolean;
}
