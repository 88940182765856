define("ava-saturation/services/xlsx-parser", ["exports", "ava-import/services/xlsx-parser"], function (_exports, _xlsxParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _xlsxParser.default;
    }
  });
});
