import porosity from 'ava-saturation/classes/dimensions/porosity';
import objectName from 'ava-import/classes/dimensions/object-name';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import permeability from 'ava-import/classes/dimensions/permeability';
import { contactAngleRes, contactAngleLab } from 'ava-import/classes/dimensions/contact-angle';
import { waterGradient, gasGradient, oilGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { iftReservoir, iftLab } from 'ava-import/classes/dimensions/interfacial-tension';
import { relativePermeabilityWater, relativePermeabilityGas, relativePermeabilityOil } from 'ava-import/classes/dimensions/relative-permeability';
import { capillaryPressureRes, capillaryPressureLab } from 'ava-import/classes/dimensions/capillary-pressure';

import WellLogReferenceType, { WellLogReferenceParser, WellLogCollectionReferenceParser } from 'ava-saturation/classes/well-log-reference-type';

export default class PointWellLogReferenceType extends WellLogReferenceType {
    constructor() {
        super(capillaryPressureRes, [
            capillaryPressureRes,
            objectName,
            porosity,
            capillaryPressureLab,
            permeability,
            contactAngleRes,
            contactAngleLab,
            waterGradient,
            gasGradient,
            oilGradient,
            iftReservoir,
            iftLab,
            relativePermeabilityWater,
            relativePermeabilityGas,
            relativePermeabilityOil,
            waterSaturation
        ]);
    }

    id = 220;
    name = 'capillary-pressure-well-log';
    subTypeAliases = [];
}

export const pointWellLogReferenceType = new PointWellLogReferenceType();

export class PointWellLogReferenceParser extends WellLogReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, pointWellLogReferenceType);
    }
}

export class PointWellLogCollectionReferenceParser extends WellLogCollectionReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, pointWellLogReferenceType);
    }
}