import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import configuration from 'ava-saturation/configuration';
import Session from 'ember-simple-auth/services/session';

export default class SessionService extends Session {
    @service intl;
    @service('store') dataStore;

    /**
     * Override this property to return true if there are any unsaved changes to alert user
     * before closing browser window/session.
     */
    hasDirtyRecords = false;

    @(computed.oneWay('data.authenticated.license')) applicationLicense;

    init() {
        super.init();
        // Alert user is there are unsaved documents
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        window.addEventListener('beforeunload', (event = window.event) => {
            if (!this.canClose()) {
                // Note: the message will be ignored by modern browsers
                const message = this.intl.findTranslationByKey('unsaved-changes-message');
                event.returnValue = message;
                return message;
            }
        });
    }

    get isAccessTokenExpired() {
        return (new Date()).getTime() > this.get('data.authenticated.expires_at');
    }


    @computed(
        'data.authenticated.{license,expires_at}',
        'data.authenticated.license.{startDate,endDate}')
    get isLicenseValid() {
        return this.licenseStatus === 'valid' || this.licenseStatus === 'about-to-expire';
    }

    // @computed('data.authenticated.{license}',
    //     'data.authenticated.license.{startDate,endDate}')
    get licenseStatus() {

        if (!this.applicationLicense) {
            return 'no-license';
        }
        if (!!this.applicationLicense.startDate && (new Date()).getTime() < (new Date(this.applicationLicense.startDate.startDate)).getTime()) {
            return 'not-started';
        }
        if (!!this.applicationLicense.endDate && (new Date()).getTime() > (new Date(this.applicationLicense.endDate)).getTime()) {
            return 'expired';
        }
        if (!!this.applicationLicense.endDate
            && ((new Date()).getTime() + (configuration.licenseExpirationWarningPeriod * 24 * 60 * 60 * 1000)) > (new Date(this.applicationLicense.endDate)).getTime()) {
            return 'about-to-expire';
        }

        return 'valid';

    }

    @computed()
    get signInToContinue() {
        return this.intl.t('home-page.sign-in');
    }

    canClose() {
        return this._canClose || !this.isAuthenticated || !this.hasDirtyRecords;
    }

    refreshAccessToken() {
        return this._refreshAccessTokenPromise ||
            this.set('_refreshAccessTokenPromise', getOwner(this)
                .lookup(this.get('data.authenticated.authenticator'))
                .restore(this.get('data.authenticated'))
                .catch(() => {
                    const return_url = window.location.pathname || 'index';
                    return this.store.clear().then(this.authenticate('authenticator:auth-code', { state: return_url }));
                })
                .finally(() => this.set('_refreshAccessTokenPromise', null)));
    }

    invalidate() {
        if (this.canClose() || confirm(this.intl.t('unsaved-changes-message'))) {
            this.set('_canClose', true);
            super.invalidate();
        }
    }
}
