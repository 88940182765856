import createSagaMiddleware from 'redux-saga';
import rootSaga from 'ava-saturation/sagas/index';

const sagaMiddleware = createSagaMiddleware();

export default {
    middleware: sagaMiddleware,
    setup: ({ reduxStore, dataStore, database, referenceStore }) => { 
        sagaMiddleware.run(rootSaga({ store: dataStore, database, referenceStore }), reduxStore.dispatch); 
    }
};