import IDimension from 'ava-saturation/interfaces/dimension';
import IPresenter, { IPresenterDefinition, IPresenterSettings, PlotPresentationalType } from 'ava-saturation/interfaces/presenter';

export default interface IPlot extends IPresenter {
}

export abstract class PlotDefinition implements IPresenterDefinition {
    abstract type: PlotPresentationalType;
    abstract icon: string;
    abstract name: string;

    get displayName(): string {
        return `${PlotPresentationalType[this.type]} plot`;
    }

    deflate(): Partial<PlotDefinition> {
        return {
            type: this.type
        };
    }
}

export interface ILegendSettings {
    title: string;
}

export enum PlotSettingsType {
    Scatter,
    Histogram
}

export interface IPlotSettings extends IPresenterSettings {
    readonly type: PlotSettingsType;
    legendSettings: ILegendSettings;
}

// each options needs to provide it's set of settings
export interface IAxisOptionSettings {
    name: string;
    deflate(): Partial<IAxisOptionSettings>;
}

export interface IAxisOption {
    dimension: IDimension;
    settings: IAxisOptionSettings;

    updateWith?(option: AbstractAxisOption): void;
}

export abstract class AbstractAxisOption implements IAxisOption {
    abstract dimension: IDimension;
    abstract settings: IAxisOptionSettings;

    updateWith?(option: AbstractAxisOption): void;

    abstract deflate(): Partial<AbstractAxisOption>;
}

export interface IAxisSettings {
    title: string;
    options: AbstractAxisOption[];
    option: AbstractAxisOption | undefined;
    selectedOption: AbstractAxisOption | undefined;
}

export interface INumericAxisOptionSettings extends IAxisOptionSettings {
    min: string;
    max: string;
}

export interface IScalableAxisOptionSettings extends IAxisOptionSettings {
    scale: ContinuousNumericScaleType;
}

export interface IGroupableAxisOptionSettings extends IAxisOptionSettings {

}

export interface ICategoricalAxisOptionSettings extends IAxisOptionSettings {
    binCount: number;
}

export enum ContinuousNumericScaleType {
    Linear,
    Logarithmic
}

export enum ScaleType {
    Category
}
