import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import { default as EmberObject } from '@ember/object';
import RSVP from 'rsvp';

export default class ObjectPromiseProxy<T> extends ObjectProxy.extend(PromiseProxyMixin) {
    content: T & EmberObject;

    public static createPromise<T>(promise: RSVP.Promise<any>): ObjectPromiseProxy<T> {
        return ObjectPromiseProxy.create({
            promise
        }) as ObjectPromiseProxy<T>;
    }
}
// @ts-ignore
// tslint:disable-next-line: no-shadowed-variable
export class Fix<ObjectPromiseProxy> {
    readonly [Symbol.toStringTag]: 'Promise';
}