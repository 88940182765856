import RSVP from 'rsvp';

export function updateApplicationState(stateStore, appStateKey, version, applicationState) {
    return new RSVP.Promise((resolve, reject) => {
        var updateRequest = stateStore.put({
            state: appStateKey,
            version: version,
            applicationState
        });

        updateRequest.onsuccess = function (successEvent) {
            resolve(successEvent.target.result);
        };

        updateRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    }).then(x => {
        return x;
    });
}

export function getApplicationtState(stateStore, appStateKey) {
    return (new RSVP.Promise((resolve, reject) => {
        let cursor = stateStore.get(appStateKey);

        cursor.onsuccess = function (successEvent) {
            let result = successEvent.target.result;

            resolve(result);
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    })).then(x => {
        return x;
    });
}