import IWidget from 'ava-saturation/interfaces/widget';

const createdLocked = (value: any, conceptId: string, state: any, intl: any):  { message: string, isInValid: boolean } => {
    let result: boolean = false;

    if (value) {
        const widgets = state.entitiesV1.saturationConceptState.widget.bySid[conceptId] as IWidget[];
        result = widgets.length > 0;
    }

    return {
        message: intl.t('validation.concept-details.locked'),
        isInValid: result
    };
};

export default {
    referenced: [
        createdLocked,
    ]
};
