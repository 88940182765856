import _ from 'lodash';
import isInRange from '../filters/is-in-range';

export default (point, intersections) => {
    let targetIntersection = _.find(intersections, i => isInRange(i.startDepth, i.endDepth, point.tvdss));

    if (targetIntersection && isInRange(targetIntersection.startDepth, targetIntersection.contactDepth, point.tvdss)) {
        point.zoneStartDepth = targetIntersection.startDepth;
        point.zoneEndDepth = targetIntersection.endDepth;
        point.contactDepth = targetIntersection.contactDepth;
        point.zoneName = targetIntersection.zoneName;
        point.segmentName = targetIntersection.segmentName;
    }
};