import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IFaciesCode from 'ava-saturation/interfaces/facies-code';
import IMoniker from 'ava-saturation/interfaces/moniker';
import FaciesMoniker from 'ava-saturation/classes/facies-moniker';

export default class FaciesCodeReferenceType extends ReferenceObjectType {
    readonly id = 330;
    readonly name = 'facies-code';
    readonly typeAliases = []; // TODO
    subTypeAliases = [];
}

export const faciesCodeReferenceType = new FaciesCodeReferenceType();

export class FaciesCodeReferenceParser extends SingleReferenceParser<IFaciesCode> {
    private _propertyMoniker: IMoniker;
    private _templateMoniker: IMoniker;

    constructor(origin: string, version: string, propertyMoniker: IMoniker, templateMoniker: IMoniker) {
        super(origin, version);

        this._propertyMoniker = propertyMoniker;
        this._templateMoniker = templateMoniker;
    }

    protected parseMoniker(object: any) {
        return new FaciesMoniker({
            origin: this.origin,
            version: this.version,
            identifier: this._templateMoniker.identifier,
            code: object.Code
        });
    }

    parse(object: any): IFaciesCode {
        let reference = super.parse(object);
        return {
            ...reference,
            type: faciesCodeReferenceType,
            propertyMoniker: this._propertyMoniker,
            templateMoniker: this._templateMoniker,
            code: object.Code
        };
    }
}

export class FaciesCodeCollectionReferenceParser extends ReferenceParser<Array<IFaciesCode>> {
    private _gridMoniker: IMoniker;
    private _propertyMoniker: IMoniker;
    private _templateMoniker: IMoniker;

    constructor(origin: string, version: string, gridMoniker: IMoniker, propertyMoniker: IMoniker, templateMoniker: IMoniker) {
        super(origin, version);

        this._gridMoniker = gridMoniker;
        this._propertyMoniker = propertyMoniker;
        this._templateMoniker = templateMoniker;
    }

    parse(object: any): Array<IFaciesCode> {
        let faciesCodes = referenceCollectionParser<IFaciesCode>(object, new FaciesCodeReferenceParser(this.origin, this.version, this._propertyMoniker, this._templateMoniker));

        faciesCodes.forEach(c => c.gridMoniker = this._gridMoniker);

        return faciesCodes;
    }
}