import { computed } from '@ember/object';
import Component from '@ember/component';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import { BandType, general } from 'ava-saturation/classes/band-types';
import { CalculationType } from 'ava-saturation/classes/calculation-types';
import { DataSourceInstance } from 'ava-saturation/classes/data-source';
import facies from 'ava-saturation/classes/dimensions/facies';
import FaciesMoniker from 'ava-saturation/classes/facies-moniker';
import { regressionLineScatterPlotDefinition } from 'ava-saturation/components/widgets-new/plot';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import { IWellLogDataset } from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import IWell from 'ava-saturation/interfaces/well-reference';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';

export default abstract class ResultsBase extends Component {
    dataSourceInstance: DataSourceInstance;
    datasets: { dataset: IWellLogDataset<any>, calculationSets: CalculationSet[] }[];
    faciesArea: ModelArea;
    presenterDefinition = regressionLineScatterPlotDefinition;
    band: IBand;
    bandType: BandType;
    applicableWells: IWell[];
    calculationMethod: CalculationType;
    gridContext: GridContext;
    onDatasetExport: (title: string) => void;
    onConstantChange: (newConstant?: {
        sid: string,
        bandId: string,
        constantKey: string,
        value: number
    }) => void;

    _calculateStep(value: number) {
        const step = Math.abs(value) / 10;
        return step.toFixed(4);
    }

    @computed('faciesArea')
    get faciesCodes() {
        if (!this.faciesArea)
            return [];

        return this.faciesArea.monikers.map(m => new FaciesMoniker({ string: m })).map((m: FaciesMoniker) => Number(m.code));
    }

    @computed('faciesCodes.[]')
    get faciesFilter() {
        if (this.faciesCodes.length === 0)
            return () => true;

        return (point: any) => this.faciesCodes.includes(point[facies.shortName]);
    }

    @computed('band')
    get bandRanges() {
        return this.band.ranges;
    }

    @computed('bandRanges.[]', 'bandType')
    get bandFilter() {
        if (this.bandType === general)
            return () => true;

        return (point: any) => this.bandRanges.length === 0 || this.bandRanges.any(r => isInRange(r.from, r.to, point[this.bandType.shortName], false, true));
    }

    @computed('bandFilter', 'faciesFilter')
    get pointFilters() {
        return [this.bandFilter, this.faciesFilter];
    }

    @computed('datasets.[]', 'pointFilters.[]')
    get filteredDatasets() {
        return this.datasets.map(ds => {
            return {
                dataset: {
                    primaryDimension: this.primaryDimension,
                    secondaryDimension: this.secondaryDimension,
                    dimensions: [this.primaryDimension, this.secondaryDimension],
                    values: ds.dataset.values.filter(v => {
                        return this.pointFilters.every(f => f(v));
                    })
                }
            };
        });
    }

    generateDataset() {
        const title = `[${this.bandType.name} - ${this.calculationMethod.name}] ${this.band.name} dataset`;

        if (this.onDatasetExport)
            this.onDatasetExport(title);
    }

    abstract get primaryDimension(): IDimension;
    abstract get secondaryDimension(): IDimension;
}