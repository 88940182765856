import Component from '@ember/component';
import { inject } from '@ember/service';
import { get } from '@ember/object';
import { connect } from 'ember-redux';
import filterBy from 'ava-saturation/utils/filter-by';
import { computed } from '@ember/object';
// utils
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import cloneDeep from 'lodash/cloneDeep';

const stateToComputed = (state) => {
    let filteredConcepts = values(state.entitiesV1.saturationConcept.byId);
    let filter = state.ui.saturationConcepts.panel.filter;
    let sortAsc = state.ui.saturationConcepts.panel.sort.asc;
    let sortKey = state.ui.saturationConcepts.panel.sort.key;

    filteredConcepts = filterBy(filteredConcepts, (item) => {
        return get(item, 'generalInformation.title');
    }, filter);

    if (sortKey)
        filteredConcepts = sortBy(filteredConcepts, [(item) => {
            return get(item, sortKey);
        }]);

    if (!sortAsc)
        filteredConcepts = reverse(filteredConcepts);

    return {
        concepts: cloneDeep(filteredConcepts)
    };
};

const dispatchToActions = (dispatch) => {
    return {
        onSearchTextChanged(filter) {
            dispatch({ type: 'SATURATION_CONCEPT_PANEL_FILTER_CHANGED', filter });
        }
    };
};

const conceptListContainer = Component.extend({
    intl: inject(),
    isSearchEnabled: false,

    contextualConcepts: computed('concepts.[]', 'gridModels.[]', function () {
        return this.concepts ? this.concepts.filter(c => this._conceptUsesGrid(c, this.gridModels)) : [];
    }),
    unrelatedConcepts: computed('concepts.[]', 'gridModels.[]', function () {
        return this.concepts ? this.concepts.reject(c => this._conceptUsesGrid(c, this.gridModels)) : [];
    }),

    _conceptUsesGrid: function (concept, gridModels) {
        if (!concept.metadata)
            return false;
        // no grid selected - can be edited anywhere
        if (concept.metadata.sourceMonikerString === null)
            return true;

        return gridModels.find(gm => gm.grid && gm.grid.moniker.string === concept.metadata.sourceMonikerString);
    },
    didInsertElement() {
        this._super(...arguments);
    },
    willDestroyElement() {
        this._super(...arguments);
        this.actions.onSearchTextChanged.call(this, null);
    }
});

export default connect(stateToComputed, dispatchToActions)(conceptListContainer);
