import merge from 'lodash/merge';

export const SATURATION_ERROR = '@@SATURATION_ERROR';
export const CLEAR_SATURATION_ERROR = '@@CLEAR_SATURATION_ERROR';
export const SATURATION_BLANK = '@@SATURATION_BLANK';

const initialState = {
};

export default ((state: any, action: any) => {
    let currentState = state || initialState;

    if (action.type === SATURATION_ERROR) {
        return merge({}, currentState, {
            origin: action.origin,
            message: action.error
        });
    }

    if (action.type === CLEAR_SATURATION_ERROR) {
        return merge({}, initialState);
    }

    return currentState;
});