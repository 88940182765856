import { SET_MODEL } from './entities-v1/saturation-concept-state/model/constants';
import { UPDATE_GENERAL_INFO } from './entities-v1/saturation-concept/constants';

export const TrackableActions = [
    SET_MODEL,
    UPDATE_GENERAL_INFO
];

export function IsTrackable(actionName: string) {
    return TrackableActions.includes(actionName);
}
