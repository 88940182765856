import ReferenceObjectType from '../classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from '../interfaces/reference-parser';
import IGrid from 'ava-saturation/interfaces/grid-reference';

export default class GridReferenceType extends ReferenceObjectType {
    readonly id = 100;
    readonly name = 'grid';
    readonly typeAliases = ['PetrelGrid'];
    subTypeAliases = [];
}

export const gridReferenceType = new GridReferenceType();

export class GridReferenceParser extends SingleReferenceParser<IGrid> {
    parse(object: any): IGrid {
        let reference = super.parse(object);
        return {
            ...reference,
            type: gridReferenceType,
        };
    }
}

export class GridCollectionReferenceParser extends ReferenceParser<Array<IGrid>> {
    parse(object: any): Array<IGrid> {
        return referenceCollectionParser<IGrid>(object, new GridReferenceParser(this.origin, this.version));
    }
}