import ApplicationInstance from '@ember/application/instance';
import configuration from 'ava-saturation/config/environment';

export function initialize(instance: ApplicationInstance) {
    const applicationRoute = instance.lookup('route:application');
    const session = instance.lookup('service:session');

    // tslint:disable-next-line: no-empty
    session.on('authenticationSucceeded', function () { });
    session.on('invalidationSucceeded', function () {
        applicationRoute.transitionTo(configuration.APP.routeIfSignedOut);
    });
}

export default {
    initialize,
    name: 'session-events',
    after: 'ember-simple-auth'
};