import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { CategorialAxisOptionSettings } from 'ava-saturation/classes/widgets/axis';

export default class CategorialAxisSettings extends Component {
    settings: CategorialAxisOptionSettings;
    _binCount: number;

    didReceiveAttrs() {
        super.init();

        set(this, '_binCount', this.settings.binCount);
    }

    @computed('_binCount')
    get binCount() {
        return this._binCount;
    }
    set binCount(value: number) {
        // values coming from the ui are almost always strings
        let parsed = Number(value);
        set(this, '_binCount', parsed);

        if (parsed <= 50 && parsed >= 5) {
            set(this.settings, 'binCount', parsed);
        }
    }
}
