import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';
import IContact from 'ava-saturation/interfaces/contact-reference';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import IPermeabilityProperty from 'ava-saturation/interfaces/permeability-property-reference';
import IPorosityProperty from 'ava-saturation/interfaces/porosity-property-reference';
import { getModelInputs } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/selectors';
import { ModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/types';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import { loadFont, ptToPx } from 'ava-saturation/utils/pdf-utils';
import blobStream from 'blob-stream';
import { titleize } from 'ember-cli-string-helpers/helpers/titleize';
import svgToPdf from 'SvgToPDFKit';
import PDFDocument from './pdf-document';
import { avaDefault as theme, fontSize } from './pdf-themes';

export class PDFBuilder {
    creationDate: string;
    concept: SaturationConcept;
    intl: any;
    doc: PDFDocument;
    pageContentWidth: number;
    pageContentHeight: number;
    stream: blobStream.IBlobStream;
    toc: any[] = [];
    footerHeight = 50;
    owner: any;
    redux: any;
    objectStore: IObjectStore;
    grids: IGrid[];
    contacts: IContact[];
    faciesProperties: IFaciesProperty[];
    porosityProperties: IPorosityProperty[];
    permeabilityProperties: IPermeabilityProperty[];

    constructor(intl: any, owner: any, redux: any, objectStore: IObjectStore) {
        this.owner = owner;
        this.redux = redux;
        this.intl = intl;
        this.objectStore = objectStore;

        this.doc = new PDFDocument({
            size: 'A4',
            margins: {
                top: 36,
                left: 36,
                bottom: 72,
                right: 36
            },
            bufferPages: true
        });

        this.pageContentWidth = this.doc.page.width - this.doc.page.margins.left - this.doc.page.margins.right;
        this.pageContentHeight = this.doc.page.height - this.doc.page.margins.top - this.doc.page.margins.bottom;
        this.stream = this.doc.pipe(blobStream());
        this.creationDate = this.formatDate(this.doc.info.CreationDate);
    }

    @computed()
    get grid() {
        const selectedGrid = this.grids.find(g => g.moniker.string === this.concept.metadata.sourceMonikerString);
        return selectedGrid ? selectedGrid.name : '';

    }

    @computed()
    get modelInputs(): ModelInput[] {
        return getModelInputs(this.redux.getState(), { sid: this.concept.id });
    }

    @computed()
    get contact() {
        const selected = this.contacts.find(c => this.modelInputs.any(i => i.moniker === c.moniker.string));
        return selected ? selected.name : '';
    }

    @computed()
    get faciesProperty() {
        const selected = this.faciesProperties.find(f => this.modelInputs.any(i => i.moniker === f.moniker.string));
        return selected ? selected.name : '';
    }

    @computed()
    get porosityProperty() {
        const selected = this.porosityProperties.find(f => this.modelInputs.any(i => i.moniker === f.moniker.string));
        return selected ? selected.name : '';
    }

    @computed()
    get permeabilityProperty() {
        const selected = this.permeabilityProperties.find(f => this.modelInputs.any(i => i.moniker === f.moniker.string));
        return selected ? selected.name : '';
    }

    private formatDate = (x: any, options = { format: 'long', hour: 'numeric', minute: 'numeric' }) => this.intl
        .formatDate(x, options)
        .replace(/\u200E/g, '');

    private t = (x: string, y?: any): string => this.intl.t(x, y);

    private v = (x: any): string => {
        return isBlank(x) ? '' : x;
    };

    private docNormal = () => this.doc
        .fillColor(theme.primaryColor['900'])
        .fontSize(fontSize.normal)
        .font('regular');

    // private docSmall = () => this.doc
    //     .fillColor(theme.primaryColor['900'])
    //     .fontSize(fontSize.small)
    //     .font('regular');

    private docH1 = (x: string) => {
        const text = titleize([x]);

        const { current, end } = this.doc.bufferedPageRange();
        if (current === end && this.doc.y !== this.doc.page.margins.top) {
            this.doc.addPage();
        }

        this.toc.push({
            level: 1,
            text,
            pageIndex: this.doc.bufferedPageRange().current
        });

        this.doc
            .fontSize(fontSize.h1)
            .font('bold')
            .fillColor(theme.primaryColor['700'])
            .text(text);

        this.docNormal();
        this.doc.moveDown(1.5);
    };

    docH2 = (x: string, { minHeight = 0 } = {}) => {
        this.doc
            .fontSize(fontSize.h2)
            .font('bold');

        const text = titleize([x]);
        const desiredHeight = this.doc.heightOfString(text) + minHeight + this.doc.currentLineHeight(true);
        const availableHeight = this.doc.page.height - this.doc.page.margins.bottom - this.doc.y;
        if (desiredHeight > availableHeight) {
            this.doc.addPage();
        }

        this.toc.push({
            level: 2,
            text,
            pageIndex: this.doc.bufferedPageRange().current
        });

        this.doc
            .fillColor(theme.primaryColor['500'])
            .text(text);

        this.docNormal();
        this.doc.moveDown(0.5);
    };

    docP = (x: string) => this.doc
        .fillColor(theme.primaryColor['900'])
        .fontSize(fontSize.normal)
        .font('regular')
        .text(x)
        .moveDown(0.5);

    docGeneralInformation = () => {
        this.docH1(this.t('sections.concept-details.general-information.title'));
        this.docTable(
            [1, 2],
            [this.t('saturation-concept.general-information.title'), this.v(this.concept.generalInformation.title)],
            [this.t('saturation-concept.general-information.description'), this.v(this.concept.generalInformation.description)],
            [this.t('saturation-concept.general-information.asset-team'), this.v(this.concept.generalInformation.assetTeam)],
            [this.t('saturation-concept.general-information.field'), this.v(this.concept.generalInformation.field)],
            [this.t('saturation-concept.general-information.reservoir'), this.v(this.concept.generalInformation.reservoir)]
        );
        this.doc.moveDown();

        this.docH2(this.t('saturation-concept.logs.created'));
        this.docTable(
            [1, 2],
            [this.t('saturation-concept.logs.date-time'), this.formatDate(this.concept.created, { format: 'long', hour: 'numeric', minute: 'numeric' })],
            [this.t('saturation-concept.logs.by'), this.v(this.concept.createdBy)]
        );
        this.doc.moveDown();

        this.docH2(this.t('saturation-concept.logs.modified'));
        this.docTable(
            [1, 2],
            [this.t('saturation-concept.logs.date-time'), this.formatDate(this.concept.modified, { format: 'long', hour: 'numeric', minute: 'numeric' })],
            [this.t('saturation-concept.logs.by'), this.v(this.concept.modifiedBy)]
        );
        this.doc.moveDown();
    };

    ensureEnoughHeight = (height: number) => {
        if (ptToPx(this.doc.page.height - this.doc.page.margins.top - this.doc.page.margins.bottom - this.doc.y) < height) {
            this.doc.addPage();
        }
    };

    // const ensureEnoughWidth = (width: number) => {
    //     if (ptToPx(doc.page.width - doc.page.margins.left - doc.page.margins.right - doc.x) < width) {
    //         doc.moveDown();
    //     }
    // };
    docTable = (columns: any[], ...rows: any[][]) => {
        const w = this.pageContentWidth / columns.reduce((a: number, b: number) => a + b);
        const strokeColor = theme.primaryColor['300'];
        const padding = 5;

        this.doc
            .moveTo(this.doc.page.margins.left, this.doc.y)
            .lineTo(this.doc.page.width - this.doc.page.margins.right, this.doc.y)
            .stroke(strokeColor);

        rows.forEach((row) => {
            const top = this.doc.y;
            const { current: topPage } = this.doc.bufferedPageRange();
            let bottom = top;
            let bottomPage = topPage;

            // text
            let left = this.doc.page.margins.left;
            row.forEach((c, i) => {
                const width = columns[i] * w - padding;

                this.doc
                    .font(i ? 'regular' : 'bold')
                    .text(c, left, top + padding, { width });

                left += width + 2 * padding;
                const { current } = this.doc.bufferedPageRange();
                if (bottomPage < current) {
                    bottomPage = current;
                    bottom = this.doc.y;
                } else if (bottom < this.doc.y) {
                    bottom = this.doc.y;
                }

                if (topPage < current) {
                    this.doc.switchToPage(topPage);
                    this.doc.y = top;
                }
            });
            bottom += padding;

            // lines
            for (; ;) {
                const { current } = this.doc.bufferedPageRange();
                left = this.doc.page.margins.left;

                /* eslint-disable no-loop-func */
                columns.forEach((c, i) => {
                    if (i) {
                        this.doc
                            .moveTo(left, current === topPage ? top : this.doc.page.margins.top)
                            .lineTo(left, current === bottomPage ? bottom : this.doc.page.height - this.doc.page.margins.bottom)
                            .stroke(strokeColor);
                    }
                    left += c * w;
                });
                /* eslint-enable no-loop-func */

                if (current === bottomPage) {
                    break;
                }

                this.doc.switchToPage(current + 1);
            }

            this.doc
                .moveTo(this.doc.page.margins.left, bottom)
                .lineTo(this.doc.page.width - this.doc.page.margins.right, bottom)
                .stroke(strokeColor);
            this.doc.y = bottom;
        });

        this.doc.moveDown(0.5);
        this.doc.x = this.doc.page.margins.left;
    };

    moveDown = (px: number) => this.docNormal().moveDown(px / 12 / 1.6);

    /**
     * The component should extends layout.
     * See ava-brand.ts
     */
    componentSvg = (componentName: string, options: any) => {
        const owner = this.owner;
        const ComponentFactory = owner.factoryFor(`component:${componentName}`);
        const component = ComponentFactory.create({ classNames: ['hidden temporary-dom'], ...options });
        component.append();

        this.ensureEnoughHeight(options.height);
        svgToPdf(this.doc, component.$('svg')[0] || component.element, this.doc.x, this.doc.y);
        this.moveDown(component.height);

        $('.temporary-dom').remove();

        this.doc.moveDown(0.5);
    };

    docTitlePage = () => {
        const headerHeight = 142;
        const logoHeight = 56;
        const titleFontSize = 36;
        const titleTop = this.doc.page.height / 2 - titleFontSize - this.doc.currentLineHeight(true);

        this.doc.rect(0, 0, this.doc.page.width, headerHeight)
            .fill(theme.primaryColor['700']);

        this.doc.y = (headerHeight - logoHeight) / 2 + 2;
        this.componentSvg('ava-brand', {
            height: logoHeight,
            color: theme.primaryColor['50']
        });

        this.doc.x = this.doc.page.margins.left + 10;
        this.doc.y = titleTop + 10;

        this.doc
            .fillColor(theme.primaryColor['500'])
            .font('regular')
            .fontSize(fontSize.normal)
            .text(this.intl.t('sections.concept.title'))
            .moveDown(0.5)
            .fillColor(theme.accentColor['500'])
            .font('bold')
            .fontSize(titleFontSize)
            .text(this.concept.generalInformation.title)
            .fillColor(theme.primaryColor['500'])
            .font('regular')
            .fontSize(fontSize.normal)
            .moveDown()
            .text(`${this.concept.createdBy}  |  ${this.creationDate}`); // TODO: [ATS] Lookup user

        this.doc
            .moveTo(this.doc.page.margins.left, this.doc.y + 15)
            .lineTo(this.doc.page.margins.left, titleTop)
            .stroke(theme.accentColor['500']);

        this.doc
            .rect(0, this.doc.page.height - this.footerHeight, this.doc.page.width, this.doc.page.height)
            .fill(theme.primaryColor['700'])
            .fontSize(10)
            .fillColor(theme.primaryColor['50'])
            .text(this.t('data-copyright', { year: new Date().getFullYear().toString() }),
                this.doc.page.margins.left,
                this.doc.page.height - 30,
                {
                    width: this.pageContentWidth,
                    height: 15,
                    align: 'right'
                });

        this.doc.addPage();
    };

    docPageFooter = () => {
        this.doc
            .rect(0, this.doc.page.height - this.footerHeight, this.doc.page.width, this.doc.page.height)
            .fill(theme.primaryColor['700'])
            .fontSize(10)
            .fillColor(theme.primaryColor['50'])
            .text(this.doc.info.Title ? this.doc.info.Title : '',
                this.doc.page.margins.left,
                this.doc.page.height - 30, {
                width: this.doc.page.width / 2 - this.doc.page.margins.left - 30,
                height: 15,
                ellipsis: true
            })
            .text(`${this.doc.bufferedPageRange().current + 1}`,
                this.doc.page.width / 2 - 30,
                this.doc.page.height - 30, {
                width: 60,
                height: 15,
                align: 'center'
            })
            .text(this.creationDate,
                this.doc.page.width / 2 + 30,
                this.doc.page.height - 30, {
                width: this.doc.page.width - this.doc.page.margins.right - this.doc.page.width / 2 - 30,
                height: 15,
                align: 'right'
            });
    };

    docPageFooters = () => {
        const { start, end } = this.doc.bufferedPageRange();
        for (let i = start + 1; i <= end; i++) {
            this.doc.switchToPage(i);
            this.docPageFooter();
        }
    };

    forConcept(concept: SaturationConcept) {
        this.concept = concept;
        return this;
    }

    docTableOfContents = () => {
        this.doc.addPage();

        this.doc
            .fillColor(theme.primaryColor['300'])
            .fontSize(fontSize.h1)
            .font('bold')
            .text(this.t('saturation-concept.pdf.toc.title'))
            .moveDown(1);

        this.toc.forEach((x) => {
            const numberWidth = 50;
            const textLeft = this.doc.page.margins.left + (x.level - 1) * 15;
            const textTop = this.doc.y;
            const textRight = this.doc.page.width - this.doc.page.margins.right - numberWidth;

            const pageNumber = x.pageIndex + 1;
            this.doc
                .fillColor(theme.primaryColor['900'])
                .fontSize(fontSize.h2 - x.level)
                .font(x.level > 1 ? 'regular' : 'bold')
                .text(x.text,
                    textLeft,
                    textTop,
                    {
                        width: textRight - textLeft
                    })
                .moveDown(-1)
                .text(pageNumber, {
                    align: 'right'
                });

            this.doc
                .link(textLeft, textTop, this.doc.page.width - this.doc.page.margins.right - textLeft, this.doc.y - textTop, x.pageIndex)
                .fontSize(fontSize.normal)
                .moveDown(0.5);
        });
    };

    docSaturationInformation = async () => {
        this.docH1('Concept');

        this.doc.moveDown();

        const table = [
            ['Grid', this.grid],
            ['Contact', this.contact],
            ['Facies Property', this.faciesProperty],
            ['Porosity Property', this.porosityProperty],
            ['Permeability Property', this.permeabilityProperty]
        ];

        this.docTable(
            [1, 2],
            ...table
        );

    };

    async build() {
        const stream = this.stream;
        this.grids = await this.objectStore.grids;
        this.contacts = await this.objectStore.contacts;
        this.faciesProperties = await this.objectStore.faciesProperties;
        this.porosityProperties = await this.objectStore.porosityProperties;
        this.permeabilityProperties = await this.objectStore.permeabilityProperties;

        this.doc.info.Title = `${this.concept.generalInformation.title}`;
        this.doc.info.Creator = 'Ava Saturation';
        this.doc.registerFont('regular', await loadFont('/assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf'));
        this.doc.registerFont('bold', await loadFont('/assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf'));
        this.docTitlePage();
        this.docGeneralInformation();
        await this.docSaturationInformation();
        this.docTableOfContents();
        this.docPageFooters();

        this.doc.end();

        return new Promise<blobStream.IBlobStream>((resolve) => stream.on('finish', () => {
            resolve(stream);
        }));
    }

}
