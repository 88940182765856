import { createCustomAction } from 'typesafe-actions';
import { DeleteWidgetPayload, DeflatedWidget, Widget } from './types';
import constants from './constants';

export const addDeflatedWidget = createCustomAction(constants.ADD_DEFLATED, type => {
    return (payload: DeflatedWidget) => ({ type, payload: payload, recordable: true });
});

export const updateDeflatedWidget = createCustomAction(constants.UPDATE_DEFLATED, type => {
    return (payload: DeflatedWidget) => ({ type, payload: payload, recordable: true });
});

export const addWidget = createCustomAction(constants.ADD, type => {
    return (payload: Widget) => ({ type, payload: payload, recordable: true });
});

export const deleteWidget = createCustomAction(constants.DELETE, type => {
    return (payload: DeleteWidgetPayload) => ({ type, payload: payload, recordable: true });
});
