export function initialize(application) {
    application.inject('route', 'screenSensor', 'service:screen-sensor');
    application.inject('controller', 'screenSensor', 'service:screen-sensor');
    application.inject('component', 'screenSensor', 'service:screen-sensor');
}

export default {
    name: 'screen-sensor',
    initialize
};
