import zone from 'ava-saturation/classes/dimensions/zone';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import segment from 'ava-saturation/classes/dimensions/segment';
import contactDepth from 'ava-saturation/classes/dimensions/contact-depth';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import measureDepth from 'ava-saturation/classes/dimensions/measure-depth';
import DatasetExtender from 'ava-saturation/classes/calculations/dependency-injection/extenders/dataset-extender-pure';
import { lerpWithoutCoefficient, lerp } from 'ava-saturation/utils/lerp';

export const dependencies = {
    functions: {
        isInRange,
        lerp,
        lerpWithoutCoefficient,
        DatasetExtender
    },
    dimensionsIndex: {
        measureDepth,
        tvdss,
        contactDepth,
        zone,
        segment
    }
};

export default function TVDssDatasetExtender(deps, trajectoryDataset) {
    this.datasetExtender = new deps.functions.DatasetExtender(
        deps.dimensionsIndex.tvdss.shortName,
        [deps.dimensionsIndex.measureDepth]
    );

    return {
        name: this.datasetExtender.name,
        datasetExtender: this.datasetExtender,
        isInRange: deps.functions.isInRange,
        lerpWithoutCoefficient: deps.functions.lerpWithoutCoefficient,
        dimensionsIndex: deps.dimensionsIndex,
        _trajectoryDataset: trajectoryDataset,
        meetsDimensionExpectations: function (dimensions) {
            return this.datasetExtender.meetsDimensionExpectations(dimensions);
        },
        isApplicable: function () {
            return this._trajectoryDataset.length !== 0;
        },
        findExtendingRecord: function (value, dataset) {
            if (!dataset)
                return null;

            const targetRange = this._trajectoryDataset.values.reduce((range, point) => {
                range.startPoint = point.md <= value.md ? point : range.startPoint;
                range.endPoint = value.md <= point.md ? (range.endPoint || point) : null;

                return range;
            }, { startPoint: null, endPoint: null });

            if (!targetRange.startPoint || !targetRange.endPoint)
                return null;

            return targetRange;
        },
        applyExtendingRecord: function (value, extendingRecord, dataset) {
            value[this.dimensionsIndex.tvdss.shortName] = this.lerpWithoutCoefficient(
                deps,
                extendingRecord.startPoint.md,
                extendingRecord.endPoint.md,
                extendingRecord.startPoint.tvdss,
                extendingRecord.endPoint.tvdss,
                value[this.dimensionsIndex.measureDepth.shortName]
            );

            const isDatasetUpdated = dataset.dimensions.indexOf(this.dimensionsIndex.tvdss) != -1;
            const action = isDatasetUpdated ? () => { } : dataset.dimensions.push;

            action.call(dataset.dimensions, this.dimensionsIndex.tvdss);
        },
        extend: function (value, dataset) {
            if (this.datasetExtender.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }
    };
}
