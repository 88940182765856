import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import SaturationConceptsController from 'ava-saturation/controllers/saturation-concepts';
import DeleteConceptMixin from 'ava-saturation/mixins/delete-concept-mixin';
import ConceptService from 'ava-saturation/services/concept';
import { File } from 'ember-file-upload';

export default class SaturationConceptsIndexController extends Controller.extend(DeleteConceptMixin, {
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    @controller saturationConcepts: SaturationConceptsController;
    @service('concept') conceptService: ConceptService;

    @(computed.oneWay('features.ExportManifest'))
    exportManifestEnabled: boolean;

    selectedSaturationConcept: any = null;

    @action
    editSaturationConcept(concept: any) {
        set(this, 'selectedSaturationConcept', concept);
        this.transitionToRoute('saturation-concepts.edit', concept.id);
    }

    @action
    createConcept() {
        this.transitionToRoute('saturation-concepts.new');
    }

    @action
    importConcept(file: File) {
        this.conceptService.importManifest(file);
    }

    @action
    exportConceptManifest(concept: any) {
        this.conceptService.exportManifest(concept);
    }

    @action
    exportConceptToPDF(concept: any) {
        this.conceptService.exportToPdf(concept);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'saturation-concepts.index': SaturationConceptsIndexController;
    }
}
