import { flatMap, isNil, isNull } from 'lodash';
import regression from 'regression';

export default class RegressionConstantsCalculator {
    xKey;
    yKey;
    constructor(xKey, yKey) {

        if (isNull(xKey) || isNil(xKey))
            throw 'A key over which the dataSet will be merged needs to be provided';

        if (isNull(yKey) || isNil(yKey))
            throw 'A key over which the dataSet will be merged needs to be provided';

        this.xKey = xKey;
        this.yKey = yKey;
    }

    calculate(dataSets) {
        let combinedDataSets = flatMap(dataSets, ds => ds.values);
        let xKey = this.xKey;
        let yKey = this.yKey;

        let points = combinedDataSets
            .filter(p =>
                isNaN(Number(p[xKey])) == false &&
                isNaN(Number(p[yKey])) == false &&
                p[xKey] != 0 &&
                p[yKey] != 0
            )
            .map(point => {
                let xKeyValue = point[xKey],
                    yKeyValue = point[yKey];

                return [xKeyValue, yKeyValue];
            });

        var result = regression.power(points, {
            precision: 4
        });

        return {
            a: result.equation[0],
            b: result.equation[1],
            string: result.string
        };
    }
}
