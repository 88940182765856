import Component from '@ember/component';
import { computed } from '@ember/object';

export default class SidenavBandItem extends Component {
    referenceId: string;

    @computed('referenceId')
    get reference(): string {
        let id = this.get('referenceId').replace(/\//g, '-');
        return id;
    }
}