import { dimensionIndexLookup } from 'ava-saturation/classes/dimensions/all';
import facies from 'ava-saturation/classes/dimensions/facies';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import { InputDictionary, WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IWell from 'ava-saturation/interfaces/well-reference';
import { flatMap } from 'lodash';

const supplementaryDatasetDimension = {
    [facies.shortName]: facies
};

export default class CalculationSetGenerator {
    static generateDatasets(context: WellModelingContextCollection, wells: IWell[]) {
        const wellMonikerStrings = Object.keys(context.byMoniker);
        const wellsOfInterest = wells.filter(well => wellMonikerStrings.find(wms => wms === well.moniker.string));

        return wellsOfInterest.reduce((acc, curr) => {
            // create the well trajectory dataset
            let trajectory = this.trajectoryCalculationSet(curr);
            // then call generateSetsFromState with the trajectory
            // it will populate every dimension described in the inputDictionary for the given wellContext
            return acc.concat(this.generateSetsFromState(context.byMoniker[curr.moniker.string], { calculationSets: [[trajectory]], dimension: null, wellContext: curr }));
        }, [] as CalculationSet[][]);
    }

    static produceCalculationSets({ logMonikers, dimension, wellContext, inputDictionary }: { logMonikers: IMoniker[], dimension: IDimension, wellContext: IWell, inputDictionary: InputDictionary }) {
        const calculationSets = logMonikers.map(logMoniker => {
            return [
                this.trajectoryCalculationSet(wellContext),
                {
                    monikerDictionary: { wellMoniker: wellContext.moniker, wellLogMoniker: logMoniker },
                    dimension,
                    isSupplementary: supplementaryDatasetDimension[dimension.shortName] !== undefined
                } as CalculationSet
            ];
        });

        if (!inputDictionary)
            return calculationSets;

        return this.generateSetsFromState(inputDictionary, { calculationSets, dimension, wellContext });
    }

    static trajectoryCalculationSet(wellContext: IWell) {
        return {
            monikerDictionary: { wellMoniker: wellContext.moniker, trajectoryMoniker: wellContext.trajectoryMoniker },
            dimension: tvdss,
            isSupplementary: false
        } as CalculationSet;
    }

    static generateSetsFromState(inputDictionary: InputDictionary, { calculationSets, dimension, wellContext }: { calculationSets: CalculationSet[][], dimension: IDimension | null, wellContext: IWell }) {
        // inputDictionary is per Well
        return Object.keys(inputDictionary.byDimensionKey).reduce((aggregate: CalculationSet[][], dimensionKey) => {
            if (dimension && dimension.shortName === dimensionKey)
                return aggregate;
            // adds all dimenstions from the input dictionary to the corresponding CalculationSet
            let result = flatMap(inputDictionary.byDimensionKey[dimensionKey].allMonikers, (moniker: IMoniker) => {
                return aggregate.map(calculationSet => {
                    let set = calculationSet.slice();
                    set.push({
                        monikerDictionary: { wellMoniker: wellContext.moniker, wellLogMoniker: moniker },
                        dimension: dimensionIndexLookup(dimensionKey),
                        isSupplementary: supplementaryDatasetDimension[dimensionKey] !== undefined
                    });

                    return set;
                });
            });

            return result.length > 0 ? result : aggregate;
        }, calculationSets);
    }
}
