import Component from '@ember/component';
import { computed, action } from '@ember/object';

export default class AvaHrefLink extends Component {
    disabled: boolean;
    @computed('disabled')
    get isDisabled() {
        return this.get('disabled');
    }

    @action
    noop() {/**/}
}
