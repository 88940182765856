import {
    PcHeightGasCalculator,
    dependencies as calculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/pc-height-gas-calculator-pure';
import DatasetCalculator from 'ava-saturation/classes/calculations/dependency-injection/calculators/dataset-calculator-pure';
import { capillaryPressureRes } from 'ava-import/classes/dimensions/capillary-pressure';
import { gasGradient, waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { pcHeightGas } from 'ava-import/classes/dimensions/capillary-pressure-height';

export const dependencies = {
    functions: {
        ...calculatorDependencies.functions,
        PcHeightGasCalculator,
        DatasetCalculator
    },
    dimensionsIndex: {
        ...calculatorDependencies.dimensionsIndex,
        capillaryPressureRes,
        gasGradient,
        waterGradient,
        pcHeightGas
    }
};

export default function PcHeightGasDatasetCalculator(deps) {
    this.datasetCalculator = new deps.functions.DatasetCalculator(
        deps.dimensionsIndex.pcHeightGas,
        [deps.dimensionsIndex.capillaryPressureRes, deps.dimensionsIndex.gasGradient, deps.dimensionsIndex.waterGradient],
        new deps.functions.PcHeightGasCalculator(deps)
    );

    return {
        producedDimension: this.datasetCalculator.producedDimension,
        datasetCalculator: this.datasetCalculator,
        meetsExpectations: function (dimensions) {
            return this.datasetCalculator.meetsExpectations(dimensions);
        },
        apply: function (value, dataset) {
            this.datasetCalculator.apply(value, dataset);
        }
    };
}