import { computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import configuration from 'ava-saturation/config/environment';
import IObjectStore from 'ava-saturation/interfaces/object-store';

export default class TelemetryService extends Service {
    @service router: RouterService;
    @service session: any;
    @service features: any;
    @service objectStore: IObjectStore;
    @(computed.oneWay('features.telemetry'))
    enabled: boolean;

    @computed()
    get appInsightsPromise(): Promise<any> {
        const self = this;
        return new Promise((resolve) => {
            const appInsightsScript = document.createElement('script');

            appInsightsScript.onload = () => {
                const appInsights = window.appInsights;
                if (appInsights)
                    appInsights.queue.push(() => {
                        appInsights.context.application.ver = 'Ava Saturation';
                        appInsights.context.application.build = this.session.applicationVersion;

                        appInsights.context.addTelemetryInitializer((envelope: any) => {
                            const data = envelope.data.baseData;
                            data.properties = data.properties || {};
                            data.properties.customerTenantId = this.session.get('data.authenticated.customerTenantId');
                            data.properties.pluginVersion = this.objectStore.pluginVersion;
                            data.properties.petrelVersion = this.objectStore.petrelVersion;
                            data.properties.saturationVersion = configuration.APP.version;
                            data.properties.currentRoute = this.router.currentRouteName;
                        });

                        this.router.on('routeDidChange', this.trackPageView.bind(self));
                        resolve(appInsights);
                    });
                else
                    resolve({});
            };
            appInsightsScript.src = `${configuration.rootURL}application-insights.js`;
            document.head.appendChild(appInsightsScript);
        });
    }

    async init() {
        super.init();

        this.features.on('telemetry', async (enable: boolean) => {
            const appInsights = await this.appInsightsPromise;
            appInsights.config.disableTelemetry = !enable;
            this.trackPageView();
        });

        this.trackPageView();
    }

    async trackEvent() {
        if (this.enabled) {
            const appInsights = await this.appInsightsPromise;
            appInsights.trackEvent(...arguments);
        }
    }

    async trackPageView() {
        if (this.enabled) {
            const appInsights = await this.appInsightsPromise;
            appInsights.context.operation.name = this.router.currentRouteName;
            appInsights.trackPageView(this.router.currentRouteName);
        }
    }
}

declare module '@ember/service' {
    interface Registry {
        'telemetry': TelemetryService;
    }
}
