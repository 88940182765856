import AvaAreaItem from 'ava-saturation/components/ava-area-item';

/**
 * @class PaperBottomNavigationAction
 * @extends AvaAreaItem
 */
export default AvaAreaItem.extend({
    tagName: '',
    showText: true
});
