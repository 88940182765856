import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    'sourceMonikerString': validator('presence', {
        presence: true,
        ignoreBlank: false
    })
});

const ModelChanges = buildModelChanges(
    ['sourceMonikerString', 'modelId'],
    {
        'sourceMonikerString': { value: true },
        'modelId': { value: true }
    });

export default Fragment.extend(Validations, ModelChanges, {
    'sourceMonikerString': DS.attr('string', { defaultValue: null }),
    'modelId': DS.attr('string', { defaultValue: null })
});
