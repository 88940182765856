import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as actions from './actions';
import { ModelArea, ModelAreaState } from './types';
import { fromPairs, map } from 'lodash';

export type ModelAreaActionType = ActionType<typeof actions> | ActionType<typeof saturationConceptActions>;

function modelAreas(state: ModelArea[] = [], action: ModelAreaActionType) {
    switch (action.type) {
        case getType(actions.add):
            return [
                ...state, {
                    ...action.payload
                }
            ];

        case getType(actions.removeModels):
            return state.map(a => a.id === action.payload.id ? { ...a, monikers: a.monikers.filter((m) => !action.payload.monikers.includes(m)) } : a);

        case getType(actions.updateName):
            return state.map(a => a.id === action.payload.id ? { ...a, name: action.payload.name } : a);

        case getType(actions.updateType):
            return state.map(a => a.id === action.payload.id ? { ...a, type: action.payload.type } : a);

        case getType(actions.deleteArea): {
            return state.filter(a => a.id !== action.payload.id);
        }
        default:
            return state;
    }
}

export function modelAreasBySid(state: Record<string, ModelArea[]> = {}, action: ModelAreaActionType) { // addModels
    switch (action.type) {
        case getType(actions.add):
        case getType(actions.removeModels):
        case getType(actions.updateName):
        case getType(actions.updateType):
        case getType(actions.deleteArea):
            return {
                ...state,
                [action.payload.sid]: modelAreas(state[action.payload.sid], action)

            };
        case getType(saturationConceptActions.createConcept):
            return {
                ...state,
                [action.payload.id]: modelAreas(state[action.payload.id], action)
            };

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        case getType(saturationConceptActions.addConcepts): {
            const actionsDict = fromPairs(map(action.payload, a => [a.id, state[a.id] ? [...state[a.id]] : []]));
            return { ...actionsDict };
        }

        default:
            return state;
    }
}

export default combineReducers<ModelAreaState>({
    bySid: modelAreasBySid
});
