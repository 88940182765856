import Component from '@ember/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import facies from 'ava-saturation/classes/dimensions/facies';
import { IWellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import IDataset from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';
import s2ab from 'ava-saturation/utils/s2ab';
import fileSaver from 'file-saver';
import CalculationStore from 'reference-store/services/calculation-store';
import RSVP from 'rsvp';
import XLSX from 'xlsx';

export default abstract class CalculationProvider extends Component {
    @service calculationStore: CalculationStore;

    constructor() {
        super(...arguments);

        set(this, 'datasets', []);
    }

    didInsertElement() {
        const calculationSets = this.get('calculationSets');

        if (calculationSets && calculationSets.length > 0) {
            this.actions.onCalculationSetsChanged.call(this, {
                updates: calculationSets,
                filter: () => false
            });
        }
    }

    wellContext: IWellContextCollection;
    gridContext: IGridModelingContext;
    calculationSets: CalculationSet[][];
    datasets: {
        calculationSets: CalculationSet[];
        dataset: any;
    }[];
    isPending: boolean;

    abstract get calculator(): { executor: Function, dependencies: { functions: Record<string, Function>, dimensionsIndex: Record<string, IDimension> } };
    abstract get metadataDimensions(): IDimension[];
    abstract processOutputDataset(dataset: IDataset<Record<string, number>>): IDataset<Record<string, number>>;

    @action
    onCalculationSetsChanged({ updates, filter }: { updates: CalculationSet[][], filter: (calculationSets: CalculationSet[]) => boolean }) {
        set(this, 'isPending', true);
        const promises = updates.map((combinations) => {
            if (combinations.length === 2 && combinations.any(c => c.dimension === facies))
                return new Promise((resolve) => resolve(undefined));

            return this.calculationStore.run(
                this.calculator.executor,
                this.calculator.dependencies,
                combinations,
                [this.gridContext.toPlainObject(), this.wellContext.toPlainObject()]
            ).then((dataset) => {
                let newDataset = {
                    calculationSets: combinations,
                    dataset: this.processOutputDataset(dataset)
                };

                return newDataset;
            }, function (error) {
                console.log(error);
                // use the ava-logger
            });
        });

        RSVP.allSettled(promises).then((all: any[]) => {
            let datasetsForRemoval = this.get('datasets').filter(ds => filter(ds.calculationSets));
            this.get('datasets').removeObjects(datasetsForRemoval);

            if (all.any(a => a.value))
                this.get('datasets').addObjects(all.filter(ds => ds.value).map(ds => ds.value));

            this.calculationSets.clear();
            this.get('datasets').forEach(ds => this.calculationSets.push(ds.calculationSets));
            set(this, 'isPending', false);
        });
    }

    @action
    export(title: string | undefined) {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Datasets Export',
            Author: 'Ava Saturation',
            CreatedDate: new Date()
        };

        this.datasets.forEach((ds: { calculationSets: CalculationSet[], dataset: IDataset<Record<string, any>> }, i: number) => {
            const sheetName = `Dataset ${i + 1}`;
            wb.SheetNames.push(sheetName);

            const metadataDimensions = this.metadataDimensions;
            const dimensions = ds.dataset.dimensions.concat(metadataDimensions);
            const wsHeader = dimensions.map(d => {
                return d.unitName ? `${d.displayName} [${d.unitName}]` : d.displayName;
            });

            const wsData = ds.dataset.values.map(v => {
                return dimensions.reduce((row: Array<string>, dimension) => {
                    row.push(v[dimension.shortName]);
                    return row;
                }, []);
            });

            wsData.insertAt(0, wsHeader);

            const ws = XLSX.utils.aoa_to_sheet(wsData);
            wb.Sheets[sheetName] = ws;
        });

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        fileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${title || 'Ava Saturation Dataset'} Export.xlsx`);
    }
}