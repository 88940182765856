import { computed } from '@ember/object';
import { inject } from '@ember/service';
import Mixin from '@ember/object/mixin';

const ReferenceAvailability = Mixin.create({
    objectStore: inject('object-store'),

    isDataStorePresent: computed('objectStore.availableStores.[]', function () {
        return this.objectStore.availableStores.length > 0;
    }),

    beforeModel() {
        if (this.isDataStorePresent) {
            return this._super(...arguments);
        }

        this.transitionTo('no-petrel');
    }
});

export default ReferenceAvailability;
