import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IPermeabilityProperty from 'ava-saturation/interfaces/permeability-property-reference';

export default class PermeabilityPropertyReferenceType extends ReferenceObjectType {
    readonly id = 143;
    readonly name = 'Permeability';
    readonly typeAliases = [];
    subTypeAliases = [];
}

export const permeabilityPropertyReferenceType = new PermeabilityPropertyReferenceType();

export class PermeabilityPropertyReferenceParser extends SingleReferenceParser<IPermeabilityProperty> {
    parse(object: any): IPermeabilityProperty {
        let reference = super.parse(object);
        const templateMoniker = this.parseTemplateMoniker(object);
        return {
            ...reference,
            type: permeabilityPropertyReferenceType,
            templateMoniker
        };
    }
}

export class PermeabilityPropertyCollectionParser extends ReferenceParser<Array<IPermeabilityProperty>> {
    parse(object: any): Array<IPermeabilityProperty> {
        return referenceCollectionParser<IPermeabilityProperty>(this.appendGridMoniker(object, 'Properties'), new PermeabilityPropertyReferenceParser(this.origin, this.version));
    }
}
