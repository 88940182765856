import { ProportionalChartBase, ChartQuadrant } from 'ava-saturation/components/charting/proportional-chart-base';
import { ChartSeries2D, LineSeries, PointSeries } from 'ava-saturation/components/charting/chart-series';
import { computed, action, set } from '@ember/object';
import { trimTrailingZeros } from 'ava-saturation/utils/model-helpers';
import { ChartAxisOptions } from 'ava-saturation/components/charting/chart-axis-options';
import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';
import d3 from 'd3';
import d3tip from 'd3-tip';
import { ChartLegendItemsGroup, ChartLegendPosition } from './chart-legend';

export default class MultiSeriesChart extends ProportionalChartBase {
    _series: ChartSeries2D[];
    xAxisOptions: ChartAxisOptions;
    yAxisOptions: ChartAxisOptions;
    titleToAxisDistance: number = 40;

    chartWrapper: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>;
    svg: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>;
    chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>;

    static counter: number = 1;
    chartId: string;
    chartAreaId: string;
    clipId: string;

    legendData: ChartLegendItemsGroup[];
    fixedLegendPosition: ChartLegendPosition | undefined;
    fixedLegendWidthInPercentage: number | undefined;

    constructor(properties?: object | undefined) {
        super(properties);

        const count = MultiSeriesChart.counter++;
        this.chartId = `multiSeriesChart${count}`;
        this.chartAreaId = `multiSeriesChartArea${count}`;
        this.clipId = `multiSeriesChartClip${count}`;

        if (!this.xAxisOptions)
            this.xAxisOptions = new ChartAxisOptions();
        if (!this.yAxisOptions)
            this.yAxisOptions = new ChartAxisOptions();
        if (!this.series)
            this.series = [];
    }

    @computed('_series')
    get series(): ChartSeries2D[] {
        return this._series;
    }
    set series(series: ChartSeries2D[]) {
        if (!series) this.set('_series', []);
        else this.set('_series', series);
    }

    @computed('screenSensor.width', 'container', 'container.clientWidth', 'forceResize', 'margin', 'predefinedWidth')
    get width() {
        if (this.predefinedWidth) return this.predefinedWidth;
        if (this.container == null)
            return 0;
        return this.container.clientWidth;
    }

    @computed('width', 'legendWidth', 'legendPosition')
    get svgWidth() {
        if (this.legendPosition === ChartLegendPosition.left ||
            this.legendPosition === ChartLegendPosition.right) {
            return this.width * (1 - this.legendWidthInPercentage / 100);
        }
        return this.width;
    }

    @computed('height')
    get svgHeight() {
        return this.height;
    }

    @computed('svgWidth', 'margin.{left,right}')
    get svgChartWidth() {
        const width = this.svgWidth - this.margin.left - this.margin.right;
        return width > 0 ? width : 0;
    }

    @computed('svgHeight', 'margin.{top,bottom}')
    get svgChartHeight() {
        const height = this.svgHeight - this.margin.top - this.margin.bottom;
        return height > 0 ? height : 0;
    }

    @computed('xAxisOptions.type', 'series.@each.points', 'quadrant', 'svgChartWidth')
    get xScale() {
        let range = [0, 1];
        if (this.series) {
            const domain = this.series.map(s => s.domainX);
            const min = d3.min(domain, (p: { min: number, max: number }) => p.min);
            const max = d3.max(domain, (p: { min: number, max: number }) => p.max);
            range = [min ? min : 0, max ? max : 0];

            if (!isNaN(this.xAxisOptions.pinMin)) {
                range[0] = this.xAxisOptions.pinMin;
            }
            if (!isNaN(this.xAxisOptions.pinMax)) {
                range[1] = this.xAxisOptions.pinMax;
            }
        }

        let scale = this.xAxisOptions.type === ContinuousNumericScaleType.Logarithmic ? d3.scaleLog() : d3.scaleLinear();

        return scale
            .domain(this.xAxisOptions.inverted ? range.reverse() : range)
            .range([0, this.svgChartWidth]);
    }

    @computed('yAxisOptions.type', 'series.@each.points', 'quadrant', 'svgChartHeight')
    get yScale() {
        let range = [0, 1];
        if (this.series) {
            const domain = this.series.map(s => s.domainY);
            const min = d3.min(domain, (p: { min: number, max: number }) => p.min);
            const max = d3.max(domain, (p: { min: number, max: number }) => p.max);
            range = [min ? min : 0, max ? max : 0];

            if (!isNaN(this.yAxisOptions.pinMin)) {
                range[0] = this.yAxisOptions.pinMin;
            }
            if (!isNaN(this.yAxisOptions.pinMax)) {
                range[1] = this.yAxisOptions.pinMax;
            }
        }

        let scale = this.yAxisOptions.type === ContinuousNumericScaleType.Logarithmic ? d3.scaleLog() : d3.scaleLinear();

        return scale
            .domain(this.yAxisOptions.inverted ? range.reverse() : range)
            .range([this.svgChartHeight, 0]);
    }

    @computed('xScale', 'svgChartHeight')
    get xAxis() {
        return this.createXAxis(this.xScale);
    }

    @computed('yScale', 'svgChartWidth')
    get yAxis() {
        return this.createYAxis(this.yScale);
    }

    @computed()
    get tip() {
        return d3tip()
            .attr('class', 'd3-tip')
            .offset([-5, 0])
            .html((d: any) => d.tipData(d));
    }

    @computed('ratio', 'legendData', 'fixedLegendPosition')
    get legendPosition(): ChartLegendPosition {
        if (!this.legendData)
            return ChartLegendPosition.hidden;

        if (this.fixedLegendPosition)
            return this.fixedLegendPosition;

        return this.ratio <= 0.8 ? ChartLegendPosition.right : ChartLegendPosition.bottom;
    }

    @computed('legendPosition', 'fixedLegendWidth')
    get legendWidthInPercentage(): number {
        if (this.fixedLegendWidthInPercentage)
            return this.fixedLegendWidthInPercentage;
        if (this.legendPosition === ChartLegendPosition.left ||
            this.legendPosition === ChartLegendPosition.right)
            return 20;
        return 100;
    }

    onLegendItemClick: (item: any) => void;

    @action
    onItemClick(item: any) {
        let series = this.series.find(s => s.id === item.id);
        if (series) {
            set(series, 'hidden', !item.selected);
        }

        if (this.onLegendItemClick)
            this.onLegendItemClick(item);
    }

    createXAxis(xScale: any) {
        if (this.quadrant === ChartQuadrant.q1 || this.quadrant === ChartQuadrant.q2) {
            if (xScale) {
                return d3.axisBottom(xScale)
                    .ticks(this.svgChartWidth / 100, 'r')
                    .tickSizeInner(-this.svgChartHeight)
                    .tickSizeOuter(0);
            }
        } else {
            if (xScale) {
                return d3.axisTop(xScale)
                    .ticks(this.svgChartWidth / 100, 'r')
                    .tickSizeInner(-this.svgChartHeight)
                    .tickSizeOuter(0);
            }
        }
        return undefined;
    }

    createYAxis(yScale: any) {
        if (this.quadrant === ChartQuadrant.q1 || this.quadrant === ChartQuadrant.q4) {
            if (yScale) {
                return d3.axisLeft(yScale)
                    .ticks(this.svgChartHeight / 100, 'r')
                    .tickSizeInner(-this.svgChartWidth)
                    .tickSizeOuter(0);
            }
        } else {
            if (yScale) {
                return d3.axisRight(yScale)
                    .ticks(this.svgChartHeight / 100, 'r')
                    .tickSizeInner(-this.svgChartWidth)
                    .tickSizeOuter(0);
            }
        }
        return undefined;
    }

    placeXAxis(xAxis: any) {
        if (xAxis) {
            const isBottom = this.quadrant === ChartQuadrant.q1 || this.quadrant === ChartQuadrant.q2;
            let toX = 0 + this.margin.left;
            let toY = (isBottom ? this.svgChartHeight : 0) + this.margin.top;
            this.svg.select('g.x.axis')
                .call(xAxis)
                .attr('transform', 'translate(' + toX + ',' + toY + ')')
                .attr('font-size', '14')
                .selectAll('.tick > line')
                .attr('opacity', 0.1);

            toX = this.svgChartWidth / 2 + this.margin.left;
            toY = (isBottom ? toY + this.titleToAxisDistance : toY - this.titleToAxisDistance);
            this.svg.selectAll('.x.axis.text').remove();
            this.svg.append('text')
                .classed('x axis text', true)
                .attr('x', toX)
                .attr('y', toY)
                .attr('font-size', '14')
                .attr('text-anchor', 'middle')
                .text(this.xAxisOptions.title);
            this.svg.select('g.x.axis').selectAll('.tick > text').each(function () {
                if (d3.select(this).text()) {
                    d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
                }
            });
        }
    }

    placeYAxis(yAxis: any) {
        if (yAxis) {
            const toRight = this.quadrant === ChartQuadrant.q2 || this.quadrant === ChartQuadrant.q3;
            let toX = (toRight ? this.svgChartWidth : 0) + this.margin.left;
            let toY = 0 + this.margin.top;
            this.svg.select(`g.y.axis`)
                .call(yAxis)
                .attr('transform', 'translate(' + toX + ',' + toY + ')')
                .attr('font-size', '14')
                .selectAll('.tick > line')
                .attr('opacity', 0.1);

            toX = (toRight ? - (toX + this.titleToAxisDistance) : toX - this.titleToAxisDistance);
            toY = (toRight ? this.svgChartHeight / 2 : - this.svgChartHeight / 2);
            let rotation = toRight ? '90' : '-90';
            this.svg.selectAll('.y.axis.text').remove();
            this.svg.append('text')
                .classed('y axis text', true)
                .attr('x', toY)
                .attr('y', toX)
                .attr('font-size', '14')
                .attr('text-anchor', 'middle')
                .attr('transform', 'rotate(' + rotation + ')')
                .text(this.yAxisOptions.title);
            this.svg.select('g.y.axis').selectAll('.tick > text').each(function () {
                if (d3.select(this).text()) {
                    d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
                }
            });
        }
    }

    addXAxisZones(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>,
        scale: d3.ScaleContinuousNumeric<number, number>) {

        if (this.yAxisOptions && this.yAxisOptions.zones) {

            chart.selectAll('.xAxisZone').remove();

            this.yAxisOptions.zones.forEach(zone => {
                chart.append('path')
                    .classed('xAxisZone', true)
                    .datum([{ y: 0 }, { y: this.svgChartHeight }])
                    .attr('fill', `${zone.color}`)
                    .attr('stroke', `${zone.color}`)
                    .attr('stroke-width', 1)
                    // @ts-ignore
                    .attr('d', d3.area()
                        // @ts-ignore
                        .y((d) => d.y)
                        .x0(scale(zone.from))
                        .x1(scale(zone.to))
                    );
            });
        }
    }

    addYAxisZones(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>,
        scale: d3.ScaleContinuousNumeric<number, number>) {

        if (this.yAxisOptions && this.yAxisOptions.zones) {

            chart.selectAll('.yAxisZone').remove();

            this.yAxisOptions.zones.forEach(zone => {
                chart.append('path')
                    .classed('yAxisZone', true)
                    .datum([{ x: 0 }, { x: this.svgChartWidth }])
                    .attr('fill', `${zone.color}`)
                    .attr('fill-opacity', `0.1`)
                    .attr('stroke-width', 0)
                    // @ts-ignore
                    .attr('d', d3.area()
                        // @ts-ignore
                        .x((d) => d.x)
                        .y0(scale(zone.from))
                        .y1(scale(zone.to))
                    );
            });
        }
    }

    didInsertElement() {
        super.didInsertElement();
        // can add a one time computed property for the container change
        // @ts-ignore
        const container = d3.select(this.element);
        this.set('chartWrapper', container.select(`#${this.chartId}`));
        this.set('svg', this.chartWrapper.select('svg'));
        this.set('chart', this.svg.select('g'));
    }

    didRender() {

        this.chart.call(this.tip);

        this.svg.select(`#${this.clipId}`).remove();
        // Add a clipPath: everything out of this area won't be drawn.
        this.svg.append('defs').append('SVG:clipPath')
            .attr('id', this.clipId)
            .append('SVG:rect')
            .attr('width', this.svgChartWidth)
            .attr('height', this.svgChartHeight)
            .attr('x', 0)
            .attr('y', 0);

        this.chart.attr('clip-path', `url(#${this.clipId})`);

        let zoom = d3.zoom()
            .filter(() => d3.event.ctrlKey)
            .scaleExtent([.5, 20])  // This control how much you can unzoom (x0.5) and zoom (x20)
            .extent([[0, 0], [this.svgChartWidth, this.svgChartHeight]])
            .on('zoom', () => {
                this.updateChart(d3.event.transform);
            });

        this.svg.call(zoom)
            .on('wheel', () => {
                if (d3.event.ctrlKey) {
                    d3.event.preventDefault();
                }
            });

        // @ts-ignore
        this.updateChart(d3.zoomTransform(this.svg.node()));
    }

    updateChart(transform: any) {
        var newX = this.xAxisOptions.allowZoom ? transform.rescaleX(this.xScale) : this.xScale;
        var newY = this.yAxisOptions.allowZoom ? transform.rescaleY(this.yScale) : this.yScale;

        // update axes with these new boundaries
        this.placeXAxis(this.createXAxis(newX));
        this.placeYAxis(this.createYAxis(newY));

        if (this.quadrant === ChartQuadrant.q1 || this.quadrant === ChartQuadrant.q2) {
            this.svg.select('g.x.axis').selectAll('.tick > text').attr('dy', '16px');
        } else {
            this.svg.select('g.x.axis').selectAll('.tick > text').attr('dy', '-4px');
        }

        // clear the old series - cannot rely on the series to clean the artefacts
        // because series can be removed and won't be in the collection anymore
        PointSeries.clearAll(this.chart);
        LineSeries.clearAll(this.chart);

        this.addXAxisZones(this.chart, newX);
        this.addYAxisZones(this.chart, newY);

        if (this.series) {
            // update circle position
            this.series.forEach((s) => {
                s.draw(this.chart, newX, newY, this.tip);
            });
        }
    }

    willDestroy() {
        this.tip.destroy();
        this._super(...arguments);
    }

    @action
    resize() {
        set(this, 'forceResize', !this.forceResize);
    }
}
