import {
    capillaryPressureLab,
    capillaryPressureRes
} from 'ava-import/classes/dimensions/capillary-pressure';
import { iftLab, iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { contactAngleLab, contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';

export const dependencies = {
    functions: {
    },
    dimensionsIndex: {
        capillaryPressureLab,
        capillaryPressureRes,
        iftLab,
        iftReservoir,
        contactAngleLab,
        contactAngleRes,
    }
};

export function PcReservoirCalculator(deps) {
    this.dimensionsIndex = deps.dimensionsIndex;

    return {
        calculate: function(value) {
            if (isNaN(Number(value[this.dimensionsIndex.capillaryPressureRes.shortName])) == false)
                return value[this.dimensionsIndex.capillaryPressureRes.shortName];

            let pcLab = Number(value[this.dimensionsIndex.capillaryPressureLab.shortName]);
            if (isNaN(pcLab))
                return null;

            let iftLabValue = Number(value[this.dimensionsIndex.iftLab.shortName]),
                iftRes = Number(value[this.dimensionsIndex.iftReservoir.shortName]);
            if (isNaN(iftLabValue) || isNaN(iftRes))
                return null;

            let caLab = Number(value[this.dimensionsIndex.contactAngleLab.shortName]),
                caRes = Number(value[this.dimensionsIndex.contactAngleRes.shortName]);
            if (isNaN(caLab) || isNaN(caRes))
                return null;

            const ratio = (iftLabValue * Math.cos(caLab));

            if (ratio == 0)
                return NaN;

            return pcLab * (iftRes * Math.cos(caRes) / ratio);
        }.bind(this)
    };
}