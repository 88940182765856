import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { defineProperty } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import configuration from 'ava-saturation/config/environment';
import IObjectStore from 'ava-saturation/interfaces/object-store';

export default class AvaHeader extends Component {
    @service session: any;
    @service objectStore: IObjectStore;

    version = configuration.APP.version;
    devVersion = configuration.APP.devVersion;

    menuOpen: boolean;
    cancelIcon = 'times-circle';
    cancelColor = 'layout-color-error';
    warningIcon = 'exclamation-triangle';
    warningIconColor = 'layout-color-warning';
    petrelVersion: string;
    pluginVersion: string;

    onToggleMenu: (state: boolean) => void;

    constructor() {
        super(...arguments);
        defineProperty(this, 'petrelVersion', oneWay('objectStore.petrelVersion'));
        defineProperty(this, 'pluginVersion', oneWay('objectStore.pluginVersion'));
    }

    @computed()
    get showDevVersion(): boolean {
        return !!localStorage.getItem('SHOW_DEV_VERSION');
    }

    @computed('session.applicationLicense', 'session.licenseStatus')
    get licenseStatusIcon(): string {
        let iconString = '';
        if (this.session.licenseStatus === 'not-started' || this.session.licenseStatus === 'about-to-expire') {
            iconString = this.warningIcon;
        }
        if (this.session.licenseStatus === 'expired' || this.session.licenseStatus === 'no-license') {
            iconString = this.cancelIcon;
        }
        return iconString;
    }

    @computed('session.applicationLicense', 'session.licenseStatus')
    get isLicenseExpired(): boolean {
        return this.session.licenseStatus === 'expired';
    }

    @computed('licenseStatusIcon')
    get licenseStatusColor(): string {
        if (this.licenseStatusIcon === this.warningIcon) {
            return this.warningIconColor;
        } else if (this.licenseStatusIcon === this.cancelIcon) {
            return this.cancelColor;
        } else {
            return '';
        }

    }

    @computed('licenseStatusColor', 'licenseStatusIcon')
    get showStatusIcon(): boolean {
        return !!this.licenseStatusColor && !!this.licenseStatusIcon;
    }

    @action
    toggleMenu() {
        if (this.onToggleMenu)
            this.onToggleMenu(!this.menuOpen);
    }

    @action
    refresh() {
        window.location.reload(true);
    }

    @action
    signOut() {
        this.session.invalidate();
    }
}
