import isPorosityPointValid from './log-filters/is-porosity-point-valid';
import isWaterSaturationPointValid from './log-filters/is-water-saturation-point-valid';

export default (point) => {
    if(!isPorosityPointValid(point))
        return;

    if(!isWaterSaturationPointValid(point))
        return;

    point.bvw = point.por * point.sw;
};