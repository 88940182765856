// import { uniq } from 'lodash';
import { parseVersions, extractActions, parsePatches } from 'ava-saturation/classes/actions';

export const SID_PLACEHOLDER = '<sid>';

const uuidTester = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/ig;

export const obfuscateActions = (actions: any[]) => actions.map((a, i) => {
    let result = {
        ...a,
        payload: {
            ...a.payload,
            sid: a.payload.sid
        },
        order: i
    };
    delete (result.patchId);
    delete (result.versionId);
    delete (result.time);
    delete (result.id);
    return result;
});

export const replacer = () => {
    let keys: Record<string, string> = {};
    let count = 0;

    return function (key: string, value: string | object | null) {
        if (key === 'sid') {
            return SID_PLACEHOLDER;
        } else if (typeof (value) === 'string') {
            const found = uuidTester.exec(value);
            if (found) {
                if (!keys[found[0]]) {
                    keys[found[0]] = `<uuid:${count++}>`;
                }
                return value.replace(uuidTester, keys[found[0]]);
            }
        }

        return value;
    };
};

export default class ConceptExporter {

    _concept: any;
    _versions: [];
    _patches: [];
    _additionalActions: [];

    constructor(concept: any, versions: [], patches: [], additionalActions: []) {
        this._concept = concept;
        this._versions = versions;
        this._patches = patches;
        this._additionalActions = additionalActions;
    }

    export = (): string => {
        const exported = {
            concept: {
                id: SID_PLACEHOLDER,
                generalInformation: { ...this._concept.generalInformation },
                metadata: { ...this._concept.metadata }
            },
            actions: obfuscateActions([...extractActions(parseVersions(this._versions), parsePatches(this._patches)), ...this._additionalActions])
        };
        const scString = JSON.stringify(exported, replacer(), '    ');

        return scString;
    }
}
