import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import ScatterPlot from 'ava-saturation/components/scatter-plot';
import { regressionLineScatterPlotDefinition } from 'ava-saturation/components/widgets-new/plot';
import d3 from 'd3';
import { isArray } from 'lodash';

export default class RegressionLineScatterPlot extends ScatterPlot {
    @service screenSensor: any;
    lineValues: Record<string, any>[];

    constructor() {
        super(...arguments);

        // @ts-ignore
        set(this, 'definition', regressionLineScatterPlotDefinition);
    }

    @computed('lineValues', 'xIndex', 'yIndex', 'zIndex')
    get lineData(): Record<string, any>[] {
        const values = this.lineValues;
        const xIndex = this.xIndex;
        const yIndex = this.yIndex;
        const zIndex = this.zIndex;

        const data = values
            .reduce((aggr: Array<Record<string, any>>, v: Record<string, any>) => {
                const x = v[xIndex];
                const y = v[yIndex];
                const z = v[zIndex];

                if (!x || !isFinite(x) || !y || !isFinite(y) || !z) {
                    return aggr;
                }

                if (isArray(z)) {
                    z.filter(zz => zz).forEach(zz => aggr.push({ x, y, z: zz }));
                    return aggr;
                }

                aggr.push({ x, y, z: z.toString() });

                return aggr;
            }, []);

        return data as Record<string, any>[];
    }

    @computed('lineData', 'xScale', 'yScale')
    get filteredLineData() {
        const xDomain = this.xScale ? this.xScale.domain() : [];
        const yDomain = this.yScale ? this.yScale.domain() : [];

        const xStart = xDomain[0], xEnd = xDomain[1],
            yStart = yDomain[0], yEnd = yDomain[1];

        if (isNaN(xStart) || isNaN(xEnd) || isNaN(yStart) || isNaN(yEnd))
            return [];

        return this.lineData.filter(v => {
            if (isInRange(xStart, xEnd, v.x) && isInRange(yStart, yEnd, v.y))
                return v;

            return null;
        }).compact();
    }

    didRender() {
        super.didRender();

        const lineData = this.filteredLineData;

        this.chart.selectAll('path').remove();

        if (!this.xScale || !this.yScale) return;

        const xScale = this.xScale;
        const yScale = this.yScale;

        var line = d3.line()
            .x(function (d: Record<string, any>) {
                return xScale(d.x);
            })
            .y(function (d: Record<string, any>) {
                return yScale(d.y);
            });

        this.chart.append('path')
            .datum(lineData)
            .attr('class', 'regression-line')
            // @ts-ignore
            .attr('d', line);
    }
}
