import porosityDimension from 'ava-saturation/classes/dimensions/porosity';
import permeabilityDimension from 'ava-saturation/classes/dimensions/permeability';

export class BandType {
    constructor(id: string, shortName: string, name: string) {
        this.id = id;
        this.shortName = shortName;
        this.name = name;
    }

    id: string;
    shortName: string;
    name: string;
}

export const general = new BandType('general', 'none', 'None');
export const porosity = new BandType('porosity', porosityDimension.shortName, 'Porosity');
export const permeability = new BandType('permeability', permeabilityDimension.shortName, 'Permeability');
export const allTypes = {
    general,
    porosity,
    permeability
};
export const allTypesById: Record<string, BandType> = {
    ...allTypes
};
