import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { SimpleMap } from 'typescript-logging';

export default class WindowManager extends Service {
    @service router: RouterService;
    public applicationWindowDictionary: SimpleMap<Window | null>;
    public _storageEventHandler?: (this: Window, evt: StorageEvent) => void;
    public readonly storageKey = 'ava_window_service';

    constructor() {
        super(...arguments);
        this.applicationWindowDictionary = new SimpleMap<Window>();
        this._addStorageListener(this.storageKey);
    }

    public open(urlString: string) {
        const url = this.router.urlFor(urlString);
        // only one instace can be opened
        if (!this.applicationWindowDictionary.exists(urlString)) {
            this.applicationWindowDictionary.put(urlString, window.open(url));

            window.localStorage.setItem(this.storageKey, urlString);
        }
    }

    public close(urlString: string) {
        if (!this.applicationWindowDictionary.exists(urlString))
            this.applicationWindowDictionary.remove(urlString);

        window.localStorage.removeItem(this.storageKey);
    }

    private _addStorageListener(storageKey: string) {
        this._storageEventHandler = (event: StorageEvent) => {
            if (this.isDestroying) { return; }
            // logic that syncs the dictionary and localStorage
            if (event.storageArea === localStorage && event.key === storageKey) {
                if (event.newValue === event.oldValue) { return; }
                // when we close we remove the record
                if ((event.newValue === null) && event.oldValue) {
                    this.applicationWindowDictionary.remove(event.oldValue);
                }
            }
        };

        window.addEventListener('storage', this._storageEventHandler, false);
    }

    public willDestroy() {
        if (this._storageEventHandler) {
            window.removeEventListener('storage', this._storageEventHandler, false);
        }

        super.init();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'window-manager': WindowManager;
    }
}
