import Component from '@ember/component';
import { action, computed, set } from '@ember/object';
import objectName from 'ava-import/classes/dimensions/object-name';
import { DataSourceInstance } from 'ava-saturation/classes/data-source';
import facies from 'ava-saturation/classes/dimensions/facies';
import segment from 'ava-saturation/classes/dimensions/segment';
import well from 'ava-saturation/classes/dimensions/well';
import zone from 'ava-saturation/classes/dimensions/zone';
import { IWellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import SettingsFactory from 'ava-saturation/classes/widgets/plot';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import { IWellDataset } from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';
import IPresenter, { IGroup, IPresenterDefinition, IPresenterSettings, PlotPresentationalType } from 'ava-saturation/interfaces/presenter';
import IReference from 'ava-saturation/interfaces/reference';
import { flatMap, isArray, uniqBy } from 'lodash';

const colors: string[] = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#fabebe',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#808080',
    '#ffffff',
    '#000000'
];

export default class Presenter extends Component implements IPresenter {
    dataSourceInstance: DataSourceInstance;
    datasets: { dataset: IWellDataset<Record<string, any>>, calculationSets: CalculationSet[] }[];
    datasetsPending: boolean;
    gridModelingContext: IGridModelingContext;
    wellContextCollection: IWellContextCollection;
    _definitionOverride: IPresenterDefinition;

    @computed('dataSourceInstance')
    get settingsDictionary() {
        return this.dataSourceInstance.presenterSettingsDictionary;
    }

    @computed('dataSourceInstance', 'dataSourceInstance.plotDefinition')
    get definition() {
        return this._definitionOverride || this.dataSourceInstance.plotDefinition;
    }
    set definition(value: IPresenterDefinition) {
        this._definitionOverride = value;
    }

    @computed('definition')
    get _internalSettings(): IPresenterSettings {
        if (!this.settingsDictionary[this.definition.type]) {
            this.settingsDictionary[this.definition.type] = SettingsFactory.create<IPresenterSettings>(this.definition);
        }

        return this.settingsDictionary[this.definition.type];
    }

    @computed('datasets.[]')
    get consumableDatasets(): IWellDataset<Record<string, any>>[] {
        return this.datasets.map(ds => ds.dataset);
    }

    private generateSampleGroup(datasets: IWellDataset<Record<string, any>>[]) {
        let sampleNames = flatMap(datasets, ds => ds.values.map((v: Record<string, any>) => v[objectName.shortName])).uniq();

        if (sampleNames && sampleNames.find(_ => true) !== undefined) {
            let i = 0;

            var values = sampleNames.map(s => {
                let result = {
                    name: s,
                    color: colors[i % colors.length],
                    type: {
                        name: 'sample'
                    }
                };
                i++;
                return result;
            });
            // @ts-ignore
            return {
                dimension: objectName,
                values: values,
                selectedValues: [...values]
            };
        }

        return undefined;
    }

    // hacky approach to avoid re-rendering
    @computed('_internalSettings',
        'consumableDatasets.[]',
        'gridModelingContext',
        'wellContextCollection.wells')
    get _settings() {
        const settings = this._internalSettings,
            datasets = this.consumableDatasets,
            groups = [] as IGroup<IReference>[],
            dimensions = uniqBy(datasets.reduce((d: IDimension[], dataset) => d.concat(dataset.dimensions), []), 'name');

        if (isArray(this.gridModelingContext.zones)) {
            const zones = this.gridModelingContext.zones;
            groups.pushObject({
                dimension: zone,
                values: zones,
                selectedValues: [...zones]
            });
        }

        if (isArray(this.gridModelingContext.segments)) {
            const segments = this.gridModelingContext.segments;
            groups.pushObject({
                dimension: segment,
                values: segments,
                selectedValues: [...segments]
            });
        }

        if (!!dimensions.find(d => d.shortName === facies.shortName) && isArray(this.gridModelingContext.faciesCodes)) {
            const faciesCodes = this.gridModelingContext.faciesCodes;
            groups.pushObject({
                dimension: facies,
                values: faciesCodes,
                selectedValues: [...faciesCodes]
            });
        }

        if (this.wellContextCollection && isArray(this.wellContextCollection.wells))
            groups.pushObject({
                dimension: well,
                values: this.wellContextCollection.wells,
                selectedValues: [...this.wellContextCollection.wells]
            });

        if (!!dimensions.find(d => d.shortName === objectName.shortName)) {
            const group = this.generateSampleGroup(this.consumableDatasets);
            if (group) {
                // @ts-ignore
                groups.pushObject(group);
            }
        }

        // will need this on a later stage. used to add groups dynamically based on the fields in the dataset

        // const metadataGroups = flatMap(datasets.filter((ds: any) => ds.metadata), ds => ds.metadata.itemsByKey);

        // if (metadataGroups.length > 0) {
        //     let merged = metadataGroups.reduce((aggregate, itemsByKey) => {
        //         return Object.keys(itemsByKey).reduce((item, key) => {
        //             let values = item[key] ? item[key].values.concat(itemsByKey[key]) : itemsByKey[key].values.slice();

        //             let newItem = item[key] || { dimension: itemsByKey[key].dimension };
        //             item[key] = newItem;

        //             newItem.values = values;

        //             return item;
        //         }, aggregate);
        //     }, {});

        //     Object.keys(merged).forEach(key => {
        //         groups.pushObject({
        //             dimension: merged[key].dimension,
        //             values: merged[key].values,
        //             selectedValues: merged[key].values.slice()
        //         });
        //     })
        // }

        if (this.definition.type === PlotPresentationalType.ScatterRegression) {
            // populate X axis options
            settings.withDimensions(dimensions.filter(d => d.unitAlias))
                // populate Y axis options
                .withGroups(groups)
                // populate selected options for the concept with the incoming dateset dimensions as defatult
                .withDefaultDimensions(dimensions);
        } else {
            // populate X axis options
            settings.withDimensions(dimensions.filter(d => d.unitAlias))
                // populate Y axis options
                .withGroups(dimensions.length ? groups : [])
                // populate selected options for data exploration with the outcoming calculation set dimensions as default
                .withDefaultDimensions(this.dataSourceInstance.calculationSetDimensions);
        }

        return settings;
    }

    @computed('definition.name')
    get presenterName(): string {
        return this.definition.name;
    }

    @computed('presenterName')
    get presenterSettingsSummary(): string {
        return `${this.presenterName}-settings-summary`;
    }

    @computed('presenterName')
    get presenterSettingsDetailed(): string {
        return `${this.presenterName}-settings-detailed`;
    }

    @computed('_settings.isPopulated')
    get isPopulated() {
        return this._settings.isPopulated;
    }

    @action
    onPresenterDefinitionChanged(definition: IPresenterDefinition) {
        // @ts-ignore
        set(this, 'dataSourceInstance.plotDefinition', definition);

        this.notifyPropertyChange('presenterName');
    }
}