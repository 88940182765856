import Component from '@ember/component';
import { action, set } from '@ember/object';

export default class ListToolbar extends Component {
    searchEnabled: boolean;
    onCreateConcept: () => void;
    onSearchTextChanged: (filter: string) => void;

    constructor() {
        super(...arguments);
        set(this, 'searchEnabled', false);
    }

    @action
    toggleSearch() {
        this.toggleProperty('searchEnabled');
    }
}
