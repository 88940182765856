import ApplicationAdapter from 'ava-saturation/adapters/application';
import configuration from 'ava-saturation/config/environment';

export default ApplicationAdapter.extend({
    namespace: `${configuration.APP.apiNamespace}/saturation-concepts`,

    pathForType() {
        return 'versions';
    },

    query(store, type, conceptId) {
        return this.ajax(this.buildURL() + `/${conceptId}/versions`, 'GET');
    },

    urlForCreateRecord(modelName, snapshot) {
        return this.buildURL() + `/${snapshot.attr('saturationConceptId')}/versions`;
    }
});
