import Component from '@ember/component';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';
import { computed } from '@ember/object';
import { FaciesLogContextFilter } from 'ava-saturation/classes/facies-log-reference-type';
import FaciesPropertyProviderMixin from 'ava-saturation/mixins/providers/facies-property-provider';

export default class FaciesPropertyContextProvider extends Component.extend(FaciesPropertyProviderMixin, {
    faciesPropertyContext: computed('filteredFaciesProperties.[]', 'faciesProperty', function() {
        return this.filteredFaciesProperties.find((fp: IFaciesProperty) => (this.faciesProperty ? this.faciesProperty.moniker.string : '') === fp.moniker.string) || this.filteredFaciesProperties[0];
    }),

    faciesLogFilter: computed('faciesPropertyContext', function() {
        return new FaciesLogContextFilter(this.faciesPropertyContext);
    })
}) {
    faciesProperty: IFaciesProperty;
}
