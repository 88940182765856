import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { allTypes } from 'ava-saturation/classes/band-types';
import IIntersection from 'ava-saturation/interfaces/intersection-reference';
import IWell from 'ava-saturation/interfaces/well-reference';
import { WellIntersectionFilter } from 'ava-saturation/mixins/providers/grid-dataset-provider';
import { add } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/actions';
import { ModelAreaStub } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { addFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/actions';
import { getModelFunctions } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/selectors';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

const stateToComputed = function (this: FunctionManager, state: any) {
    const functions = getModelFunctions(state, {
        sid: this.conceptId
    });

    return {
        functions
    };
};

const dispatchToActions = function (this: FunctionManager, dispatch: Dispatch) {
    return {
        onFunctionCreate(this: FunctionManager) {
            if (this.functionDuplicates.length !== 0)
                throw 'Attempting to create a duplicate function';

            if (!this.zoneArea)
                throw 'Attempting to create a function without a zone associated with it';

            const areas: ModelAreaStub[] = [this.zoneArea];

            if (this.segmentArea)
                areas.pushObject(this.segmentArea);

            if (this.faciesArea)
                areas.pushObject(this.faciesArea);

            areas.forEach(a => {
                if (a.isStub) {
                    delete a.isStub;

                    dispatch(add(a));
                }
            });

            dispatch(addFunction({
                id: uuid(),
                name: this.functionName,
                sid: this.conceptId,
                modelMoniker: this.sourceModel.moniker,
                bandType: allTypes.general.id,
                areaIds: areas.map(a => a.id)
            }));

            set(this, 'zoneArea', null);
            set(this, 'segmentArea', null);
            set(this, 'faciesArea', null);
            set(this, 'functionName', '');
            set(this, 'resetName', true);
        }
    };
};

export class FunctionManager extends Component {
    // inputs
    conceptId: string;
    sourceModel: Model;

    zoneArea: ModelAreaStub | null;
    segmentArea: ModelAreaStub | null;
    faciesArea: ModelAreaStub | null;

    functions: ModelFunction[];

    wells: IWell[];
    applicableIntersections: IIntersection[];

    // params
    functionName: string;
    resetName: boolean = true;

    @computed('functions', 'zoneArea', 'segmentArea', 'faciesArea')
    get functionDuplicates() {
        return this.functions.filter(f => {
            const areas = [
                this.zoneArea,
                this.segmentArea,
                this.faciesArea
                // @ts-ignore
            ].compact().map(a => a.id);

            return areas.length !== 0 && areas.length === f.areaIds.length && areas.every(a => f.areaIds.includes(a));
        });
    }

    @computed('zoneArea', 'segmentArea', 'applicableIntersections')
    get intersections() {
        if (!this.zoneArea)
            return [];

        return this.applicableIntersections.filter(i => {
            // @ts-ignore
            const condition = this.zoneArea.monikers.includes(i.zoneMoniker.string);

            return (this.segmentArea ? this.segmentArea.monikers.includes(i.segmentMoniker.string) : true) && condition;

        });
    }

    @computed('intersections.[]')
    get wellFilter(): WellIntersectionFilter {
        return new WellIntersectionFilter(this.intersections);
    }

    @computed('wellFilter', 'wells')
    get applicableWells() {
        return this.wells.filter(w => this.wellFilter.predicate(w));
    }

    @computed('functionDuplicates', 'zoneArea', 'functionName')
    get isFunctionValid() {
        return this.functionDuplicates.length === 0 &&
            this.zoneArea &&
            this.functionName && this.functionName.trim();
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionManager);