import { NonNumericDimension } from 'ava-saturation/classes/dimension';

const contactKey = 'contact';

export class Contact extends NonNumericDimension {
    static readonly id: string = contactKey;
    /**
     * Describes the fluid contact dimension
     * @ref https://www.glossary.oilfield.slb.com/Terms/c/contact.aspx
     */
    constructor() {
        super();

        this.displayName = 'Contact';
        this.name = this.shortName = contactKey;
    }
}

export const contact = new Contact();
