import well from 'ava-saturation/classes/dimensions/well';
import DatasetExtender from 'ava-saturation/classes/calculations/dependency-injection/extenders/dataset-extender-pure';

export const dependencies = {
    functions: {
        DatasetExtender
    },
    dimensionsIndex: {
        well
    }
};

export default function WellDatasetExtender(deps, wells) {
    this.datasetExtender = new deps.functions.DatasetExtender(
        deps.dimensionsIndex.well.shortName,
        [],
    );

    return {
        name: this.datasetExtender.name,
        datasetExtender: this.datasetExtender,
        dimensionsIndex: deps.dimensionsIndex,
        _wells: wells,
        meetsDimensionExpectations: function (dimensions) {
            return this.datasetExtender.meetsDimensionExpectations(dimensions);
        },
        isApplicable: function () {
            return this._wells != null && this._wells.length !== 0;
        },
        findExtendingRecord: function (value, dataset) {
            if (dataset && dataset.wellMoniker) {
                return this._wells.find(w => w.moniker.string === dataset.wellMoniker.string) || null;
            }

            return null;
        },
        /* eslint-disable no-unused-vars */
        applyExtendingRecord: function (value, extendingRecord, dataset) {
            value[this.dimensionsIndex.well.shortName] = extendingRecord.name;
        },
        extend: function (value, dataset) {
            if (this.datasetExtender.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }
    };
}