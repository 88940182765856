import IMoniker from 'ava-saturation/interfaces/moniker';
import { isEmpty } from '@ember/utils';

export interface IMonikerDescriptiveFormat {
    origin: string;
    version: string | null;
    identifier: string;
}

export interface IMonikerStringFormat {
    string: string;
}

export default class Moniker implements IMoniker {
    constructor(moniker: IMonikerDescriptiveFormat | IMonikerStringFormat);
    constructor(moniker?: any) {
        let parsed = moniker.string ?
            this.deconstruct(moniker) :
            this.construct(moniker);

        this.origin = parsed.origin;
        if (parsed.version) {
            this.version = parsed.version;
        } else {
            this.version = null;
        }

        this.identifier = parsed.identifier;
        this.string = parsed.string;
    }

    string: string;
    origin: string;
    version: string | null;
    identifier: string;

    isValid(): boolean {
        return !isEmpty(this.origin) && !isEmpty(this.identifier);
    }

    isParentOf(moniker: IMoniker): boolean {
        if (!moniker) {
            return false;
        }

        if (this.origin !== moniker.origin) {
            return false;
        }

        if (this.version !== moniker.version) {
            return false;
        }

        if (this.identifier !== moniker.identifier) {
            return false;
        }

        return true;
    }

    protected parse(definition: IMonikerStringFormat): IMonikerDescriptiveFormat {
        var pattern = /(\w+)(::([\d\.]{5}))?\/\/(.+)/g;
        var result = pattern.exec(definition.string);
        if (result != null) {
            return {
                origin: result[1],
                version: result[3],
                identifier: result[4]
            };
        }

        return {} as IMonikerDescriptiveFormat;
    }

    protected deconstruct(format: IMonikerStringFormat): IMoniker {
        return {
            ...(this.parse(format)),
            string: format.string
        } as IMoniker;
    }

    protected construct(format: IMonikerDescriptiveFormat): IMoniker {
        let monikerString = `${format.origin}`;
        monikerString += format.version ? `::${format.version}` : '';
        monikerString += `//${format.identifier}`;

        return {
            ...format,
            string: monikerString
        } as IMoniker;
    }
}