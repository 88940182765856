import Service from '@ember/service';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const ranges = [
    { name: 'xs', upTo: 600 },
    { name: 'sm', upTo: 960 },
    { name: 'md', upTo: 1280 },
    { name: 'lg', upTo: 1920 },
    { name: 'xl', upTo: Infinity },
].sort((a, b) => a - b);

const getSize = (width) => {
    if (width == null || isNaN(Number(width)) == true) return ranges[ranges.length - 1].name;
    let filtered = ranges.filter(range => range.upTo > width);
    if (filtered.length == 0) return ranges[ranges.length - 1].name;
    return filtered[0].name;
};

const getGreaterSizes = (size) => {
    let index = ranges.findIndex(range => range.name == size);
    if (index == -1) return [];
    return ranges.slice(index + 1).map(range => range.name);
};

const getSmallerSizes = (size) => {
    let index = ranges.findIndex(range => range.name == size);
    if (index == -1) return [];
    return ranges.slice(0, index).map(range => range.name);
};

export default Service.extend({
    resize: service(),
    width: window.innerWidth,
    height: window.innerHeight,
    size: getSize(window.innerWidth),
    init() {
        this._super(...arguments);
        /* eslint-disable no-unused-vars */
        this.resize.on('debouncedDidResize', event => {
            this.set('width', window.innerWidth);
            this.set('height', window.innerHeight);
            this.set('size', getSize(window.innerWidth));
        });
    },
    getSize(width) {
        return getSize(width);
    },
    xsScreenSize: computed('size', function () {
        return this.size === 'xs';
    }),
    smScreenSize: computed('size', function () {
        return this.size === 'sm';
    }),
    mdScreenSize: computed('size', function () {
        return this.size === 'md';
    }),
    greaterSizes: computed('size', function () {
        return getGreaterSizes(this.size) || [];
    }),
    smallerSizes: computed('size', function () {
        return getSmallerSizes(this.size) || [];
    })
});