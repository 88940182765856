import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IWaterSaturationProperty from 'ava-saturation/interfaces/water-saturation-property-reference';

export default class WaterSaturationPropertyReferenceType extends ReferenceObjectType {
    readonly id = 214;
    readonly name = 'WaterSaturation';
    readonly typeAliases = ['PetrelWaterSaturationProperty'];
    subTypeAliases = ['PetrelWaterSaturationTemplate'];
}

export const waterSaturationPropertyReferenceType = new WaterSaturationPropertyReferenceType();

export class WaterSaturationPropertyReferenceParser extends SingleReferenceParser<IWaterSaturationProperty> {
    parse(object: any): IWaterSaturationProperty {
        let reference = super.parse(object);
        const templateMoniker = this.parseTemplateMoniker(object);
        return {
            ...reference,
            type: waterSaturationPropertyReferenceType,
            templateMoniker
        };
    }
}

export class WaterSaturationPropertyCollectionParser extends ReferenceParser<Array<IWaterSaturationProperty>> {
    parse(object: any): Array<IWaterSaturationProperty> {
        return referenceCollectionParser<IWaterSaturationProperty>(this.appendGridMoniker(object, 'Properties'), new WaterSaturationPropertyReferenceParser(this.origin, this.version));
    }
}
