define("ava-saturation/initializers/mock-calcucaltion-store", ["exports", "mock-store/initializers/mock-calculation-store"], function (_exports, _mockCalculationStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mockCalculationStore.default;
    }
  });
});
