export default {
    version: 20190418,
    apply: function (db, event/*, transaction*/) {
        if (!db || !event)
            throw 'Database missing';

        if (event.oldVersion < this.version) {
            // TODO [NV]: expose
            let conceptStoreName = 'saturation-concepts',
                versionStoreName = 'saturation-concept-version',
                stateStoreName = 'saturation-concept-state',
                actionStoreName = 'saturation-concept-action',
                patchStoreName = 'saturation-concept-patch';

            if (db.objectStoreNames.contains(conceptStoreName))
                db.deleteObjectStore(conceptStoreName);

            if (db.objectStoreNames.contains(stateStoreName))
                db.deleteObjectStore(stateStoreName);

            if (db.objectStoreNames.contains(actionStoreName))
                db.deleteObjectStore(actionStoreName);

            if (db.objectStoreNames.contains(patchStoreName))
                db.deleteObjectStore(patchStoreName);

            if (db.objectStoreNames.contains(versionStoreName))
                db.deleteObjectStore(versionStoreName);

            const appState = 'appState';

            db.createObjectStore(appState, { keyPath: 'state' });
        }
    }
};