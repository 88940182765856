// import { createSelector } from 'reselect';

export const getTrackedActions = (state: any) => {
    const actions = state.entitiesV1.saturationConceptActions.byId;

    return Object.keys(actions)
        .filter(key => actions[key].trackable)
        .map(key => actions[key]);
};

export const getActionsByConceptId = (state: any, props: { sid: string }) => {
    const actions = state.entitiesV1.saturationConceptActions.byId;
    return Object.keys(actions)
        .filter(key => {
            return actions[key].saturationConceptId === props.sid;
        })
        .map(key => actions[key]);
};