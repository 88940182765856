/**
 * @module ember-paper
 */
import Mixin from '@ember/object/mixin';
/**
 * @class ColorMixin
 * @extends Ember.Mixin;
 */
import ColorMixin from 'ember-paper/mixins/color-mixin';

export default Mixin.create(ColorMixin, {
    classNameBindings: ['pds-grey-darker:md-pds-grey-A700', 'pds-grey-dark:md-pds-grey-700', 'pds-grey-mid:md-pds-grey-500', 'pds-grey-light:md-pds-grey-100', 'pds-grey-lighter:md-pds-grey-50']
});