import { assert } from '@ember/debug';
import { computed, set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { calculateForQC, dependencies } from 'ava-saturation/classes/calculations/dependency-injection/interpolator-pure';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import { WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import CalculationSetGenerator from 'ava-saturation/classes/widgets/calculation-set-generator';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import { toPlainObject } from 'ava-saturation/interfaces/well-log-reference';
import excelCorrelation from 'ava-saturation/utils/excel-correlation';
import s2ab from 'ava-saturation/utils/s2ab';
import fileSaver from 'file-saver';
import { groupBy, values } from 'lodash';
import CalculationStore from 'reference-store/services/calculation-store';
import DatasetStore from 'reference-store/services/dataset-store';
import RSVP from 'rsvp';
import XLSX from 'xlsx';
import QCGenPair from 'ava-saturation/interfaces/qc-gen-pair';

export class QCLogPair {
    name: string;
    originalLog: any;
    syntheticLog: any;
    color: string;
}

export enum QCState {
    InDesign = 'InDesign',
    GridDatLoading = 'GridDatLoading',
    Loading = 'Loading',
    Ready = 'Ready',
}

export class QCWorkflowContext {
    grid: IGrid;
    selectedPairs: QCLogPair[];
    state: QCState;
    selectedWells: string[];
    selectedZones: string[];
    selectedSegments: string[];

    constructor() {
        set(this, 'selectedPairs', []);
        set(this, 'selectedWells', []);
        set(this, 'selectedZones', []);
        set(this, 'selectedSegments', []);
    }
}

export default class QCWorkflow extends Service {
    @service datasetStore: DatasetStore;
    @service calculationStore: CalculationStore;

    _model: any;
    _gridRelatedData: any;
    _logData: Map<string, any> | undefined;
    _correlations: { label: string, value: number, wellMoniker: string, zoneValues: { zone: string, value: number }[] }[];

    _pairsColors = ['#2b908f', '#F46036', '#90ee7e', '#fa4443', '#69d2e7'];
    _originalLogColors = ['#B40404', '#F42C2C', '#FC3C04', '#740C0C', '#DC043C', '#BF1028'];
    _syntheticLogColors = ['#1CACF4', '#042494', '#1C648C', '#0635EC', '#142C54', '#84CCFC'];

    context: QCWorkflowContext;

    constructor(properties?: object | undefined) {
        super(properties);

        this.context = new QCWorkflowContext();
        this.context.state = QCState.InDesign;
        this._correlations = [];
    }

    @computed('_model')
    get model(): any {
        return this._model;
    }
    set model(value: any) {
        set(this, '_model', value);
        this.applyGrid(this._model.grids.find((g: IGrid) =>
            (this.context.grid ? this.context.grid.moniker.string : '') === g.moniker.string) || this._model.grids[0]);
    }

    applyGrid(grid: IGrid) {
        set(this.context, 'grid', grid);
        set(this.context, 'state', QCState.GridDatLoading);
        let promises: RSVP.Promise<any>[] = [
            this.datasetStore.getIntersections(grid.moniker),
        ];
        RSVP.allSettled(promises)
            .then((all: any[]) => {
                set(this, '_gridRelatedData', {
                    intersections: all[0].value,
                    zones: this.model.zones.filter((zone: any) => zone.gridMoniker.string === this.context.grid.moniker.string),
                    segments: this.model.segments.filter((segment: any) => segment.gridMoniker.string === this.context.grid.moniker.string),
                });
                set(this.context, 'state', QCState.InDesign);
                set(this.context, 'selectedZones', this.relatedZones.map((z: any) => z.moniker.string));
                set(this.context, 'selectedSegments', this.relatedSegments.map((z: any) => z.moniker.string));
            });
    }

    @computed('_gridRelatedData')
    get relatedZones() {
        return this._gridRelatedData ? this._gridRelatedData.zones : [];
    }

    @computed('_gridRelatedData')
    get relatedSegments() {
        return this._gridRelatedData ? this._gridRelatedData.segments : [];
    }

    @computed('model.waterSaturationLogs')
    get globalLogs() {
        // TODO: [TT] We need a type for this
        // isSynthetic: Boolean
        // moniker: Moniker
        // name: String
        // wellLogs: Array<IWellLog>
        return this.extractUnique(this.model.waterSaturationLogs, (log) => log.VersionId._instance,
            (logs) => ({
                moniker: logs[0].moniker,
                name: logs[0].name,
                isSynthetic: logs[0].isSynthetic,
                wellLogs: logs,
            }));
    }

    @computed('globalLogs.[]')
    get swLogsImported(): any[] {
        return this.globalLogs.filter((l: any) => !l.isSynthetic);
    }

    @computed('globalLogs.[]')
    get swLogsSynthetic(): any[] {
        return this.globalLogs.filter((l: any) => l.isSynthetic);
    }

    @computed('globalLogs')
    get wells(): any[] {
        let wellMonikers: string[] = [];
        this.globalLogs.forEach((gl) => {
            gl.wellLogs.forEach((log: any) => {
                wellMonikers.push(log.wellMoniker.string);
            });
        });
        return this.model.wells.filter((w: any) => wellMonikers.includes(w.moniker.string));
    }

    @computed('context.selectedPairs', 'context.selectedPairs.length', 'context.selectedWells.length', 'context.selectedZones.length', 'context.selectedSegments.length')
    get pairs() {
        return this.context.selectedPairs.map((pair: QCLogPair) => this.combineLogs(pair));
    }

    @computed('context.selectedPairs', 'context.selectedPairs.length')
    get logs(): any[] {
        const logs = this.context.selectedPairs.reduce((a: any[], pair: QCLogPair) => {
            // filter out wellLog pairs which do not have Original/Synthetic logs
            let filteredOriginal = pair.originalLog.wellLogs.filter((ow: any) => pair.syntheticLog.wellLogs.filter((sw: any) => ow.wellMoniker.string === sw.wellMoniker.string).length > 0);
            let filteredSynthetic = pair.syntheticLog.wellLogs.filter((sw: any) => pair.originalLog.wellLogs.filter((ow: any) => sw.wellMoniker.string === ow.wellMoniker.string).length > 0);
            return a.concat(filteredOriginal).concat(filteredSynthetic);
        }, []);
        var uniqueLogs = this.extractUnique(logs, (l: any) => l.moniker.string);
        var groupedLogs = groupBy(uniqueLogs, 'VersionId._instance');

        for (const key in groupedLogs) {
            let index = Object.keys(groupedLogs).indexOf(key);
            groupedLogs[key].forEach((el: any) => {
                if (el.isSynthetic) {
                    // here we are mutating uniqueLogs
                    el.color = this._syntheticLogColors[index % this._syntheticLogColors.length];
                } else {
                    // here we are mutating uniqueLogs
                    el.color = this._originalLogColors[index % this._originalLogColors.length];
                }
            });
        }

        return uniqueLogs.map((log: any) => {
            if (!this._logData) return {};
            return {
                logMoniker: log.moniker,
                log: this._logData.get(log.moniker.string).values,
                wellMoniker: log.wellMoniker,
                color: log.color,
                name: log.name,
                interpolations: this._logData.get(log.moniker.string).interpolations,
            };
        }).filter(l => l.log.length > 0);
    }

    @computed('_gridRelatedData')
    get zoneAreas(): any[] {
        return this._gridRelatedData.intersections.map((intersection: any) => {
            return {
                zone: intersection.zoneName,
                zoneMoniker: intersection.zoneMoniker.string,
                startDepth: intersection.startDepth,
                endDepth: intersection.endDepth,
                color: this._gridRelatedData.zones.find((z: any) => z.moniker.string === intersection.zoneMoniker.string).color,
                wellMonikerString: intersection.wellMoniker.string,
            };
        });
    }

    pair(originalLog: any, syntheticLog: any) {
        let pair = new QCLogPair();
        pair.originalLog = originalLog;
        pair.syntheticLog = syntheticLog;
        pair.color = this._pairsColors[this.context.selectedPairs.length % 5];
        pair.name = `${syntheticLog.name} -> ${originalLog.name}`;
        this.context.selectedPairs.pushObject(pair);
    }

    interpolatePoints(originalLogData: any[], syntheticLogData: any[]) {
        const sMinMd = syntheticLogData[0].md;
        const sMaxMd = syntheticLogData[syntheticLogData.length - 1].md;
        let current = 0;
        let result: any[] = [];
        originalLogData.forEach((point: any) => {
            if (point.md < sMinMd || point.md > sMaxMd) return;
            while (syntheticLogData[current + 1].md <= point.md) current++;
            let p = { ...syntheticLogData[current] };
            p.md = point.md;
            p.tvdss = point.tvdss;
            p.contactDepth = point.contactDepth;
            p.zone = point.zone;
            p.segment = point.segment;
            result.push(p);
        });
        return result;
    }

    interpolateWellLogsData(originalLog: any, syntheticLog: any) {
        const points = this.interpolatePoints(originalLog.values, syntheticLog.values);
        syntheticLog.interpolations = syntheticLog.interpolations || new Map<string, any[]>();
        syntheticLog.interpolations.set(originalLog.wellLogMonikers[0].string, points);
    }

    interpolatePair(pair: QCLogPair) {
        pair.originalLog.wellLogs.forEach((original: any) => {
            if (!this._logData) return;
            const originalData = this._logData.get(original.moniker.string);
            let syntheticLog = pair.syntheticLog.wellLogs.find((l: any) => l.wellMoniker.string === original.wellMoniker.string);

            if (!syntheticLog) return;
            let syntheticData = this._logData.get(syntheticLog.moniker.string);
            if (!syntheticData || syntheticData.values.length === 0) return;
            this.interpolateWellLogsData(originalData, syntheticData);
        });
    }

    calculateCorrelationPerPair(pair: QCLogPair) {
        pair.originalLog.wellLogs.forEach((original: any) => {
            if (!this._logData) return;

            const logValues = this.getLogDataForCorrelation(original, pair);
            if (!logValues) return;
            const cc = excelCorrelation(logValues.original.map((point: any) => point.sw), logValues.synthetic.map((point: any) => point.sw));
            let zoneValues: any = [];
            this.relatedZones.forEach((zone: any) => {
                const oValues = logValues.original.filter((point: any) => point.zone === zone.name).map((point: any) => point.sw);
                const sValues = logValues.synthetic.filter((point: any) => point.zone === zone.name).map((point: any) => point.sw);
                if (oValues.length !== sValues.length) return;
                if (oValues.length < 2) return;
                const ccZone = excelCorrelation(oValues, sValues);
                zoneValues.push({ zone: zone.name, value: ccZone });
            });
            const result = {
                label: pair.name,
                value: cc,
                wellMoniker: original.wellMoniker.string,
                zoneValues: zoneValues
            };
            this._correlations.pushObject(result);
        });
    }

    removePair(pair: QCLogPair) {
        this.context.selectedPairs.removeObject(pair);
    }

    run() {
        this._correlations.clear();
        set(this.context, 'state', QCState.Loading);

        const logs = this.context.selectedPairs.reduce((a: any[], pair: QCLogPair) => {
            // filter out wellLog pairs which do not have Original/Synthetic logs
            let filteredOriginal = pair.originalLog.wellLogs.filter((ow: any) => pair.syntheticLog.wellLogs.filter((sw: any) => ow.wellMoniker.string === sw.wellMoniker.string).length > 0);
            let filteredSynthetic = pair.syntheticLog.wellLogs.filter((sw: any) => pair.originalLog.wellLogs.filter((ow: any) => sw.wellMoniker.string === ow.wellMoniker.string).length > 0);
            return a.concat(filteredOriginal).concat(filteredSynthetic);
        }, []);

        const uniqueLogs = this.extractUnique(logs, (l: any) => l.moniker.string);

        let promises: Promise<unknown>[] = [];
        let wellModelingContext = new WellModelingContextCollection();
        uniqueLogs.forEach((l) => {
            wellModelingContext.tryAdd(this.wells.find(w => w.moniker.string === l.wellMoniker.string));
            wellModelingContext.byMoniker[l.wellMoniker.string].tryAdd(waterSaturation);
            wellModelingContext.byMoniker[l.wellMoniker.string].byDimensionKey[waterSaturation.shortName].tryAdd([l]);
        });
        const calculationSets = CalculationSetGenerator.generateDatasets(wellModelingContext, this.model.wells);

        const gridContext = {
            intersections: this._gridRelatedData.intersections,
            zones: this._gridRelatedData.zones,
            segments: this._gridRelatedData.segments,
        };

        const wellContext = {
            wells: this.model.wells,
            porosityLogs: this.model.porosityLogs.map((log: any) => toPlainObject(log)),
            waterSaturationLogs: this.model.waterSaturationLogs.map((log: any) => toPlainObject(log)),
            wellPointLogs: this.model.wellPointLogs.map((log: any) => toPlainObject(log))
        };

        calculationSets.forEach((calculationSet: any) => {

            promises.push(this.calculationStore.run(calculateForQC, dependencies, calculationSet, [gridContext, wellContext]));
        });

        RSVP.allSettled(promises)
            .then((all: any[]) => {
                this._logData = new Map(all.map(result => {
                    if (result.state !== 'fulfilled') console.log(result.reason);
                    // because of the way we populate the WellModelingContextCollection
                    // we know that there is only one wellLogMoniker per calculation
                    assert('Calculations should be done log by log', result.value.wellLogMonikers.length === 1);
                    return [result.value.wellLogMonikers[0].string, result.value];
                }));

                this.context.selectedPairs.forEach((pair: QCLogPair) => this.interpolatePair(pair));
                this.context.selectedPairs.forEach((pair: QCLogPair) => this.calculateCorrelationPerPair(pair));

                set(this.context, 'state', QCState.Ready);
            });
    }

    exportCrossPlot(title: string | undefined) {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: 'Cross Plot Data',
            Author: 'Ava Saturation',
            CreatedDate: new Date()
        };

        this.pairs.forEach((pair) => {
            let wsHeader = ['well', 'zone', 'md', 'tvdss', `Sw (${pair.originalLogName})`, `Sw (${pair.synheticLogName})`];

            const byWell = values(groupBy(pair.mixed, (v) => {
                return v.well;
            }));

            let ws = XLSX.utils.aoa_to_sheet([[pair.label]]);
            byWell.forEach((well, index) => {
                let wsData = well.map((v) => {
                    return [
                        v.well,
                        v.zone,
                        v.md,
                        v.tvdss,
                        v.log1,
                        v.log2
                    ];
                });

                wsData.insertAt(0, wsHeader);
                XLSX.utils.sheet_add_aoa(ws, wsData, { origin: { r: 1, c: index * (wsHeader.length + 1) } });
            });

            let sheetName = pair.label.length > 31 ? pair.label.slice(0, 31) : pair.label;
            let sheetNameIndex = 0;
            // name should be uniq
            while (wb.SheetNames.find(sheet => sheet === sheetName) !== undefined) {
                sheetNameIndex++;
                const suffix = ` (${sheetNameIndex})`;
                sheetName = sheetName.length + suffix.length > 31 ? `${sheetName.slice(0, 31 - suffix.length)}${suffix}` : `${sheetName}${suffix}`;
            }
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        fileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${title || 'Ava Saturation Corss Plot Data'} Export.xlsx`);
    }

    extractUnique(array: any[], accessor: (item: any) => any, mapper?: (items: any[]) => any) {
        const map = new Map<any, any>();
        array.forEach((item) => {
            const key = accessor(item);
            if (!map.has(key)) {
                map.set(key, []);
            }
            map.get(key).push(item);
        });
        let result: any[] = [];
        if (mapper)
            map.forEach((items) => { result.push(mapper(items)); });
        else
            map.forEach((items) => { result = result.concat(items); });
        return result;
    }

    combineWellLogs(log1Data: any[], log2Data: any[]): any[] {
        let result: any[] = [];

        let log1index = 0;
        let log2index = 0;

        const selectedZones = this.context.selectedZones.map((moniker: string) => this.relatedZones.find((z: any) => z.moniker.string === moniker).name);
        const selectedSegments = this.context.selectedSegments.map((moniker: string) => this.relatedSegments.find((z: any) => z.moniker.string === moniker).name);

        while (log1index < log1Data.length || log2index < log2Data.length) {
            if (log1index >= log1Data.length) {
                break;
            }
            if (log2index >= log2Data.length) {
                break;
            }
            if (log1Data[log1index].md === log2Data[log2index].md) {
                if (selectedZones.includes(log1Data[log1index].zone) &&
                    selectedSegments.includes(log1Data[log1index].segment)) {
                    result.push({
                        log1: log1Data[log1index].sw,
                        log1Name: log1Data[log1index]['water-saturation-log'],
                        log2: log2Data[log2index].sw,
                        log2Name: log2Data[log2index]['water-saturation-log'],
                        md: log1Data[log1index].md,
                        well: log1Data[log1index].well,
                        tvdss: log1Data[log1index].tvdss,
                        zone: log1Data[log1index].zone,
                        segment: log1Data[log1index].segment,
                    });
                }
                log1index++;
                log2index++;
            } else if (log1Data[log1index].md < log2Data[log2index].md) {
                log1index++;
            } else {
                log2index++;
            }
        }

        return result;
    }

    combineLogs(pair: QCLogPair) {

        let result: any[] = [];
        pair.originalLog.wellLogs.filter((log: any) => this.context.selectedWells.includes(log.wellMoniker.string))
            .forEach((log: any) => {
                const data = this.getLogDataForCorrelation(log, pair);
                if (!data) return;
                result = result.concat(this.combineWellLogs(data.original, data.synthetic));
            });
        return { mixed: result, color: pair.color, label: pair.name, originalLogName: pair.originalLog.name, synheticLogName: pair.syntheticLog.name };
    }

    getLogDataForCorrelation(originalLog: any, pair: QCLogPair) {
        if (!this._logData) return undefined;

        const originalData = this._logData.get(originalLog.moniker.string);
        if (!originalData) return undefined;
        const syntheticLog = pair.syntheticLog.wellLogs.find((l: any) => l.wellMoniker.string === originalLog.wellMoniker.string);
        if (!syntheticLog) return undefined;
        const syntheticData = this._logData.get(syntheticLog.moniker.string);
        if (!syntheticData || !syntheticData.interpolations) return undefined;
        if (!syntheticData.interpolations.has(originalLog.moniker.string)) return undefined;
        const syntheticValues = syntheticData.interpolations.get(originalLog.moniker.string);
        const minMd = syntheticValues[0].md;
        const maxMd = syntheticValues[syntheticValues.length - 1].md;
        const originalValues = originalData.values.filter((point: any) => point.md >= minMd && point.md <= maxMd);
        assert('Original and synhetic logs do not match on samples', originalValues.length === syntheticValues.length);

        return { original: originalValues, synthetic: syntheticValues };
    }

    clean() {
        set(this, 'context', new QCWorkflowContext());
        set(this.context, 'state', QCState.InDesign);
        set(this, '_gridRelatedData', undefined);
        set(this, '_logData', undefined);
    }

    selectLogPairs(_pairs: QCGenPair[]) {
        // [ATS] For automatic pair selections after log is created.
        // (pairs || []).forEach(p => {

        //     // this.pair(p.log, )
        // });
        // console.log(pairs);
    }
}

declare module '@ember/service' {
    interface Registry {
        'qc-workflow': QCWorkflow;
    }
}
