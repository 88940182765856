import Evented from '@ember/object/evented';
import { keys } from '@ember/polyfills';
import Service, { inject } from '@ember/service';
import { isPresent } from '@ember/utils';
import configuration from 'ava-saturation/config/environment';
import { computedHash } from 'ava-saturation/utils/model-helpers';

export default Service.extend(Evented, {
    session: inject(),

    authorizedFeatures: computedHash('session.data.authenticated.features'),

    enable(key, value) {
        const features = this.get('session.data.features') || {};
        features[key] = value;
        this.session.set('data.features', features);
        this.notifyPropertyChange(key);
        this.trigger(key, value);
    },

    reset() {
        this.session.set('data.features', undefined);
        keys(configuration.APP.features)
            .forEach((x) => this.notifyPropertyChange(x));
    },

    setUnknownProperty(key) {
        throw new Error(`Please use enable method to set "${key}" feature flag value.`);
    },

    unknownProperty(key) {
        const value = this.get(`session.data.features.${key}`);
        if (isPresent(value)) {
            return value;
        }

        if (isPresent(this.get(`authorizedFeatures.${key}`))) {
            return true;
        }

        return configuration.APP.features[key];
    }
});
