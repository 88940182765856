import facies from 'ava-saturation/classes/dimensions/facies';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';

import {
    lerpWithoutCoefficient,
    dependencies as lerpDependencies
} from 'ava-saturation/utils/lerp';
import isInZone from 'ava-saturation/calculations/log-filters/is-in-zone';
import isInZeroOneRange, {
    dependencies as inRangeDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/filters/is-in-zero-one-range';
import WellDatasetExtender, {
    dependencies as wellExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/well-extender-pure';
import FaciesCodeDatasetExtender, {
    dependencies as faciesCodeExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/facies-code-extender-pure';
import ContactDepthDatasetExtender, {
    dependencies as contactDepthExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/contact-depth-extender-pure';
import TVDssDatasetExtender, {
    dependencies as tvdssExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/tvdss-dataset-extender-pure';

import PcReservoirDatasetCalculator, {
    dependencies as pcResCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/pc-reservoir-dataset-calculator-pure';
import PcHeightGasDatasetCalculator, {
    dependencies as pcHGCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/pc-height-gas-dataset-calculator-pure';
import PcHeightOilDatasetCalculator, {
    dependencies as pcHOCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/pc-height-oil-dataset-calculator-pure';
import JValueDatasetCalculator, {
    dependencies as jValueCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/j-value-dataset-calculator-pure';

export default function DatasetIterator(deps, calculators, extenders, filters) {
    return {
        calculators: calculators,
        extenders: extenders,
        filters: filters,
        iterate(dataset) {
            for (let i = 0; i < dataset.values.length; i++) {
                this.extenders
                    .filter(extender => extender.isApplicable())
                    .forEach(extender => extender.extend(dataset.values[i], dataset));

                this.calculators
                    .forEach(calculator => calculator.apply(dataset.values[i], dataset));
            }

            if (this.filters.length > 0)
                dataset.values = dataset.values.filter(v => this.filters.reduce((isValid, filter) => isValid && filter(v), true));

            return {
                primaryDimension: dataset.dimensions[0],
                secondaryDimension: dataset.dimensions[1],
                dimensions: dataset.dimensions,
                values: dataset.values,
                wellMoniker: dataset.wellMoniker,
                wellLogMonikers: [dataset.wellLogMoniker]
            };
        }
    };
}

export function FluentDatasetIteratorBuilder(deps) {
    const builder = {
        withCalculator: function (datasetCalculator) {
            this.calculators.push(datasetCalculator);

            return this;
        },
        withExtender: function (datasetExtender) {
            this.extenders.push(datasetExtender);

            return this;
        },

        withFilter: function (filter) {
            this.filters.push(filter);

            return this;
        },
        build() {
            let iterator = new deps.functions.DatasetIterator(deps, this.calculators, this.extenders, this.filters);

            this.reset();

            return iterator;
        },
        reset() {
            this.calculators = [];
            this.extenders = [];
            this.filters = [];
        }
    };

    builder.reset();

    return builder;
}

export const dependencies = {
    functions: {
        lerpWithoutCoefficient,
        isInZone,
        isInZeroOneRange,
        WellDatasetExtender,
        FaciesCodeDatasetExtender,
        ContactDepthDatasetExtender,
        TVDssDatasetExtender,

        ...lerpDependencies.functions,
        ...inRangeDependencies.functions,
        ...wellExtenderDependencies.functions,
        ...faciesCodeExtenderDependencies.functions,
        ...contactDepthExtenderDependencies.functions,
        ...tvdssExtenderDependencies.functions,

        PcReservoirDatasetCalculator,
        PcHeightGasDatasetCalculator,
        PcHeightOilDatasetCalculator,
        JValueDatasetCalculator,

        ...pcResCalculatorDependencies.functions,
        ...pcHGCalculatorDependencies.functions,
        ...pcHOCalculatorDependencies.functions,
        ...jValueCalculatorDependencies.functions,

        DatasetIterator,
        FluentDatasetIteratorBuilder
    },
    dimensionsIndex: {
        facies,
        tvdss,

        ...wellExtenderDependencies.dimensionsIndex,
        ...faciesCodeExtenderDependencies.dimensionsIndex,
        ...contactDepthExtenderDependencies.dimensionsIndex,
        ...tvdssExtenderDependencies.dimensionsIndex,

        ...pcResCalculatorDependencies.dimensionsIndex,
        ...pcHGCalculatorDependencies.dimensionsIndex,
        ...pcHOCalculatorDependencies.dimensionsIndex,
        ...jValueCalculatorDependencies.dimensionsIndex,
    }
};

export function calculate(deps, gridContext, wellContext, datasets) {
    const contactDepthExtender = new deps.functions.ContactDepthDatasetExtender(deps, gridContext.intersections, gridContext.contactDepths, true);
    const wellExtender = new deps.functions.WellDatasetExtender(deps, wellContext.wells);

    let faciesDataset = datasets
        .find(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) != -1);
    let faciesDatasetExtender = new deps.functions.FaciesCodeDatasetExtender(deps, faciesDataset);

    let trajectoryDataset = datasets
        .find(ds => ds.dimensions.indexOf(deps.dimensionsIndex.tvdss) != -1);
    let trajectoryDatasetExtender = new deps.functions.TVDssDatasetExtender(deps, trajectoryDataset);

    let iterator = new deps.functions.FluentDatasetIteratorBuilder(deps)
        .withExtender(trajectoryDatasetExtender)
        .withExtender(faciesDatasetExtender)
        .withExtender(contactDepthExtender)
        .withExtender(wellExtender)
        .withFilter(deps.functions.isInZone)
        .withCalculator(new deps.functions.PcReservoirDatasetCalculator(deps))
        .withCalculator(new deps.functions.PcHeightGasDatasetCalculator(deps))
        .withCalculator(new deps.functions.PcHeightOilDatasetCalculator(deps))
        .withCalculator(new deps.functions.JValueDatasetCalculator(deps))
        .build();

    const mainDataset = datasets
        .find(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) == -1 && ds.dimensions.indexOf(deps.dimensionsIndex.tvdss) == -1);

    return iterator.iterate(mainDataset);
}
