import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
    grid: null,
    contacts: null,

    filteredContacts: computed('grid', 'contacts.[]', function () {
        return this.contacts.filterBy('gridMoniker.string', this.grid.moniker.string);
    })
});