import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { ReferenceParserBase } from 'ava-saturation/interfaces/reference-parser';
import IContactDepth from 'ava-saturation/interfaces/contact-depth-reference';
import IMoniker from 'ava-saturation/interfaces/moniker';

export default class ContactDepthReferenceType extends ReferenceObjectType {
    readonly id = 320;
    readonly name = 'contact-depth';
    readonly typeAliases = ['ContactDepth'];
    subTypeAliases = [];
}

export const contactDepthReferenceType = new ContactDepthReferenceType();

export class ContactDepthReferenceParser<T extends IContactDepth> extends ReferenceParserBase<T> {
    private _gridMoniker: IMoniker;

    constructor(origin: string, version: string, gridMoniker: IMoniker) {
        super(origin, version);

        this._gridMoniker = gridMoniker;
    }

    parse(object: any): T {
        const identifierSelector = (object: any) => object._instance;
        return {
            ...object,
            gridMoniker: this._gridMoniker,
            contactMoniker: this.parseMoniker(object.ContactId, identifierSelector),
            zoneMoniker: this.parseMoniker(object.ZoneId, identifierSelector),
            segmentMoniker: this.parseMoniker(object.SegmentId, identifierSelector),
            depth: object.Depth,
        };
    }
}

export class ContactDepthCollectionReferenceParser extends ReferenceParserBase<Array<IContactDepth>> {
    private _gridMoniker: IMoniker;

    constructor(origin: string, version: string, gridMoniker: IMoniker) {
        super(origin, version);
        
        this._gridMoniker = gridMoniker;
    }

    parse(object: any): Array<IContactDepth> {
        return referenceCollectionParser<IContactDepth>(object, new ContactDepthReferenceParser(this.origin, this.version, this._gridMoniker));
    }
}

function referenceCollectionParser<T extends IContactDepth>(object: any, singleReferenceParser: ReferenceParserBase<T>): Array<T> {
    return (object as Array<any>).map(o => singleReferenceParser.parse(o));
}