import { createAsyncAction, createCustomAction } from 'typesafe-actions';
import constants from './constants';
import { ReferenceVersion, ReferenceVersionPayload } from './types';

export const addReferenceVersion = createCustomAction(constants.ADD, type => {
    return (referenceVersion: ReferenceVersionPayload) => ({ type, payload: referenceVersion, recordable: true });
});

export const deleteReferenceVersion = createCustomAction(constants.DELETE, type => {
    return (referenceVersion: ReferenceVersion) => ({ type, payload: referenceVersion, recordable: true });
});

export const updateReferenceVersion = createCustomAction(constants.UPDATE, type => {
    return (referenceVersion: ReferenceVersionPayload) => ({ type, payload: referenceVersion, recordable: true });
});

export const setReferenceVersionAction = createCustomAction(constants.SET, type => {
    return (referenceVersion: { id: string, sid: string, moniker: string }) => ({ type, payload: referenceVersion, recordable: true });
});

export const setReferenceVersionActionAsync = createAsyncAction(
    constants.SET_REQUEST,
    constants.SET_SUCCESS,
    constants.SET_FAILURE
)<{ id: string, sid: string, moniker: string }, ReferenceVersionPayload, any>();
