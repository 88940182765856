define("ava-saturation/components/wizard-steps/summary-step", ["exports", "ava-import/components/wizard-steps/summary-step"], function (_exports, _summaryStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _summaryStep.default;
    }
  });
});
