define("ava-saturation/classes/validators/in-range-validator", ["exports", "ava-import/classes/validators/in-range-validator"], function (_exports, _inRangeValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _inRangeValidator.default;
    }
  });
});
