import Component from '@ember/component';
import { computed } from '@ember/object';
import { cuddy, leverettJ, skelt } from 'ava-saturation/classes/calculation-types';
import { FaciesLogContextFilter } from 'ava-saturation/classes/facies-log-reference-type';
import { IWellContextCollection, WellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { getModelAreas } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/selectors';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { getConceptFunctions } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/selectors';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import { connect } from 'ember-redux';

const stateToComputed = function (this: FunctionExplorer, state: any) {
    const functions = getConceptFunctions(state, { sid: this.conceptId });
    const areasBySid = getModelAreas(state, {
        sid: this.conceptId,
    });

    return {
        functions,
        areasBySid
    };
};

const dispatchToActions = function (this: FunctionExplorer) {
    return {
    };
};

export class FunctionExplorer extends Component {
    // inputs
    conceptId: string;
    sourceModel: Model;
    gridContext: GridContext;
    wellContextCollection: IWellContextCollection;
    areasBySid: ModelArea[];
    functions: ModelFunction[];
    faciesLogFilter: FaciesLogContextFilter;

    @computed('areasBySid')
    get areas() {
        return this.areasBySid.filter(a => a.modelMoniker === this.sourceModel.moniker);
    }

    @computed('wellContextCollection', 'faciesLogFilter')
    get wellContext() {
        return new WellContextCollection({
            ...this.wellContextCollection,
            faciesLogFilters: [this.faciesLogFilter]
        });
    }

    get calculationTypes() {
        return [
            cuddy,
            skelt,
            leverettJ
        ];
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionExplorer);
