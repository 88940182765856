import WellLogReferenceType, { WellLogReferenceParser, WellLogCollectionReferenceParser } from 'ava-saturation/classes/well-log-reference-type';
import { referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import facies from 'ava-saturation/classes/dimensions/facies';
import IWellLog, { IWellLogFilter } from 'ava-saturation/interfaces/well-log-reference';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';

export default class FaciesLogReferenceType extends WellLogReferenceType {
    constructor() {
        super(facies, [facies]);
    }

    id = 213;
    name = 'facies-log';
    subTypeAliases = ['PetrelFaciesTemplate', 'PetrelFaciesTemplateUpdate'];
}

export const faciesLogReferenceType = new FaciesLogReferenceType();

export class FaciesLogReferenceParser extends WellLogReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, faciesLogReferenceType);
    }

    parse(object: any): IWellLog {
        let reference = super.parse(object);
        // added the case because the mock data format is not aligned with the petrel one
        // once we align it this condition needs to be dropped
        const templateMoniker = this.parseTemplateMoniker(object, (object) => object.Template && object.Template.ObjectId._instance || object.TemplateId._instance);
        return {
            ...reference,
            templateMoniker
        };
    }
}

export class FaciesLogCollectionReferenceParser extends WellLogCollectionReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, faciesLogReferenceType);
    }

    parse(object: any): Array<IWellLog> {
        return referenceCollectionParser<IWellLog>(this.appendWellMoniker(object, 'Logs'), new FaciesLogReferenceParser(this.origin, this.version));
    }
}

export class FaciesLogFilter implements IWellLogFilter {
    predicate(wellLog: IWellLog) {
        return wellLog.type === faciesLogReferenceType;
    }
}

export class FaciesLogContextFilter implements IWellLogFilter {
    private _faciesPropertyContext: IFaciesProperty | null;

    constructor(faciesPropertyContext: IFaciesProperty | null) {
        this._faciesPropertyContext = faciesPropertyContext;
    }

    predicate(wellLog: IWellLog): boolean {
        if (wellLog.type.id !== faciesLogReferenceType.id)
            return true;

        if (this._faciesPropertyContext)
            return this._faciesPropertyContext.templateMoniker.string === wellLog.templateMoniker.string;

        return false;
    }
}