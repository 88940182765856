import { createCustomAction } from 'typesafe-actions';
import { ModelFunction, UpdateBandTypePayload, AddRemoveAreasPayload, DeleteModelFunctionPayload, UpdateFunctionNamePayload } from './types';
import constants from './constants';

export const addFunction = createCustomAction(constants.ADD, type => {
    return (payload: ModelFunction) => ({ type, payload: payload, recordable: true });
});

export const deleteFunction = createCustomAction(constants.DELETE, type => {
    return (payload: DeleteModelFunctionPayload) => ({ type, payload: payload, recordable: true });
});

export const updateFunctionName = createCustomAction(constants.UPDATE_FUNCTION_NAME, type => {
    return (payload: UpdateFunctionNamePayload) => ({ type, payload: payload, recordable: true });
});

export const updateBandType = createCustomAction(constants.UPDATE_BAND_TYPE, type => {
    return (payload: UpdateBandTypePayload) => ({ type, payload: payload, recordable: true });
});

export const addAreas = createCustomAction(constants.ADD_AREAS, type => {
    return (payload: AddRemoveAreasPayload) => ({ type, payload: payload, recordable: true });
});

export const removeAreas = createCustomAction(constants.REMOVE_AREAS, type => {
    return (payload: AddRemoveAreasPayload) => ({ type, payload: payload, recordable: true });
});
