import { NonNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import { ICPSample } from 'ava-import/classes/cp-sample';
import { IWellLogDataset } from 'ava-saturation/interfaces/dataset';
import IMoniker from 'ava-saturation/interfaces/moniker';

// dimensions
import IDimension from 'ava-saturation/interfaces/dimension';
import { pointWellLogReferenceType } from 'ava-saturation/classes/point-well-log-reference-type';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import objectName from 'ava-import/classes/dimensions/object-name';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import permeability from 'ava-import/classes/dimensions/permeability';
import { contactAngleRes, contactAngleLab } from 'ava-import/classes/dimensions/contact-angle';
import { waterGradient, gasGradient, oilGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { iftReservoir, iftLab } from 'ava-import/classes/dimensions/interfacial-tension';
import { relativePermeabilityWater, relativePermeabilityGas, relativePermeabilityOil } from 'ava-import/classes/dimensions/relative-permeability';
import { capillaryPressureRes, capillaryPressureLab } from 'ava-import/classes/dimensions/capillary-pressure';
import measureDepth, { MeasureDepthDataset, MeasureDepthDatasetParser, MeasureDepthDatasetJsonFormatter } from 'ava-saturation/classes/dimensions/measure-depth';

const pointWellLogKey = 'point';

class PointWellLog extends NonNumericDimension {
    /**
     * Describes a geological zone
     * @ref https://www.glossary.oilfield.slb.com/Terms/z/zone.aspx
     */
    constructor() {
        super();

        this.displayName = 'Well Log Point';
        this.name = this.shortName = pointWellLogKey;
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.WellLog;
}

const pointWellLog = new PointWellLog();

export class PointLogDataset extends MeasureDepthDataset<ICPSample> implements IWellLogDataset<ICPSample> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super(pointWellLogReferenceType.dimensions.without(measureDepth), wellMoniker);

        this.primaryDimension = capillaryPressureRes;
        this.wellLogMoniker = wellLogMoniker;
    }

    wellLogMoniker: IMoniker;
}

const pointLogDatasetCreator = (wellMoniker: IMoniker, wellLogMoniker: IMoniker) => {
    return () => new PointLogDataset(wellMoniker, wellLogMoniker);
};

export class PointLogDatasetParser extends MeasureDepthDatasetParser<ICPSample, PointLogDataset> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super(wellMoniker, pointLogDatasetCreator(wellMoniker, wellLogMoniker));
    }

    // this impacts only the mocks atm
    protected parseSample(sample: Record<string, any>): ICPSample {
        Object.keys(sample).forEach((key) => {
            // replacing the null values with NaN
            // later on Number() will be called on those values
            // those null values are also in the petrel calculations
            // TODO: [TT] investigate
            sample[key] = sample[key] || NaN;
        });
        return { ...sample } as ICPSample;
    }
}

export const pointLogDatasetSettings = {
    samplesAlias: 'SortedPointWellLogSamples',
    dimension: pointWellLog.toPlainObject(),
    targetDimensions: pointWellLogReferenceType.dimensions.map(d => d.toPlainObject()),
    valueMap: {
        [objectName.shortName]: objectName.displayName,
        [measureDepth.shortName]: measureDepth.displayName,
        [porosity.shortName]: porosity.displayName,
        [waterSaturation.shortName]: waterSaturation.displayName,
        [permeability.shortName]: permeability.displayName,
        [contactAngleRes.shortName]: contactAngleRes.displayName,
        [contactAngleLab.shortName]: contactAngleLab.displayName,
        [iftReservoir.shortName]: iftReservoir.displayName,
        [iftLab.shortName]: iftLab.displayName,
        [gasGradient.shortName]: gasGradient.displayName,
        [oilGradient.shortName]: oilGradient.displayName,
        [waterGradient.shortName]: waterGradient.displayName,
        [relativePermeabilityGas.shortName]: relativePermeabilityGas.displayName,
        [relativePermeabilityOil.shortName]: relativePermeabilityOil.displayName,
        [relativePermeabilityWater.shortName]: relativePermeabilityWater.displayName,
        [capillaryPressureLab.shortName]: capillaryPressureLab.displayName,
        [capillaryPressureRes.shortName]: capillaryPressureRes.displayName
    }
};

export class PointLogDatasetJsonFormatter extends MeasureDepthDatasetJsonFormatter {
    constructor() {
        super(pointLogDatasetSettings);
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName != pointWellLog.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        if (!monikerDictionary.wellLogMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `
            wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)},
            wellLogMoniker: ${JSON.stringify(monikerDictionary.wellLogMoniker)}
        `;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellLogMoniker.identifier}", ${JSON.stringify(pointLogDatasetSettings)})`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): MeasureDepthDataset<ICPSample> | null {
        return new PointLogDataset(monikerDictionary.wellMoniker, monikerDictionary.wellLogMoniker);
    }

    getSampleFormat(samplePrefix: string): string {
        return `
            ${super.getSampleFormat(samplePrefix)},
            ${porosity.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[porosity.shortName]}
        `;
    }
}

export default pointWellLog;