import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { IWellContextCollection, WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import IWell from 'ava-saturation/interfaces/well-reference';

export default class WellProvider extends Component {
    didReceiveAttrs() {
        // @ts-ignore
        set(this, 'wellContextCollection.wells', this.wells);
    }

    dataSourceProviders!: string[];
    wellContextCollection: IWellContextCollection;
    // The modeling context needs to be here in order to update the collection easily in case of an update from the reference store
    // or we probably need a modeling context to move it to.
    modelingContextCollection: WellModelingContextCollection;

    @computed('wellContextCollection.wells.[]', 'wellContextCollection.wellFilters.[]')
    get wells(): IWell[] {
        const filters = this.wellContextCollection.wellFilters || [];
        return this.wellContextCollection.wells
            .filter((g: IWell) => this.dataSourceProviders.indexOf(g.moniker.origin) !== -1)
            .filter((w: IWell) => filters.filter(f => f.predicate(w)).length === filters.length); // all filters need to be satisfied
    }
}