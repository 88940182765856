import Component from '@ember/component';
import { action } from '@ember/object';
import $ from 'jquery';

export default class SidenavItem extends Component {
    referenceId: string;
    selector: string;
    @action
    navigateTo(event: Event) {
        event.preventDefault();
        if (this.referenceId) {
            // const scrollHeight = $(`#${this.get('referenceId')}`).position().top - ;
            $('.document-layout-container').animate({
                // @ts-ignore
                scrollTop: $('.document-layout-container').scrollTop() + $(`${this.selector || '#'}${this.referenceId}`).position().top
            }, 500, 'swing');
        }
    }
}