import Component from '@ember/component';

import CalculationProvider from 'ava-saturation/components/calculation-provider';
import { getOwner } from '@ember/application';
import { ProcessorType } from 'ava-saturation/classes/data-source';
import { action, computed } from '@ember/object';

import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import { IWellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import { IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';

export default class CalculationProviderMediator extends Component {
    processorType: ProcessorType;
    autoUpdateCalculationSets: boolean = false;

    wellContext: IWellContextCollection;
    gridContext: IGridModelingContext;
    calculationSets: CalculationSet[][];

    didInsertElement() {
        // @ts-ignore
        this.bootstrapCalculationSets();

        this.calculationProvider.didInsertElement.call(this.calculationProvider, ...arguments);
    }

    didReceiveAttrs() {
        this.bootstrapContexts();

        if (this.autoUpdateCalculationSets && this.calculationSets !== this.calculationProvider.calculationSets) {
            this.bootstrapCalculationSets();
            this.onCalculationSetsChanged({ updates: this.calculationSets, filter: () => true });
        }
    }

    @computed('processorType')
    get calculationProvider(): CalculationProvider {
        const owner = getOwner(this);
        const componentName = `${this.processorType}-calculation-provider`;
        const lookup = owner.lookup(`component:${componentName}`);

        this.bootstrapCalculationSets(lookup);
        this.bootstrapContexts(lookup);

        return lookup;
    }

    private bootstrapCalculationSets(component: CalculationProvider | null = null) {
        // @ts-ignore
        (component || this.calculationProvider).set('calculationSets', this.get('calculationSets'));
    }

    private bootstrapContexts(component: CalculationProvider | null = null) {
        // @ts-ignore
        (component || this.calculationProvider).set('wellContext', this.get('wellContext'));

        // @ts-ignore
        (component || this.calculationProvider).set('gridContext', this.get('gridContext'));
    }

    @computed('calculationProvider.datasets.[]')
    get datasets(): any {
        // @ts-ignore
        return this.get('calculationProvider.datasets');
    }

    @computed('calculationProvider.isPending')
    get isPending(): any {
        // @ts-ignore
        return this.get('calculationProvider.isPending');
    }

    @action
    onCalculationSetsChanged(...args: any) {
        this.calculationProvider.actions.onCalculationSetsChanged.call(this.calculationProvider, ...args);
    }

    @action
    export() {
        this.calculationProvider.actions.export.call(this.calculationProvider, ...arguments);
    }
    // private getTargetProvider() {

    //     // if (!lookup.componentFor) {
    //     //     return !!lookup.lookupFactory(componentName);
    //     // }
    //     // return !!(lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner));
    // }
}