import Moniker, { IMonikerStringFormat, IMonikerDescriptiveFormat } from 'ava-saturation/classes/moniker';

export interface IFaciesMonikerDescriptiveFormat extends IMonikerDescriptiveFormat {
    code: string | null;
}

const suffixSeparator = '#';

export default class FaciesMoniker extends Moniker {
    // @ts-ignore
    public code: string = this.code;

    constructor(moniker: IFaciesMonikerDescriptiveFormat | IMonikerStringFormat) {
        super(moniker);
    }

    isValid(): boolean {
        const isValid = super.isValid();

        return isValid && this.code != null;
    }

    private parseSuffix(definition: IMonikerStringFormat) {
        const result = definition.string.split(suffixSeparator);

        return {
            root: { string: result[0] },
            code: result[1]
        };
    }

    parse(definition: IMonikerStringFormat): IFaciesMonikerDescriptiveFormat {
        const result = this.parseSuffix(definition);

        const parsed = super.parse(result.root) as IFaciesMonikerDescriptiveFormat;
        parsed.code = result.code;

        return parsed;
    }

    deconstruct(format: IMonikerStringFormat): FaciesMoniker {
        const deconstructed = super.deconstruct(format) as FaciesMoniker;

        this.code = deconstructed.code;

        return deconstructed;
    }

    construct(format: IFaciesMonikerDescriptiveFormat): FaciesMoniker {
        const constructed = super.construct(format) as FaciesMoniker;

        this.code = constructed.code;

        return {
            ...constructed,
            string: `${constructed.string}${suffixSeparator}${constructed.code}`
        } as FaciesMoniker;
    }
}