import facies from 'ava-saturation/classes/dimensions/facies';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import IWidget from 'ava-saturation/interfaces/widget';

const propertyInUse = (value: any, conceptId: string, state: any, intl: any): { message: string, isInValid: boolean } => {

    let result: boolean = false;
    if (value) {
        const modelAreas = state.entitiesV1.saturationConceptState.modelArea.bySid[conceptId] as ModelArea[];
        const modelFunction = state.entitiesV1.saturationConceptState.modelFunction.bySid[conceptId] as ModelFunction[];

        const areas = modelAreas.filter(a => a.type === facies.shortName).map(a => a.id);
        result = modelFunction.any(f => f.areaIds.any(a => areas.includes(a)));
    }

    return {
        message: intl.t('validation.concept-details.facies.in-use'),
        isInValid: result
    };
};

const createdLocked = (value: any, conceptId: string, state: any, intl: any):  { message: string, isInValid: boolean } => {
    let result: boolean = false;

    if (value) {
        const widgets = state.entitiesV1.saturationConceptState.widget.bySid[conceptId] as IWidget[];
        result = widgets.length > 0;
    }

    return {
        message: intl.t('validation.concept-details.locked'),
        isInValid: result
    };
};

export default {
    referenced: [
        propertyInUse,
        createdLocked,
    ]
};