import {
    PcReservoirCalculator,
    dependencies as calculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/pc-reservoir-calculator-pure';
import DatasetCalculator from 'ava-saturation/classes/calculations/dependency-injection/calculators/dataset-calculator-pure';

import {
    capillaryPressureLab,
    capillaryPressureRes
} from 'ava-import/classes/dimensions/capillary-pressure';
import { iftLab, iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { contactAngleLab, contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';

export const dependencies = {
    functions: {
        ...calculatorDependencies.functions,
        PcReservoirCalculator,
        DatasetCalculator
    },
    dimensionsIndex: {
        ...calculatorDependencies.dimensionsIndex,
        capillaryPressureLab,
        capillaryPressureRes,
        iftLab,
        iftReservoir,
        contactAngleLab,
        contactAngleRes,
    }
};

export default function PcHeightGasDatasetCalculator(deps) {
    this.datasetCalculator = new deps.functions.DatasetCalculator(
        deps.dimensionsIndex.capillaryPressureRes,
        [deps.dimensionsIndex.capillaryPressureLab, deps.dimensionsIndex.iftLab, deps.dimensionsIndex.iftReservoir, deps.dimensionsIndex.contactAngleLab, deps.dimensionsIndex.contactAngleRes],
        new deps.functions.PcReservoirCalculator(deps)
    );

    return {
        producedDimension: this.datasetCalculator.producedDimension,
        datasetCalculator: this.datasetCalculator,
        meetsExpectations: function (dimensions) {
            return this.datasetCalculator.meetsExpectations(dimensions);
        },
        apply: function (value, dataset) {
            this.datasetCalculator.apply(value, dataset);
        }
    };
}