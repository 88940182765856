import Mixin from '@ember/object/mixin';

const DeleteConceptMixin = Mixin.create({
    conceptForDeletion: undefined,

    actions: {
        queueConceptForDeletion(concept) {
            this.set('conceptForDeletion', concept);
        },

        deleteConcept(result) {
            const conceptId = this.conceptForDeletion.id;
            this.set('conceptForDeletion', undefined);
            if (result === 'ok') {
                this.transitionToRoute('saturation-concepts.delete', conceptId);
            }
        }
    }
});

export default DeleteConceptMixin;
