export default ({
    CREATE: '@saturationConceptAction/CREATE',
    CREATE_REQUEST: '@saturationConceptAction/CREATE_REQUEST',
    CREATE_SUCCESS: '@saturationConceptAction/CREATE_SUCCESS',
    CREATE_FAILURE: '@saturationConceptAction/CREATE_FAILURE',
    FETCH: '@saturationConceptAction/FETCH',
    FETCH_REQUEST: '@saturationConceptAction/FETCH_REQUEST',
    FETCH_SUCCESS: '@saturationConceptAction/FETCH_SUCCESS',
    FETCH_FAILURE: '@saturationConceptAction/FETCH_FAILURE',
    REMOVE: '@saturationConceptActionsCache/REMOVE', // TODO: Move this or rename it.
    REMOVE_REQUEST: '@saturationConceptActionsCache/REMOVE_REQUEST', // TODO: Move this or rename it.
    REMOVE_SUCCESS: '@saturationConceptActionsCache/REMOVE_SUCCESS', // TODO: Move this or rename it.
    REMOVE_FAILURE: '@saturationConceptActionsCache/REMOVE_FAILURE', // TODO: Move this or rename it.
} as const);
