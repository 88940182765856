import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fromPairs, map } from 'lodash';
import {
    SaturationConceptAction,
    SaturationConceptActionsState,
} from './types';
import * as recordableActions from './actions';
import * as conceptActions from '../saturation-concept/actions';
// Create a tagged union type
export type SaturationConceptActionType = ActionType<typeof recordableActions> | ActionType<typeof conceptActions>;

function saturationConceptActionReducer(
    state: Record<string, SaturationConceptAction> = {},
    action: SaturationConceptActionType
) {
    switch (action.type) {
        case getType(recordableActions.createSaturationConceptActionAsync.success):
            // action payload is a single action object
            return {
                ...state,
                [action.payload.id]: action.payload
            };

        case getType(recordableActions.fetchSaturationConceptActionAsync.success): {
            // action payload is SaturationConceptAction[]
            const actionsDict = fromPairs(map(action.payload, a => [a.id, a]));
            return {
                ...state,
                ...actionsDict
            };
        }

        case getType(conceptActions.sanitizeState):
        case getType(conceptActions.deleteConcept):
            let newState = { ...state };
            const keys = Object.keys(state).filter(k => newState[k].saturationConceptId === action.payload.id);
            keys.forEach(k => delete newState[k]);
            return newState;

        case getType(recordableActions.removeSaturationConceptActionCacheAsync.success):
            // action payload = actionIds[]
            let clonedState = { ...state };
            action.payload.forEach((a: SaturationConceptAction) => delete clonedState[a.id]);
            return clonedState;

        default:
            return state;
    }
}

export default combineReducers<SaturationConceptActionsState>({
    byId: saturationConceptActionReducer
});
