export default {
    1: {
        typeId: 1,
        typeName: 'notStarted',
        shortName: 'Not Started',
        icon: 'lock_open circle'
    },
    5: {
        typeId: 5,
        typeName: 'inProgress',
        shortName: 'In Progress',
        icon: 'lock_open circle'
    },
    10: {
        typeId: 10,
        typeName: 'completed',
        shortName: 'Completed',
        icon: 'check circle'
    },
    14: {
        typeId: 14,
        typeName: 'warning',
        shortName: 'Warning',
        icon: 'warning' 
    },
    15: {
        typeId: 15,
        typeName: 'error',
        shortName: 'Error',
        icon: 'error'
    },
};