import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';
import { IWellLogDataset } from 'ava-saturation/interfaces/dataset';
import IMoniker from 'ava-saturation/interfaces/moniker';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import measureDepth, { MeasureDepthDataset, IMeasureDepthSample, MeasureDepthDatasetParser, MeasureDepthDatasetJsonFormatter } from 'ava-saturation/classes/dimensions/measure-depth';
import IDimension from 'ava-saturation/interfaces/dimension';

import UomRangeValidator, { uomInRangeKey } from 'ava-import/classes/validators/uom-range-validator';
import MissingValuesValidator, { requiredFieldValidatorKey } from 'ava-import/classes/validators/missing-values-validator';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

const waterSaturationKey = 'sw';

export class WaterSaturation extends ContinuosNumericDimension {
    /**
     * Describes the water saturation dimension
     * @ref https://www.glossary.oilfield.slb.com/Terms/w/water_saturation.aspx
     */
    constructor() {
        super();

        this.displayName = 'Water Saturation';
        this.name = this.shortName = waterSaturationKey;
        this.unitAlias = 'Saturation';

        this.validators[requiredFieldValidatorKey] = {
            bootstrap: () => new MissingValuesValidator(this.displayName, { treatEmptyAsInvalid: true })
        };
        this.validators[uomInRangeKey] = {
            bootstrap: ([uom]) => new UomRangeValidator(uom, this.displayName)
        };

        this.chartingPreferences = new ChartingAxisPreferences({ value: 0, forced: true }, { value: 1, forced: false }, false);
    }

    // Sw & Phi intentionally have the same priority
    readonly axisPriority: number = DimensionAxisDisplayPriority.WaterSaturation;
}

const waterSaturation = new WaterSaturation();

export interface IWaterSaturationSample extends IMeasureDepthSample {
    [waterSaturationKey]: number;
}

export class WaterSaturationDataset extends MeasureDepthDataset<IWaterSaturationSample> implements IWellLogDataset<IWaterSaturationSample> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super([waterSaturation], wellMoniker);

        this.primaryDimension = waterSaturation;
        this.wellLogMoniker = wellLogMoniker;
    }

    wellLogMoniker: IMoniker;
}

const waterSaturationDatasetCreator = (wellMoniker: IMoniker, wellLogMoniker: IMoniker) => {
    return () => new WaterSaturationDataset(wellMoniker, wellLogMoniker);
};

export class WaterSaturationDatasetParser extends MeasureDepthDatasetParser<IWaterSaturationSample, WaterSaturationDataset> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super(wellMoniker, waterSaturationDatasetCreator(wellMoniker, wellLogMoniker));
    }

    protected parseSample(sample: Record<string, any>): IWaterSaturationSample {
        return { ...sample } as IWaterSaturationSample;
    }
}

export const waterSaturationDatasetSettings = {
    samplesAlias: 'Samples',
    dimension: waterSaturation.toPlainObject(),
    targetDimensions: [waterSaturation, measureDepth].map(d => d.toPlainObject()),
    valueMap: {
        [measureDepth.shortName]: 'MD',
        [waterSaturation.shortName]: 'Value'
    }
};

export class SwDatasetJsonFormatter extends MeasureDepthDatasetJsonFormatter {
    constructor() {
        super(waterSaturationDatasetSettings);
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName !== waterSaturation.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        if (!monikerDictionary.wellLogMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `
            wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)},
            wellLogMoniker: ${JSON.stringify(monikerDictionary.wellLogMoniker)}
        `;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellLogMoniker.identifier}", ${JSON.stringify(waterSaturationDatasetSettings)})`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): MeasureDepthDataset<IWaterSaturationSample> | null {
        if (!monikerDictionary.wellMoniker)
            return null;

        if (!monikerDictionary.wellLogMoniker)
            return null;

        return new WaterSaturationDataset(monikerDictionary.wellMoniker, monikerDictionary.wellLogMoniker);
    }

    getSampleFormat(samplePrefix: string): string {
        return `
            ${super.getSampleFormat(samplePrefix)},
            ${waterSaturation.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[waterSaturation.shortName]}
        `;
    }
}

export default waterSaturation;
