import RSVP from 'rsvp';

export function addSaturationConceptState(stateStore, saturationConceptId, saturationConceptState) {
    return new RSVP.Promise((resolve, reject) => {
        var addRequest = stateStore.add({
            saturationConceptId,
            versionId: saturationConceptState.versionId,
            patchId: saturationConceptState.patchId,
            aggregate: saturationConceptState.aggregate
        });

        addRequest.onsuccess = function (successEvent) {
            resolve(successEvent.target.result);
        };

        addRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    }).then(x => {
        return x;
    });
}

export function updateSaturationConceptState(stateStore, saturationConceptId, saturationConceptState) {
    return new RSVP.Promise((resolve, reject) => {
        var updateRequest = stateStore.put({
            saturationConceptId,
            versionId: saturationConceptState.versionId,
            patchId: saturationConceptState.patchId,
            aggregate: saturationConceptState.aggregate
        });

        updateRequest.onsuccess = function (successEvent) {
            resolve(successEvent.target.result);
        };

        updateRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    }).then(x => {
        return x;
    });
}

export function getSaturationConceptState(stateStore, saturationConceptId) {
    return (new RSVP.Promise((resolve, reject) => {
        let cursor = stateStore.get(saturationConceptId);

        cursor.onsuccess = function (successEvent) {
            let result = successEvent.target.result;

            resolve(result);
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    })).then(x => {
        return x;
    });
}

export function removeSaturationConceptStateByConceptId(stateStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var request = stateStore.delete(saturationConceptId);

        request.onsuccess = function (successEvent) {
            var result = successEvent.target.result;
            resolve(result);
        };

        request.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    }).then(x => {
        return x;
    });
}
