import IValidator from 'ava-import/interfaces/validator';
import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

export enum DimensionType {
    Numeric,
    Categorical
}

export enum NumericDimensionCategory {
    Discrete,
    Continuous
}

export default interface IDimension {
    name: string;
    shortName: string;
    displayName: string;
    type: DimensionType;
    valueField: string;
    // TODO [NV]: Add internal/external flag for the dimensions

    unitAlias: string;

    // This is set runtime when dimensions are loaded
    unitName: string;
    displayUnitSymbols: string[];

    serialize(): string;

    toPlainObject(): Record<string, any>;

    // TODO [NV]: need to work-around typing resctrictions - this is not good
    validators: {
        [key: string]: {
            bootstrap: (...args: any[]) => IValidator<any>
        }
    };

    // TODO [TT]: This does not belong here
    axisPriority: DimensionAxisDisplayPriority;

    chartingPreferences: ChartingAxisPreferences;
}

export interface INumericDimension extends IDimension {
    type: DimensionType.Numeric;
    category: NumericDimensionCategory;
    scaleType: ContinuousNumericScaleType;
}

export interface IDiscreteNumericDimension extends INumericDimension {
    category: NumericDimensionCategory.Discrete;
}

export interface IContinuousNumericDimension extends IDimension {
    category: NumericDimensionCategory.Continuous;
    forcedScaleOverride: ContinuousNumericScaleType;
}

export enum DimensionAxisDisplayPriority {
    None = 0,
    MeasuredDepth = None + 1,
    TVD = MeasuredDepth + 1,
    HAFWL = TVD + 1,
    PcHeight = HAFWL,
    // Phi & Sw intentionaly have the same priority
    Porosity = HAFWL + 1,
    WaterSaturation = Porosity,
    IFT = Porosity + 1,
    ContactAngle = IFT,
    PressureGradient = ContactAngle,
    RelativePermeability = PressureGradient,
    Zone = 5,
    Segment = Zone + 1,
    Facies = Segment + 1,
    Well = Facies + 1,
    WellLog = Well + 1,
    BVW = Porosity + WaterSaturation,
    JValue = WaterSaturation + PcHeight,
    CapillaryPressure = JValue + 1,
}