export function initialize(application) {
    application.inject('route', 'telemetry', 'service:telemetry');
    application.inject('controller', 'telemetry', 'service:telemetry');
    application.inject('component', 'telemetry', 'service:telemetry');
    application.inject('model', 'telemetry', 'service:telemetry');
}

export default {
    name: 'telemetry',
    initialize
};
