// probably not the correct place to do this...
import { contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import { iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { DataSourceDefinition, generalLogSourceDefinition, pointLogSourceDefinition } from './data-source';
import { allTypes } from './band-types';

export class CalculationType {
    constructor(id: string, name: string, dataSourceDefinition: DataSourceDefinition, mappingParameters: Record<string, number | undefined>) {
        this.id = id;
        this.name = name;
        this.dataSourceDefinition = dataSourceDefinition;
        this.mappingParameters = mappingParameters;
    }

    id: string;
    name: string;
    dataSourceDefinition: DataSourceDefinition;
    mappingParameters: Record<string, number | undefined>;
}

export const cuddy = new CalculationType('cuddy', 'Cuddy FOIL', generalLogSourceDefinition, {});
export const skelt = new CalculationType('skelt', 'Skelt-Harrison', generalLogSourceDefinition, {});
export const leverettJ = new CalculationType('leverett', 'Leverett J', pointLogSourceDefinition, {
    [iftReservoir.shortName]: undefined,
    [contactAngleRes.shortName]: undefined,
    [waterGradient.shortName]: undefined,
    hydrocarbonGradient: undefined
});

export const availableCalculationMethods = (bandType: string) => {

    switch (bandType) {
        case allTypes.general.id: {
            return [cuddy, skelt, leverettJ];
        }
        case allTypes.porosity.id: {
            return [cuddy, leverettJ];
        }
        case allTypes.permeability.id: {
            return [leverettJ];
        }
        default:
            throw 'Missing calculation set for band!';
    }
};