// import IMoniker from 'ava-saturation/interfaces/moniker';
// import { PlotPresentationalType } from 'ava-saturation/interfaces/presenter';
// import { fromPairs, map } from 'lodash';
// import { Axis, Legend, PayloadPresenterSettingsDictionary, Widget, DeflatedWidget, PlotSettings, WidgetState } from './types';
// import { get } from 'lodash';
import { fromPairs, map } from 'lodash';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as actions from './actions';
import { DeflatedWidget, WidgetState } from './types';

export type WidgetActionType = ActionType<typeof actions> | ActionType<typeof saturationConceptActions>;

export function widgets(state: DeflatedWidget[] = [], action: WidgetActionType) {
    switch (action.type) {
        case getType(actions.addDeflatedWidget):
        case getType(actions.updateDeflatedWidget):
            return [
                ...state.filter(s => s.id !== action.payload.id),
                { ...action.payload }
            ];
        case getType(actions.deleteWidget):
            return state.filter(f => f.id !== action.payload.id);
        default:
            return state;
    }
}

export function widgetsBySid(state: Record<string, DeflatedWidget[]> = {}, action: WidgetActionType) {
    switch (action.type) {
        case getType(actions.addDeflatedWidget):
        case getType(actions.updateDeflatedWidget):
        case getType(actions.addWidget):
        case getType(actions.deleteWidget):
            return {
                ...state,
                [action.payload.sid]: widgets(state[action.payload.sid], action)
            };

        case getType(saturationConceptActions.createConcept):
            return {
                ...state,
                [action.payload.id]: widgets(state[action.payload.id], action)
            };

        case getType(saturationConceptActions.addConcepts): {
            const actionsDict = fromPairs(map(action.payload, a => [a.id, state[a.id] ? [...state[a.id]] : []]));
            return { ...actionsDict };
        }

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept):
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;

        default:
            return state;

    }
}

export default combineReducers<WidgetState>({
    bySid: widgetsBySid
});

// export function widgets(state: Widget[] = [], action: WidgetActionType) {
//     switch (action.type) {
//         case getType(actions.addSerializedWidget):
//             return [
//                 ...state, {
//                     ...toWidget(action.payload)
//                 }
//             ];
//         case getType(actions.deleteWidget):
//             return state.filter(f => f.id !== action.payload.id);
//         default:
//             return state;
//     }
// }
// const toWidget = (payload: DeflatedWidgetPayload): Widget => {
//     return {
//         id: payload.id,
//         sid: payload.sid,
//         name: payload.name,
//         acitveDataSource: payload.dataSourceInstance.definition.type,
//         activePlot: payload.dataSourceInstance.plotDefinition.type,
//         dataSource: {
//             [payload.dataSourceInstance.definition.type]: {
//                 type: payload.dataSourceInstance.definition.type,
//                 inputs: reduceWellModelingContextCollection(payload.dataSourceInstance.wellModelingContextCollection)
//             }
//         },
//         plotSettings: reducePresenterSettingsDictionary(payload.dataSourceInstance.presenterSettingsDictionary)
//     };
// };

// const reduceWellModelingContextCollection = (collection: { byMoniker: Record<string, { byDimensionKey: Record<string, { allMonikers: IMoniker[] }> }> }): Record<string, Record<string, string[]>> => {
//     return Object.keys(collection.byMoniker).reduce((m, mkey) => {
//         return {
//             ...m,
//             [mkey]: Object.keys(collection.byMoniker[mkey].byDimensionKey).reduce((d, dkey) => {
//                 return {
//                     ...d,
//                     [dkey]: collection.byMoniker[mkey].byDimensionKey[dkey].allMonikers.map(moniker => moniker.string)
//                 };
//             }, {})
//         };
//     }, {});
// };

// const reducePresenterSettingsDictionary = (dict: Record<string, PayloadPresenterSettingsDictionary>): Record<number, PlotSettings> => {

//     return Object.keys(dict).reduce((presenterSettings, dkey) => {

//         let legend = dict[dkey].legendSettings;

//         let result: Record<number, PlotSettings> = {
//             ...presenterSettings,
//             [dkey]: {
//                 type: dkey,
//                 legend: {
//                     title: legend && legend.title || ''
//                 },
//                 axes:
//                     Object.keys(dict[dkey]).filter(k => k.includes('AxisSettings')).map(k => {
//                         const setting = get(dict[dkey], k);
//                         return {
//                             key: k[0],
//                             dimensionKey: setting.selectedOption.dimension.shortName,
//                             settings: { ...setting.selectedOption.settings }
//                         };
//                     })
//             }
//         };

//         return result;
//     }, {} as Record<number, PlotSettings>);
// };
