import WellLogReferenceType, { WellLogReferenceParser, WellLogCollectionReferenceParser } from 'ava-saturation/classes/well-log-reference-type';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import IWellLog, { IWellLogFilter } from 'ava-saturation/interfaces/well-log-reference';

export default class WaterSaturationLogReferenceType extends WellLogReferenceType {
    constructor() {
        super(waterSaturation, [waterSaturation]);
    }

    id = 212;
    name = 'water-saturation-log';
    subTypeAliases = ['PetrelWaterSaturationTemplate'];
}

export const waterSaturationLogReferenceType = new WaterSaturationLogReferenceType();

export class WaterSaturationLogReferenceParser extends WellLogReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, waterSaturationLogReferenceType);
    }
}

export class WaterSaturationLogCollectionReferenceParser extends WellLogCollectionReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, waterSaturationLogReferenceType);
    }
}

export class WaterSaturationLogFilter implements IWellLogFilter {
    predicate(wellLog: IWellLog) {
        return wellLog.type === waterSaturationLogReferenceType;
    }
}