import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { ModelFunction, ModelFunctionState } from './types';
import * as actions from './actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import { fromPairs, map } from 'lodash';

export type ModelFunctionActionType = ActionType<typeof actions> | ActionType<typeof saturationConceptActions>;

export function modelFunctions(state: ModelFunction[] = [], action: ModelFunctionActionType) {
    switch (action.type) {
        case getType(actions.addFunction):
            return [
                ...state, {
                    ...action.payload
                }
            ];
        case getType(actions.updateBandType):
            return state.map(f => f.id === action.payload.id ? { ...f, bandType: action.payload.bandType } : f);
        case getType(actions.updateFunctionName): {
            return state.map(f => f.id === action.payload.id ? {
                ...f,
                name: action.payload.name
            } : f);
        }
        case getType(actions.deleteFunction):
            return state.filter(f => f.id !== action.payload.id);
        default:
            return state;
    }
}

export function modelFunctionsBySid(state: Record<string, ModelFunction[]> = {}, action: ModelFunctionActionType) {
    switch (action.type) {
        case getType(actions.addFunction):
        case getType(actions.updateFunctionName):
        case getType(actions.updateBandType):
        case getType(actions.deleteFunction):
            return {
                ...state,
                [action.payload.sid]: modelFunctions(state[action.payload.sid], action)
            };
        case getType(saturationConceptActions.createConcept):
            return {
                ...state,
                [action.payload.id]: modelFunctions(state[action.payload.id], action)
            };
        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept):
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;

        case getType(saturationConceptActions.addConcepts): {
            const actionsDict = fromPairs(map(action.payload, a => [a.id, state[a.id] ? [...state[a.id]] : []]));
            return { ...actionsDict };
        }

        default:
            return state;

    }
}

export default combineReducers<ModelFunctionState>({
    bySid: modelFunctionsBySid
});
