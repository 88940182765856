import { IWellLogDataset } from 'ava-saturation/interfaces/dataset';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';

import IMoniker from 'ava-saturation/interfaces/moniker';
import measureDepth, { IMeasureDepthSample, MeasureDepthDataset, MeasureDepthDatasetParser, MeasureDepthDatasetJsonFormatter } from 'ava-saturation/classes/dimensions/measure-depth';
import IDimension from 'ava-saturation/interfaces/dimension';

import IsZeroValidator, { isZeroKey } from 'ava-import/classes/validators/is-zero-validator';
import UomRangeValidator, { uomInRangeKey } from 'ava-import/classes/validators/uom-range-validator';
import MissingValuesValidator, { requiredFieldValidatorKey } from 'ava-import/classes/validators/missing-values-validator';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

// import { Porosity as PorosityBase } from '@ava-saturation/dimensions';

const porosityKey = 'por';

export class Porosity extends ContinuosNumericDimension {
    /**
     * Describes the porosity dimension
     * @ref https://www.glossary.oilfield.slb.com/Terms/p/porosity.aspx
     */
    constructor() {
        super();

        // console.log(new Test().shortName);
        this.displayName = 'Porosity';
        this.name = this.shortName = porosityKey;
        this.unitAlias = 'Porosity';

        this.validators[requiredFieldValidatorKey] = {
            bootstrap: () => new MissingValuesValidator(this.displayName)
        };
        this.validators[isZeroKey] = {
            bootstrap: () => new IsZeroValidator(this.displayName)
        };
        this.validators[uomInRangeKey] = {
            bootstrap: ([uom]) => new UomRangeValidator(uom, this.displayName)
        };

        this.chartingPreferences = new ChartingAxisPreferences({ value: 0, forced: true }, { value: 1, forced: false }, false);
    }

    // Sw & Phi intentionally have the same priority
    readonly axisPriority: number = DimensionAxisDisplayPriority.Porosity;
}

const porosity = new Porosity();

export interface IPorositySample extends IMeasureDepthSample {
    [porosityKey]: number;
}

export class PorosityDataset extends MeasureDepthDataset<IPorositySample> implements IWellLogDataset<IPorositySample> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super([porosity], wellMoniker);

        this.primaryDimension = porosity;
        this.wellLogMoniker = wellLogMoniker;
    }

    wellLogMoniker: IMoniker;
}

const porosityDatasetCreator = (wellMoniker: IMoniker, wellLogMoniker: IMoniker) => {
    return () => new PorosityDataset(wellMoniker, wellLogMoniker);
};

export class PorosityDatasetParser extends MeasureDepthDatasetParser<IPorositySample, PorosityDataset> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super(wellMoniker, porosityDatasetCreator(wellMoniker, wellLogMoniker));
    }

    protected parseSample(sample: Record<string, any>): IPorositySample {
        return { ...sample } as IPorositySample;
    }
}

export const porosityDatasetSettings = {
    samplesAlias: 'Samples',
    dimension: porosity.toPlainObject(),
    targetDimensions: [measureDepth, porosity].map(d => d.toPlainObject()),
    valueMap: {
        [measureDepth.shortName]: 'MD',
        [porosity.shortName]: 'Value'
    }
};

export class PorosityDatasetJsonFormatter extends MeasureDepthDatasetJsonFormatter {
    constructor() {
        super(porosityDatasetSettings);
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName !== porosity.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        if (!monikerDictionary.wellLogMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `
            wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)},
            wellLogMoniker: ${JSON.stringify(monikerDictionary.wellLogMoniker)}
        `;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellLogMoniker.identifier}", ${JSON.stringify(porosityDatasetSettings)})`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): MeasureDepthDataset<IPorositySample> | null {
        return new PorosityDataset(monikerDictionary.wellMoniker, monikerDictionary.wellLogMoniker);
    }

    getSampleFormat(samplePrefix: string): string {
        return `
            ${super.getSampleFormat(samplePrefix)},
            ${porosity.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[porosity.shortName]}
        `;
    }
}

export default porosity;