import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as referenceVersionActions from './actions';
import { MonikerVersion, ReferenceVersionState, RVStateItem } from './types';

export type ReferenceVersionActionType = ActionType<typeof referenceVersionActions> | ActionType<typeof saturationConceptActions>;

export const referenceVersionById = (state: { [key: string]: RVStateItem } = {}, action: ReferenceVersionActionType) => {

    switch (action.type) {
        case getType(referenceVersionActions.addReferenceVersion):
        case getType(referenceVersionActions.updateReferenceVersion):
        case getType(referenceVersionActions.setReferenceVersionActionAsync.success):
            return {
                ...state,
                [action.payload.id]: {
                    id: action.payload.id,
                    sid: action.payload.sid,
                    byMoniker: referenceVersionByMoniker(state[action.payload.id] ? state[action.payload.id].byMoniker : {}, action)
                }
            };

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            const keys = Object.keys(state).filter(k => newState[k].sid === action.payload.id);
            keys.forEach(k => delete newState[k]);
            return newState;
        }

        case getType(referenceVersionActions.deleteReferenceVersion): {
            const { [action.payload.id]: value, ...newState } = state;
            return newState;
        }
        default:
            return state;
    }
};

const referenceVersionByMoniker = (state: { [key: string]: MonikerVersion } = {}, action: ReferenceVersionActionType) => {

    switch (action.type) {
        case getType(referenceVersionActions.addReferenceVersion):
        case getType(referenceVersionActions.updateReferenceVersion):
        case getType(referenceVersionActions.setReferenceVersionActionAsync.success):
            return {
                ...state,
                [action.payload.moniker]: {
                    moniker: action.payload.moniker,
                    timestamp: action.payload.timestamp
                }
            };
        default:
            return state;
    }
};

export default combineReducers<ReferenceVersionState>({
    byId: referenceVersionById
});