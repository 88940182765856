import { setReferenceVersionActionAsync } from 'ava-saturation/store/entities-v1/saturation-concept-state/reference-version/actions';
import { call, put, takeEvery as takeLatest } from 'redux-saga/effects';

export const getVersion = (moniker: string): number => {
    console.log(moniker);
    return Date.now();
};

export function* setReferenceVersion(action: ReturnType<typeof setReferenceVersionActionAsync.request>) {

    const { payload } = action;
    const { id, sid, moniker } = payload;

    const timestamp = yield call(getVersion, moniker);

    yield put(setReferenceVersionActionAsync.success({ id, moniker, sid, timestamp }));
}

export default function* watch() {
    yield takeLatest(setReferenceVersionActionAsync.request, setReferenceVersion);
}
