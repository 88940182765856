import PdfkitDocument from 'pdfkit';

export class PDFDocument extends PdfkitDocument {
    [x: string]: any;

    bufferedPageRange() {
        return {
            start: this._pageBufferStart,
            end: this._pageBufferStart + this._pageBuffer.length - 1,
            count: this._pageBuffer.length,
            current: this._pageBuffer.indexOf(this.page)
        };
    }

    addPage(options?: PDFKit.PDFDocumentOptions): PDFKit.PDFDocument {
        const { current, end } = this.bufferedPageRange();
        if (current < end) {
            this.switchToPage(current + 1);
            this.x = this.page.margins.left;
            this.y = this.page.margins.top;
            return this;
        }

        return super.addPage(options);
    }
}

export default PDFDocument;
