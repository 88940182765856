import { IWellDataset, IDatasetSample } from 'ava-saturation/interfaces/dataset';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';

import IMoniker from 'ava-saturation/interfaces/moniker';
import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';
import IDimension from 'ava-saturation/interfaces/dimension';
import { DatasetParserBase } from 'ava-saturation/interfaces/dataset-parser';

import UomRangeValidator, { uomInRangeKey } from 'ava-import/classes/validators/uom-range-validator';
import MissingValuesValidator, { requiredFieldValidatorKey } from 'ava-import/classes/validators/missing-values-validator';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

const measureDepthKey = 'md';

export class MeasuredDepth extends ContinuosNumericDimension {
    /**
     * Describes the measured depth dimension
     * @ref https://www.glossary.oilfield.slb.com/en/Terms/m/measured_depth.aspx
     */
    constructor() {
        super();

        this.displayName = 'Measured Depth';
        this.name = this.shortName = measureDepthKey;
        this.unitAlias = 'Standard_Depth_Index';

        this.validators[requiredFieldValidatorKey] = {
            bootstrap: () => new MissingValuesValidator(this.displayName)
        };
        this.validators[uomInRangeKey] = {
            bootstrap: ([uom]) => new UomRangeValidator(uom, this.displayName)
        };

        this.chartingPreferences = new ChartingAxisPreferences({ value: 0, forced: false }, { value: 10000, forced: false }, true);
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.MeasuredDepth;
}

const measureDepth = new MeasuredDepth();

export interface IMeasureDepthSample extends IDatasetSample {
    [measureDepthKey]: number;
}

export class MeasureDepthDataset<T extends IMeasureDepthSample> implements IWellDataset<T> {
    primaryDimension: IDimension;
    secondaryDimension: IDimension;
    dimensions: IDimension[];
    values: T[];
    wellMoniker: IMoniker;
    metadata: Record<string, any>;

    constructor(dimensions: IDimension[], wellMoniker: IMoniker) {
        this.primaryDimension = measureDepth;
        this.secondaryDimension = measureDepth;

        this.dimensions = dimensions;
        this.dimensions.push(measureDepth);
        this.wellMoniker = wellMoniker;
    }
}

const measureDepthDatasetCreator = (wellMoniker: IMoniker) => {
    return () => new MeasureDepthDataset<IMeasureDepthSample>([], wellMoniker);
};

export class MeasureDepthDatasetParser<T extends IMeasureDepthSample, TDataset extends MeasureDepthDataset<T>> extends DatasetParserBase<T, TDataset> {
    constructor(wellMoniker: IMoniker, datasetCreator?: () => TDataset) {
        super(datasetCreator || <() => TDataset> measureDepthDatasetCreator(wellMoniker));
    }

    protected parseSample(sample: Record<string, any>): T {
        return <T> { [measureDepthKey]: <number> sample.MD };
    }
}

export class JsonFormatterSettings {
    provider: string;
    sampleFormatter: string;
    converterMap: string;
    dimensionIndexMap: Record<string, string>;
    contextKey: string;
}

export class MeasureDepthDatasetJsonFormatter {
    datasetSettings: Record<string, any>;

    constructor(datasetSettings: Record<string, any>) {
        this.datasetSettings = datasetSettings;
    }

    format(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }, settings: JsonFormatterSettings) {
        if (this.test(dimension, monikerDictionary) == false)
            return null;

        let blankDataset = this.getBlankDataset(monikerDictionary);

        if (!blankDataset)
            return null;

        let dimensionRegister = blankDataset.dimensions.map(d => `${settings.contextKey}.dimensionsIndex.${settings.dimensionIndexMap[d.shortName]}`);

        const datasetName = `${dimension.shortName}Dataset`;

        return {
            datasetName: datasetName,
            definition: `
            const ${dimension.shortName}ConverterMap = ${settings.converterMap}([${dimensionRegister}]);
            const ${datasetName} = {
                values: ${this.formatProviderString(settings.provider, monikerDictionary, settings.dimensionIndexMap)}.map(s => {
                    return ${settings.sampleFormatter}(s, {
                        targetDimensions: [${dimensionRegister}],
                        valueMap: ${JSON.stringify(this.datasetSettings.valueMap)}
                    }, ${dimension.shortName}ConverterMap);
                }),
                dimensions: [${dimensionRegister}],
                ${this.formatMonikers(monikerDictionary)}
            };`
        };
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName != measureDepth.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)}`;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellMoniker}")`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): IWellDataset<IMeasureDepthSample> | null {
        return new MeasureDepthDataset<IMeasureDepthSample>([], monikerDictionary.wellMoniker);
    }

    // not in use with the latest changes
    getSampleFormat(samplePrefix: string): string {
        return `${measureDepth.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[measureDepth.shortName]}`;
    }
}

export default measureDepth;
