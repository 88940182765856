import ObjectPromiseProxy from 'ava-saturation/classes/object-promise-proxy';
import RSVP from 'rsvp';
import IDataset, { IDatasetSample } from 'ava-saturation/interfaces/dataset';
import IDatasetParser from 'ava-saturation/interfaces/dataset-parser';

export default class DatasetObjectPromiseProxy<TReturnValue extends IDataset<IDatasetSample> | IDataset<IDatasetSample>[]> extends ObjectPromiseProxy<TReturnValue | never> {
    // Property '[Symbol.toStringTag]' is missing in type 'Fix<ReferenceObjectPromiseProxy<IReference | IReference[]> & { promise: Promise<T>; }>'
    // There should be a work-around. Still seeing the error on my side. Surprisingly it works
    // Attempted to install @types/es6-promise & @types/es6-collections - didn't do the job
    // See ObjectPromiseProxy for the "FIX"

    public static createAndParse<TReturnValue extends IDataset<IDatasetSample>>(promise: RSVP.Promise<any>, parser: IDatasetParser<IDatasetSample, TReturnValue>, settings: { autoParse: boolean } = { autoParse: true }): DatasetObjectPromiseProxy<TReturnValue> {
        return DatasetObjectPromiseProxy.create({
            promise: promise.then((result: string) => {
                var fromString = settings.autoParse ? JSON.parse(result) : result;
                return parser.parse(fromString);
            })
        }) as DatasetObjectPromiseProxy<TReturnValue>;
    }

    public static createDataSetPromise<T extends IDataset<IDatasetSample> | IDataset<IDatasetSample>[]>(promise: RSVP.Promise<any>): DatasetObjectPromiseProxy<T> {
        return DatasetObjectPromiseProxy.create({
            promise
        }) as DatasetObjectPromiseProxy<T>;
    }

    public static empty<T extends IDataset<IDatasetSample>>(value: any): DatasetObjectPromiseProxy<T> {
        return DatasetObjectPromiseProxy.create({
            promise: RSVP.Promise.resolve<T>(<T> value)
        }) as DatasetObjectPromiseProxy<T>;
    }

    public next<TResult1 extends IDataset<IDatasetSample> | IDataset<IDatasetSample>[] = TReturnValue, TResult2 extends IDataset<IDatasetSample> | IDataset<IDatasetSample>[] = never>(
        onFulfilled?: ((value: TReturnValue) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
        label?: string
    ): DatasetObjectPromiseProxy<TResult1 | TResult2> {
        return DatasetObjectPromiseProxy.createDataSetPromise<TResult1 | TResult2>(
            this.then(onFulfilled, onRejected, label)
        );
    }
}