import Component from '@ember/component';
import { set } from '@ember/object';

export default class PromptDialog extends Component {
    title: string;
    message: string;
    ok: string;
    cancel: string;

    constructor() {
        super(...arguments);
        set(this, 'ok', 'Ok');
        set(this, 'cancel', 'Cancel');
    }
}