import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
    ModelInput,
    ModelInputsState
} from './types';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as modelInputActions from './actions';
import { fromPairs, map } from 'lodash';
// Create a tagged union type
export type ModelInputAction = ActionType<typeof modelInputActions> | ActionType<typeof saturationConceptActions>;

function modelInputs(state: ModelInput[] = [], action: ModelInputAction) {
    switch (action.type) {
        case getType(modelInputActions.addModelInput): {
            // dispose of all the inputs which are with the same type for that concept/grid selection
            let clone = state.filter(input => input.modelMoniker !== action.payload.modelMoniker || input.type !== action.payload.type);
            return [
                ...clone, {
                    ...action.payload
                }
            ];
        }

        default:
            return state;
    }
}

export function modelInputsBySid(state: Record<string, ModelInput[]> = {}, action: ModelInputAction) {
    switch (action.type) {
        case getType(modelInputActions.addModelInput):
            return {
                ...state,
                [action.payload.sid]: modelInputs(state[action.payload.sid], action)

            };
        case getType(saturationConceptActions.createConcept):
            return {
                ...state,
                [action.payload.id]: modelInputs(state[action.payload.id], action)
            };
        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept):
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;

        case getType(saturationConceptActions.addConcepts): {
            const actionsDict = fromPairs(map(action.payload, a => [a.id, state[a.id] ? [...state[a.id]] : []]));
            return { ...actionsDict };
        }
        default:
            return state;
    }
}

export default combineReducers<ModelInputsState>({
    bySid: modelInputsBySid
});
