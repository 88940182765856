import bvw from 'ava-saturation/classes/dimensions/bvw';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import {
    BVWCalculator,
    dependencies as calculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/bvw-calculator-pure';
import DatasetCalculator from 'ava-saturation/classes/calculations/dependency-injection/calculators/dataset-calculator-pure';

export const dependencies = {
    functions: {
        ...calculatorDependencies.functions,
        BVWCalculator,
        DatasetCalculator
    },
    dimensionsIndex: {
        ...calculatorDependencies.dimensionsIndex,
        bvw,
        waterSaturation,
        porosity
    }
};

export default function BVWDatasetCalculator(deps) {
    this.datasetCalculator = new deps.functions.DatasetCalculator(
        deps.dimensionsIndex.bvw,
        [deps.dimensionsIndex.porosity, deps.dimensionsIndex.waterSaturation],
        new deps.functions.BVWCalculator(deps)
    );

    return {
        producedDimension: this.datasetCalculator.producedDimension,
        datasetCalculator: this.datasetCalculator,
        meetsExpectations: function (dimensions) {
            return this.datasetCalculator.meetsExpectations(dimensions);
        },
        apply: function(value, dataset) {
            this.datasetCalculator.apply(value, dataset);
        }
    };
}