import configuration from 'ava-saturation/config/environment';

export function initialize(instance) {
    instance.lookup('service:intl').setLocale(configuration.APP.locale);
}

export default {
    initialize,
    name: 'ava-internationalization',
};
