import { NonNumericDimension } from 'ava-saturation/classes/dimension';

const faciesPropertyKey = 'faciesProperty';

export class FaciesProperty extends NonNumericDimension {
    static id: string = faciesPropertyKey;

    constructor() {
        super();

        this.displayName = 'FaciesProperty';
        this.name = this.shortName = faciesPropertyKey;
    }
}

export const faciesProperty = new FaciesProperty();
