import Mixin from '@ember/object/mixin';
import AuthenticatedRouteMixin from 'ava-saturation/mixins/auth/authenticated-route-mixin';
import { observer } from '@ember/object';
import configuration from 'ava-saturation/config/environment';

export default Mixin.create(AuthenticatedRouteMixin, {
    avaClasticsLicenseObserver: observer('session.isAuthenticated', 'session.applicationLicense', function () {
        if (this.get('session.isAuthenticated')
            && (this.get('session.licenseStatus') === 'no-license'
                || this.get('session.licenseStatus') === 'expired')) {
            this.transitionTo(configuration.APP.routeIfNoLicense);
        }
    }),

    beforeModel() {
        if (this.get('session.isAuthenticated')
            && (this.get('session.licenseStatus') === 'no-license'
                || this.get('session.licenseStatus') === 'expired')) {
            this.transitionTo(configuration.APP.routeIfNoLicense);
        } else {
            return this._super(...arguments);
        }
    },
});
