import { NonNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import { faciesCodeReferenceType } from 'ava-saturation/classes/facies-code-reference-type';
import { porosityLogReferenceType } from 'ava-saturation/classes/porosity-log-reference-type';
import { waterSaturationLogReferenceType } from 'ava-saturation/classes/water-saturation-log-reference-type';

const wellLogKey = 'well-log';

const wellLog = new NonNumericDimension();
wellLog.displayName = 'Well Log';
wellLog.name = wellLog.shortName = wellLogKey;
wellLog.axisPriority = DimensionAxisDisplayPriority.WellLog;

export interface IWellLogSample {
    [wellLogType: string]: string;
}

export default wellLog;

const porosityLogKey = porosityLogReferenceType.name;

const porosityLog = new NonNumericDimension();
porosityLog.displayName = 'Porosity Log';
porosityLog.name = porosityLog.shortName = porosityLogKey;

const waterSaturationLogKey = waterSaturationLogReferenceType.name;

const waterSaturationLog = new NonNumericDimension();
waterSaturationLog.displayName = 'Water Saturation Log';
waterSaturationLog.name = waterSaturationLog.shortName = waterSaturationLogKey;

const faciesLogKey = faciesCodeReferenceType.name;

const faciesLog = new NonNumericDimension();
faciesLog.displayName = 'Facies Log';
faciesLog.name = faciesLog.shortName = faciesLogKey;

export {
    porosityLog,
    waterSaturationLog,
    faciesLog
};