import Component from '@ember/component';

export default Component.extend({
    tagName: 'md-expansion-panel',
    classNameBindings: ['expanded:md-open:md-close'],

    expanded: false,
    monitorKeyDownEvents: true,

    expand(event) {
        this.set('expanded', true);
        if (this.onExpandedChange) {
            this.onExpandedChange(true, event);
        }
    },

    collapse(event) {
        this.set('expanded', false);
        if (this.onExpandedChange) {
            this.onExpandedChange(false, event);
        }
    },

    keyDown(ev) {
        if (this.monitorKeyDownEvents) {
            switch (ev.keyCode) {
                case 13: // enter
                    this.expand();
                    break;
                case 27: // escape
                    this.collapse();
                    break;
            }
        }
    }
});