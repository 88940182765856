import { inject } from '@ember/service';
import Service from '@ember/service';
import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import reducers from 'ava-saturation/store';
import middlewares from 'ava-saturation/middlewares/index';
import {
    getApplicationtState,
    updateApplicationState
} from 'ava-saturation/cache/application-state';
import configuration from 'ava-saturation/config/environment';
import uuid from 'ava-saturation/utils/uuid';
import { getConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { createSaturationConceptActionAsync } from 'ava-saturation/store/entities-v1/saturation-concept-actions/actions';
import excludeProperties from 'ava-saturation/utils/exclude-props';
import { merge } from 'lodash';

// TODO: Disable for prod.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const extractMiddlewares = (middlewaresParam) => {
    return middlewaresParam.map(m => typeof (m) === 'function' ? m : m.middleware);
};

const extractMiddlewareSetups = (middlewaresParam) => {
    return middlewaresParam.map(m => typeof (m) === 'function' ? () => { } : m.setup);
};

const makeStoreInstance = ({ middlewares: middlewaresParam, state, reducers }) => {
    if (state) {
        return createStore(reducers, state, composeEnhancers(
            applyMiddleware(...middlewaresParam)
        ));
    }

    return createStore(reducers, composeEnhancers(
        applyMiddleware(...middlewaresParam)
    ));
};

const excludedKeys = ['type', 'saturationConceptId', 'conceptId', 'recordable', 'trackable', 'anchorVersionId', 'anchorPatchId'];

export default Service.extend({
    middlewares,
    reducers,
    extractMiddlewares,
    extractMiddlewareSetups,
    makeStoreInstance,
    dataStore: inject('store'),
    indexedDb: inject('indexed-db'),
    initStore(state) {
        const _reducers = this.reducers;
        const _middlewares = this.middlewares;

        const extractedMiddlewares = this.extractMiddlewares(_middlewares);
        const extractedSetups = this.extractMiddlewareSetups(_middlewares);

        this.store = this.makeStoreInstance({
            middlewares: extractedMiddlewares,
            state,
            reducers: _reducers
        });

        extractedSetups.forEach(s => s({
            reduxStore: this.store,
            dataStore: this.dataStore,
            database: this.indexedDb
        }));
    },
    async rehydrateStore() {
        const db = await this.indexedDb.openConnection();

        let transaction = db.transaction(['appState'], 'readwrite');
        let appStateStore = transaction.objectStore('appState');

        const result = await getApplicationtState(appStateStore, 'redux');

        //this is the place to migrate the state if needed (could wipe it out as well)
        //state.version
        const state = result ? result.applicationState : null;
        this.initStore(state);

        await updateApplicationState(appStateStore, 'redux', configuration.APP.devVersion, this.getState());

        this.indexedDb.disposeConnection();

    },
    async saveState() {
        const db = await this.indexedDb.openConnection();

        let transaction = db.transaction(['appState'], 'readwrite');
        let appStateStore = transaction.objectStore('appState');

        await updateApplicationState(appStateStore, 'redux', configuration.APP.devVersion, this.getState());

        this.indexedDb.disposeConnection();
    },
    getState() {
        return this.store.getState();
    },
    dispatch(action) {
        this.store.dispatch(action);

        if (action.recordable || action.trackable) {
            const concept = getConceptById(this.getState(), { sid: action.payload.sid });

            let candidate = {
                id: uuid(),
                'action-type': action.type,
                time: new Date(),
                recordable: action.recordable,
                trackable: action.trackable,
                anchorVersionId: concept.versionId,
                anchorPatchId: concept.patchId,
                saturationConceptId: concept.id
            };

            candidate = merge(candidate, {
                parameters: excludeProperties(action, excludedKeys)
            });

            this.store.dispatch(createSaturationConceptActionAsync.success(candidate));
        }

        this.saveState();
    },
    subscribe(func) {
        return this.store.subscribe(func);
    },
    replaceReducer(nextReducer) {
        return this.store.replaceReducer(nextReducer);
    }
});
