import toFixedNumber from 'ava-saturation/utils/to-fixed-number';

export const dependencies = {
    functions: {
        toFixedNumber
    },
    dimensionsIndex: {}
};

export function HAFWLCalculator(deps) {
    this.toFixedNumber = deps.functions.toFixedNumber;

    return {
        calculate: function (value) {
            if (isNaN(Number(value.contactDepth)))
                return null;

            if (isNaN(Number(value.tvdss)))
                return null;

            return this.toFixedNumber(Math.abs(value.contactDepth - value.tvdss));
        }.bind(this)
    };
}
