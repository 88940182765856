import { computed } from '@ember/object';
import Component from '@ember/component';
import { allTypesById, BandType } from 'ava-saturation/classes/band-types';
import facies from 'ava-saturation/classes/dimensions/facies';
import segment from 'ava-saturation/classes/dimensions/segment';
import zone from 'ava-saturation/classes/dimensions/zone';
import IDimension from 'ava-saturation/interfaces/dimension';
import IFaciesCode from 'ava-saturation/interfaces/facies-code';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import ISegment from 'ava-saturation/interfaces/segment-reference';
import IZone from 'ava-saturation/interfaces/zone-reference';
import { getBands } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/selectors';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { getModelAreas } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/selectors';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import { connect } from 'ember-redux';

export const stateToComputed = function (this: SidenavFunctionItem, state: any) {
    const areas = getModelAreas(state, {
        sid: this.concept.id
    });
    const bands = getBands(state, { sid: this.instance.sid, functionId: this.instance.id });
    return {
        areas,
        allBands: bands
    };
};

export const dispatchToActions = function (/* dispatch: Dispatch */) {
    return {
    };
};

export class SidenavFunctionItem extends Component {
    concept: SaturationConcept;
    grid: IGrid;
    instance: ModelFunction;
    areas: ModelArea[];
    zones: Array<IZone>;
    segments: Array<ISegment>;
    faciesCodes: Array<IFaciesCode>;

    // state
    functions: ModelFunction[];
    allBands: IBand[];

    private findAreaByType(areas: ModelArea[], type: IDimension) {
        return areas.filter(a => a.type === type.name).find(a => this.instance.areaIds.includes(a.id));
    }

    @computed('areas.[]')
    get zoneArea() {
        return this.findAreaByType(this.areas, zone) as ModelArea;
    }

    @computed('areas.[]')
    get segmentArea() {
        return this.findAreaByType(this.areas, segment) as ModelArea;
    }

    @computed('areas.[]')
    get faciesArea() {
        return this.findAreaByType(this.areas, facies) as ModelArea;
    }

    @computed('zoneArea', 'zones.[]')
    get zoneElements() {
        if (!this.zoneArea)
            return [];

        return this.zones.filter(z => this.zoneArea.monikers.includes(z.moniker.string));
    }

    @computed('segmentArea', 'segments.[]')
    get segmentElements() {
        if (!this.segmentArea)
            return [];

        return this.segments.filter(s => this.segmentArea.monikers.includes(s.moniker.string));
    }

    @computed('faciesArea', 'faciesCodes.[]')
    get faciesElements() {
        if (!this.faciesArea)
            return [];

        return this.faciesCodes.filter(f => this.faciesArea.monikers.includes(f.moniker.string));
    }

    @computed('zoneArea.name', 'zoneElements.[]')
    get zoneDisplayName() {
        if (this.zoneArea.name)
            return this.zoneArea.name;

        return this.zoneElements[0].name;
    }

    @computed('segmentArea.name', 'segmentElements.[]')
    get segmentDisplayName() {
        if (!this.segmentArea)
            return null;

        if (this.segmentArea.name)
            return this.segmentArea.name;

        return this.segmentElements[0].name;
    }

    @computed('faciesArea.name', 'faciesElements.[]')
    get faciesDisplayName() {
        if (!this.faciesArea)
            return null;

        if (this.faciesArea.name)
            return this.faciesArea.name;

        return this.faciesElements[0].name;
    }

    @computed('zoneDisplayName', 'segmentDisplayName', 'faciesDisplayName')
    get displayName() {
        const names = [
            this.zoneDisplayName,
            this.segmentDisplayName,
            this.faciesDisplayName
        ].compact();

        return `${names.join(' - ')}`;
    }

    // bands
    @computed('instance.bandType')
    get bandType(): BandType {
        return allTypesById[this.instance.bandType];
    }

    @computed('allBands.[]', 'bandType')
    get bands(): Array<IBand> {
        return this.allBands.filter(b => b.type === this.bandType.id && b.functionId === this.instance.id);
    }

    @computed('instance')
    get referenceId(): string {
        return `function_${this.instance.id}`;
    }
}
export default connect(stateToComputed, dispatchToActions)(SidenavFunctionItem);