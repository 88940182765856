import { call, take } from 'redux-saga/effects';

export function* conditionalTakeSaga(filter) {
    let action;

    if(!filter)
        throw 'Conditional Saga: Filter was empty.';

    while (true) {
        action = yield take('*');
        if (filter(action)) {
            break;
        }
    }

    return action;
}

export default function conditionalTake(filter) {
    return call(conditionalTakeSaga, filter);
}