export interface FunctionBand {
    id: string;
    name: string;
    type: string;
    sid: string;
    modelMoniker: string;
    calculationType: string;
    ranges: FunctionBandRange[];
    calculationSetIds: string[];
    functionId: string;
    colorIndex: number;
}

export interface FunctionBandRange {
    from: number;
    to: number;
}

export interface UpdateRangesPayload {
    id: string;
    sid: string;
    ranges: FunctionBandRange[];
}

export interface UpdateCalculationSetsPayload {
    id: string;
    sid: string;
    calculationSetIds: string[];
}

export interface DeleteFunctionBandPayload {
    id: string;
    sid: string;
}

export interface UpdateCalculationTypePayload {
    id: string;
    sid: string;
    calculationType: string;
}

export interface FunctionBandState {
    bySid: Record<string, FunctionBand[]>;
}

export interface IBand {
    id: string;
    name: string;
    type: string;
    sid: string;
    modelMoniker: string;
    calculationType: string;
    functionId: string;
    colorClass: string;
    ranges: FunctionBandRange[];
}

export interface IRange extends FunctionBandRange  {
    id: string;
    name: string;
    color: string;
    parentId: string;
    bandType: string;
    functionId: string;
}

export const GLOBAL_BAND_NAME = 'Global';