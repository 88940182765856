import { registerDeprecationHandler } from '@ember/debug';

export function initialize(): void {
    // 06.12.2019 [TT]: The only unhandled deprecation messages are coming from ember-paper
  registerDeprecationHandler((message: any, options: any, next: any) => {
    if (options && options.until && options.until === '4.0.0') {
      return;
    } else {
      next(message, options);
    }
  });
}

export default {
  initialize
};
