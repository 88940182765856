export default abstract class CacheKey {
    key: string;

    constructor(cacheKeys: Array<string>) {
        if (!cacheKeys || cacheKeys.length === 0)
            throw 'Cache keys need to be provided';

        this.key = cacheKeys.reduce((aggregate, currentKey) => {
            return aggregate ? (currentKey ? `${aggregate}_${currentKey}` : aggregate) : `${currentKey || ''}`;
        }, '');
    }
}
