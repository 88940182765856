import Component from '@ember/component';
import { getConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { connect } from 'ember-redux';

const stateToComputed = function (this: ConceptProvider, state: any) {
    return {
        concept: getConceptById(state, { sid: this.conceptId })
    };
};

const dispatchToActions = () => {
    return {
    };
};

export class ConceptProvider extends Component {
    conceptId: string;
}

export default connect(stateToComputed, dispatchToActions)(ConceptProvider);