import Component from '@ember/component';
import { action, computed, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import zone from 'ava-saturation/classes/dimensions/zone';
import { StatementGenerator } from 'ava-saturation/classes/statements/statement-generator';
import IContact from 'ava-saturation/interfaces/contact-reference';
import IDimension from 'ava-saturation/interfaces/dimension';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import { getCalculationConstants } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/selectors';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { getCalculationParametersBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/selectors';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { getFunctionBandsBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/selectors';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { getModelAreas } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/selectors';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { getModelFunctions } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/selectors';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import copyToClipboard from 'ava-saturation/utils/copy-to-clipboard';
import { connect } from 'ember-redux';
import { groupBy, values } from 'lodash';

export const stateToComputed = function (this: MapperBase, state: any) {
    const functions = getModelFunctions(state, {
        sid: this.concept.id
    });

    const bands = getFunctionBandsBySid(state, {
        sid: this.concept.id
    });

    const areasBySid = getModelAreas(state, {
        sid: this.concept.id
    });

    const constants = getCalculationConstants(state, {
        sid: this.concept.id
    });

    const parameters = getCalculationParametersBySid(state, {
        sid: this.concept.id
    });

    return {
        functions,
        bands,
        areasBySid,
        constants,
        parameters
    };
};

export const dispatchToActions = function (this: MapperBase, /* dispatch: Dispatch */) {
    return {
    };
};

export class MapperBase extends Component {
    @service objectStore: IObjectStore;

    // inputs
    concept: SaturationConcept;
    grid: IGrid;
    contact: IContact;

    gridModelingContext: GridContext;

    // state
    functions: ModelFunction[];
    bands: IBand[];
    areasBySid: ModelArea[];
    constants: CalculationConstant[];
    parameters: CalculationParameter[];

    // actions

    // parameters
    swPropertyName: string;
    swPropertyNameValidationMessages: string[] = [];
    treatUndefinedAsWater = true;
    recalculateProperties = true;
    // areaId: functionId
    functionMap: Record<string, string>;

    findAreaByType(modelFunction: ModelFunction, areas: ModelArea[], type: IDimension) {
        return areas.filter(a => a.type === type.name).find(a => modelFunction.areaIds.includes(a.id)) as ModelArea;
    }

    @computed('areasBySid')
    get areas(): ModelArea[] {
        return this.areasBySid.filter(a => a.modelMoniker === this.grid.moniker.string);
    }

    @computed('functions.[]', 'bands.[]', 'areas.[]')
    get functionGroups(): Record<string, ModelFunction[]> {
        return groupBy(this.functions, f => this.findAreaByType(f, this.areas, zone).id);
    }

    @computed('gridModelingContext')
    get gridContext() {
        return new GridContext(this.gridModelingContext);
    }

    @computed('functionGroups')
    get hasEligibleFunctions() {
        return Object.keys(this.functionGroups).length !== 0;
    }

    @computed('functionMap', 'hasEligibleFunctions', 'swPropertyName', 'swPropertyNameValidationMessages.[]')
    get isAvailable() {
        return this.hasEligibleFunctions && values(this.functionMap).compact().length > 0 && this.swPropertyName && this.swPropertyNameValidationMessages.length === 0;
    }

    @computed('grid', 'path', 'swPropertyName')
    get propertyNameValidation(): Promise<string> {

        return new Promise<string>(resolve => this.objectStore.doesSwPropertyExist(this.grid.moniker, this.path + '\\' + this.swPropertyName)
            .then(result => {
                if (result) {
                    return resolve('Property name must be unique.');
                }
                resolve();
            }));
    }

    get pathArray(): string[] {
        return ['Ava Saturation', this.concept.generalInformation.title, new Date().toLocaleDateString(), new Date().toLocaleTimeString()];

    }

    get path(): string {
        return this.pathArray.join('\\');
    }

    get pathEncoded(): string {
        return this.pathArray.map(p => encodeURIComponent(p)).join('\\');
    }

    async validateSwPropertyName() {
        // if (await this.objectStore.doesSwPropertyExist(this.grid.moniker, this.path + '\\' + this.swPropertyName)) {
        //     set(this, 'swPropertyNameValidationMessages', ['Sw property name must be unique.']);
        // } else {
        //     set(this, 'swPropertyNameValidationMessages', []);
        // }
    }

    @action
    async swPropertyNameChange(value: string) {
        set(this, 'swPropertyName', value);
        debounce(this, this.validateSwPropertyName, 200);
    }

    @action
    async applyMapping() {
        const pathArray = this.pathArray;
        let propertyCollection = await this.objectStore.generatePropertyCollection(this.grid.moniker, this.pathEncoded);

        let aboveContactPropertyName = `${this.contact.name} `;
        let zonesPropertyName = `Zones`;
        let segmentPropertyName = `Segments`;

        let aboveContactProperty = await this.objectStore.generateAboveContactProperty(this.grid.moniker, this.contact.moniker, this.recalculateProperties, {
            name: aboveContactPropertyName,
            propertyCollectionMoniker: propertyCollection.moniker
        });
        aboveContactPropertyName = aboveContactProperty.name;
        let zonesProperty = await this.objectStore.generateZoneIndexProperty(this.grid.moniker, this.recalculateProperties, {
            name: zonesPropertyName,
            propertyCollectionMoniker: propertyCollection.moniker
        });
        zonesPropertyName = zonesProperty.name;
        let segmentProperty = await this.objectStore.generateSegmentIndexProperty(this.grid.moniker, this.recalculateProperties, {
            name: segmentPropertyName,
            propertyCollectionMoniker: propertyCollection.moniker
        });
        segmentPropertyName = segmentProperty.name;
        const propertyNames = {
            aboveContactProperty: aboveContactPropertyName,
            zonesProperty: zonesPropertyName,
            segmentProperty: segmentPropertyName,
            porosityProperty: this.gridContext.porosityProperty ? this.gridContext.porosityProperty.name : null,
            permeabilityProperty: this.gridContext.permeabilityProperty ? this.gridContext.permeabilityProperty.name : null,
            faciesProperty: this.gridContext.faciesProperty ? this.gridContext.faciesProperty.name : null,
        };

        const statement = StatementGenerator.generateStatement(
            propertyNames,
            pathArray,
            this.gridContext,
            this.constants,
            this.parameters,
            this.bands,
            this.functionMap,
            this.functions,
            this.areas,
            this.treatUndefinedAsWater,
            this.swPropertyName
        );

        await this.objectStore.generateSwProperty(propertyCollection.moniker, {
            propertyName: this.swPropertyName,
        });

        await this.objectStore.runCalculatorStatement(this.grid.moniker, {
            statement: statement
        });

        const normalizationStatement = StatementGenerator.generateNormalizeStatement(pathArray, this.swPropertyName);

        await this.objectStore.runCalculatorStatement(this.grid.moniker, {
            statement: normalizationStatement
        });

        if (this.treatUndefinedAsWater) {
            const fillGapStatement = StatementGenerator.generateFillGapStatement(pathArray, this.swPropertyName);

            await this.objectStore.runCalculatorStatement(this.grid.moniker, {
                statement: fillGapStatement
            });
        }
    }

    @action
    async copyStatementToClipboard() {

        const propertyNames = {
            aboveContactProperty: `${this.contact.name} (${this.concept.generalInformation.title})`,
            zonesProperty: `Zones (hierarchy) (${this.concept.generalInformation.title})`,
            segmentProperty: `Segments (${this.concept.generalInformation.title})`,
            porosityProperty: this.gridContext.porosityProperty ? this.gridContext.porosityProperty.name : null,
            permeabilityProperty: this.gridContext.permeabilityProperty ? this.gridContext.permeabilityProperty.name : null,
            faciesProperty: this.gridContext.faciesProperty ? this.gridContext.faciesProperty.name : null,
        };

        const statement = StatementGenerator.generateStatement(
            propertyNames,
            [],
            this.gridContext,
            this.constants,
            this.parameters,
            this.bands,
            this.functionMap,
            this.functions,
            this.areas,
            this.treatUndefinedAsWater,
            this.swPropertyName);

        copyToClipboard(statement);
    }
}

export default connect(stateToComputed, dispatchToActions)(MapperBase);
