import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import { IWaterSaturationSample } from 'ava-saturation/classes/dimensions/water-saturation';
import { IPorositySample } from 'ava-saturation/classes/dimensions/porosity';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

const bvwKey = 'bvw';

class BVW extends ContinuosNumericDimension {
    /**
     * Describes the bulk volume dimension regarding water
     * @ref https://www.glossary.oilfield.slb.com/en/Terms/b/bulk_volume.aspx
     */
    constructor() {
        super();

        this.displayName = 'Bulk Volume Water';
        this.name = this.shortName = bvwKey;
        this.unitAlias = 'Volume';

        this.chartingPreferences = new ChartingAxisPreferences({ value: 0, forced: true }, { value: 1, forced: false }, false);
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.BVW;
}

const bvw = new BVW();

export interface IBVWSample extends IWaterSaturationSample, IPorositySample {
    [bvwKey]: number;
}

export default bvw;
