import { permeability } from 'ava-saturation/classes/band-types';

import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { FunctionBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';

const propertyInUseInFunction = (value: any, conceptId: string, state: any, intl: any): { message: string, isInValid: boolean } => {
    let result: boolean = false;

    if (value) {
        const modelFunction = state.entitiesV1.saturationConceptState.modelFunction.bySid[conceptId] as ModelFunction[];

        result = modelFunction.any(f => f.bandType === permeability.id);
    }

    return {
        message: intl.t('validation.concept-details.permeability.used-in-function'),
        isInValid: result
    };
};

const propertyInUseInBand = (value: any, conceptId: string, state: any, intl: any): { message: string, isInValid: boolean } => {
    let result: boolean = false;
    if (value) {
        const functionBands = state.entitiesV1.saturationConceptState.functionBand.bySid[conceptId] as FunctionBand[] || [];
        result = functionBands.any(b => b.type === permeability.id && b.name !== 'Global');
    }

    return {
        message: intl.t('validation.concept-details.permeability.used-in-band'),
        isInValid: result
    };
};

export default {
    referenced: [
        propertyInUseInFunction,
        propertyInUseInBand,
    ]
};