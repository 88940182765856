import { computed } from '@ember/object';
import Component from '@ember/component';
import { IWellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import IWell from 'ava-saturation/interfaces/well-reference';
import { addCalculationSet, deleteCalculationSet } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/actions';
import { CalculationSet, DeleteCalculationSetPayload, DimensionKey } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/types';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import uuid from 'ava-saturation/utils/uuid';
import { Dispatch } from 'redux';

export abstract class InputsBase extends Component {
    band: IBand;
    well: IWell;
    wellContext: IWellContextCollection;
    faciesArea: ModelArea;

    // state
    calculationSets: CalculationSet[];

    onCalculationSetChange: (type: 'create' | 'delete', calculationSet: CalculationSet | DeleteCalculationSetPayload) => void;

    @computed('calculationSets.[]', 'well')
    get targetSet() {
        return this.calculationSets.find(cs => cs.wellMoniker === this.well.moniker.string);
    }

    abstract gatherSetsByDimensionKey(): DimensionKey;

    createCalculationSet(dispatch: Dispatch) {
        let calculationSet = {
            id: uuid(),
            bandId: this.band.id,
            functionId: this.band.functionId,
            sid: this.band.sid,
            modelMoniker: this.band.modelMoniker,
            calculationType: this.band.calculationType,
            wellMoniker: this.well.moniker.string,
            byDimensionKey: {
                ...this.gatherSetsByDimensionKey()
            }
        };
        dispatch(addCalculationSet(calculationSet));
        if (this.onCalculationSetChange) {
            this.onCalculationSetChange('create', calculationSet);
        }
    }

    deleteCalculationSet(dispatch: Dispatch) {
        if (this.targetSet) {
            let calculationSet = {
                id: this.targetSet.id,
                sid: this.targetSet.sid,
                functionId: this.band.functionId,
                bandId: this.band.id
            };

            dispatch(deleteCalculationSet(calculationSet));

            if (this.onCalculationSetChange) {
                this.onCalculationSetChange('create', calculationSet);
            }
        }
    }
}