import WellInputItem from 'ava-saturation/components/widgets-new/well-input-item';

import { computed } from '@ember/object';

import pointLog from 'ava-saturation/classes/dimensions/point-well-log';
import { pointWellLogReferenceType } from 'ava-saturation/classes/point-well-log-reference-type';

export default class PointLogWellInputItem extends WellInputItem {
    pointLogKeys: string[] = [pointLog.shortName];

    @computed('wellContextCollection.wellPointLogs.[]', 'wellContext')
    get pointLogs() {
        return (this.wellContextCollection.wellPointLogs || []).filterBy('wellMoniker.string', this.wellContext.moniker.string);
    }

    get pointDimension() {
        return pointLog;
    }

    get pointLogType() {
        return pointWellLogReferenceType;
    }

    @computed('filter', 'inputDictionary.byDimensionKey')
    get targetCollections(): { dimension: any, logs: any, type: any }[] {
        return Object.keys(this.filter ? this.inputDictionary.byDimensionKey : {
            [this.pointDimension.shortName]: null
        }).filter(key => this.pointLogKeys.indexOf(key) !== -1).map(key => {
            return {
                // @ts-ignore
                dimension: this.get(`${key}Dimension`),
                // @ts-ignore
                logs: this.get(`${key}Logs`),
                // @ts-ignore
                type: this.get(`${key}LogType`)
            };
        });
    }
}