import { observes } from '@ember-decorators/object';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import SkeltHarrisonRegressionConstantsCalculator from 'ava-saturation/calculations/regression-constants/skelt-harrison-calculator';
import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import well from 'ava-saturation/classes/dimensions/well';
import { updateCalculationConstants } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/actions';
import { getCalculationConstants } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/selectors';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import copyToClipboard from 'ava-saturation/utils/copy-to-clipboard';
import isEnterPressed from 'ava-saturation/utils/is-enter-pressed';
import { connect } from 'ember-redux';
import { cloneDeep, flatMap, maxBy, minBy } from 'lodash';
import { Dispatch } from 'redux';
import ResultsBase from './results-base';

export const stateToComputed = function (this: GeneralSkeltResults, state: any) {
    const calculationConstantsBySid = getCalculationConstants(state, {
        sid: this.band.sid
    });

    return {
        calculationConstantsBySid
    };
};

export const dispatchToActions = function (this: GeneralSkeltResults, dispatch: Dispatch) {
    return {
        restoreConstant(this: GeneralSkeltResults, constantKey: string) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {
                    a: constantKey === 'A' ? this.regression.a : this.regressionConstantA,
                    b: constantKey === 'B' ? this.regression.b : this.regressionConstantB,
                    c: constantKey === 'C' ? this.regression.c : this.regressionConstantC,
                    d: constantKey === 'D' ? this.regression.d : this.regressionConstantD,
                }
            }));

            // @ts-ignore
            set(this, `regressionConstant${constantKey}`, NaN);
        },
        resetConstants(this: GeneralSkeltResults) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {}
            }));
        },
        acceptChanges(this: GeneralSkeltResults) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {
                    a: this.regressionConstantA,
                    b: this.regressionConstantB,
                    c: this.regressionConstantC,
                    d: this.regressionConstantD
                }
            }));

            // @ts-ignore
            set(this, `regressionConstantA`, NaN);
            // @ts-ignore
            set(this, `regressionConstantB`, NaN);
            // @ts-ignore
            set(this, `regressionConstantC`, NaN);
            // @ts-ignore
            set(this, `regressionConstantD`, NaN);
        }
    };
};

export class GeneralSkeltResults extends ResultsBase {
    @service intl: any;

    _regressionConstantA: number; // TODO: Fix type => string
    _regressionConstantB: number; // TODO: Fix type => string
    _regressionConstantC: number; // TODO: Fix type => string
    _regressionConstantD: number; // TODO: Fix type => string

    // state
    calculationConstantsBySid: CalculationConstant[];

    didReceiveAttrs() {
        if (isNaN(this._regressionConstantA))
            set(this, '_regressionConstantA', this.calculationConstants.constants.a || this.regression.a);

        if (isNaN(this._regressionConstantB))
            set(this, '_regressionConstantB', this.calculationConstants.constants.b || this.regression.b);

        if (isNaN(this._regressionConstantC))
            set(this, '_regressionConstantC', this.calculationConstants.constants.c || this.regression.c);

        if (isNaN(this._regressionConstantD))
            set(this, '_regressionConstantD', this.calculationConstants.constants.d || this.regression.d);
    }

    get primaryDimension() {
        const _sw = cloneDeep(waterSaturation);

        return _sw;
    }

    get secondaryDimension() {
        const _hafwl = cloneDeep(hafwl);

        return _hafwl;
    }

    @computed('calculationConstantsBySid')
    get calculationConstants(): CalculationConstant {
        return this.calculationConstantsBySid.filter(c => c.bandId === this.band.id)[0];
    }

    @observes('filteredDatasets.[]')
    onDatasetUpdate() {
        if (this.areCalculationConstantsDirty) {
            set(this, '_regressionConstantA', this.regression.a);
            set(this, '_regressionConstantB', this.regression.b);
            set(this, '_regressionConstantC', this.regression.c);
            set(this, '_regressionConstantD', this.regression.d);
        }
    }

    @computed('filteredDatasets.[]')
    get regression() {
        const calculator = new SkeltHarrisonRegressionConstantsCalculator();
        const constants = calculator.calculate(this.filteredDatasets.map(ds => ds.dataset));

        return {
            a: constants.a,
            b: constants.b,
            c: 1,
            d: 0,
        };
    }

    @computed('_regressionConstantA', 'regression.a', 'calculationConstants.constants.a')
    get regressionConstantA() {
        return this._regressionConstantA || this.calculationConstants.constants.a || this.regression.a;
    }
    set regressionConstantA(this: GeneralSkeltResults, value: number) {
        // @ts-ignore
        set(this, '_regressionConstantA', value);
    }

    @computed('_regressionConstantB', 'regression.b', 'calculationConstants.constants.b')
    get regressionConstantB() {
        return this._regressionConstantB || this.calculationConstants.constants.b || this.regression.b;
    }
    set regressionConstantB(value: number) {
        // @ts-ignore
        set(this, '_regressionConstantB', value);
    }

    @computed('_regressionConstantC', 'regression.c', 'calculationConstants.constants.c')
    get regressionConstantC() {
        return this._regressionConstantC || this.calculationConstants.constants.c || this.regression.c;
    }
    set regressionConstantC(value: number) {
        // @ts-ignore
        set(this, '_regressionConstantC', value);
    }

    @computed('_regressionConstantD', 'regression.d', 'calculationConstants.constants.d')
    get regressionConstantD() {
        return this._regressionConstantD || this.calculationConstants.constants.d || this.regression.d;
    }
    set regressionConstantD(value: number) {
        // @ts-ignore
        set(this, '_regressionConstantD', value);
    }

    @computed(
        'calculationConstants.constants.a',
        '_regressionConstantA',
        'calculationConstants.constants.b',
        '_regressionConstantB',
        'calculationConstants.constants.c',
        '_regressionConstantC',
        'calculationConstants.constants.d',
        '_regressionConstantD')
    get areCalculationConstantsDirty() {
        // @ts-ignore
        if (this._regressionConstantA === '' || this._regressionConstantB === '' || this._regressionConstantC === '' || this._regressionConstantD === '')
            return false;

        return this._regressionConstantA !== this.calculationConstants.constants.a ||
            this._regressionConstantB !== this.calculationConstants.constants.b ||
            this._regressionConstantC !== this.calculationConstants.constants.c ||
            this._regressionConstantD !== this.calculationConstants.constants.d;
    }

    @computed('regressionConstantA')
    get dynamicConstantAStep() {
        return this._calculateStep(this.regressionConstantA);
    }

    @computed('regressionConstantB')
    get dynamicConstantBStep() {
        return this._calculateStep(this.regressionConstantB);
    }

    @computed('regressionConstantC')
    get dynamicConstantCStep() {
        return this._calculateStep(this.regressionConstantC);
    }

    @computed('regressionConstantC')
    get dynamicConstantDStep() {
        return this._calculateStep(this.regressionConstantD);
    }

    @computed('filteredDatasets.[]', 'regressionConstantA', 'regressionConstantB', 'regressionConstantC', 'regressionConstantD')
    get regressionLineValues() {
        const plotValues = flatMap(this.filteredDatasets.map(ds => ds.dataset.values)),
            a = this.regressionConstantA,
            b = this.regressionConstantB,
            c = this.regressionConstantC,
            d = this.regressionConstantD;

        const dimensionShortName = hafwl.shortName;
        const min = get(minBy(plotValues, dimensionShortName), dimensionShortName),
            max = get(maxBy(plotValues, dimensionShortName), dimensionShortName);

        let increment = Math.abs(max - min) / 1000;
        let lineValues = [];

        for (var i = min; i < max; i += increment) {
            if (this.regressionConstantD > 0 || i >= Math.abs(this.regressionConstantD)) {
                lineValues.push({
                    [waterSaturation.shortName]: 1 - a * Math.exp(-Math.pow((b / (i + d)), c)),
                    [hafwl.shortName]: i,
                    [well.shortName]: this.applicableWells[0]
                });
            }
        }

        return lineValues;
    }

    @action
    updateConstant(constantKey: string, value: number) {
        // @ts-ignore
        set(this, `regressionConstant${constantKey}`, value);
    }

    @action
    onConstantKeyPress(constantKey: string, value: string, event: KeyboardEvent) {
        if (isEnterPressed(event)) {
            event.stopPropagation();
            this.actions.updateConstant.call(this, constantKey, value);
        }
    }

    @action
    copyResultingFunction(key: string) {
        const formula = this.intl.t(`sections.saturation-height-function.function-modeling.skelt-harrison-method.functions-formula.${key}`, {
            regressionA: this.regressionConstantA,
            regressionB: this.regressionConstantB,
            regressionC: this.regressionConstantC,
            regressionD: this.regressionConstantD,
        });
        copyToClipboard(formula);
    }

    @action
    exportDataset() {
        this.generateDataset();
    }
}

export default connect(stateToComputed, dispatchToActions)(GeneralSkeltResults);
