import { capillaryPressureRes } from 'ava-import/classes/dimensions/capillary-pressure';
import { oilGradient, waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';

export const dependencies = {
    functions: {
    },
    dimensionsIndex: {
        capillaryPressureRes,
        oilGradient,
        waterGradient
    }
};

export function PcHeightOilCalculator(deps) {
    this.dimensionsIndex = deps.dimensionsIndex;

    return {
        calculate: function(value) {
            let pc = Number(value[this.dimensionsIndex.capillaryPressureRes.shortName]);
            if (isNaN(pc))
                return null;

            let wGradient = Number(value[this.dimensionsIndex.waterGradient.shortName]);
            if (isNaN(wGradient))
                return null;

            let oilGradientValue = Number(value[this.dimensionsIndex.oilGradient.shortName]);
            if (isNaN(oilGradientValue))
                return null;

            return pc / (wGradient - oilGradientValue);
        }.bind(this)
    };
}