import { action, computed } from '@ember/object';
import Component from '@ember/component';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';

export default class GridSelection extends Component {
    onGridChanged: (gridModel: { grid: IGrid, model: Model }) => void;

    @action
    onGridSelected(gridModel: { grid: IGrid, model: Model }): void {
        this.onGridChanged(gridModel);
    }

    brokenRules: {
        referenced: [
            {
                isInValid: boolean,
                message: string
            }
        ]
    };

    @computed('brokenRules.[]')
    get message(): string {
        return this.brokenRules.referenced[0].message;
    }

    @computed('brokenRules.[]')
    get isLocked(): boolean {
        return this.brokenRules && this.brokenRules.hasOwnProperty('referenced') && (this.brokenRules.referenced ? this.brokenRules.referenced.length > 0 : false);
    }
}
