import { computed, set } from '@ember/object';
import facies from 'ava-saturation/classes/dimensions/facies';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import IWellLog from 'ava-saturation/interfaces/well-log-reference';
import { DimensionKey } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';
import { InputsBase } from './inputs-base';

export const stateToComputed = function (this: InputsBase) {
    return {
    };
};

export const dispatchToActions = function (this: GeneralSkeltInputs, dispatch: Dispatch) {
    return {
        onLogSelected(this: GeneralSkeltInputs, key: string, log: IWellLog) {
            // @ts-ignore
            set(this, `${key}Log`, log);

            this.deleteCalculationSet(dispatch);
        },
        toggleSet(this: GeneralSkeltInputs, value: boolean) {
            const action = value ? this.createCalculationSet : this.deleteCalculationSet;

            action.call(this, dispatch);
        }
    };
};

export class GeneralSkeltInputs extends InputsBase {
    waterSaturationLog: IWellLog;
    faciesLog: IWellLog;
    faciesArea: ModelArea;

    init() {
        super.init();

        set(this, 'waterSaturationLog', this.waterSaturationLogs[0]);
        if (this.faciesArea)
            set(this, 'faciesLog', this.faciesLogs[0]);
    }

    @computed('well')
    get waterSaturationLogs() {
        return this.wellContext.waterSaturationLogs.filter(l => l.wellMoniker.string === this.well.moniker.string);
    }

    @computed('waterSaturationLogs.[]', 'targetSet', 'waterSaturationLog')
    get selectedSwLog() {
        if (!this.targetSet)
            return this.waterSaturationLog;

        let monikers = Object.values(this.targetSet.byDimensionKey).map(v => v.moniker);

        return this.waterSaturationLogs.find(wl => monikers.includes(wl.moniker.string));
    }

    @computed('well')
    get faciesLogs() {
        return this.wellContext.faciesLogs.filter(l => l.wellMoniker.string === this.well.moniker.string);
    }

    @computed('faciesLogs.[]', 'targetSet', 'faciesLog')
    get selectedFaciesLog() {
        if (!this.targetSet || !this.faciesArea)
            return this.faciesLog;

        let monikers = Object.values(this.targetSet.byDimensionKey).map(v => v.moniker);

        return this.faciesLogs.find(wl => monikers.includes(wl.moniker.string));
    }

    gatherSetsByDimensionKey(): DimensionKey {
        const dictionary: DimensionKey = {
            [waterSaturation.shortName]: { moniker: this.waterSaturationLog.moniker.string, restricted: false }
        };

        if (this.faciesArea)
            dictionary[facies.shortName] = { moniker: this.faciesLog.moniker.string, restricted: false };

        return dictionary;
    }
}

export default connect(stateToComputed, dispatchToActions)(GeneralSkeltInputs);