import Component from '@ember/component';
import { computed } from '@ember/object';
import IGrid from 'ava-saturation/interfaces/grid-reference';

export default class GridContextProvider extends Component {
    grids: IGrid[];
    grid: IGrid | null;

    @computed('grids', 'grid')
    get gridContext(): IGrid {
        return this.grids.find((g: IGrid) => (this.grid ? this.grid.moniker.string : '') === g.moniker.string) || this.grids[0];
    }
}