/* istanbul ignore file */
// TT: untestable unsed in petrel-script context
import _ from 'lodash';
import { lerpWithoutCoefficient } from './lerp';

var dataSet1 = [];
var dataSet2 = [];

// USED FOR BENCHMARKING
for (let i = 1; i < 20001; i++) {
    dataSet1.push({ MD: i, Por: i * i });
    dataSet2.push({ MD: i + 0.5, WatSat: i * i + 0.5 });
}

const shouldInterpolateValueForKey = (point, key) => {
    return _.isNull(point[key]) || _.isNil(point[key]);
};

const setValueInterpolatorForKey = (point, startingPoint, closingPoint, key) => {
    closingPoint.subscribe.call(closingPoint, point);
    point[`${key}_interp`] = {
        startingPoint: _.merge({}, startingPoint),
        closingPoint: closingPoint
    };
};

var outputDataSet = [];
const getBlankClosingPointForKey = function (masterKey, targetKey) {
    return {
        subscribers: [],
        setValue: function (value) {
            _.each(this.subscribers, s => s[`onClosing${targetKey}PointChanged`](value));
        },
        subscribe: function (point) {
            this.subscribers.push(point);
            point[`onClosing${targetKey}PointChanged`] = function (value) {
                let startingPoint = this[`${targetKey}_interp`].startingPoint,
                    closingPoint = value;

                if (_.isEmpty(startingPoint))
                    return;

                this[targetKey] = lerpWithoutCoefficient(startingPoint[masterKey], closingPoint[masterKey], startingPoint[targetKey], closingPoint[targetKey], this[masterKey]);

                delete this[`${targetKey}_interp`];
                delete this[`onClosing${targetKey}PointChanged`];

                outputDataSet.push(this);
            };
        }
    };
};

var lastGoodValuePerKey = {};
var unknownValuePerKey = {};
const processDataPoint = function (point, masterKey, targetKeys) {
    var requiresInterpolation = false;
    _.each(targetKeys, key => {
        if (shouldInterpolateValueForKey(point, key)) {
            requiresInterpolation = requiresInterpolation || true;
            setValueInterpolatorForKey(point, lastGoodValuePerKey[key], unknownValuePerKey[key], key);
        } else {
            lastGoodValuePerKey[key] = { [masterKey]: point[masterKey], [key]: point[key] };
            unknownValuePerKey[key].setValue(lastGoodValuePerKey[key]);
            unknownValuePerKey[key] = getBlankClosingPointForKey(masterKey, key);
        }
    });

    if (!requiresInterpolation)
        outputDataSet.push(point);
};

export default function mergeDataSetsByKey(dataSets, masterKey, targetKeys) {
    let ordered = _.orderBy(_.flatMap(dataSets, ds => ds), masterKey);
    unknownValuePerKey = _.reduce(targetKeys, (prev, k) => _.merge(prev, { [k]: getBlankClosingPointForKey(masterKey, k) }), {});

    // MERGE DATASETS o(n)
    var previous = null;
    for (let i = 0; i < ordered.length; i++) {
        if (previous) {
            if (previous.MD == ordered[i].MD) {
                ordered[i] = _.merge(previous, ordered[i].MD);
            } else {
                processDataPoint(previous, masterKey, targetKeys);
            }
        }

        var current = previous = ordered[i];

        if (i == (ordered.length - 1))
            processDataPoint(current, masterKey, targetKeys);
    }

    return outputDataSet;
}
