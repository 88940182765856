import { getOwner } from '@ember/application';
import { computed, set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import * as actions from 'ava-saturation/classes/actions';
import { extractActions, extractCachedActions, missingPatches, missingVersions, parsePatches, parseVersions } from 'ava-saturation/classes/actions';
import ConceptExporter, { SID_PLACEHOLDER } from 'ava-saturation/classes/concept-exporter';
import { PDFBuilder } from 'ava-saturation/classes/pdf-builder';
import { getActionsByConceptId } from 'ava-saturation/store/entities-v1/saturation-concept-actions/selectors';
import { applyPatch, createConcept } from 'ava-saturation/store/entities-v1/saturation-concept/actions';
import { getLocalConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { GeneralInformation, Metadata, SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import { IsTrackable } from 'ava-saturation/store/utils';
import uuid from 'ava-saturation/utils/uuid';
import { File } from 'ember-file-upload';
import fileSaver from 'file-saver';
import RSVP from 'rsvp';
import IObjectStore from 'ava-saturation/interfaces/object-store';
export default class ConceptService extends Service.extend({
    // anything which *must* be merged to prototype here
}) {
    @service redux: any;
    @service synchronization: any;
    @service store: any;
    @service router: any;
    @service intl: any;
    @service objectStore: IObjectStore;

    status: string;

    init() {
        super.init();
        set(this, 'status', '');
    }

    @computed('status')
    get loading(): boolean {
        return this.status !== '';
    }

    async create(params?: { metadata?: Metadata, generalInformation?: GeneralInformation, modelMoniker?: string }) {
        const { metadata, generalInformation, modelMoniker, } = params || {};

        const record = this.store.createRecord('saturation-concept');
        const modelId = modelMoniker ? uuid() : modelMoniker;

        if (!metadata) {

            set(record, 'metadata', {
                sourceMonikerString: modelMoniker,
                modelId: modelId
            });

        } else {

            set(record, 'metadata', {
                ...metadata
            });
        }

        if (generalInformation) {
            set(record, 'generalInformation', {
                ...generalInformation,
                'asset-team': generalInformation.assetTeam
            });
        }

        const concept = await record.save();
        let saturationConcept = concept.toJSON();
        saturationConcept.id = concept.get('id');

        this.redux.dispatch(createConcept(saturationConcept));

        return saturationConcept as SaturationConcept;
    }

    restoreActions(conceptId: string, patches: any, versions: any) {

        const localConcept = getLocalConceptById(this.redux.getState(), { sid: conceptId });
        const notAppliedActions = extractCachedActions(getActionsByConceptId(this.redux.getState(), { sid: conceptId }));

        const versionsToApply = missingVersions(localConcept, parseVersions(versions));
        const patchestToApply = missingPatches(localConcept, parsePatches(patches));

        const actionsToApply = extractActions(versionsToApply, patchestToApply);

        if (actionsToApply.length > 0 && notAppliedActions.length > 0) {
            this.logger.error('There are unsynchronized changes for the Saturation concept causing conflict with the version retrieved from the server.');
            return false;
        }

        for (let action of actionsToApply) {
            this.redux.dispatch({ type: action.type, payload: action.payload });

            this.redux.dispatch(applyPatch({
                sid: conceptId,
                patchId: action.patchId,
                versionId: action.versionId
            }));
        }
        return true;
    }

    async exportToPdf(concept: SaturationConcept): Promise<any> {
        set(this, 'status', 'exportingToPDF');
        const intl = this.intl;

        let builder = new PDFBuilder(intl, getOwner(this), this.redux, this.objectStore);

        const stream = await builder
            .forConcept(concept)
            .build();

        const name = `${concept.generalInformation.title}.pdf`;

        fileSaver.saveAs(stream.toBlob('application/pdf'), name);
        set(this, 'status', '');
    }

    async exportManifest(concept: SaturationConcept) {
        this.set('status', 'exporting');
        const conceptId = concept.id;
        this.synchronization.disableSync();

        try {
            let result = await RSVP.hash({
                concept: this.store.query('saturation-concept', conceptId),
                versions: this.store.query('saturation-concept-version', conceptId),
                patches: this.store.query('saturation-concept-patch', conceptId)
            });

            const notAppliedActions = actions.extractCachedActions(getActionsByConceptId(this.redux.getState(), { sid: conceptId }));
            const exporter = new ConceptExporter(concept, result.versions, result.patches, notAppliedActions);

            const blob = new Blob([exporter.export()], { type: 'text/plain;charset=utf-8' });
            fileSaver.saveAs(blob, `${concept.generalInformation.title}.json`);
        } catch {
            // [ATS] TODO: When error handler is implemented.
        } finally {
            this.synchronization.enableSync();
            this.set('status', '');
        }
    }

    async importManifest(file: File) {
        this.set('status', 'importing');

        try {
            const manifest = await this.loadFile(file);
            const keys: Record<string, string> = {};

            let conceptManifest = JSON.parse(manifest, function (key: string, value: any) {
                if (key === 'title') {
                    return undefined;
                } else if (/<uuid:([0-9]+)>/.test(value)) {
                    if (!keys[value]) {
                        keys[value] = uuid();
                    }
                    return keys[value];
                }

                return value;
            });

            const concept = await this.create({
                generalInformation: {
                    ...conceptManifest.concept.generalInformation,
                    'asset-team': conceptManifest.concept.generalInformation.assetTeam
                },
                metadata: {
                    ...conceptManifest.concept.metadata
                }
            });

            conceptManifest = JSON.parse(manifest, function (key: string, value: any) {
                const valueTest = /<uuid:([0-9]+)>/;
                if (key === 'title') {
                    return concept.generalInformation.title;
                } else if (value === SID_PLACEHOLDER) {
                    return concept.id;
                } else if (valueTest.test(value)) {
                    const keyFound = valueTest.exec(value);
                    if (keyFound) {
                        const k = keyFound[0];
                        if (!keys[k]) {
                            keys[k] = uuid();
                        }
                        return value.replace(valueTest, keys[k]);
                    }
                }

                return value;
            });

            conceptManifest.actions.forEach((a: { type: string, payload: any }) => {
                this.redux.dispatch({ type: a.type, payload: a.payload, recordable: true, trackable: IsTrackable(a.type) });
            });

            this.router.transitionTo('saturation-concepts.edit', concept.id);
            // this.router.currentRoute.refresh();
        } catch {
            // [ATS] TODO: When error handler is implemented.
        } finally {
            this.set('status', '');
        }
    }

    async loadFile(file: File) {
        set(this, 'status', 'loadingFile');
        const content = await file.readAsBinaryString();
        set(this, 'status', '');
        return content;
    }

}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
    interface Registry {
        'concept': ConceptService;
    }
}
