export default config;

/**
 * Type declarations for
 *    import config from './config/environment'
 *
 * For now these need to be managed by the developer
 * since different ember addons can materialize new entries.
 */
declare const config: {
    environment: any;
    modulePrefix: string;
    podModulePrefix: string;
    locationType: string;
    rootURL: string;
    sessionData: any;
    APP: {
        apiNamespace: string,
        cdnEndpoint: string,
        mockStoreURL: string,
        mockStoreToken: string,
        mockUploadToken: string,
        version: string,
        devVersion: string,
        routeIfNoLicense: string,
        routeIfSignedOut: string,
        routeIfNotSupported: string,
        ajaxTrustedHosts: string,
        cdnEndpoints: string[],
        licenseExpirationWarningPeriod: number,
        features: Record<string, boolean>
    }
};
