import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import { IContactDepthSample } from 'ava-saturation/classes/dimensions/contact-depth';
import { ITVDSample } from 'ava-saturation/classes/dimensions/tvdss';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

const hafwlKey = 'hafwl';

export class HAFWL extends ContinuosNumericDimension {
    /**
     * Describes the height above free water level dimension
     */
    constructor() {
        super();

        this.displayName = 'Height Above Free Water Level';
        this.name = this.shortName = hafwlKey;
        this.unitAlias = 'Standard_Depth_Index';

        this.chartingPreferences = new ChartingAxisPreferences({ value: 0, forced: false }, { value: 10000, forced: false }, false);
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.HAFWL;
}

const hafwl = new HAFWL();

export interface IHAFWLSample extends IContactDepthSample, ITVDSample {
    [hafwlKey]: number;
}

export default hafwl;
