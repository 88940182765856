import DS from 'ember-data';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    patches: validator('has-many')
});

const ModelChanges = buildModelChanges(
    ['patches'],
    {
        'patches': {
            items: true,
            itemModel: true
        }
    });

export default DS.Model.extend(Validations, ModelChanges, {
    'patches': DS.hasMany('saturation-concept-patch', { defaultValue: [] }),
    'version-no': DS.attr('number'),
    'created': DS.attr('date'),
    'saturationConceptId': DS.attr('string'),
    'anchorVersionId': DS.attr('string'),
    'anchorPatchId': DS.attr('string')
});
