import { createSelector } from 'reselect';
import { ModelArea } from './types';

export const getModelAreasBySid = (state: any, props: {
    sid: string,
}): Array<ModelArea> =>
    state.entitiesV1.saturationConceptState.modelArea.bySid[props.sid];

// [NV]: This is handled in the component where it's used using a computed property to monitor for changes it can be disposed.
export const getModelAreas = createSelector([getModelAreasBySid], (models) => models);
