import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
    grid: null,
    faciesProperties: null,

    filteredFaciesProperties: computed('grid', 'faciesProperties.[]', function () {
        return this.faciesProperties.filterBy('gridMoniker.string', this.grid.moniker.string);
    })
});