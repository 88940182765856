import IFaciesCode from 'ava-saturation/interfaces/facies-code';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import IZone from 'ava-saturation/interfaces/zone-reference';
import IContact from 'ava-saturation/interfaces/contact-reference';
import ISegment from 'ava-saturation/interfaces/segment-reference';
import IIntersection from 'ava-saturation/interfaces/intersection-reference';
import IContactDepth from 'ava-saturation/interfaces/contact-depth-reference';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';
import IPorosityProperty from 'ava-saturation/interfaces/porosity-property-reference';
import IPermeabilityProperty from 'ava-saturation/interfaces/permeability-property-reference';
import IPropertyStatistics from 'ava-saturation/interfaces/property-statistics';

export default interface IGridContext {
    grid: IGrid;
}

export interface IAsyncContext {
    isPending: boolean;
}

export interface IGridModelingContext extends IGridContext, IAsyncContext {
    contact: IContact;
    faciesProperty: IFaciesProperty;
    porosityProperty: IPorosityProperty;
    porosityStatistics: IPropertyStatistics;
    permeabilityProperty: IPermeabilityProperty;
    permeabilityStatistics: IPropertyStatistics;
    intersections: Array<IIntersection>;
    applicableIntersections: Array<IIntersection>;
    contactDepths: Array<IContactDepth>;
    zones: Array<IZone>;
    segments: Array<ISegment>;
    faciesCodes: Array<IFaciesCode>;

    toPlainObject(): Record<string, any>;
}

export class GridContext implements IGridModelingContext {
    constructor(modelingContext: IGridModelingContext) {
        this.grid = modelingContext.grid;
        this.contact = modelingContext.contact;
        this.faciesProperty = modelingContext.faciesProperty;
        this.porosityProperty = modelingContext.porosityProperty;
        this.porosityStatistics = modelingContext.porosityStatistics;
        this.permeabilityProperty = modelingContext.permeabilityProperty;
        this.permeabilityStatistics = modelingContext.permeabilityStatistics;
        this.intersections = modelingContext.intersections;
        this.applicableIntersections = modelingContext.applicableIntersections;
        this.contactDepths = modelingContext.contactDepths;
        this.zones = modelingContext.zones.filter(z => z.gridMoniker.string === this.grid.moniker.string);
        this.segments = modelingContext.segments.filter(s => s.gridMoniker.string === this.grid.moniker.string);
        this.faciesCodes = modelingContext.faciesCodes.filter(f => f.gridMoniker.string === this.grid.moniker.string);
    }

    contact: IContact;
    faciesProperty: IFaciesProperty;
    porosityProperty: IPorosityProperty;
    porosityStatistics: IPropertyStatistics;
    permeabilityProperty: IPermeabilityProperty;
    permeabilityStatistics: IPropertyStatistics;
    intersections: IIntersection[];
    applicableIntersections: Array<IIntersection>;
    contactDepths: IContactDepth[];
    zones: IZone[];
    segments: ISegment[];
    faciesCodes: IFaciesCode[];
    grid: IGrid;
    isPending: boolean;

    toPlainObject() {
        return {
            intersections: this.intersections,
            contactDepths: this.contactDepths,
            zones: this.zones,
            segments: this.segments
        };
    }
}
