import Component from '@ember/component';
import { inject } from '@ember/service';
import $ from 'jquery';

export default Component.extend({
    selectedTab: 'getStarted',
    screenSensor: inject(),
    actions: {
        createConcept() {
            this.onCreateConcept();
        },
        openCPImport() {
            this.openCPImport();
        },
        openQualityControl() {
            this.openQualityControl();
        },
        onTabChange(tab) {
            this.set('selectedTab', tab);
        },
        scrollTo(selector) {
            $('.homepage-layout').animate({
                scrollTop: $('.homepage-layout').scrollTop() + $(selector).position().top
            }, 500, 'swing');
        }
    }
});
