import { capillaryPressureRes } from 'ava-import/classes/dimensions/capillary-pressure';
import { iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import permeability from 'ava-import/classes/dimensions/permeability';
import porosity from 'ava-saturation/classes/dimensions/porosity';

export const dependencies = {
    functions: {
    },
    dimensionsIndex: {
        capillaryPressureRes,
        iftReservoir,
        contactAngleRes,
        permeability,
        porosity
    }
};

export function JValueCalculator(deps) {
    this.jconversionFactor = 0.2166;
    this.dimensionsIndex = deps.dimensionsIndex;

    return {
        calculate: function (value) {
            let pcRes = Number(value[this.dimensionsIndex.capillaryPressureRes.shortName]);
            if (isNaN(pcRes))
                return null;

            let iftRes = Number(value[this.dimensionsIndex.iftReservoir.shortName]);
            if (isNaN(iftRes))
                return null;

            let caRes = Number(value[this.dimensionsIndex.contactAngleRes.shortName]);
            if (isNaN(caRes))
                return null;

            let porosityValue = Number(value[this.dimensionsIndex.porosity.shortName]);
            if (isNaN(porosityValue))
                return null;

            let permeabilityValue = Number(value[this.dimensionsIndex.permeability.shortName]);
            if (isNaN(permeabilityValue))
                return null;

            return this.jconversionFactor * (pcRes / (iftRes * Math.cos(caRes)) * Math.sqrt(permeabilityValue / porosityValue));
        }.bind(this)
    };
}
