import toFixedNumber from 'ava-saturation/utils/to-fixed-number';

export default (point) => {
    if (isNaN(Number(point.contactDepth)))
        return;

    if (isNaN(Number(point.tvdss)))
        return;

    point.hafwl = toFixedNumber(Math.abs(point.contactDepth - point.tvdss));
};
