import Component from '@ember/component';
import { assert } from '@ember/debug';
import { set } from '@ember/object';
import { DataSourceDefinition, generalLogSourceDefinition, pointLogSourceDefinition } from 'ava-saturation/classes/data-source';
import { WidgetInstance, WidgetSettings } from 'ava-saturation/classes/widgets/widget';
import IWell from 'ava-saturation/interfaces/well-reference';
import IWidget, { IWidgetInstance } from 'ava-saturation/interfaces/widget';
import IWidgetContext from 'ava-saturation/interfaces/widget-context';
import uuid from 'ava-saturation/utils/uuid';

class ExplorationWidgetContext implements IWidgetContext {
    widgets: Array<IWidgetInstance>;
    wells: IWell[];

    constructor(wells: IWell[]) {
        this.widgets = [];
        assert(`${ExplorationWidgetContext.name} didn't recieve a well collection!`, wells != null);
        set(this, 'wells', wells);

        this.add(generalLogSourceDefinition);
        this.add(pointLogSourceDefinition);
    }

    add(definition: DataSourceDefinition): void {
        const widget = new WidgetInstance({ settings: new WidgetSettings(definition) }, this.wells);
        set(widget, 'id', uuid());

        this.widgets.pushObject(widget);
    }
    remove(widget: IWidget): void {
        const widgetIndex = this.widgets.findIndex((w: IWidgetInstance) => w.id === widget.id);

        this.widgets.removeAt(widgetIndex);
    }
    clone(widget: IWidget): void {
        const _clone = WidgetInstance.inflate(widget.instance.deflate(), this.wells);
        set(_clone, 'id', uuid());
        set(_clone, 'name', `Copy of ${widget.displayName}`);

        this.widgets.pushObject(_clone);
    }
    rename(id: string, title: string): void {
        let widget = this.widgets.find(x => x.id === id);
        if (widget) {
            widget.name = title;
        }
    }
}

export default class WidgetContext extends Component {
    context: IWidgetContext;
    wells: IWell[];

    init() {
        super.init();
        assert(`${WidgetContext.name} no wells recieved from model!`, this.wells != null);
        set(this, 'context', new ExplorationWidgetContext(this.wells));
    }
}
