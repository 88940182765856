import { DataSourceInstance } from 'ava-saturation/classes/data-source';
import IDimension from 'ava-saturation/interfaces/dimension';
import IReference from 'ava-saturation/interfaces/reference';

export default interface IPresenter {
    dataSourceInstance: DataSourceInstance;
}

export interface IPresenterDefinition {
    type: PlotPresentationalType; // add other presentational types here
    icon: string;
    name: string;

    displayName: string;
    deflate(): Partial<IPresenterDefinition>;
}

export interface IGroup<TValue> {
    dimension: IDimension;
    values: Array<TValue>;
    selectedValues: Array<TValue>;
}

export interface IReferenceGroup extends IGroup<IReference> {

}

export enum PlotPresentationalType {
    Scatter,
    ScatterRegression,
    Histogram
}

export interface IPresenterSettings {
    isPopulated: boolean;
    /**
     * Populates the X & Y axes options array
     * @param dimensions
     */
    withDimensions(dimensions: IDimension[]): IPresenterSettings;
    /**
     * Populates the Z axis options array
     * @param groups
     */
    withGroups(groups: IGroup<Record<string, any>>[]): IPresenterSettings;
    /**
     * Sets the default option for all axes
     * @param dimensions
     */
    withDefaultDimensions(dimensions: IDimension[]): IPresenterSettings;
}