import Controller from '@ember/controller';

export default class ExploreController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    currentlyLoading: boolean;
}

declare module '@ember/controller' {
    interface Registry {
        'explore': ExploreController;
    }
}
