export function initialize(application) {
    application.inject('route', 'intl', 'service:intl');
    application.inject('controller', 'intl', 'service:intl');
    application.inject('component', 'intl', 'service:intl');
    application.inject('helper', 'intl', 'service:intl');
    application.inject('model', 'intl', 'service:intl');
}

export default {
    name: 'intl',
    initialize
};
