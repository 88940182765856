import { NonNumericDimension } from 'ava-saturation/classes/dimension';

const porosityPropertyKey = 'porosityProperty';

export class PorosityProperty extends NonNumericDimension {
    static id: string = porosityPropertyKey;

    constructor() {
        super();

        this.displayName = 'PorosityProperty';
        this.name = this.shortName = porosityPropertyKey;
    }
}

export const porosityProperty = new PorosityProperty();
