import RSVP from 'rsvp';
import merge from 'lodash/merge';
import find from 'lodash/find';

export function addSaturationConceptAction(conceptActionsStore, saturationConceptId, action) {
    return new RSVP.Promise((resolve, reject) => {
        action = merge(action, { saturationConceptId });
        var addRequest = conceptActionsStore.add(action);

        addRequest.onsuccess = function () {
            resolve();
        };

        addRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function removeSaturationConceptActionsByConceptId(conceptActionsStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptActionsStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let removedActions = [];

        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor) {
                removedActions.push(openedCursor.value);
                openedCursor.delete();
                openedCursor.continue();
            } else {
                resolve(removedActions);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function removeSaturationConceptActionsByActionIds(conceptActionsStore, saturationConceptId, actionIds) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptActionsStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let removedActions = [];

        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor && find(actionIds, aId => aId === openedCursor.value.id)) {
                removedActions.push(openedCursor.value);
                openedCursor.delete();
                openedCursor.continue();
            } else if (openedCursor) {
                openedCursor.continue();
            } else {
                resolve(removedActions);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function getSaturationConceptActions(conceptActionsStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptActionsStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let actions = [];

        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor) {
                actions.push(openedCursor.value);
                openedCursor.continue();
            } else {
                resolve(actions);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function getTrackedSaturationConceptActions(conceptActionsStore) {
    return new RSVP.Promise((resolve, reject) => {
        // var trackableIndex = conceptActionsStore.index("trackable");

        // var onlyTrackable = IDBKeyRange.only(true);
        var query = conceptActionsStore.getAll();

        query.onsuccess = function (successEvent) {
            let result = successEvent.target.result,
                filtered = result.filter(a => a.trackable);

            resolve(filtered);
        };

        query.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}
