import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { ReferenceParserBase } from 'ava-saturation/interfaces/reference-parser';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IPropertyStatistics from 'ava-saturation/interfaces/property-statistics';

export default class PropertyStatisticsReferenceType extends ReferenceObjectType {
    readonly id = 149;
    readonly name = 'property-statistics';
    readonly typeAliases = [];
    subTypeAliases = [];
}

export const propertyStatisticsReferenceType = new PropertyStatisticsReferenceType();

export class PropertyStatisticsReferenceParser extends ReferenceParserBase<IPropertyStatistics> {
    private _gridMoniker: IMoniker;
    private _propertyMoniker: IMoniker;

    constructor(origin: string, version: string, gridMoniker: IMoniker, propertyMoniker: IMoniker) {
        super(origin, version);

        this._gridMoniker = gridMoniker;
        this._propertyMoniker = propertyMoniker;
    }

    parse(object: any): IPropertyStatistics {
        return {
            type: propertyStatisticsReferenceType,
            gridMoniker: this._gridMoniker,
            propertyMoniker: this._propertyMoniker,
            dataType: object['Type of data'],
            min: object.Min,
            max: object.Max,
            delta: object.Delta,
            valuesCount: object['Number of defined values'],
            mean: object.Mean,
            deviation: object['Std. dev.'],
            variance: object.Variance,
            sum: object.Sum
        };
    }
}
