import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { IWidgetInstance } from 'ava-saturation/interfaces/widget';

export default class WidgetListItem extends Component {
    widget: IWidgetInstance;
    isChecked = true;
    @service intl: any;

    onWidgetToggle: Function;

    init() {
        super.init();
        this.onWidgetToggle(this.widget.id);
    }

    @computed('widget.name')
    get name(): string {
        return this.widget.name;
    }
    @computed('widget.dataSourceInstance.definition')
    get dataSourceName(): string {
        return this.intl.t(`datasource.${this.widget.dataSourceInstance.definition.name}`);
    }

    @computed('widget.dataSourceInstance.plotDefinition')
    get plotName(): string {
        return this.intl.t(`plots.${this.widget.dataSourceInstance.plotDefinition.name}.title`);
    }

    @computed('name', 'dataSourceName', 'plotName')
    get displayName(): string {
        return this.name || `${this.dataSourceName} - ${this.plotName}`;
    }

    @action
    checked() {
        this.toggleProperty('isChecked');
        this.onWidgetToggle(this.widget.id);
    }
}
