import wellLog from 'ava-saturation/classes/dimensions/well-log';
import DatasetExtender from 'ava-saturation/classes/calculations/dependency-injection/extenders/dataset-extender-pure';

export const dependencies = {
    functions: {
        DatasetExtender
    },
    dimensionsIndex: {
        wellLog
    }
};

export default function WellLogDatasetExtender(deps, wellsLogs) {
    this.datasetExtender = new deps.functions.DatasetExtender(
        deps.dimensionsIndex.wellLog.shortName,
        [],
    );

    return {
        name: this.datasetExtender.name,
        datasetExtender: this.datasetExtender,
        dimensionsIndex: deps.dimensionsIndex,
        _wellsLogs: wellsLogs,
        meetsDimensionExpectations: function (dimensions) {
            return this.datasetExtender.meetsDimensionExpectations(dimensions);
        },
        isApplicable: function() {
            return Array.isArray(this._wellsLogs) && this._wellsLogs.length != 0;
        },
        findExtendingRecord: function(value, dataset) {
            if (dataset && dataset.wellLogMonikers)
                return this._wellsLogs.filter(w => dataset.wellLogMonikers.map(m => m.string).indexOf(w.moniker.string) != -1) || null;

            return null;
        },
        /* eslint-disable no-unused-vars */
        applyExtendingRecord: function(value, extendingRecord, dataset) {
            //metadata
            extendingRecord.forEach(r => value[r.type.name] = r.name);
        },
        extend: function(value, dataset) {
            if (this.datasetExtender.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }
    };
}
