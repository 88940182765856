import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { ContactAngle, contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import { iftReservoir, InterfacialTension } from 'ava-import/classes/dimensions/interfacial-tension';
import { PressureGradient, waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { updateCalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/actions';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

export const stateToComputed = function (this: GeneralLeverettJParameters) {
    return {
    };
};

export const dispatchToActions = function (this: GeneralLeverettJParameters, dispatch: Dispatch) {
    return {
        updateParameterByKey(this: GeneralLeverettJParameters, key: string, value: string) {
            // @ts-ignore
            dispatch(updateCalculationParameter({
                id: this.calculationParameters.id,
                sid: this.calculationParameters.sid,
                byDimensionKey: {
                    [key]: value === '' || value === undefined ? undefined : Number(value)
                }
            }));
        },
    };
};

export class GeneralLeverettJParameters extends Component {
    ift: InterfacialTension = iftReservoir;
    contactAngle: ContactAngle = contactAngleRes;
    pressureGradient: PressureGradient = waterGradient;

    // inputs
    calculationParameters: CalculationParameter;

    // parameters
    _iftValue: number | undefined;
    _contactAngleValue: number | undefined;
    _waterGradientValue: number | undefined;
    _hydrocarbonGradientValue: number | undefined;

    didReceiveAttrs() {
        set(this, '_iftValue', this.iftValue);
        set(this, '_contactAngleValue', this.contactAngleValue);
        set(this, '_waterGradientValue', this.waterGradientValue);
        set(this, '_hydrocarbonGradientValue', this.hydrocarbonGradientValue);
    }

    @computed('calculationParameters')
    get iftValue() {
        return this.calculationParameters.byDimensionKey[iftReservoir.shortName];
    }

    @computed('calculationParameters')
    get contactAngleValue() {
        return this.calculationParameters.byDimensionKey[contactAngleRes.shortName];
    }

    @computed('calculationParameters')
    get waterGradientValue() {
        return this.calculationParameters.byDimensionKey[waterGradient.shortName];
    }

    @computed('calculationParameters')
    get hydrocarbonGradientValue() {
        return this.calculationParameters.byDimensionKey.hydrocarbonGradient;
    }
}

export default connect(stateToComputed, dispatchToActions)(GeneralLeverettJParameters);