export default {
    version: 20171215,
    apply: function (db, event, transaction) {
        if (!db || !event)
            throw 'Database missing';

        if (event.oldVersion < this.version) {
            // TODO [NV]: expose
            let conceptStoreName = 'saturation-concepts', 
                versionStoreName = 'saturation-concept-versions',
                stateStoreName = 'saturation-concept-states';
                
            if(db.objectStoreNames.contains(versionStoreName))
                db.deleteObjectStore(versionStoreName);

            let conceptStore = transaction.objectStore(conceptStoreName);
            conceptStore.createIndex('versionId', 'versionId', { unique: false });
            
            let conceptStateStore = transaction.objectStore(stateStoreName);
            conceptStateStore.createIndex('versionId', 'versionId', { unique: false });

            var versionStore = db.createObjectStore(versionStoreName, { keyPath: 'id' });
            versionStore.createIndex('saturationConceptId', 'saturationConceptId', { unique: false });
            versionStore.createIndex('recordedActions', 'recordedActions', { unique: false });
        }
    }
};