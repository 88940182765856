export default {
    version: 20171214,
    apply: function (db, event) {
        if (!db || !event)
            throw 'Database missing';

        if (event.oldVersion < this.version) {
            // TODO [NV]: expose
            let conceptStoreName = 'saturation-concepts', 
                stateStoreName = 'saturation-concept-states', 
                actionStoreName = 'saturation-concept-actions';

            if(db.objectStoreNames.contains(conceptStoreName))
                db.deleteObjectStore(conceptStoreName);
                
            if(db.objectStoreNames.contains(stateStoreName))
                db.deleteObjectStore(stateStoreName);
            
            if(db.objectStoreNames.contains(actionStoreName))
                db.deleteObjectStore(actionStoreName);

            var conceptStore = db.createObjectStore(conceptStoreName, { keyPath: 'id' });
            conceptStore.createIndex('state', 'state', { unique: false });

            var stateStore = db.createObjectStore(stateStoreName, { keyPath: 'id' }); 
            stateStore.createIndex('state', 'state', { unique: false });

            var actionStore = db.createObjectStore(actionStoreName, { keyPath: [ 'id', 'saturationConceptId'] });
            actionStore.createIndex('action', 'action', { unique: false });
        }
    }
};