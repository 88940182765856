import { observes } from '@ember-decorators/object';
import { action, computed, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { DataSourceDefinition } from 'ava-saturation/classes/data-source';
import { IWellContextCollection, WellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import WidgetBase, { WidgetInstance, WidgetSettings } from 'ava-saturation/classes/widgets/widget';
import { GridContext, IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { IWidgetSettings } from 'ava-saturation/interfaces/widget';
import isEnterPressed from 'ava-saturation/utils/is-enter-pressed';
import isEscapePressed from 'ava-saturation/utils/is-escape-pressed';

export default class Widget extends WidgetBase {
    @service screenSensor: any;

    constructor() {
        super(...arguments);

        if (!this.settings) {
            this.settings = new WidgetSettings();
        }
    }

    settings: IWidgetSettings;
    instance: WidgetInstance;
    resize: boolean;

    panelOpen: boolean = true;
    panelOptions: Array<string> = ['Settings', 'Inputs'];
    panelOption: string = 'Inputs';
    selectedTab: string = 'AllData';
    gridModelingContext: IGridModelingContext;
    wellContextCollection: IWellContextCollection;
    editOpen: boolean = false;
    editedTitle: string = '';

    onRemove: (ctx: Widget) => void;
    onRename: (id: string, editedTitle: string) => void;

    @observes('resize', 'panelOpen')
    onResize() {
        debounce(this, this.notifyPropertyChange, 'forceResize', 200);
    }

    get forceResize(): any {
        return true;
    }

    @computed('instance.dataSourceInstance')
    get dataSourceInstance() {
        return this.instance.dataSourceInstance;
    }

    @computed('gridModelingContext')
    get gridContext() {
        return new GridContext(this.gridModelingContext);
    }

    @computed('wellContextCollection')
    get wellContext() {
        return new WellContextCollection(this.wellContextCollection);
    }

    @action
    onKeyDown(event: KeyboardEvent) {
        if (isEnterPressed(event)) {
            this.actions.rename.call(this);
        } else if (isEscapePressed(event)) {
            this.actions.onCancelEditTitle.call(this);
        }
    }

    @action
    togglePanel(): void {
        this.toggleProperty('panelOpen');
    }

    @action
    onTabChange(tab: string): void {
        set(this, 'selectedTab', tab);
    }

    @action
    onEditTitle(): void {
        set(this, 'editedTitle', this.displayName);
        this.toggleProperty('editOpen');
    }

    @action
    rename(): void {
        this.toggleProperty('editOpen');
        this.onRename(this.id, this.editedTitle);
    }

    @action
    onCancelEditTitle(): void {
        this.toggleProperty('editOpen');
    }

    @action
    onSourceChanged(definition: DataSourceDefinition) {
        set(this, 'panelOption', 'Inputs');

        if (this.dataSourceInstance.definition !== definition)
            this.onDataSourceChanged(definition);
    }
}
