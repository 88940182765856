import { createCustomAction } from 'typesafe-actions';
import constants from './constants';
import { CalculationConstant } from './types';

export const addCalculationConstants = createCustomAction(constants.ADD, type => {
    return (constant: CalculationConstant) => ({ type, payload: constant, recordable: true });
});

export const updateCalculationConstants = createCustomAction(constants.UPDATE, type => {
    return (update: CalculationConstant) => ({ type, payload: update, recordable: true });
});

export const deleteCalculationConstants = createCustomAction(constants.DELETE, type => {
    return (payload: { sid: string, bandId: string }) => ({ type, payload: payload, recordable: true });
});
