import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { IInputDictionary, IMonikerCollection } from 'ava-saturation/classes/well-modeling-context';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IWellLog, { IWellLogReferenceType } from 'ava-saturation/interfaces/well-log-reference';

export default class WellLogTypeInputItem extends Component {
    isActive: boolean;
    logs: IWellLog[];
    dimension: IDimension;
    type: IWellLogReferenceType;
    inputDictionary: IInputDictionary | undefined;
    filter: boolean;

    inputUpdated: (logs: IWellLog[], dimension: IDimension, add: boolean) => void;

    @computed('monikerCollection.allMonikers.[]')
    get activeMonikers(): IMoniker[] {
        return ((this.monikerCollection && this.monikerCollection.allMonikers) || []).slice(0);
    }

    @computed('inputDictionary.byDimensionKey', 'dimension')
    get monikerCollection(): IMonikerCollection {
        // @ts-ignore
        return this.get(`inputDictionary.byDimensionKey.${this.dimension.shortName}`);
    }

    @computed('filter', 'monikerCollection.allMonikers')
    get targetLogs(): IWellLog[] {
        return this.logs.filter(log => {
            return !this.filter ? this.logs : this.get('monikerCollection').allMonikers.find((moniker: IMoniker) => moniker.string === log.moniker.string);
        });
    }

    @action
    onChecked(value: boolean) {
        this.inputUpdated(value ? this.logs : [], this.dimension, value);
    }
}
