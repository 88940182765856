import merge from 'lodash/merge';
const initialState = {
    panel: {
        open: false,
        filter: '',
        sort: {
            asc: false,
            key: 'created'
        }
    }
};

export default ((state, action) => {
    let currentState = state || initialState;

    if(action.type === 'SATURATION_CONCEPT_PANEL_TOGGLED') {
        return merge({}, currentState, {
            panel: {
                open: action.open,
                filter: currentState.panel.filter,
                sort: currentState.panel.sort
            }
        });
    }

    if(action.type === 'SATURATION_CONCEPT_PANEL_FILTER_CHANGED') {
        return merge({}, currentState, {
            panel: {
                open: currentState.panel.open,
                filter: action.filter,
                sort: currentState.panel.sort
            }
        });
    }

    if(action.type === 'SATURATION_CONCEPT_PANEL_SORT_BY_CHANGED') {
        return merge({}, currentState, {
            panel: {
                open: currentState.panel.open,
                filter: currentState.panel.filter,
                sort: {
                    key: action.option,
                    asc: currentState.panel.sort.asc
                }
            }
        });
    }

    if(action.type === 'SATURATION_CONCEPT_PANEL_SORT_CHANGED') {
        return merge({}, currentState, {
            panel: {
                open: currentState.panel.open,
                filter: currentState.panel.filter,
                sort: {
                    key: currentState.panel.sort.key,
                    asc: action.sortAsc
                }
            }
        });
    }

    return currentState;
});