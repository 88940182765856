import Component from '@ember/component';
import layout from 'ava-saturation/templates/components/ava-area-item';

export default Component.extend({
    layout,
    showText: false,
    bubbles: true,
    actions: {
        buttonClicked() {
            if (this.onClick) {
                this.onClick(...arguments);
            }
        }
    }
});
