import { scatterPlotDefinition, histogramPlotDefinition } from 'ava-saturation/components/widgets-new/plot';
import { ScatterPlotSettings } from 'ava-saturation/components/scatter-plot';
import { IPresenterDefinition, IPresenterSettings } from 'ava-saturation/interfaces/presenter';
import { HistogramPlotSettings } from 'ava-saturation/components/histogram-plot';

export default class SettingsFactory {
    static create<T extends IPresenterSettings | ScatterPlotSettings | HistogramPlotSettings>(definition: IPresenterDefinition): T {
        if (definition === scatterPlotDefinition)
            return new ScatterPlotSettings() as T;

        if (definition === histogramPlotDefinition)
            return new HistogramPlotSettings() as T;

        return new ScatterPlotSettings() as T;
    }
}