import { IDiscreteReference } from 'ava-saturation/interfaces/reference';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { StatementBuilder } from 'ava-saturation/interfaces/statement-builder';

export class FunctionStatement implements StatementBuilder<ModelFunction> {
    private modelFunction: ModelFunction;
    private areas: ModelArea[];
    private thenStatementBuilder: (modelFunction: ModelFunction) => string;
    private otherwiseStatementBuilder: () => string;

    constructor(modelFunction: ModelFunction, areas: ModelArea[]) {
        this.modelFunction = modelFunction;
        this.areas = areas;
    }
    then(statementBilder: (modelFunction: ModelFunction) => string): StatementBuilder<ModelFunction> {
        this.thenStatementBuilder = statementBilder;
        return this;
    }
    otherwise(statementBilder: () => string): StatementBuilder<ModelFunction> {
        this.otherwiseStatementBuilder = statementBilder;
        return this;
    }
    build(propertyMap: {
        [dimensionKey: string]: string;
    }, elementMap: {
        [dimensionKey: string]: IDiscreteReference[];
    }) {
        const areas = this.areas.filter(a => this.modelFunction.areaIds.includes(a.id));
        const conditional = areas.map(a => {
            const propertyName = propertyMap[a.type];
            const elementMonikerStrings: Record<string, number> = {};
            elementMap[a.type].forEach(e => elementMonikerStrings[e.moniker.string] = e.code);
            return `(${a.monikers.map(m => `${propertyName}=${elementMonikerStrings[m]}`).join(' OR ')})`;
        }).join(' AND ');
        return `if(${conditional}, ${this.thenStatementBuilder(this.modelFunction)}, ${this.otherwiseStatementBuilder()})`;
    }
}
