define("ava-saturation/services/petrel-event-channel", ["exports", "petrel-store/services/petrel-event-channel"], function (_exports, _petrelEventChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _petrelEventChannel.default;
    }
  });
});
