import Controller from '@ember/controller';

export default class AuthorizeController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    session: any;

    tryLogin() {
        this.session.authenticate('authenticator:auth-code', { state: 'index' });
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'authorize': AuthorizeController;
    }
}