import Router from '@ember/routing/router';
import { inject } from '@ember/service';

import config from './config/environment';

const router = Router.extend({
    redux: inject(),
    location: config.locationType,
    rootURL: config.rootURL
});

router.map(function () {
    // common routes
    this.route('authorize');
    this.route('login');
    this.route('not-supported');
    this.route('page-not-found', { path: '/*wildcard' });
    // application specific routes require user authentication!
    this.route('index', { path: '/' }, function () {
        this.route('explore', { resetNamespace: true }, function () { /* addd sub routes here */ });
        this.route('saturation-concepts', { resetNamespace: true }, function () {
            this.route('edit', { path: '/:saturation_concept_id' });
            this.route('new' );
            this.route('delete', { path: '/delete/:saturation_concept_id' });
        });
        this.route('quality-control', { resetNamespace: true });
        this.route('asset-management', { resetNamespace: true });
        this.route('no-license', { resetNamespace: true });
        this.route('no-petrel', { resetNamespace: true });
        this.route('signed-out', { resetNamespace: true });
    });
});

export default router;
