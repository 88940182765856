import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import ISegment from 'ava-saturation/interfaces/segment-reference';

export default class SegmentReferenceType extends ReferenceObjectType {
    readonly id = 130;
    readonly name = 'segment';
    readonly typeAliases = ['PetrelSegment', 'PetrelSegmentUpdate'];
    subTypeAliases = [];
}

export const segmentReferenceType = new SegmentReferenceType();

export class SegmentReferenceParser extends SingleReferenceParser<ISegment> {
    parse(object: any): ISegment {
        let reference = super.parse(object);
        return {
            ...reference,
            type: segmentReferenceType,
            code: object.Index
        };
    }
}

export class SegmentCollectionReferenceParser extends ReferenceParser<Array<ISegment>> {
    parse(object: any): Array<ISegment> {
        return referenceCollectionParser<ISegment>(this.appendGridMoniker(object, 'Segments'), new SegmentReferenceParser(this.origin, this.version));
    }
}