import { computed } from '@ember/object';
import Component from '@ember/component';
import IDimension from 'ava-saturation/interfaces/dimension';
import IReference from 'ava-saturation/interfaces/reference';
import { addModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/actions';
import { getModelInputs } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/selectors';
import { ModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/types';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';
import IMoniker from 'ava-saturation/interfaces/moniker';

const stateToComputed = function (this: PropertyStateContextProvider, state: any) {
    const modelsBySid = getModelInputs(state, { sid: this.conceptId });

    return {
        modelsBySid
    };
};

const dispatchToActions = function (this: PropertyStateContextProvider, dispatch: Dispatch) {
    return {
        onInputChanged(this: PropertyStateContextProvider, propertyModel: { item: IReference, model: ModelInput }) {
            let { item } = propertyModel;

            let model = {
                id: uuid(),
                sid: this.conceptId,
                moniker: item.moniker.string,
                modelMoniker: this.model.moniker,
                type: this.type.name,
            };

            dispatch(addModelInput(model));
        }
    };
};

export interface IGridReference extends IReference {
    gridMoniker: IMoniker;
}

export class PropertyStateContextProvider extends Component {
    conceptId: string;
    model: Model;
    type: IDimension;
    properties: Array<IGridReference>;
    modelsBySid: Array<ModelInput>;
    rules: Array<any>;

    @computed('modelsBySid', 'model.moniker', 'type')
    get models(): ModelInput[] {
        return this.modelsBySid.filter(input => input.modelMoniker === this.model.moniker && input.type === this.type.name);
    }

    @computed('models')
    get modelContext(): ModelInput {
        return this.models[0];
    }

    @computed('properties', 'modelContext')
    get propertyContext(): IGridReference | undefined {
        return this.properties.find((p: IReference) => (this.modelContext ? this.modelContext.moniker : '') === p.moniker.string);
    }

    @computed('rules.[]')
    get isValid(): boolean {

        if (this.rules) {
            return this.rules.filter(r => r.test()).length === 0;
        }

        return true;
    }

    @computed('properties', 'models')
    get propertyModels(): Array<{ item: IGridReference, model: ModelInput | undefined }> {
        return this.properties.filter(p => p.gridMoniker.string === this.model.moniker).map(p => {
            return {
                item: p,
                model: this.models.find(m => m.moniker === p.moniker.string)
            };
        });
    }
}

export default connect(stateToComputed, dispatchToActions)(PropertyStateContextProvider);