import { computed } from '@ember/object';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import { porosityLogReferenceType } from 'ava-saturation/classes/porosity-log-reference-type';
import { waterSaturationLogReferenceType } from 'ava-saturation/classes/water-saturation-log-reference-type';
import WellInputItem from 'ava-saturation/components/widgets-new/well-input-item';

export default class GeneralLogInputItem extends WellInputItem {
    generalLogKeys: string[] = [porosity.shortName, waterSaturation.shortName];

    @computed('wellContextCollection.porosityLogs.[]', 'wellContext')
    get porLogs() {
        return this.wellContextCollection.porosityLogs.filterBy('wellMoniker.string', this.wellContext.moniker.string);
    }

    @computed('wellContextCollection.waterSaturationLogs.[]', 'wellContext')
    get swLogs() {
        return this.wellContextCollection.waterSaturationLogs.filterBy('wellMoniker.string', this.wellContext.moniker.string);
    }

    get porDimension() {
        return porosity;
    }

    get porLogType() {
        return porosityLogReferenceType;
    }

    get swDimension() {
        return waterSaturation;
    }

    get swLogType() {
        return waterSaturationLogReferenceType;
    }

    @computed('filter', 'inputDictionary.byDimensionKey')
    get targetCollections(): { dimension: any, logs: any, type: any }[] {
        return Object.keys(this.filter ? this.inputDictionary.byDimensionKey : {
            [this.porDimension.shortName]: null,
            [this.swDimension.shortName]: null
        }).filter(key => this.generalLogKeys.indexOf(key) !== -1).map(key => {
            return {
                // @ts-ignore
                dimension: this.get(`${key}Dimension`),
                // @ts-ignore
                logs: this.get(`${key}Logs`),
                // @ts-ignore
                type: this.get(`${key}LogType`)
            };
        });
    }
}