define("ava-saturation/classes/validators/uom-class-validator", ["exports", "ava-import/classes/validators/uom-class-validator"], function (_exports, _uomClassValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uomClassValidator.default;
    }
  });
});
