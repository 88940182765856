import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import CPImportRouteMixin from 'ava-import/mixins/cp-import-route-mixin';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';
import RSVP from 'rsvp';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, CPImportRouteMixin, {
    broadcastChannel: new BroadcastChannel('cp-import'),
    objectStore: inject(),
    queryParams: {
        refreshModel: true
    },

    onBroadcastMessage(e: { type: string }) {
        if (e.type === 'success') {
            this.objectStore.refresh(['wellPointLogs']);
            this.refresh();
        }
    },

    model() {
        return RSVP.hash({
            grids: this.objectStore.grids,
            wells: this.objectStore.wells,
            contacts: this.objectStore.contacts,
            zones: this.objectStore.zones,
            segments: this.objectStore.segments,
            faciesProperties: this.objectStore.faciesProperties,
            porosityLogs: this.objectStore.porosityLogs,
            waterSaturationLogs: this.nonSyntheticWaterSaturationLogs(),
            faciesLogs: this.objectStore.faciesLogs,
            wellPointLogs: this.objectStore.wellPointLogs
        });
    },

    async nonSyntheticWaterSaturationLogs() {
        const logs = await this.objectStore.waterSaturationLogs;
        return logs.filter((log: any) => !log.isSynthetic);
    },

    afterModel(_model: any) {
        // will handle subscription changes from here
        // return this.transitionTo({
        //     queryParams: {
        //         refreshModel: true
        //     }
        // });
    },
    actions: {
        willTransition(transition: any) {

            if (transition.targetName === 'saturation-concepts.new') {
                return;
            }

            let controller = this.controllerFor('explore.index');
            if (!controller.get('transitionConfirmed')) {
                set(controller, 'transition', transition);
                set(controller, 'modalOpen', true);
                transition.abort();
            }
            set(controller, 'transitionConfirmed', false);
        }
    }
});
