import Mixin from '@ember/object/mixin';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { computed } from '@ember/object';
import { getOwner } from '@ember/application';

export default Mixin.create(AuthenticatedRouteMixin, {
    session: computed({
        get() {
            return getOwner(this).lookup('service:session');
        }
    }),
    model() {
        if (this.get('session.isAccessTokenExpired')) {
            return this.session.refreshAccessToken();
        }
    }
});
