import { createSelector } from 'reselect';
import { ModelFunction } from './types';
import { getConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { getModelAreasBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/selectors';

export const getFunctionsBySid = (state: any, props: { sid: string }): ModelFunction[] => state.entitiesV1.saturationConceptState.modelFunction.bySid[props.sid];

export const getConceptFunctions = createSelector([getFunctionsBySid], (functions) => functions);

export const getModelFunctions = createSelector([getConceptById, getFunctionsBySid],
    (concept, functions) => functions.filter(f => f.modelMoniker === concept.metadata.sourceMonikerString));

export const getAreaType = (_state: any, props: { type: string }): string => props.type;

export const getModelFunctionsForAreaType = createSelector([getConceptById, getFunctionsBySid, getModelAreasBySid, getAreaType], (concept, functions, areas, areaType) => {
    return functions.filter(f =>
        f.modelMoniker === concept.metadata.sourceMonikerString
        && areas.any(a => a.type === areaType && f.areaIds.contains(a.id))
    );
});