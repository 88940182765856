import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { defineProperty } from '@ember/object';
import { oneWay } from '@ember/object/computed';
import AssetManagementController from 'ava-saturation/controllers/asset-management';
import SaturationController from 'ava-saturation/controllers/index';

export default class SaturationIndexController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    @controller index: SaturationController;
    @controller assetManagement: AssetManagementController;
    @controller saturationConcepts: any;
    @service resourceManager: any;
    @service redux: any;

    @alias('index.tokenParams') tokenParams: string;
    @alias('index.isDataStorePresent') isDataStorePresent: boolean;

    constructor() {
        super(...arguments);
        // TODO: [TT] Replace with @oneWay when ember-decorators gets updated
        defineProperty(this, 'ectoken', oneWay('session.data.authenticated.ec_token'));
    }

    clickLink(linkData: any, event: MouseEvent) {
        this.resourceManager.getResource(linkData, event);
    }

    getGuide(event: MouseEvent) {
        this.resourceManager.getQuickStartGuide(event);
    }

    async downloadPlugin() {
        return this.resourceManager.getPlugin();
    }

    @action
    onConceptSelected(concept: any) {
        this.transitionToRoute('saturation-concepts.edit', concept.id);
    }

    @action
    createConcept() {
        this.transitionToRoute('saturation-concepts.new');
    }

    @action
    openCPImport() {
        this.assetManagement.openCPImport();
    }

    @action
    openQualityControl() {
        this.transitionToRoute('quality-control');
    }

    @action
    getTemplate() {
        this.assetManagement.getTemplate();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'index.index': SaturationIndexController;
    }
}
