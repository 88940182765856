import { createSelector } from 'reselect';
import { Model } from './types';
import { SaturationConcept } from '../../saturation-concept/types';
import { getConceptById } from '../../saturation-concept/selectors';

export const getModelsInternal = (state: any): Record<string, Model> => state.entitiesV1.saturationConceptState.model.byId;
export const getModelByIdInternal = (state: any, props: { modelId?: string | null }): Model | undefined => props.modelId ? state.entitiesV1.saturationConceptState.model.byId[props.modelId] : undefined;

export const getModelById = createSelector([getModelByIdInternal], (model) => model);

export const getModels = createSelector([getConceptById, getModelsInternal], (concept: SaturationConcept, models) => {
    if (concept) {

        return Object.keys(models).reduce((aggregate: Record<string, Model>, key) => {
            const model = models[key];
            if (model.sid === concept.id) {
                aggregate[key] = model;
            }
            return aggregate;
        }, {});
    } else
        return models;
});

export const getModelsByConceptId = createSelector([getConceptById, getModelsInternal], (concept: SaturationConcept, models) => {
    return Object.keys(models).reduce((aggregate: Record<string, Model>, key) => {
        const model = models[key];
        if (model.sid === concept.id) {
            aggregate[key] = model;
        }
        return aggregate;
    }, {});
});
