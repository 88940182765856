export default function (string) {
    var escaped = '';
    for (var i = 0; i < string.length; ++i) {
        switch (string[i]) {
            case '{':
            case '}':
            case '[':
            case ']':
            case '-':
            case '/':
            case '\\':
            case '(':
            case ')':
            case '*':
            case '+':
            case '?':
            case '.':
            case '^':
            case '$':
            case '|':
                escaped += '\\';
                break;
            default:
                escaped += string[i];
        }
    }
    return escaped;
}
