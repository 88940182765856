/**
 * @module ember-paper
 */
import PaperSelect from 'ember-paper/components/paper-select';

import ColorMixin from 'ava-saturation/mixins/overrides/color-mixin';

/**
 * @class PaperSelect
 * @extends Ember.Component
 * @uses ColorMixin
 */
export default PaperSelect.extend(ColorMixin, {
    classNameBindings: ['toolbar:md-toolbar-select', 'maxWidth:md-input-max-width', 'noLabel:md-input-no-label']
});