import Service from '@ember/service';
import RSVP from 'rsvp';
import config from 'ava-saturation/configuration';
import migrations from 'ava-saturation/migrations/index';

export default Service.extend({
    config,
    connection: null,
    async init() {
        //TODO [NV]: Move the polyfills if they are needed
        // window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        // window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
        // window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
        this._super(...arguments);
        if (!window.indexedDB)
            throw 'IndexedDB is required';

        await this._upgradeDatabase();
    },
    _getTargetDatabaseVersion() {
        return migrations.targetVersion;
    },
    _applyMigrations(database, upgradeEvent, transaction) {
        migrations.apply(database, upgradeEvent, transaction);
    },
    _processConnection(openRequest, { onSuccess, onFail }) {
        openRequest.onsuccess = (event) => {
            onSuccess(event.target.result);
        }; // resolve the database
        openRequest.onerror = (event) => {
            this.set('connection', null);
            onFail(event.target.error);
        }; // handle connection errors
        openRequest.onblocked = () => {
            this.set('connection', null);
            onFail('The cache database is in use by another process. Please close the window and reopen it.');
        };

        return openRequest;
    },
    async _openConnectionWithoutVersion() {
        return await new RSVP.Promise((resolve, reject) => {
            let databaseName = this.get('config.name');
            let openRequest = null;
            let connectionAttempt = 0;

            const createConnection = () => {
                openRequest = window.indexedDB.open(databaseName);

                this._processConnection(openRequest, {
                    onSuccess: resolve,
                    onFail: (event) => {
                        if (connectionAttempt > 10) {
                            reject(event);
                            return;
                        }

                        connectionAttempt++;
                        setTimeout(createConnection, 250);
                    }
                });
            };

            createConnection();
        });
    },
    async _upgradeDatabase() {
        var self = this;
        let databaseName = this.get('config.name');

        let connectionAttempt = 0;

        await new RSVP.Promise((resolve, reject) => {
            const createConnection = () => {
                let request = window.indexedDB.open(databaseName, this._getTargetDatabaseVersion());

                request.onupgradeneeded = upgradeEvent => {
                    self._applyMigrations(upgradeEvent.target.result, upgradeEvent, request.transaction);
                    resolve();
                };
                request.onsuccess = (event) => {
                    resolve();
                    event.target.result.close();
                };
                request.onerror = (event) => {
                    // will attempt to open a connection 10 times otherwise will fail
                    if (connectionAttempt > 10) {
                        reject(event);
                        return;
                    }

                    connectionAttempt++;
                    setTimeout(createConnection, 250);
                };
            };

            createConnection();
        });
    },
    async openConnection(shouldRefreshConnection = true) {
        let connection = this.connection;
        if (connection && !shouldRefreshConnection)
            return RSVP.resolve(connection);

        const newConnection = await this._openConnectionWithoutVersion();

        let currentConnection = this.connection;
        if (currentConnection)
            currentConnection.close();

        this.set('connection', newConnection);
        return newConnection;
    },
    disposeConnection() {
        try {
            // safely close the connection - it could be disposed before the close method is manually invoked
            this.connection.close();
        } catch (ex) {
            this.set('connection', null);
        }
    }
});