import { observes } from '@ember-decorators/object';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';
import { DataSourceDefinition } from 'ava-saturation/classes/data-source';
import { IWellContextCollection, WellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import WidgetBase, { WidgetInstance, WidgetSettings } from 'ava-saturation/classes/widgets/widget';
import { GridContext, IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { IWidgetSettings } from 'ava-saturation/interfaces/widget';

export default class ReadonlyWidget extends WidgetBase {
    @service screenSensor: any;

    init() {
        super.init();

        if (!this.settings) {
            this.settings = new WidgetSettings();
        }
    }

    settings: IWidgetSettings;
    instance: WidgetInstance;
    resize: boolean;

    gridModelingContext: IGridModelingContext;
    wellContextCollection: IWellContextCollection;

    onRemove: (ctx: ReadonlyWidget) => void;
    onRename: (id: string, editedTitle: string) => void;

    @observes('resize', 'panelOpen')
    onResize() {
        debounce(this, this.notifyPropertyChange, 'forceResize', 200);
    }

    get forceResize(): any {
        return true;
    }

    @computed('instance.dataSourceInstance')
    get dataSourceInstance() {
        return this.instance.dataSourceInstance;
    }

    @computed('gridModelingContext')
    get gridContext() {
        return new GridContext(this.gridModelingContext);
    }

    @computed('wellContextCollection')
    get wellContext() {
        return new WellContextCollection(this.wellContextCollection);
    }

    @action
    onSourceChanged(definition: DataSourceDefinition) {
        if (this.dataSourceInstance.definition !== definition)
            this.onDataSourceChanged(definition);
    }
}
