import RSVP from 'rsvp';
import merge from 'lodash/merge';
import find from 'lodash/find';

export function getSaturationConceptPatches(patchStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = patchStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let patches = [];

        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor) {
                patches.push(openedCursor.value);
                openedCursor.continue();
            } else {
                resolve(patches);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function addSaturationConceptPatch(patchStore, saturationConceptId, patch) {
    return new RSVP.Promise((resolve, reject) => {
        patch = merge(patch, { saturationConceptId });

        var addRequest = patchStore.put(patch);

        addRequest.onsuccess = function (successEvent) {
            resolve(successEvent.target.result);
        };

        addRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function addSaturationConceptPatches(patchStore, saturationConceptId, patches) {
    return new RSVP.Promise((resolve, reject) => {
        let i = 0, patchesCount = patches.length;
        let putNext = () => {
            if (i < patchesCount) {
                let patch = patches[i];
                let putRequest = patchStore.put({
                    id: patch.id,
                    saturationConceptId,
                    created: patch.created,
                    recordedActions: patch.recordedActions,
                    generalInfo: patch.generalInfo
                });

                i++;

                putRequest.onsuccess = putNext;
                putRequest.onerror = function (errorEvent) {
                    reject(errorEvent.target.error);
                };
            } else {
                resolve();
            }
        };

        putNext();
    });
}

export function removeSaturationConceptPatchesByConceptId(patchStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = patchStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let removedPatches = [];

        cursor.onsuccess = function (successEvent) {
            var cursor = successEvent.target.result;

            if (cursor) {
                removedPatches.push(cursor.value);
                cursor.delete();
                cursor.continue();
            } else {
                resolve(removedPatches);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function removeSaturationConceptPatchesByPatchIds(conceptActionsStore, saturationConceptId, patchIds) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptActionsStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let removedPatches = [];

        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor && find(patchIds, pId => pId === openedCursor.value.id)) {
                removedPatches.push(openedCursor.value);
                openedCursor.delete();
                openedCursor.continue();
            } else if (openedCursor) {
                openedCursor.continue();
            } else {
                resolve(removedPatches);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}
