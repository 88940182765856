import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { IWidgetInstance } from 'ava-saturation/interfaces/widget';

export default class PageContextSelection extends Component {
    // this is a direct *referece to the widget store DON'T mutate it
    widgets: IWidgetInstance[];
    selectedWidgets: string[] = [];

    @action
    forwardSelection(closureAction: string, entity: any) {
        // @ts-ignore
        this[`${closureAction}`](entity);
    }

    @action
    widgetToggled(widgetId: string) {
        const idx = this.selectedWidgets.indexOf(widgetId);

        if (idx === -1) {
            this.selectedWidgets.push(widgetId);
        } else {
            this.selectedWidgets.splice(idx, 1);
        }
    }

    @computed()
    get deflatedWidgets() {
        return this.widgets
            .filter(w => this.selectedWidgets.includes(w.id))
            .map(ew => ew.deflate());
    }
}
