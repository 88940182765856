import Component from '@ember/component';
import { observer } from '@ember/object';
import jQuery from 'jquery';

/**
 * @class PaperSearch
 * @extends Ember.Component
 */
export default Component.extend({
    tagName: 'md-search',
    searchText: null,
    didRender() {
        this._super(...arguments);

        jQuery('.clear-btn-icon').addClass('clear-btn-icon--rotate');
    },

    searchTextObserver: observer('searchText', function () {
        var timer = this.delayTimer;
        var self = this;

        clearTimeout(timer);

        timer = setTimeout(function () {
            var searchText = self.get('searchText');
            self.onSearchTextChanged(searchText);
        }, 200);

        this.set('delayTimer', timer);
    }),

    actions: {
        searchFieldFocus() {
            jQuery('#input-search-field').focus();
        },
        onToolbarTap() {
            this.actions.searchFieldFocus();
        },
        toggleSearch() {
            this.set('searchText', null);
            this.onSearchTextChanged(this.searchText);
        }
    }
});
