/**
 * @module ember-paper
 */
import PaperToolbar from 'ember-paper/components/paper-toolbar';

import ColorMixin from 'ava-saturation/mixins/overrides/color-mixin';

/**
 * @class PaperToolbar
 * @extends Ember.Component
 * @uses ColorMixin
 */
export default PaperToolbar.extend(ColorMixin, {
    classNameBindings: ['whiteframe:md-whiteframe-z2', 'whiteframe-z1:md-whiteframe-z1']
});