import DS from 'ember-data';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    generalInformation: validator('belongs-to'),
    metadata: validator('belongs-to'),
    recordedActions: validator('has-many')
});

const ModelChanges = buildModelChanges(
    ['generalInformation', 'metadata', 'recordedActions'],
    {
        'generalInformation': { model: true },
        'metadata': { model: true },
        'recordedActions': {
            items: true,
            itemModel: true
        }
    });

export default DS.Model.extend(Validations, ModelChanges, {
    'generalInformation': fragment('saturation-concept-info', { defaultValue: {} }),
    'metadata': fragment('saturation-concept-metadata', { defaultValue: {} }),
    'recordedActions': fragmentArray('saturation-concept-action', { defaultValue: [] }),
    'saturationConceptId': DS.attr('string'),
    'created': DS.attr('date'),
    'anchorVersionId': DS.attr('string'),
    'anchorPatchId': DS.attr('string')
});
