import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { DataSourceDefinition, dataSourceDefinitionFactory, DataSourceInstance, generalLogSourceDefinition } from 'ava-saturation/classes/data-source';
import { PlotDefinition } from 'ava-saturation/interfaces/plot';
import IWell from 'ava-saturation/interfaces/well-reference';
import IWidget, { IWidgetInstance, IWidgetSettings } from 'ava-saturation/interfaces/widget';

export default abstract class WidgetBase extends Component implements IWidget {
    @service intl: any;

    @computed('instance.id')
    get id(): string {
        return this.instance.id;
    }

    @computed('instance.displayName')
    get name(): string {
        return this.instance.displayName;
    }

    abstract instance: WidgetInstance;

    @computed('instance.dataSourceInstance.definition')
    get dataSourceName(): string {
        assert(`${WidgetBase.name} undefined dataSourceInstance!`, this.instance.dataSourceInstance != null);
        return this.intl.t(`datasource.${this.instance.dataSourceInstance!.definition.name}`);
    }

    @computed('instance.dataSourceInstance.plotDefinition')
    get plotName(): string {
        assert(`${WidgetBase.name} undefined dataSourceInstance!`, this.instance.dataSourceInstance != null);
        return this.intl.t(`plots.${this.instance.dataSourceInstance!.plotDefinition.name}.title`);
    }

    @computed('name', 'dataSourceName', 'plotName')
    get displayName(): string {
        return this.name || `${this.dataSourceName} - ${this.plotName}`;
    }

    abstract onRename(id: string, name: string): void;

    @action
    onDataSourceChanged(sourceDefinition: DataSourceDefinition) {
        this.instance.onDataSourceChanged(sourceDefinition);
    }

    @action
    onPresenterChanged(presenterDefinition: PlotDefinition) {
        this.instance.onPresenterChanged(presenterDefinition);
    }
}

export class WidgetInstance implements IWidgetInstance {
    constructor(inflatable: Partial<WidgetInstance>, wells: IWell[]) {
        assert(`${WidgetInstance.name} must recieve a well collection to support cloning!`, wells != null);
        set(this, 'wells', wells);

        //#region SMELLS IN HERE
        // when restoring from a Partial the data source will be created twice
        // 1. Make all new calls with the same Partial
        // 2. Always set the DS instance in the ctor
        // 2.1 remove the set call in settings
        set(this, 'settings', inflatable.settings ? WidgetSettings.inflate(inflatable.settings!) : new WidgetSettings());
        set(this, 'id', inflatable.id!); // will be undefined if no id was set
        set(this, 'displayName', inflatable.displayName!); // will be undefined if no displayName was set
        if (inflatable.dataSourceInstance)
            set(this, 'dataSourceInstance', DataSourceInstance.inflate(inflatable.dataSourceInstance!, wells));
        //#endregion SMELLS IN HERE
    }

    id: string;
    displayName: string;
    _settings: WidgetSettings;
    wells: IWell[];

    get settings(): IWidgetSettings {
        return this._settings;
    }
    set settings(value: IWidgetSettings) {
        //#region SMELLS IN HERE
        set(this, '_settings', new WidgetSettings(value.dataSourceDefinition));
        set(this, 'dataSourceInstance', new DataSourceInstance({ definition: this._settings.dataSourceDefinition }, this.wells));
        //#endregion SMELLS IN HERE
    }

    dataSourceInstance: DataSourceInstance;

    @computed('displayName')
    get name(): string {
        return this.displayName;
    }
    set name(name: string) {
        set(this, 'displayName', name);
    }

    onDataSourceChanged<T extends DataSourceDefinition>(dataSourceDefinition: T): void {
        set(this, 'settings', new WidgetSettings(dataSourceDefinition));
    }

    onPresenterChanged<T extends PlotDefinition>(presenterDefinition: T): void {
        // @ts-ignore
        set(this, 'dataSourceInstance.plotDefinition', presenterDefinition);
    }

    static inflate(inflatable: Partial<WidgetInstance>, wells: IWell[]): WidgetInstance {
        return new WidgetInstance(inflatable, wells);
    }

    deflate(): Object {
        assert(`${WidgetInstance.name} dataSourceInstance is undefined!`, this.dataSourceInstance != null);
        return {
            id: this.id,
            displayName: this.displayName,
            settings: this.settings.deflate(),
            dataSourceInstance: this.dataSourceInstance!.deflate()
        };
    }
}

export class WidgetSettings implements IWidgetSettings {
    constructor(dataSourceDefinition?: Partial<DataSourceDefinition>) {
        this.dataSourceDefinition = dataSourceDefinition ? dataSourceDefinitionFactory(dataSourceDefinition) : generalLogSourceDefinition;
    }

    dataSourceDefinition: DataSourceDefinition;

    static inflate(o: Partial<WidgetSettings>): WidgetSettings {
        assert(`${WidgetSettings.name} undefined  datasource definition!`, o.dataSourceDefinition != null);
        return new WidgetSettings(o.dataSourceDefinition!);
    }

    deflate(): Object {
        return {
            dataSourceDefinition: this.dataSourceDefinition.deflate()
        };
    }
}