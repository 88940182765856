import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';

export default class FaciesPropertyReferenceType extends ReferenceObjectType {
    readonly id = 141;
    readonly name = 'Facies';
    readonly typeAliases = ['PetrelFaciesProperty', 'PetrelFaciesPropertyUpdate'];
    subTypeAliases = [];
}

export const faciesPropertyReferenceType = new FaciesPropertyReferenceType();

export class FaciesPropertyReferenceParser extends SingleReferenceParser<IFaciesProperty> {
    parse(object: any): IFaciesProperty {
        let reference = super.parse(object);
        const templateMoniker = this.parseTemplateMoniker(object);
        return {
            ...reference,
            type: faciesPropertyReferenceType,
            templateMoniker
        };
    }
}

export class FaciesPropertyCollectionParser extends ReferenceParser<Array<IFaciesProperty>> {
    parse(object: any): Array<IFaciesProperty> {
        return referenceCollectionParser<IFaciesProperty>(this.appendGridMoniker(object, 'Properties'), new FaciesPropertyReferenceParser(this.origin, this.version));
    }
}