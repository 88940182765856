import { flatMap, map } from 'lodash';
import { createSelector, ParametricSelector } from 'reselect';
import { FunctionBand, IBand, IRange } from './types';

export interface BasicSearchProps {
    sid: string;
}

export interface SearchProps extends BasicSearchProps {
    functionId: string;
}

export const bandTheme = [
    'layout-band-background-grey',
    'layout-band-background-light-red',
    'layout-band-background-light-green',
    'layout-band-background-light-yellow',
    'layout-band-background-light-blue',
    'layout-band-background-orange',
    'layout-band-background-dark-purple',
    'layout-band-background-lighter-green',
    'layout-band-background-light-purple',
    'layout-band-background-dark-green',
    'layout-band-background-brown',
    'layout-band-background-lighter-yellow',
    'layout-band-background-dark-red',
    'layout-band-background-medium-green-yellow',
    'layout-band-background-lighter-red',
    'layout-band-background-darker-purple'
];

export const getFunctionBandsBySid: ParametricSelector<any, BasicSearchProps, FunctionBand[]> = (state: any, props: BasicSearchProps) =>
    state.entitiesV1.saturationConceptState.functionBand.bySid[props.sid];

export const getBands = createSelector<any, SearchProps, FunctionBand[], IBand[]>(
    [getFunctionBandsBySid], (bands) => {
        return bands.map(b => ({
            ...b,
            colorClass: bandTheme[b.colorIndex]
        }));
    }
);

export const getRanges = createSelector<any, SearchProps, FunctionBand[], IRange[]>([getFunctionBandsBySid], (bands) => {
    return flatMap(bands, (b) => map(b.ranges, (r) => ({
        id: '-1',
        from: r.from,
        to: r.to,
        parentId: b.id,
        color: bandTheme[b.colorIndex],
        bandType: b.type,
        functionId: b.functionId,
        name: b.name
    })));
}
);
