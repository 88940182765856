import { NonNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';

const wellKey = 'well';

const well = new NonNumericDimension();
well.displayName = 'Well';
well.name = well.shortName = wellKey;
well.axisPriority = DimensionAxisDisplayPriority.Well;

export interface IWellSample {
    [wellKey]: string;
}

export default well;