import Route from '@ember/routing/route';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';

import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, {

    objectStore: service(),
    qcWorkflow: service(),

    beforeModel() {
        // In case property was created from Saturation Concept.
        this.objectStore.refresh(['waterSaturationLogs', 'waterSaturationProperties']);
    },

    model() {
        return RSVP.hash({
            grids: this.objectStore.grids,
            wells: this.objectStore.wells,
            contacts: this.objectStore.contacts,
            zones: this.objectStore.zones,
            segments: this.objectStore.segments,
            faciesProperties: this.objectStore.faciesProperties,
            porosityLogs: this.objectStore.porosityLogs,
            waterSaturationLogs: this.objectStore.waterSaturationLogs,
            faciesLogs: this.objectStore.faciesLogs,
            wellPointLogs: this.objectStore.wellPointLogs,
            waterSaturationProperties: this.objectStore.waterSaturationProperties,
        });
    },

    afterModel(model: any) {
        this.qcWorkflow.model = model;
    },

    actions: {
        willTransition() {
            this.qcWorkflow.clean();
        }
    },
});
