define("ava-saturation/services/petrel-script", ["exports", "petrel-store/services/petrel-script"], function (_exports, _petrelScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _petrelScript.default;
    }
  });
});
