import Component from '@ember/component';
import { action, defineProperty, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { NumericScalableAxisOptionSettings } from 'ava-saturation/classes/widgets/axis';

export default class NumericAxisSettings extends Component {
    settings: NumericScalableAxisOptionSettings;

    constructor() {
        super(...arguments);

        defineProperty(this, 'scale', alias('settings.scale'));
    }

    @action
    scaleChanged(value: number) {
        // @ts-ignore
        set(this, 'scale', value);
    }
}
