import { get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import { contact } from 'ava-saturation/classes/dimensions/contact';
import { faciesProperty } from 'ava-saturation/classes/dimensions/facies-property';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';
import { addModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/actions';
import { setModel } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/actions';
import { addDeflatedWidget } from 'ava-saturation/store/entities-v1/saturation-concept-state/widget/actions';
import { DeflatedWidget } from 'ava-saturation/store/entities-v1/saturation-concept-state/widget/types';
import uuid from 'ava-saturation/utils/uuid';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, {
    store: inject('store'),
    redux: inject(),
    concept: inject(),

    //
    queryParams: {
        sourceMonikerString: {
            refresh: true
        },
        grid: {
            refresh: true
        },
        contact: {
            refresh: true
        },
        facies: {
            refresh: true
        },
        widgets: {
            refresh: true
        }
    },

    async model(params: any) {
        const sourceMonikerString: string = get(params, 'grid');
        const contactMonikerString: string = get(params, 'contact');
        const faciesMonikerString: string = get(params, 'facies');

        // const record = this.store.createRecord('saturation-concept');
        // const modelId = sourceMonikerString ? uuid() : sourceMonikerString;

        // set(record, 'metadata', {
        //     sourceMonikerString: sourceMonikerString,
        //     modelId: modelId
        // });

        // const concept = await record.save();

        // let saturationConcept = concept.toJSON();
        // saturationConcept.id = concept.get('id');

        // this.redux.dispatch(createConcept(saturationConcept));

        const saturationConcept = await this.concept.create({
            modelMoniker: sourceMonikerString
        });

        if (saturationConcept.metadata.modelId) {
            this.redux.dispatch(setModel({
                id: saturationConcept.metadata.modelId,
                moniker: sourceMonikerString,
                sid: saturationConcept.id
            }));

            if (contactMonikerString) {
                this.redux.dispatch(addModelInput({
                    id: uuid(),
                    sid: saturationConcept.id,
                    moniker: contactMonikerString,
                    modelMoniker: sourceMonikerString,
                    type: contact.name,
                }));
            }

            if (faciesMonikerString) {
                this.redux.dispatch(addModelInput({
                    id: uuid(),
                    sid: saturationConcept.id,
                    moniker: faciesMonikerString,
                    modelMoniker: sourceMonikerString,
                    type: faciesProperty.name,
                }));
            }

            if (params.widgets) {
                const widgets = JSON.parse(params.widgets) as DeflatedWidget[];
                widgets.forEach(w => {
                    const id = uuid();
                    return this.redux.dispatch(addDeflatedWidget({
                        id,
                        sid: saturationConcept.id,
                        widgetDefinition: JSON.stringify({
                            ...w,
                            id
                        })
                    }));
                });
            }
        }

        this.transitionTo('saturation-concepts.edit', saturationConcept.id);
    }
});
