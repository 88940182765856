import QualityControlQcChart from 'ava-saturation/components/quality-control/qc-chart';
import { PointSeries } from 'ava-saturation/components/charting/chart-series';
import { computed, action } from '@ember/object';
import { ChartLegendItemsGroup, ChartLegendItem } from '../charting/chart-legend';
import IZone from 'ava-saturation/interfaces/zone-reference';
import ISegment from 'ava-saturation/interfaces/segment-reference';
import IWell from 'ava-saturation/interfaces/well-reference';
import { inject as service } from '@ember/service';
import QCWorkflow from 'ava-saturation/services/qc-workflow';
import { ChartAxisOptions } from '../charting/chart-axis-options';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';

export default class CrossPlotChart extends QualityControlQcChart {
    @service qcWorkflow: QCWorkflow;

    data: any[];
    panelOpen: boolean = true;
    selectedTab: string = 'Wells';
    selectedSegment: ISegment;

    @computed('qcWorkflow.pairs')
    get series(): PointSeries[] {
        const xAccessor = (point: any) => point.log1;
        const yAccessor = (point: any) => point.log2;
        return this.qcWorkflow.pairs.map(d => {
            let series = new PointSeries();
            series.title = d.label;
            series.points = d.mixed;
            series.xAccessor = xAccessor;
            series.yAccessor = yAccessor;
            series.color = d.color;
            series.tipData = (point: any) => `<div class="d3-tip-inner" style="background-color:${d.color};">
                            <b>${d.label}</b> <br/>
                            ${point.log1Name}: <b>${xAccessor(point).toFixed(3)}</b> <br />
                            ${point.log2Name}: <b>${yAccessor(point).toFixed(3)}</b> <br />
                            Well: <b>${point.well}</b> <br />
                            TVDss: <b>${point.tvdss.toFixed(2)}</b> <br />
                            Zone: <b>${point.zone}</b> <br />
                            Segment: <b>${point.segment}</b>
                        </div>`;
            return series;
        });
    }

    @computed('orderedWells', 'qcWorkflow.context.selectedWells.length')
    get selectedWells(): IWell[] {
        return this.qcWorkflow.context.selectedWells.map((moniker: string) => this.orderedWells.find((w: IWell) => w.moniker.string === moniker));
    }

    @computed('qcWorkflow.wells')
    get orderedWells() {
        let sorted = this.qcWorkflow.wells.sortBy('name');
        this.qcWorkflow.context.selectedWells.clear();
        this.qcWorkflow.context.selectedWells.addObject(sorted[0].moniker.string);
        return sorted;
    }

    @computed('qcWorkflow.context.selectedZones.length')
    get selectedZones(): IZone[] {
        return this.qcWorkflow.context.selectedZones.map((moniker: string) => this.qcWorkflow.relatedZones.find((z: IZone) => z.moniker.string === moniker));
    }

    @computed('qcWorkflow.context.selectedSegments.length')
    get selectedSegments(): IZone[] {
        return this.qcWorkflow.context.selectedSegments.map((moniker: string) => this.qcWorkflow.relatedSegments.find((z: IZone) => z.moniker.string === moniker));
    }

    @computed('series', 'series.@each.hidden')
    get legendData(): ChartLegendItemsGroup[] {
        let countItem = new ChartLegendItem();
        countItem.id = 'countItem';
        countItem.name = `N: ${this.series.filter(s => !s.hidden).reduce((all, current) => current.points.length + all, 0)}`;
        let countGroup = new ChartLegendItemsGroup();
        countGroup.items = [countItem];

        let group = new ChartLegendItemsGroup();
        group.name = 'Legend';
        group.items = this.series.map(s => {
            let item = new ChartLegendItem();
            item.id = s.id;
            item.name = s.title;
            item.selectable = true;
            item.selected = !s.hidden;
            item.color = s.color;
            return item;
        });

        return [countGroup, group];
    }

    @action
    togglePanel() {
        this.toggleProperty('panelOpen');
    }

    @action
    onTabChange(tab: string) {
        this.set('selectedTab', tab);
    }

    @action
    onWellToggle(well: IWell, isSelected: boolean) {
        if (isSelected) {
            this.qcWorkflow.context.selectedWells.removeObject(well.moniker.string);
        } else {
            this.qcWorkflow.context.selectedWells.pushObject(well.moniker.string);
        }
    }

    @action
    onFilterAdded(filter: any, filterType: any) {
        if (filterType === 'zone') {
            const moniker = filter.moniker.string;
            if (this.qcWorkflow.context.selectedZones.includes(moniker)) {
                this.qcWorkflow.context.selectedZones.removeObject(moniker);
            } else {
                this.qcWorkflow.context.selectedZones.pushObject(moniker);
            }
        }
        if (filterType === 'segment') {
            const moniker = filter.moniker.string;
            if (this.qcWorkflow.context.selectedSegments.includes(moniker)) {
                this.qcWorkflow.context.selectedSegments.removeObject(moniker);
            } else {
                this.qcWorkflow.context.selectedSegments.pushObject(moniker);
            }
        }
    }

    @action
    exportCrossPlotData() {
        this.qcWorkflow.exportCrossPlot('Cross Plot Data Export');
    }

    @computed()
    get xAxisOptions(): ChartAxisOptions {
        let options = new ChartAxisOptions();
        options.title = `${waterSaturation.displayName} (Original logs)`;
        options.pinMin = 0;
        options.pinMax = 1;
        return options;
    }

    @computed()
    get yAxisOptions(): ChartAxisOptions {
        let options = new ChartAxisOptions();
        options.title = `${waterSaturation.displayName} (Synthetic logs)`;
        options.pinMin = 0;
        options.pinMax = 1;
        return options;
    }

}
