import { on } from '@ember/object/evented';
import Mixin from '@ember/object/mixin';
import Route from '@ember/routing/route';

export default Mixin.create({
    _ensureConfirmationMixinOnRoute: on('init', function () {
        // @ts-ignore this can not be typed in Mixin context
        if (!(this instanceof Route)) {
            throw Error('BeforeUnload Mixin must be mixed into a Route.');
        }
    }),
    // @ts-ignore
    onUnload(event: UIEvent) {
        /* intentionally left blank to implement own custom teardown logic */
    },
    // @ts-ignore
    onBeforeunload(event: UIEvent) {
        /* intentionally left blank to implement own custom teardown logic */
    },
    handleEvent(event: UIEvent) {
        let fnName = event.type.split('');

        if (fnName.length) {
            fnName[0] = fnName[0].toUpperCase();
            // @ts-ignore this can not be typed in Mixin context
            const fn = this['on' + fnName.join('')];

            if (typeof fn === 'function') {
                fn.apply(this, arguments);
            }
        }
    },
    activate() {
        const _super = this._super(...arguments);

        if (window && window.addEventListener) {
            window.addEventListener('beforeunload', this, false);
            window.addEventListener('unload', this, false);
        }

        return _super;
    },
    deactivate() {
        const _super = this._super(...arguments);

        if (window && window.removeEventListener) {
            window.removeEventListener('beforeunload', this, false);
            window.removeEventListener('unload', this, false);
        }

        return _super;
    }
});
