import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import configuration from 'ava-saturation/config/environment';
import { isConflictError } from 'ember-ajax/errors';
import AjaxService from 'ember-ajax/services/ajax';
import { Promise } from 'rsvp';

const delay = (min, max) => new Promise((resolve) => setTimeout(resolve, Math.floor(Math.random() * (max - min)) + min));

export default class Ajax extends AjaxService.extend({
    get headers() {
        let headers = {};
        const accessToken = this.get('session.data.authenticated.access_token');
        if (isPresent(accessToken)) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return headers;
    },
    request(url, options) {
        const requestBase = this._super;
        const makeRequest = () => {
            if (!this.get('session.isAuthenticated') || url.indexOf('/.well-known/') >= 0) {
                return requestBase.call(this, url, options);
            }

            if (this.get('session.isAccessTokenExpired')) {
                return this.session
                    .refreshAccessToken()
                    .then(() => {
                        const headers = this.headers;
                        options.beforeSend = function (xhr) {
                            Object.keys(headers).forEach(function (key) {
                                return xhr.setRequestHeader(key, headers[key]);
                            });
                        };
                        return requestBase.call(this, this._appendEcTokenIfNeeded(url), options);
                    });
            }

            return requestBase.call(this, this._appendEcTokenIfNeeded(url), options);
        };

        const retryAfterDelay = (t1, t2) => {
            return (error) => {
                if (isConflictError(error)) {
                    return Promise.reject(error);
                }

                return delay(t1, t2).then(makeRequest);
            };
        };

        return makeRequest()
            .catch(retryAfterDelay(100, 1000))
            .catch(retryAfterDelay(500, 2500));
    },
    _appendEcTokenIfNeeded(url) {
        if (url.indexOf('ectoken=') > 0 || this.cdnEndpoints.every((x) => url.indexOf(x) < 0)) {
            return url;
        }

        return `${url}${url.indexOf('?') >= 0 ? '&' : '?'}ectoken=${this.get('session.data.authenticated.ec_token')}`;
    }
}) {
    @service session;
    trustedHosts = configuration.APP.ajaxTrustedHosts;
    cdnEndpoints = configuration.APP.cdnEndpoints;
}
