import objectName from 'ava-import/classes/dimensions/object-name';
import { calculate, dependencies } from 'ava-saturation/classes/calculations/dependency-injection/iterator-pure';
import facies from 'ava-saturation/classes/dimensions/facies';
import segment from 'ava-saturation/classes/dimensions/segment';
import well from 'ava-saturation/classes/dimensions/well';
import zone from 'ava-saturation/classes/dimensions/zone';
import CalculationProvider from 'ava-saturation/components/calculation-provider';
import IDataset from 'ava-saturation/interfaces/dataset';

// "#651d93",
// "#b2d582",
// "#f26391",
// "#787867",
// "#f9b594",
// "#c70039",
// "#0862a8",
// "#2dbe79",
// "#f7c741",
// "#388ce4",
// "#755419",
// "#4cc003",
// "#d65460",
// "#006080",
// "#FFA700",
// "#A98CBA",
// "#9DBA8C",
// "#671E6B",
// "#d4864d",
// "#a8c5e2",
// "#1ac7fd",

export default class IteratingCalculationProvider extends CalculationProvider {
    get metadataDimensions() {
        return [zone, segment, well, facies];
    }

    processOutputDataset(dataset: IDataset<Record<string, number>>): IDataset<Record<string, number>> {
        // @ts-ignore
        let targetLog = this.wellContext.wellPointLogs.find(log => log.moniker.string === dataset.wellLogMonikers[0].string);
        if (!targetLog)
            return dataset;

        // @ts-ignore
        dataset.values.forEach(v => v[objectName.shortName] = `[${targetLog.name}] ${v[objectName.shortName]}`);
        // dynamically build legend values
        // let allDatasets = this.datasets.map(ds => ds.dataset);
        // let sampleNames = flatMap(allDatasets, ds => ds.values.map((v: Record<string, any>) => v[objectName.shortName]).uniq());

        // if (sampleNames && sampleNames.find(_ => true) != undefined) {
        //     let i = 0;
        //     //@ts-ignore
        //     dataset.metadata = {
        //         itemsByKey: {
        //             [objectName.shortName]: {
        //                 dimension: objectName,
        //                 values: sampleNames.map(s => {
        //                     let result = {
        //                         name: s,
        //                         color: colors[i % colors.length],
        //                         type: {
        //                             name: "sample"
        //                         }
        //                     };
        //                     i++;
        //                     return result;
        //                 })
        //             }
        //         }
        //     };
        // }

        return dataset;
    }

    get calculator() {
        return {
            executor: calculate,
            dependencies
        };
    }
}