// dimensions
import facies from 'ava-saturation/classes/dimensions/facies';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';

// functions
import {
    lerpWithoutCoefficient,
    dependencies as lerpDependencies
} from 'ava-saturation/utils/lerp';
import isInZeroOneRange, {
    dependencies as inRangeDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/filters/is-in-zero-one-range';
import isInZone from 'ava-saturation/calculations/log-filters/is-in-zone';
import isPorosityNotZero from 'ava-saturation/calculations/log-filters/is-porosity-not-zero';
import isPorosityPointValid from 'ava-saturation/classes/calculations/dependency-injection/filters/is-porosity-point-valid';
import isWaterSaturationNotOne from 'ava-saturation/calculations/log-filters/is-water-saturation-not-one';
import isWaterSaturationPointValid from 'ava-saturation/classes/calculations/dependency-injection/filters/is-water-saturation-point-valid';

// extenders
import WellDatasetExtender, {
    dependencies as wellExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/well-extender-pure';
import WellLogDatasetExtender, {
    dependencies as wellLogExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/well-log-extender-pure';
import FaciesCodeDatasetExtender, {
    dependencies as faciesCodeExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/facies-code-extender-pure';
import ContactDepthDatasetExtender, {
    dependencies as contactDepthExtenderDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/extenders/contact-depth-extender-pure';

// calculators
import BVWDatasetCalculator, {
    dependencies as bvwCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/bvw-dataset-calculator-pure';
import HAFWLDatasetCalculator, {
    dependencies as hafwlCalculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/hafwl-dataset-calculator-pure';

export default function DatasetInterpolator(
    deps,
    conditioningDatasetFiltersByKey,
    keyInterpolators,
    onDatasetPointReady,
    onDatasetReady
) {
    const isNil = (value) => {
        return null == value;
    };

    return {
        dependencies: deps,
        _conditioningDatasetFiltersByKey: conditioningDatasetFiltersByKey,
        keyInterpolators: keyInterpolators,
        _onDatasetPointReady: onDatasetPointReady,
        _onDatasetReady: onDatasetReady,
        getMasterKey() {
            return this.masterDimension.shortName;
        },
        getTargetKeys() {
            return this.targetDimensions.map(d => d.shortName);
        },
        _shouldInterpolateValueForKey: function(point, key) {
            return isNil(point[key]);
        },
        _shouldAwaitInterpolation: function(point, context) {
            return context.getTargetKeys().reduce((result, tk) => result || Object.prototype.hasOwnProperty.call(point, `${tk}_interp`), false);
        },
        _setValueInterpolatorForKey: function(point, startingPoint, closingPoint, key, context) {
            closingPoint.subscribe.call(closingPoint, point, context);
            point[`${key}_interp`] = {
                startingPoint: Object.assign({}, startingPoint),
                closingPoint: closingPoint
            };
        },
        _getBlankClosingPointForKey: function(masterKey, targetKey) {
            return {
                subscribers: [],
                setValue: function (value) {
                    this.subscribers.forEach((s) => s[`onClosing${targetKey}PointChanged`](value));
                },
                subscribe: function (point, context) {
                    this.subscribers.push(point);
                    point[`onClosing${targetKey}PointChanged`] = function (value) {
                        let startingPoint = this[`${targetKey}_interp`].startingPoint,
                            closingPoint = value;

                        if (Object.keys(startingPoint).length === 0) {
                            return;
                        }

                        let keyInterpolator = context.keyInterpolators[targetKey] || deps.functions.lerpWithoutCoefficient;

                        this[targetKey] = keyInterpolator(
                            deps,
                            startingPoint[masterKey],
                            closingPoint[masterKey],
                            startingPoint[targetKey],
                            closingPoint[targetKey],
                            this[masterKey]
                        );

                        delete this[`${targetKey}_interp`];
                        delete this[`onClosing${targetKey}PointChanged`];

                        let requiresInterpolation = context._shouldAwaitInterpolation(this, context);
                        if (!requiresInterpolation) {
                            context.onDatasetPointReady(this, context.outputDataset);
                            context.outputDataset.values.push(this);
                        }
                    };
                }
            };
        },
        _processDataPoint: function(point, masterKey, targetKeys) {
            let context = this;
            targetKeys.forEach(key => {
                if (this._shouldInterpolateValueForKey(point, key)) {
                    this._setValueInterpolatorForKey(point, this.lastGoodValuePerKey[key], this.blankValuePerKey[key], key, context);
                } else {
                    let value = {};
                    // [masterKey]: point[masterKey], [key]: point[key]
                    value[masterKey] = point[masterKey];
                    value[key] = point[key];

                    this.lastGoodValuePerKey[key] = value;
                    this.blankValuePerKey[key].setValue(value);
                    this.blankValuePerKey[key] = this._getBlankClosingPointForKey(masterKey, key);
                }
            });

            let requiresInterpolation = this._shouldAwaitInterpolation(point, context);
            if (!requiresInterpolation) {
                this.onDatasetPointReady(point, this.outputDataset);
                this.outputDataset.values.push(point);
            }
        },
        _reset: function() {
            this.blankValuePerKey = {};
            this.lastGoodValuePerKey = {};
        },
        onDatasetPointReady: function(point, outputDataset) {
            if (this._onDatasetPointReady)
                this._onDatasetPointReady(point, outputDataset);
        },
        generateDimensionDictionary: function(datasets) {
            let dimensions = datasets.flatMap(d => d.dimensions);

            let dimensionDictionary = {};

            dimensions.forEach(d => {
                dimensionDictionary[d.shortName] = dimensionDictionary[d.shortName] ?
                    dimensionDictionary[d.shortName].concat(d) :
                    [d];
            });

            return dimensionDictionary;
        },
        isolateMasterDimension: function(dimensionDictionary) {
            const masterDimensionCandidates = Object.keys(dimensionDictionary).reduce((dimensions, key) => {
                if (dimensionDictionary[key].length > 1)
                    dimensions.push(dimensionDictionary[key][0]);

                return dimensions;
            }, []);

            if (masterDimensionCandidates.length !== 1)
                throw 'The datasets cannot be merged on more than one key.';

            return masterDimensionCandidates[0];
        },
        isolateDimensions: function(dimensionDictionary) {
            const targetDimensionCandidates = Object.keys(dimensionDictionary).reduce((dimensions, key) => {
                dimensions.push(dimensionDictionary[key][0]);

                return dimensions;
            }, []);

            if (targetDimensionCandidates.length < 2)
                throw 'Interpolation is not possible across a single dimension.';

            return targetDimensionCandidates;
        },
        bootstrapInterpolationKeys: function(masterKey, targetKeys) {
            this.blankValuePerKey = targetKeys.reduce((prev, k) => {
                prev[k] = this._getBlankClosingPointForKey(masterKey, k);
                return prev;
            }, {});

            this.lastGoodValuePerKey = {};
        },
        conditionInputDataset: function(dataset) {
            const conditioningKeys = Object.keys(this._conditioningDatasetFiltersByKey);

            if (dataset.dimensions.some(d => conditioningKeys.indexOf(d.shortName) != -1)) {
                dataset.values = dataset.values.filter(v => {
                    return conditioningKeys.every(key => {
                        if (dataset.dimensions.some(d => d.shortName == key)) {
                            return this._conditioningDatasetFiltersByKey[key](deps, v);
                        }

                        return true;
                    });
                });
            }
        },
        bootstrapOutputDataset: function(datasets) {
            const definingDataset = datasets.find(d => d.wellMoniker !== null);

            const wellLogPairs = datasets.map(d => d.wellLogMoniker).filter(d => d);

            this.outputDataset = {
                primaryDimension: this.masterDimension,
                secondaryDimension: this.targetDimensions[0],
                dimensions: this.targetDimensions.concat([this.masterDimension]),
                values: [],
                wellMoniker: definingDataset ? definingDataset.wellMoniker : null,
                wellLogMonikers: wellLogPairs // #well-log-moniker-extensions
            };
        },
        runInterpolation: function(datasets, masterKey, targetKeys) {
            let orderedValues = datasets
                .reduce((values, dataset) => values.concat(dataset.values), [])
                .sort((v1, v2) => {
                    return v1[masterKey] - v2[masterKey];
                });

            var previous = null;
            for (let i = 0; i < orderedValues.length; i++) {
                if (previous) {
                    if (previous[masterKey] === orderedValues[i][masterKey]) {
                        orderedValues[i] = Object.assign(previous, orderedValues[i]);
                    } else {
                        this._processDataPoint(previous, masterKey, targetKeys);
                    }
                }

                var current = previous = orderedValues[i];

                if (i === (orderedValues.length - 1))
                    this._processDataPoint(current, masterKey, targetKeys);
            }
        },
        interpolate: function(datasets) {
            if (datasets.length < 2)
                return null;

            this._reset();

            datasets.forEach(ds => this.conditionInputDataset(ds));

            let dimensionDictionary = this.generateDimensionDictionary(datasets);

            this.masterDimension = this.isolateMasterDimension(dimensionDictionary);
            const allDimensions = this.isolateDimensions(dimensionDictionary);
            this.targetDimensions = allDimensions.filter(d => d.shortName != this.masterDimension.shortName);

            let masterKey = this.getMasterKey(),
                targetKeys = this.getTargetKeys();

            this.bootstrapInterpolationKeys(masterKey, targetKeys);
            this.bootstrapOutputDataset(datasets);

            this.runInterpolation(datasets, masterKey, targetKeys);

            if (this._onDatasetReady)
                this.outputDataset = this._onDatasetReady(this.outputDataset);

            return this.outputDataset;
        }
    };
}

export function FluentDatasetInterpolatorBuilder(deps) {
    return {
        dependencies: deps,
        conditioningFilters: {},
        interpolators: {},
        calculators: {},
        namedExtenders: {},
        filters: [],
        withDimensionInterpolator: function (dimension, interpolator) {
            this.interpolators[dimension.shortName] = interpolator;

            return this;
        },
        withCalculator: function (datasetCalculator) {
            this.calculators[datasetCalculator.producedDimension.shortName] = datasetCalculator;

            return this;
        },
        withExtender: function (datasetExtender) {
            this.namedExtenders[datasetExtender.name] = datasetExtender;

            return this;
        },
        withFilter: function (filter) {
            this.filters.push(filter);

            return this;
        },
        withConditioningFilter: function (dimension, filter) {
            this.conditioningFilters[dimension.shortName] = filter;

            return this;
        },
        build: function() {
            const onDatasetPointReady = (point, dataset) => {
                const extenderKeys = Object.keys(this.namedExtenders);

                extenderKeys.forEach(key => {
                    const extender = this.namedExtenders[key];

                    if (extender.isApplicable)
                        extender.extend(point, dataset);
                });

                const calculatorKeys = Object.keys(this.calculators);

                calculatorKeys.forEach(key => {
                    const calculator = this.calculators[key];

                    calculator.apply(point, dataset);
                });
            };

            const onDatasetReady = (dataset) => {
                if (this.filters.length > 0)
                    dataset.values = dataset.values.filter(v => this.filters.reduce((isValid, filter) => isValid && filter(v), true));

                return dataset;
            };

            return new deps.functions.DatasetInterpolator(this.dependencies, this.conditioningFilters, this.interpolators, onDatasetPointReady, onDatasetReady);
        }
    };
}

export const dependencies = {
    functions: {
        lerpWithoutCoefficient,
        isInZone,
        isInZeroOneRange,
        isPorosityNotZero,
        isPorosityPointValid,
        isWaterSaturationNotOne,
        isWaterSaturationPointValid,

        WellDatasetExtender,
        WellLogDatasetExtender,
        FaciesCodeDatasetExtender,
        ContactDepthDatasetExtender,

        ...lerpDependencies.functions,
        ...inRangeDependencies.functions,
        ...wellExtenderDependencies.functions,
        ...wellLogExtenderDependencies.functions,
        ...faciesCodeExtenderDependencies.functions,
        ...contactDepthExtenderDependencies.functions,

        BVWDatasetCalculator,
        HAFWLDatasetCalculator,

        ...bvwCalculatorDependencies.functions,
        ...hafwlCalculatorDependencies.functions,

        DatasetInterpolator,
        FluentDatasetInterpolatorBuilder
    },
    dimensionsIndex: {
        facies,
        porosity,
        waterSaturation,

        ...wellExtenderDependencies.dimensionsIndex,
        ...wellLogExtenderDependencies.dimensionsIndex,
        ...faciesCodeExtenderDependencies.dimensionsIndex,
        ...contactDepthExtenderDependencies.dimensionsIndex,

        ...bvwCalculatorDependencies.dimensionsIndex,
        ...hafwlCalculatorDependencies.dimensionsIndex
    }
};

export function calculate(deps, gridContext, wellContext, datasets) {
    const contactDepthExtender = new deps.functions.ContactDepthDatasetExtender(deps, gridContext.intersections, gridContext.contactDepths);
    const wellExtender = new deps.functions.WellDatasetExtender(deps, wellContext.wells);
    const wellLogExtender = new deps.functions.WellLogDatasetExtender(deps, wellContext.porosityLogs
        .concat(wellContext.waterSaturationLogs)
    );

    let interpolatorBuilder = new deps.functions.FluentDatasetInterpolatorBuilder(deps)
        .withCalculator(new deps.functions.BVWDatasetCalculator(deps))
        .withCalculator(new deps.functions.HAFWLDatasetCalculator(deps))
        .withExtender(contactDepthExtender)
        .withExtender(wellExtender)
        .withExtender(wellLogExtender)
        .withFilter(deps.functions.isInZone)
        .withFilter(deps.functions.isPorosityNotZero)
        .withFilter(deps.functions.isWaterSaturationNotOne)
        .withConditioningFilter(deps.dimensionsIndex.porosity, deps.functions.isPorosityPointValid)
        .withConditioningFilter(deps.dimensionsIndex.waterSaturation, deps.functions.isWaterSaturationPointValid);

    let faciesDatasets = datasets
        .filter(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) != -1);

    faciesDatasets.forEach(ds => interpolatorBuilder.withExtender(new deps.functions.FaciesCodeDatasetExtender(deps, ds)));

    const interpolator = interpolatorBuilder.build();
    const mainDatasets = datasets
        .filter(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) == -1);

    const result = interpolator.interpolate(mainDatasets);

    return result;
}

export function calculateForQC(deps, gridContext, wellContext, datasets) {
    const contactDepthExtender = new deps.functions.ContactDepthDatasetExtender(deps, gridContext.intersections, gridContext.contactDepths);
    const wellExtender = new deps.functions.WellDatasetExtender(deps, wellContext.wells);
    const wellLogExtender = new deps.functions.WellLogDatasetExtender(deps, wellContext.porosityLogs
        .concat(wellContext.waterSaturationLogs)
    );

    let interpolatorBuilder = new deps.functions.FluentDatasetInterpolatorBuilder(deps)
        .withCalculator(new deps.functions.BVWDatasetCalculator(deps))
        .withCalculator(new deps.functions.HAFWLDatasetCalculator(deps))
        .withExtender(contactDepthExtender)
        .withExtender(wellExtender)
        .withExtender(wellLogExtender)
        .withFilter(deps.functions.isInZone)
        .withFilter(deps.functions.isPorosityNotZero)
        .withConditioningFilter(deps.dimensionsIndex.porosity, deps.functions.isPorosityPointValid)
        .withConditioningFilter(deps.dimensionsIndex.waterSaturation, deps.functions.isWaterSaturationPointValid);

    let faciesDatasets = datasets
        .filter(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) != -1);

    faciesDatasets.forEach(ds => interpolatorBuilder.withExtender(new deps.functions.FaciesCodeDatasetExtender(deps, ds)));

    const interpolator = interpolatorBuilder.build();
    const mainDatasets = datasets
        .filter(ds => ds.dimensions.indexOf(deps.dimensionsIndex.facies) == -1);

    const result = interpolator.interpolate(mainDatasets);

    return result;
}
