import Ember from 'ember';
import Mixin from '@ember/object/mixin';
import { isBlank, isPresent } from '@ember/utils';

const { Logger } = Ember;

export default Mixin.create({
    queryParams: {
        code: { refreshModel: true },
        state: { refreshModel: true },
        error: { refreshModel: true },
        error_description: { refreshModel: true }
    },

    model(params) {
        if (isPresent(params.error)) {
            Logger.error(params);
            return params;
        }

        const returnUrl = params.state.split('~')[1];
        const destinationUrl = this.getDestinationRoute(returnUrl);
        if (this.get('session.isAuthenticated')) {
            this.transitionTo(destinationUrl);
            return;
        }

        return this.session
            .authenticate('authenticator:auth-code', params)
            .then(() => {
                this.transitionTo(destinationUrl);
            })
            .catch(error => {
                Logger.error(error);
                return {
                    error,
                    code: params.code,
                    state: params.state,
                    error_description: params.error_description
                };
            });
    },

    getDestinationRoute(encoded) {
        if (isBlank(encoded)) {
            return 'index';
        }

        try {
            return window.atob(encoded);
        } catch (error) {
            Logger.warn('Invalid return url.', error);
            return 'index';
        }
    }
});
