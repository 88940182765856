import { action, computed } from '@ember/object';
import Component from '@ember/component';
import IContact from 'ava-saturation/interfaces/contact-reference';
import { ModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/types';

export default class ContactSelection extends Component {
    onContactChanged: (contactModel: { item: IContact, model: ModelInput }) => void;

    @action
    onContactSelected(contactModel: { item: IContact, model: ModelInput }): void {
        this.onContactChanged(contactModel);
    }

    brokenRules: {
        referenced: [
            {
                isInValid: boolean,
                message: string
            }
        ]
    };

    @computed('brokenRules.[]')
    get message(): string {
        return this.brokenRules.referenced[0].message;
    }

    @computed('brokenRules.[]')
    get isLocked(): boolean {
        return this.brokenRules && this.brokenRules.hasOwnProperty('referenced') && (this.brokenRules.referenced ? this.brokenRules.referenced.length > 0 : false);
    }

}
