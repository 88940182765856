import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import QCWorkflow from 'ava-saturation/services/qc-workflow';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import QCGenPair from 'ava-saturation/interfaces/qc-gen-pair';
import IWaterSaturationProperty from 'ava-saturation/interfaces/water-saturation-property-reference';

export default class QualityControlCreateLog extends Component {
    @service screenSensor: any;
    @service qcWorkflow: QCWorkflow;
    @service objectStore: IObjectStore;

    selectedProperty: IWaterSaturationProperty;
    selectedLog: any;
    pairs: QCGenPair[] = [];

    onGenerateLogs: (properties: string[]) => void;

    @computed('qcWorkflow.model', 'qcWorkflow.context.grid')
    get swProperties() {
        return this.qcWorkflow.model.waterSaturationProperties.filter((property: any) => property.gridMoniker.string === this.qcWorkflow.context.grid.moniker.string);
    }

    @computed('pairs.[]')
    get availablePairs(): QCGenPair[] {
        return this.get('pairs');
    }

    @computed('selectedProperty', 'propertyAdded')
    get isPairingDisabled(): boolean {
        return this.selectedProperty === undefined || this.propertyAdded;
    }

    @computed('selectedProperty', 'pairs.[]')
    get propertyAdded() {
        return this.selectedProperty && this.pairs.find(p => p.property.moniker.identifier === this.selectedProperty.moniker.identifier) !== undefined;
    }

    @computed('pairs.[]')
    get canRun(): boolean {
        return this.pairs.length > 0;
    }

    @action
    async generateLogs() {
        if (this.onGenerateLogs) {
            this.onGenerateLogs([...this.getPropertyIdsFromPairs()]);
        }
        this.pairs.clear();
    }

    @action
    onPropertySelected(property: IWaterSaturationProperty) {
        this.set('selectedProperty', property);
    }

    @action
    onLogSelected(property: { name: string }) {
        this.set('selectedLog', property);
    }

    @action
    pairLogs() {
        this.pairs.pushObject(new QCGenPair(this.get('selectedProperty'), this.get('selectedLog')));
        this.setProperties({ selectedProperty: undefined, selectedLog: undefined });
    }

    @action
    removePair(pairId: string) {
        this.pairs.removeObject(this.pairs.findBy('id', pairId)!);
    }

    getPropertyIdsFromPairs() {
        return this.availablePairs.map(p => p.property.moniker.identifier);
    }
}
