import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

import Controller from '@ember/controller';

import { cpImportRoute } from 'ava-import/routes/cp-import';

import SaturationController from 'ava-saturation/controllers/index';
import WindowManager from 'ava-saturation/services/window-manager';

export default class AssetManagementController extends Controller {
    @controller index: SaturationController;
    @service windowManager: WindowManager;
    @service screenSensor: any;
    @service resourceManager: any;

    @alias('index.isDataStorePresent') isDataStorePresent: boolean;

    @action
    getTemplate() {
        return this.resourceManager.getCPImportTemplate();
    }

    @action
    openCPImport() {
        this.windowManager.open(cpImportRoute);
    }
}
// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'asset-management': AssetManagementController;
    }
}
