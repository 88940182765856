import { ContinuosNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import measureDepth, { IMeasureDepthSample, MeasureDepthDataset, MeasureDepthDatasetParser, MeasureDepthDatasetJsonFormatter } from 'ava-saturation/classes/dimensions/measure-depth';
import IMoniker from 'ava-saturation/interfaces/moniker';
import { ITrajectoryDataset } from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

const tvdssKey = 'tvdss';

export class TVD extends ContinuosNumericDimension {
    /**
     * Describes the true vertical depth dimension
     * @ref https://www.glossary.oilfield.slb.com/Terms/t/tvd.aspx
     */
    constructor() {
        super();

        this.displayName = 'TVDss';
        this.name = this.shortName = tvdssKey;
        this.unitAlias = 'Standard_Depth_Index';

        this.chartingPreferences = new ChartingAxisPreferences({ value: -10000, forced: false }, { value: 0, forced: false }, false);
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.TVD;
}

const tvdss = new TVD();

export interface ITVDSample extends IMeasureDepthSample {
    [tvdssKey]: number;
}

export class TVDDataset extends MeasureDepthDataset<ITVDSample> implements ITrajectoryDataset<ITVDSample> {
    constructor(wellMoniker: IMoniker, trajectoryMoniker: IMoniker) {
        super([tvdss], wellMoniker);

        this.primaryDimension = tvdss;
        this.trajectoryMoniker = trajectoryMoniker;
    }

    trajectoryMoniker: IMoniker;
}

const tvdssDatasetCreator = (wellMoniker: IMoniker, trajectoryMoniker: IMoniker) => {
    return () => new TVDDataset(wellMoniker, trajectoryMoniker);
};

export class TVDDatasetParser extends MeasureDepthDatasetParser<ITVDSample, TVDDataset> {
    constructor(wellMoniker: IMoniker, trajectoryMoniker: IMoniker) {
        super(wellMoniker, tvdssDatasetCreator(wellMoniker, trajectoryMoniker));
    }

    protected parseSample(sample: Record<string, any>): ITVDSample {
        const parsed = super.parseSample(sample);
        parsed[tvdssKey] = sample.Z as number;

        return parsed;
    }
}

export const tvdssDatasetSettings = {
    dimension: tvdss.toPlainObject(),
    targetDimensions: [tvdss, measureDepth].map(d => d.toPlainObject()),
    valueMap: {
        [tvdss.shortName]: ['Point', 'Z'],
        [measureDepth.shortName]: 'MD'
    }
};

export class TvdssDatasetJsonFormatter extends MeasureDepthDatasetJsonFormatter {
    constructor() {
        super(tvdssDatasetSettings);
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName != tvdss.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        if (!monikerDictionary.trajectoryMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `
            wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)},
            trajectoryMoniker: ${JSON.stringify(monikerDictionary.trajectoryMoniker)}
        `;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellMoniker.identifier}", ${JSON.stringify(tvdssDatasetSettings)})`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): MeasureDepthDataset<ITVDSample> | null {
        return new TVDDataset(monikerDictionary.wellMoniker, monikerDictionary.trajectoryMoniker);
    }

    getSampleFormat(samplePrefix: string): string {
        return `
            ${super.getSampleFormat(samplePrefix)},
            ${tvdss.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[tvdss.shortName]}
        `;
    }
}

export default tvdss;
