import Route from '@ember/routing/route';
import { inject, inject as service } from '@ember/service';
import avaLogger from 'ava-logger';
import { dimensionsArray } from 'ava-saturation/classes/dimensions/all';
import ENV from 'ava-saturation/config/environment';
import BeforeUnload from 'ava-saturation/mixins/before-unload';
import BrowserDetectionRouteMixin from 'ava-saturation/mixins/browser-detection-route-mixin';

export default Route.extend(BeforeUnload, BrowserDetectionRouteMixin, {
    windowManager: inject(),

    /** Override the mixins hook */
    onBeforeunload() {
        this.windowManager.applicationWindowDictionary.forEachValue((avaWindow) => {
            avaWindow.close();
        });
    },
    unitStore: service(),
    redux: service(),
    logger: undefined,

    async model() {
        // don't bootstrap the unit store dimensions for test environment
        if (ENV.environment !== 'test' && this.unitStore.isAvailable) {
            this.logger = avaLogger(this);

            this.unitStore.bootstrapDimensions(dimensionsArray).then(() => {
                this.logger.debug(`Bootstraped dimensions with ${this.unitStore.id}`);
            });
        }

        await this.redux.rehydrateStore();
    }
});
