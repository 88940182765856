import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as functionActions from '../model-function/actions';
import * as actions from './actions';
import { BandFilter, BandFilterState } from './types';

export type BandFilterActionType = ActionType<typeof actions> | ActionType<typeof functionActions> | ActionType<typeof saturationConceptActions>;

export function modelBandFilterById(state: Record<string, BandFilter> = {}, action: BandFilterActionType) {
    switch (action.type) {
        case getType(actions.addBandFilter):
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload
                }
            };

        case getType(actions.updateBandFilterDimensionKeys):
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    byDimensionKey: {
                        ...action.payload.byDimensionKey
                    }
                }
            };

        case getType(functionActions.deleteFunction): {
            let newState = { ...state };
            const keys = Object.keys(state).filter(k => newState[k].functionId === action.payload.id);
            keys.forEach(k => delete newState[k]);
            return newState;
        }

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            const keys = Object.keys(state).filter(k => newState[k].sid === action.payload.id);
            keys.forEach(k => delete newState[k]);
            return newState;
        }

        case getType(actions.deleteBandFilter): {
            const { [action.payload.id]: value, ...newState } = state;
            return newState;
        }

        default:
            return state;
    }
}

export function modelBandFilter(state: BandFilter[] = [], action: BandFilterActionType): BandFilter[] {
    switch (action.type) {
        case getType(actions.addBandFilter):
            return [
                ...state, {
                    ...action.payload
                }
            ];

        case getType(actions.updateBandFilterDimensionKeys):
            return state.map(f => f.id === action.payload.id ? { ...f, byDimensionKey: { ...action.payload.byDimensionKey } } : f);

        case getType(functionActions.deleteFunction):
            return state.filter(b => b.functionId !== action.payload.id);

        case getType(actions.deleteBandFilter): {
            return state.filter(f => f.id !== action.payload.id);
        }

        default:
            return state;
    }
}

export function modelBandFilterBySid(state: Record<string, BandFilter[]> = {}, action: BandFilterActionType): Record<string, BandFilter[]> {
    switch (action.type) {

        case getType(actions.updateBandFilterDimensionKeys):
        case getType(actions.deleteBandFilter):
        case getType(actions.addBandFilter):
        case getType(functionActions.deleteFunction):
            return {
                ...state,
                [action.payload.sid]: modelBandFilter(state[action.payload.sid], action)
            };

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        default:
            return state;
    }
}
export default combineReducers<BandFilterState>({
    byId: modelBandFilterById,
    bySid: modelBandFilterBySid
});