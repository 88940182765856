import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
    grid: null,
    zones: null,
    segments: null,

    filteredZones: computed('grid', 'zones.[]', function () {
        return this.zones.filterBy('gridMoniker.string', this.grid.moniker.string);
    }),

    filteredSegments: computed('grid', 'segments.[]', function () {
        return this.segments.filterBy('gridMoniker.string', this.grid.moniker.string);
    })
});