import WellLogReferenceType, { WellLogReferenceParser, WellLogCollectionReferenceParser } from 'ava-saturation/classes/well-log-reference-type';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import IWellLog, { IWellLogFilter } from 'ava-saturation/interfaces/well-log-reference';

export default class PorosityLogReferenceType extends WellLogReferenceType {
    constructor() {
        super(porosity, [porosity]);
    }

    id = 211;
    name = 'porosity-log';
    subTypeAliases = ['PetrelPorosityTemplate'];
}

export const porosityLogReferenceType = new PorosityLogReferenceType();

export class PorosityLogReferenceParser extends WellLogReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, porosityLogReferenceType);
    }
}

export class PorosityLogCollectionReferenceParser extends WellLogCollectionReferenceParser {
    constructor(origin: string, version: string) {
        super(origin, version, porosityLogReferenceType);
    }
}

export class PorosityLogFilter implements IWellLogFilter {
    predicate(wellLog: IWellLog) {
        return wellLog.type === porosityLogReferenceType;
    }
}