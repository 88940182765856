/* istanbul ignore file */
// TT: Reading from the clipboard reqiures user permission.
// MDN: First, you will need to have the "clipboardRead" permission established for your extension.
// TODO: remove jQuery from here
import $ from 'jquery';
export default function copyToClipboard(valueToCopy) {
    let value = valueToCopy;
    let temp = $('<input>');
    $('body').append(temp[0]);
    temp.val(value).select();
    document.execCommand('copy');
    temp.remove();
}