import Ember from 'ember';
import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/string';

export function escapeHTML([param], { prefix }) {
    let value = Ember.Handlebars.Utils.escapeExpression(param);

    if (prefix == 'rgb') {
        return htmlSafe(`fill: rgb(${value});`);
    } else if (prefix == 'color') {
        return htmlSafe(`fill: ${value};`);
    } else if (prefix == 'width') {
        return htmlSafe(`width: ${value}px;`);
    } else {
        return htmlSafe(value);
    }
}

export default helper(escapeHTML);