import Component from '@ember/component';
import { computed, set } from '@ember/object';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IReference from 'ava-saturation/interfaces/reference';
import { removeModels, updateName } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/actions';
import { ModelAreaStub } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import isEnterPressed from 'ava-saturation/utils/is-enter-pressed';
import isEscapePressed from 'ava-saturation/utils/is-escape-pressed';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

const stateToComputed = function () {
    return {};
};

const dispatchToActions = function (this: AggregateArea, dispatch: Dispatch) {
    return {
        onAreaSelected(this: AggregateArea, selected: boolean) {
            this.onAreaSelected({ selected, area: this.area });
        },
        toggleEdit(this: AggregateArea) {
            this.toggleProperty('isEdited');
            set(this, 'titlePreview', this.area.name);
        },
        toggleExpansion(this: AggregateArea) {
            this.toggleProperty('isExpanded');
        },
        removeFromAggregate(this: AggregateArea, targetElement: IGridReference) {
            dispatch(removeModels({
                sid: this.area.sid,
                id: this.area.id,
                monikers: [targetElement.moniker.string]
            }));
        },
        onCompleteEdit(this: AggregateArea) {
            dispatch(updateName({
                sid: this.area.sid,
                id: this.area.id,
                name: this.titlePreview || ''
            }));

            this.toggleProperty('isEdited');
        }
    };
};

export interface IGridReference extends IReference {
    gridMoniker: IMoniker;
}

export class AggregateArea extends Component {
    elements: IGridReference[];
    type: IDimension;
    area: ModelAreaStub;
    onAreaSelected: ({ selected, area }: { selected: boolean, area: ModelAreaStub }) => void;
    selectedAreas: ModelAreaStub[];

    titlePreview: string | null;

    isEdited: boolean;
    isExpanded: boolean;

    keyPress(event: Event) {
        if (isEnterPressed(event)) {
            this.actions.onCompleteEdit.call(this);
        }
    }

    keyDown(event: Event) {
        if (isEscapePressed(event) && this.isEdited) {
            this.actions.toggleEdit.call(this);
        }
    }

    @computed('elements', 'area')
    get targetElements() {
        return this.elements.filter(e => this.area.monikers.includes(e.moniker.string));
    }

    @computed('area.name')
    get displayName() {
        return this.area.name;
    }

    @computed('selectedAreas.[]', 'area')
    get isSelected() {
        return this.selectedAreas.includes(this.area);
    }
}

export default connect(stateToComputed, dispatchToActions)(AggregateArea);