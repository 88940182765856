import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export function validateProperty(rules, value, concept, state, intl) {
    const conceptId = concept.id;

    rules = rules || {};

    const keys = Object.keys(rules);
    return keys.reduce((result, k) => {
        const brokenRules = rules[k].map(r => {
            return r(value, conceptId, state, intl);
        }).filter(r => r.isInValid);

        return {
            ...result,
            [k]: brokenRules
        };
    }, {});
}

export default Helper.extend({
    redux: service(),
    intl: service('intl'),
    /* istanbul ignore next */
    compute([rules, value, concept]) {
        const state = this.redux.getState();
        return validateProperty(rules, value, concept, state, this.intl);
    }
});