import { createSelector } from 'reselect';
import { DeflatedWidget } from './types';
// import { DataSourceType } from 'ava-saturation/classes/data-source';
// import { PlotPresentationalType } from 'ava-saturation/interfaces/presenter';
// import { DeflatedWidgetPayload, Widget, PayloadPresenterSettingsDictionary, Axis, Legend } from './types';
// import { Moniker } from 'ava-saturation/classes/moniker';

export const getWidgetsBySid = (state: any, props: { sid: string }): DeflatedWidget[] => state.entitiesV1.saturationConceptState.widget.bySid[props.sid];

export const getWidgets = createSelector([getWidgetsBySid], (widgets) => widgets);

// export const getSerializedWidgets = createSelector([getWidgetsBySid],
//     (widgets) => {
//         return widgets.map(toSerialized);
//     });

// const toSerialized = (widget: Widget): DeflatedWidgetPayload => {
//     return {
//         id: widget.id,
//         sid: widget.sid,
//         name: widget.name,
//         dataSourceInstance: {
//             definition: {
//                 type: widget.acitveDataSource
//             },
//             plotDefinition: {
//                 type: widget.activePlot
//             },
//             presenterSettingsDictionary: expandPresenterSettingsDictionary(widget.plotSettings),
//             wellModelingContextCollection: {}
//         }
//     };
// };

// const expandPresenterSettingsDictionary = (
//     plotSettings: Record<string, { type: PlotPresentationalType; axes: Axis[]; legend: Legend; }>): Record<string, PayloadPresenterSettingsDictionary> => {

//     return Object.keys(plotSettings).reduce((presenterSettings, pkey) => {

//         const legend = plotSettings[pkey].legend;
//         const axisSettings = plotSettings[pkey].axes.reduce((axesObject, axis) => {
//             return {
//                 ...axesObject,
//                 [`${axis.key}AxisSettings`]: {
//                     selectedOption: {
//                         dimension: {
//                             shortName: axis.dimensionKey
//                         },
//                         settings: {
//                             ...axis.settings
//                         }
//                     }
//                 }
//             };
//         }, {});
//         let result: Record<number, PayloadPresenterSettingsDictionary> = {
//             ...presenterSettings,
//             [pkey]: {
//                 ...axisSettings,
//                 type: pkey,
//                 legendSettings: {
//                     ...legend
//                 },
//             }
//         };

//         return result;
//     }, {});
// };
