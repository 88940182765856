/**
 * @module ember-paper
 */
import PaperTabs from 'ember-paper/components/paper-content';
import ColorMixin from 'ava-saturation/mixins/overrides/color-mixin';
/**
 * @class PaperTabs
 * @extends Ember.Component
 * @uses ColorMixin
 */
export default PaperTabs.extend(ColorMixin, {
    classNameBindings: ['lightGrey:md-light-grey']
});