import Component from '@ember/component';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import QCWorkflow, { QCState } from 'ava-saturation/services/qc-workflow';

export default class QualityControlSelectLog extends Component {
    @service qcWorkflow: QCWorkflow;

    selectedSynthetic: any;
    selectedOriginal: any;

    @computed('qcWorkflow.swLogsSynthetic')
    get syntheticLogs() {
        return this.qcWorkflow.swLogsSynthetic;
    }

    @computed('qcWorkflow.swLogsImported')
    get originalLogs() {
        return this.qcWorkflow.swLogsImported;
    }

    @computed('qcWorkflow.context', 'qcWorkflow.context.selectedPairs[]')
    get availableLogPairs() {
        return this.qcWorkflow.context.selectedPairs;
    }

    @computed('selectedOriginal', 'selectedSynthetic', 'alreadyAdded')
    get isPairingDisabled(): boolean {
        return !this.selectedOriginal || !this.selectedSynthetic || this.alreadyAdded;
    }

    @computed('selectedOriginal', 'selectedSynthetic', 'qcWorkflow.context.selectedPairs.[]')
    get alreadyAdded() {
        return this.selectedOriginal
            && this.selectedSynthetic
            && this.qcWorkflow.context.selectedPairs.find(p =>
                p.originalLog.moniker.identifier === this.selectedOriginal.moniker.identifier
                && p.syntheticLog.moniker.identifier === this.selectedSynthetic.moniker.identifier
            ) !== undefined;
    }

    @computed('qcWorkflow.context', 'qcWorkflow.context.selectedPairs.length', 'qcWorkflow.context.state')
    get canRun(): boolean {
        return this.qcWorkflow.context.state !== QCState.Loading && this.qcWorkflow.context.selectedPairs.length > 0;
    }

    @action
    pairLogs() {
        this.qcWorkflow.pair(this.selectedOriginal, this.selectedSynthetic);
        this.setProperties({ selectedOriginal: undefined, selectedSynthetic: undefined });
    }

    @action
    removePair(pair: any) {
        this.qcWorkflow.removePair(pair);
    }

    @action
    run() {
        this.qcWorkflow.run();
    }

    @action
    onSyntheticLogSelected(log: any) {
        set(this, 'selectedSynthetic', log);
    }

    @action
    onOriginalLogSelected(log: any) {
        set(this, 'selectedOriginal', log);
    }
}
