import { createCustomAction } from 'typesafe-actions';
import { CalculationSet, DeleteCalculationSetPayload } from './types';
import constants from './constants';

export const addCalculationSet = createCustomAction(constants.ADD, type => {
    return (payload: CalculationSet) => ({ type, payload: payload, recordable: true });
});

export const deleteCalculationSet = createCustomAction(constants.DELETE, type => {
    return (payload: DeleteCalculationSetPayload) => ({ type, payload: payload, recordable: true });
});
