import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class SaturationConceptsController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    @service screenSensor: any;
    // normal class body definition here
    currentlyLoading: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'saturation-concepts': SaturationConceptsController;
    }
}
