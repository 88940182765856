import migration_20171214 from './indexedDb_20171214';
import migration_20171215 from './indexedDb_20171215';
import migration_20180115 from './indexedDb_20180115';
import indexedDb_20190418 from './indexedDb_20190418';

export default {
    targetVersion: indexedDb_20190418.version,
    apply: function(database, upgradeEvent, transaction) {
        migration_20171214.apply(database, upgradeEvent, transaction);
        migration_20171215.apply(database, upgradeEvent, transaction);
        migration_20180115.apply(database, upgradeEvent, transaction);
        indexedDb_20190418.apply(database, upgradeEvent, transaction);
    }
};