import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import CPImportRouteMixin from 'ava-import/mixins/cp-import-route-mixin';
import avaLogger from 'ava-logger';
import { contact } from 'ava-saturation/classes/dimensions/contact';
import { faciesProperty } from 'ava-saturation/classes/dimensions/facies-property';
import { permeabilityProperty } from 'ava-saturation/classes/dimensions/permeability-property';
import { porosityProperty } from 'ava-saturation/classes/dimensions/porosity-property';
import segment from 'ava-saturation/classes/dimensions/segment';
import zone from 'ava-saturation/classes/dimensions/zone';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';

import RSVP from 'rsvp';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, CPImportRouteMixin, {
    logger: avaLogger(this, 'Edit Saturation Concept'),
    redux: inject(),
    synchronization: inject(),
    objectStore: inject(),
    store: inject('store'),
    concept: inject(),

    onBroadcastMessage(e) {
        if (e.type === 'success') {
            this.objectStore.refresh(['wellPointLogs']);
            this.refresh();
        }
    },

    async model(params) {
        let conceptId = params.saturation_concept_id;

        const contacts = await this.objectStore.contacts;
        const faciesProperties = await this.objectStore.faciesProperties;
        const porosityProperties = await this.objectStore.porosityProperties;
        const permeabilityProperties = await this.objectStore.permeabilityProperties;
        const zones = await this.objectStore.zones;
        const segments = await this.objectStore.segments;

        return RSVP.hash({
            grids: this.objectStore.grids,
            wells: this.objectStore.wells,
            contacts: {
                type: contact,
                items: contacts
            },
            zones: {
                type: zone,
                items: zones
            },
            segments: {
                type: segment,
                items: segments
            },
            faciesProperties: {
                type: faciesProperty,
                items: faciesProperties
            },
            porosityProperties: {
                type: porosityProperty,
                items: porosityProperties
            },
            permeabilityProperties: {
                type: permeabilityProperty,
                items: permeabilityProperties
            },
            porosityLogs: this.objectStore.porosityLogs,
            waterSaturationLogs: this.nonSyntheticWaterSaturationLogs(),
            faciesLogs: this.objectStore.faciesLogs,
            wellPointLogs: this.objectStore.wellPointLogs,
            conceptId: conceptId,
            versions: this.store.query('saturation-concept-version', conceptId),
            patches: this.store.query('saturation-concept-patch', conceptId)
        });
    },

    async nonSyntheticWaterSaturationLogs() {
        const logs = await this.objectStore.waterSaturationLogs;
        return logs.filter(log => !log.isSynthetic);
    },

    afterModel({ conceptId, patches, versions }) {
        if (!this.concept.restoreActions(conceptId, patches, versions)) {
            return this.transitionTo('saturation-concepts');
        }

        this.synchronization.attachSync(conceptId);
        this.synchronization.enableSync();
    },
    deactivate() {
        this.synchronization.disableSync();
    },

});
