import { ILegendSettings } from 'ava-saturation/interfaces/plot';
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import IReference from 'ava-saturation/interfaces/reference';

export default class PlotLegend extends Component implements ILegendSettings {
    title: string;

    selectedItems: IReference[];

    @computed('selectedItems.[]')
    get _selected() {
        return this.selectedItems;
    }

    onItemClick: (item: string) => void;

    @action
    onClick(item: string) {
        this.onItemClick(item);
    }
}