import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { set } from '@ember/object';
import { DataSourceDefinition } from 'ava-saturation/classes/data-source';
import { WidgetInstance, WidgetSettings } from 'ava-saturation/classes/widgets/widget';
import IWell from 'ava-saturation/interfaces/well-reference';
import IWidget, { IWidgetInstance } from 'ava-saturation/interfaces/widget';
import IWidgetContext from 'ava-saturation/interfaces/widget-context';
import * as widgetActions from 'ava-saturation/store/entities-v1/saturation-concept-state/widget/actions';
import { getWidgets } from 'ava-saturation/store/entities-v1/saturation-concept-state/widget/selectors';
import { DeflatedWidget } from 'ava-saturation/store/entities-v1/saturation-concept-state/widget/types';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

class ConceptWidgetContext implements IWidgetContext {
    widgets: IWidgetInstance[] = [];
    wells: IWell[];
    onRemove: (id: string) => void;
    onAdd: (widget: DeflatedWidget) => void;

    constructor(wells: IWell[]) {

        assert(`${ConceptWidgetContext.name} didn't recieve a well collection!`, wells != null);
        set(this, 'wells', wells);
    }

    add(definition: DataSourceDefinition): void {
        const widget = new WidgetInstance({ settings: new WidgetSettings(definition) }, this.wells);
        set(widget, 'id', uuid());

        this.widgets.pushObject(widget);
        if (this.onAdd) {
            // @ts-ignore
            this.onAdd(widget.deflate());
        }
    }

    remove(widget: IWidget): void {
        // const widgetIndex = this.widgets.findIndex((w: IWidgetInstance) => w.id === widget.id);

        // this.widgets.removeAt(widgetIndex);
        if (this.onRemove) {
            this.onRemove(widget.id);
        }
    }
    clone(widget: IWidget): void {
        const _clone = WidgetInstance.inflate(widget.instance.deflate(), this.wells);
        set(_clone, 'id', uuid());
        set(_clone, 'name', `Copy of ${widget.displayName}`);

        this.widgets.pushObject(_clone);
    }
    rename(id: string, title: string): void {
        let widget = this.widgets.find(x => x.id === id);
        if (widget) {
            widget.name = title;
        }
    }
}
const stateToComputed = function (this: WidgetContext, state: any) {
    const widgets = getWidgets(state, {
        sid: this.conceptId
    });

    return {
        widgets
    };
};

const dispatchToActions = function (this: WidgetContext, dispatch: Dispatch) {
    return {
        addWidget(this: WidgetContext, widget: DeflatedWidget) {
            widget.sid = this.conceptId;
            dispatch(widgetActions.addDeflatedWidget(widget));
        },
        removeWidget(this: WidgetContext, id: string) {
            dispatch(widgetActions.deleteWidget({
                id,
                sid: this.conceptId
            }));
        }
    };
};

export class WidgetContext extends Component {
    context: ConceptWidgetContext;
    conceptId: string;
    widgets: DeflatedWidget[];
    wells: IWell[];

    init() {
        super.init();
        assert(`${WidgetContext.name} no wells recieved from model!`, this.wells != null);

        set(this, 'context', new ConceptWidgetContext(this.wells));
        this.context.onAdd = this.actions.addWidget.bind(this);
        this.context.onRemove = this.actions.removeWidget.bind(this);
        // this call is made because on the first load observers are not triggered
        // /bc stateToComputed merges to the Component prototype
        // thus the widgets property is populated before this ctor is ran
        this.w();
    }
    @observes('widgets.[]')
    w() {
        if (this.context) {
            set(this.context, 'widgets', this.widgets.map(w => WidgetInstance.inflate(JSON.parse(w.widgetDefinition), this.wells)));
        }
    }
}

export default connect(stateToComputed, dispatchToActions)(WidgetContext);
