import Component from '@ember/component';
import { set } from '@ember/object';
import { updateGeneralInformation } from 'ava-saturation/store/entities-v1/saturation-concept/actions';
import { GeneralInformation, SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import { connect } from 'ember-redux';
import { every } from 'lodash';
import { Dispatch } from 'redux';

const stateToComputed = () => {
    return {};
};

const dispatchToActions = function (this: GeneralInformationComponent, dispatch: Dispatch) {
    const self = this;
    return {
        titleChanged(newTitle: string) {
            set(self, 'generalInfo', {
                ...self.generalInfo,
                title: newTitle
            });
        },
        descriptionChanged(newDescription: string) {
            set(self, 'generalInfo', {
                ...self.generalInfo,
                description: newDescription
            });
        },
        assetTeamChanged(newAssetTeam: string) {
            set(self, 'generalInfo', {
                ...self.generalInfo,
                assetTeam: newAssetTeam
            });
        },
        fieldChanged(newField: string) {
            set(self, 'generalInfo', {
                ...self.generalInfo,
                field: newField
            });
        },
        reservoirChanged(newReservoir: string) {
            set(self, 'generalInfo', {
                ...self.generalInfo,
                reservoir: newReservoir
            });
        },
        focusLost() {
            if (every(self.keyState, a => a))
                dispatch(updateGeneralInformation({ sid: self.concept.id, generalInformation: self.generalInfo }));
        },
        onValidityChange(key: string, isValid: boolean) {
            set(self, `keyState`, {
                ...self.keyState,
                [key]: isValid
            });
        }
    };
};

export class GeneralInformationComponent extends Component {
    constructor() {
        super(...arguments);

        set(this, 'keyState', {});
    }

    concept: SaturationConcept;
    generalInfo: GeneralInformation;
    keyState: Record<string, boolean>;

    didReceiveAttrs() {
        super.init();

        set(this, 'generalInfo', {
            title: this.concept.generalInformation.title,
            description: this.concept.generalInformation.description,
            assetTeam: this.concept.generalInformation.assetTeam,
            field: this.concept.generalInformation.field,
            reservoir: this.concept.generalInformation.reservoir,
        });
    }
}

export default connect(stateToComputed, dispatchToActions)(GeneralInformationComponent);