import Component from '@ember/component';
import IWidgetContext from 'ava-saturation/interfaces/widget-context';
import { computed, action } from '@ember/object';
import IWidget from 'ava-saturation/interfaces/widget';
import { DataSourceDefinition, generalLogSourceDefinition, pointLogSourceDefinition } from 'ava-saturation/classes/data-source';

export default class WidgetStore extends Component {
    context: IWidgetContext;
    dataSourceDefinitions: DataSourceDefinition[] = [generalLogSourceDefinition, pointLogSourceDefinition];

    @computed('context.widgets.[]')
    get widgets() {
        return this.context.widgets;
    }

    @action
    add(definition: DataSourceDefinition): void {
        this.context.add(definition);
    }

    @action
    remove(widget: IWidget): void {
        this.context.remove(widget);
    }

    @action
    clone(widget: IWidget): void {
        this.context.clone(widget);
    }

    @action
    rename(id: string, title: string): void {
        this.context.rename(id, title);
        this.notifyPropertyChange('context.widgets');
    }
}