export const GET_REQUEST = '@saturationConcept/GET_REQUEST';
export const GET_SUCCESS = '@saturationConcept/GET_SUCCESS';
export const GET_FAILURE = '@saturationConcept/GET_FAILURE';
export const GET_ALL_REQUEST = '@saturationConcept/GET_ALL_REQUEST';
export const GET_ALL_SUCCESS = '@saturationConcept/GET_ALL_SUCCESS';
export const GET_ALL_FAILURE = '@saturationConcept/GET_ALL_FAILURE';
export const PUT_REQUEST = '@saturationConcept/PUT_REQUEST';
export const PUT_SUCCESS = '@saturationConcept/PUT_SUCCESS';
export const PUT_FAILURE = '@saturationConcept/PUT_FAILURE';
export const DELETE_REQUEST = '@saturationConcept/DELETE_REQUEST';
export const DELETE_SUCCESS = '@saturationConcept/DELETE_SUCCESS';
export const DELETE_FAILURE = '@saturationConcept/DELETE_FAILURE';

export const PATCH_REQUEST = '@saturationConcept/PATCH_REQUEST';
export const PATCH_SUCCESS = '@saturationConcept/PATCH_SUCCESS';
export const PATCH_FAILURE = '@saturationConcept/PATCH_FAILURE';
export const VERSION_REQUEST = '@saturationConcept/VERSION_REQUEST';
export const VERSION_SUCCESS = '@saturationConcept/VERSION_SUCCESS';
export const VERSION_FAILURE = '@saturationConcept/VERSION_FAILURE';

export const UPDATE_GENERAL_INFO = '@saturationConcept/UPDATE_GENERAL_INFO';
export const UPDATE_VERSION = '@saturationConcept/UPDATE_VERSION';
export const UPDATE_PATCH = '@saturationConcept/UPDATE_PATCH';

export const APPLY_PATCH = '@saturationConcept/APPLY_PATCH';

export const SANITIZE = '@saturationConcept/SANITIZE'

export default ({
    GET_REQUEST,
    GET_SUCCESS,
    GET_FAILURE,
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    PUT_REQUEST,
    PUT_SUCCESS,
    PUT_FAILURE,
    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILURE,

    PATCH_REQUEST,
    PATCH_SUCCESS,
    PATCH_FAILURE,
    VERSION_REQUEST,
    VERSION_SUCCESS,
    VERSION_FAILURE,

    UPDATE_GENERAL_INFO: '@saturationConcept/UPDATE_GENERAL_INFO',
    UPDATE_VERSION: '@saturationConcept/UPDATE_VERSION',
    UPDATE_PATCH: '@saturationConcept/UPDATE_PATCH',

    APPLY_PATCH: '@saturationConcept/APPLY_PATCH',

    SANITIZE: '@saturationConcept/SANITIZE'
} as const);
