import { createSelector, ParametricSelector } from 'reselect';
import { CalculationSet } from './types';

export interface SearchProps {
    sid: string;
}

const getCalculationSets: ParametricSelector<any, SearchProps, CalculationSet[]> = (state: any, props: SearchProps) =>
    state.entitiesV1.saturationConceptState.calculationSet.bySid[props.sid] || [];

export const getCalculationSetsBySid = createSelector([getCalculationSets], (cs) => cs);