import Component from '@ember/component';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import { getModelFunctions } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/selectors';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import { connect } from 'ember-redux';

export const stateToComputed = function (this: SidenavFunctions, state: any) {
    const functions = getModelFunctions(state, {
        sid: this.concept.id
    });

    return {
        functions
    };
};

export const dispatchToActions = function (/* dispatch: Dispatch */) {
    return {
    };
};

export class SidenavFunctions extends Component {
    concept: SaturationConcept;
    grid: IGrid;

    // state
    functions: ModelFunction[];
}

export default connect(stateToComputed, dispatchToActions)(SidenavFunctions);