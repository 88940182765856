import Component from '@ember/component';
import { NumericAxisOptionSettings } from 'ava-saturation/classes/widgets/axis';
import { action, set } from '@ember/object';

export default class NumericAxisSettings extends Component {
    settings: NumericAxisOptionSettings;

    @action
    minValueChanged(value: string) {
        set(this.settings, 'min', value);
    }

    @action
    maxValueChanged(value: string) {
        set(this.settings, 'max', value);
    }
}
