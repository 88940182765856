import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import d3 from 'd3';

export enum ChartQuadrant {
    q1,
    q2,
    q3,
    q4
}

export class ChartMargin {
    top: number;
    right: number;
    left: number;
    bottom: number;

    constructor(q: ChartQuadrant) {
        this.forQuadrant(q);
    }

    forQuadrant(q: ChartQuadrant) {
        if (q == ChartQuadrant.q1) {
            this.top = 30;
            this.right = 20;
            this.left = 75;
            this.bottom = 60;
        } else if (q == ChartQuadrant.q2) {
            this.top = 30;
            this.right = 75;
            this.left = 20;
            this.bottom = 60;
        } else if (q == ChartQuadrant.q3) {
            this.top = 60;
            this.right = 75;
            this.left = 20;
            this.bottom = 30;
        } else if (q == ChartQuadrant.q4) {
            this.top = 60;
            this.right = 20;
            this.left = 75;
            this.bottom = 30;
        }
    }
}

export abstract class ProportionalChartBase extends Component {
    @service screenSensor: any;

    _quadrant: ChartQuadrant;
    container: Element | null;

    _forceResize: any = false;

    predefinedWidth: number;
    predefinedHeight: number;

    constructor(properties?: object | undefined) {
        super(properties);

        if (!this._quadrant)
            this.set('_quadrant', ChartQuadrant.q1);
    }

    didInsertElement() {
        // can add a one time computed property for the container change
        // @ts-ignore
        const container = d3.select(this.element);
        this.set('container', container.node());
    }

    @computed('_quadrant')
    get quadrant(): ChartQuadrant {
        return this._quadrant;
    }
    set quadrant(value: ChartQuadrant) {
        this.set('_quadrant', value);
    }

    @computed('quadrant')
    get margin(): ChartMargin {
        return new ChartMargin(this.quadrant);
    }

    @computed('screenSensor.width', 'container', 'forceResize', 'margin', 'predefinedWidth')
    get width(): number {
        if (this.predefinedWidth) return this.predefinedWidth;
        if (this.container == null)
            return 0;

        return this.container.clientWidth - this.margin.left - this.margin.right;
    }

    @computed('width', 'ratio', 'predefinedHeight')
    get height(): number {
        if (this.predefinedHeight) return this.predefinedHeight;
        return this.ratio * this.width;
    }

    @computed('width')
    get ratio(): number {
        const size = this.screenSensor.getSize(Math.round(this.width * 0.8)); // x 0.8 ration plot to screen width

        switch (size) {
            case 'md': {
                return 0.7;
            }
            case 'sm': {
                return 0.8;
            }
            case 'xs': {
                return 0.9;
            }
            default: {
                return 0.6;
            }
        }
    }

    @computed()
    get forceResize(): any {
        return this._forceResize;
    }
    set forceResize(value: any) {
        this._forceResize = {} || value;
    }
}
