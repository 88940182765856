import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class NoLicenseController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    @service session: any;

    @action
    retry() {
        if (isPresent(this.session.data.authenticated.expires_at)) {
            // @ts-ignore
            set(this, 'session.data.authenticated.expires_at', 0);
            this.session.refreshAccessToken();
        }
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'no-license': NoLicenseController;
    }
}
