/**
 * @module ember-paper
 */
import ColorMixin from 'ava-saturation/mixins/overrides/color-mixin';
import PaperSwitch from 'ember-paper/components/paper-switch';

/**
 * @class PaperSwitch
 * @extends Ember.Component
 * @uses ColorMixin
 */
export default PaperSwitch.extend(ColorMixin, {
    classNameBindings: ['noMargin:md-switch-no-margin']
});