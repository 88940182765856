import { createSelector } from 'reselect';
import { SaturationConcept } from './types';
import { keys } from 'lodash';

export const getConceptById = (state: any, props: { sid: string }): SaturationConcept => state.entitiesV1.saturationConcept.byId[props.sid];

export const getLocalConceptById = (state: any, props: { sid: string }): SaturationConcept => state.entitiesV1.localSaturationConcepts.byId[props.sid];

export const getConceptIds = (state: any): string[] => keys(state.entitiesV1.saturationConcept.byId);

export const getConceptModel = createSelector(
    getConceptById,
    (concept) => concept.metadata.modelId
);
