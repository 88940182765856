
export const ADD = '@model/ADD';
export const DELETE = '@model/DELETE';
export const SET_MODEL = '@model/SET_MODEl';

export default ({
    ADD,
    DELETE,
    SET_MODEL
} as const);
