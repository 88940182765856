import IReferenceType from '../interfaces/reference-type';

export default abstract class ReferenceObjectType implements IReferenceType {
    abstract id: number;
    abstract name: string;
    abstract typeAliases: string[];
    abstract subTypeAliases: string[];

    public equals(type: IReferenceType): boolean {
        return this.id === type.id;
    }
}