import Component from '@ember/component';
import { computed, set } from '@ember/object';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IReference from 'ava-saturation/interfaces/reference';
import { add, deleteArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/actions';
import { ModelArea, ModelAreaStub } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { getModelFunctions } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/selectors';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import nextLetter from 'ava-saturation/utils/next-letter';
import { connect } from 'ember-redux';
import { flatMap } from 'lodash';
import { Dispatch } from 'redux';

const stateToComputed = function (this: AreaManager, state: any) {
    const functions = getModelFunctions(state, {
        sid: this.conceptId
    });

    return {
        functions
    };
};

const dispatchToActions = function (this: AreaManager, dispatch: Dispatch) {
    return {
        onAreaSelected(this: AreaManager, props: { selected: boolean, area: ModelArea | ModelAreaStub }) {
            const { selected, area } = props;

            if (selected) {
                this.selectedAreas.pushObject(area);
            } else {
                this.selectedAreas.removeObject(area);
            }
        },
        selectAll(this: AreaManager, selected: boolean) {
            this.selectedAreas.clear();
            if (selected) {
                this.selectedAreas.pushObjects(this.allAvailableAreas);
            }
        },
        onGroupingInitiated(this: AreaManager) {
            this.availableSelectedAreas.forEach(a => dispatch(deleteArea(a)));

            const area = {
                // [TT] NOTE: the name of the first element will take precedence and override a custom one if such was given
                ...this.selectedAreas[0],
                monikers: flatMap(this.availableSelectedAreas, a => a.monikers)
            } as ModelAreaStub;

            let nameLetters = this.aggregateAreas.map(a => a.name ? a.name.replace(`${this.aggregateNameTemplate} `, '') : '').sort();
            nameLetters = nameLetters.filter(l => l.length === 1);
            const newLetter = nextLetter(nameLetters[nameLetters.length - 1]);

            area.name = area.monikers.length > 1 ? (area.name || `${this.aggregateNameTemplate} ${newLetter}`) : null;

            delete area.isStub;

            dispatch(add(area));

            this.selectedAreas.clear();
        },
        onUpgroupingInitiated(this: AreaManager) {
            const aggregates = this.availableSelectedAreas.filter(a => a.monikers.length > 1);

            aggregates.forEach(a => dispatch(deleteArea(a)));

            this.selectedAreas.clear();
        }
    };
};

export interface IGridReference extends IReference {
    gridMoniker: IMoniker;
}

export class AreaManager extends Component {
    constructor() {
        super(...arguments);
        set(this, 'selectedAreas', []);
    }

    conceptId: string;
    model: Model;
    functions: ModelFunction[];
    elements: IGridReference[];
    aggregateAreas: ModelArea[];
    standaloneAreas: ModelArea[];
    type: IDimension;
    selectedAreas: ModelArea[];

    @computed('availableSelectedAreas.[]')
    get isGroupingEnabled() {
        return this.availableSelectedAreas.length > 1;
    }

    @computed('availableSelectedAreas.[]')
    get isUngroupingEnabled() {
        return this.availableSelectedAreas.filter(a => a.monikers.length > 1).length !== 0;
    }

    @computed('availableSelectedAreas.[]')
    get isIndeterminate() {
        return this.availableSelectedAreas.length > 0 && this.availableSelectedAreas.length < this.allAvailableAreas.length;
    }

    @computed('availableSelectedAreas.[]')
    get allSelected() {
        return this.allAvailableAreas.length > 0 && this.availableSelectedAreas.length === this.allAvailableAreas.length;
    }

    @computed('type')
    get aggregateNameTemplate() {
        return `${this.type.displayName} Group`;
    }

    @computed('functions')
    get areaIdsInUse() {
        return flatMap(this.functions, f => f.areaIds);
    }

    @computed('selectedAreas.[]', 'areaIdsInUse')
    get availableSelectedAreas() {
        return this.selectedAreas.filter(a => !this.areaIdsInUse.includes(a.id));
    }

    @computed('standaloneAreas.[]', 'areaIdsInUse')
    get allAvailableAreas() {
        return [...this.standaloneAreas, ...this.aggregateAreas].filter(a => !this.areaIdsInUse.includes(a.id));
    }
}

export default connect(stateToComputed, dispatchToActions)(AreaManager);
