import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';
import { addConcepts, deleteConcept } from 'ava-saturation/store/entities-v1/saturation-concept/actions';
import { getConceptIds } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { getTrackedActions } from 'ava-saturation/store/entities-v1/saturation-concept-actions/selectors';
import { extractCachedActions } from 'ava-saturation/classes/actions';

import RSVP from 'rsvp';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, {
    objectStore: inject(),
    redux: inject(),
    store: inject('store'),

    model() {
        return RSVP.hash({
            concepts: this.store.findAll('saturation-concept', { reload: true }),
            // saturationConcepts: new RSVP.Promise((resolve, reject) => {
            //     const restoreConcepts = (concepts) => {
            //         this.dispatchSaga(APPLY_TRACKED_SATURATION_CONCEPT_ACTIONS, {
            //             resolve: () =>  {
            //                 resolve(concepts);
            //             },
            //             reject
            //         });
            //     };
            //     this.dispatchSaga(GET_SATURATION_CONCEPTS, { resolve: restoreConcepts, reject });
            // }),
            grids: this.objectStore.grids
        });
    },

    afterModel(model: { concepts: any[] }) {
        const state = this.redux.getState();
        let localConcepts = getConceptIds(state);
        let trackedActions = extractCachedActions(getTrackedActions(state));

        const concepts = model.concepts.map((c: any) => {
            let concept = c.toJSON();
            concept.id = c.get('id');
            concept.generalInformation.assetTeam = concept.generalInformation['asset-team'];

            let i = localConcepts.indexOf(concept.id);

            if (i > -1) {
                localConcepts = localConcepts.removeAt(i, 1);
            }

            return concept;
        });

        localConcepts.forEach(c => this.redux.dispatch(deleteConcept({ id: c })));

        this.redux.dispatch(addConcepts(concepts));

        for (let concept of concepts) {
            const actions = trackedActions.filter((a: any) => a.conceptId === concept.id);

            for (let action of actions) {
                if (action.patchId !== concept.patchId || action.versionId !== concept.versionId)
                    continue;

                this.redux.dispatch({ type: action.type, payload: action.payload });
            }
        }
    }
});
