/* eslint-disable dot-notation */
import { inject } from '@ember/service';
import { Promise, reject } from 'rsvp';
import { assign } from '@ember/polyfills';
import { isPresent, isBlank, isEmpty } from '@ember/utils';
import { run, next } from '@ember/runloop';
import OAuth2Authenticator from 'ember-simple-auth/authenticators/oauth2-password-grant';
import configuration from 'ava-saturation/config/environment';

export default OAuth2Authenticator.extend({
    ajax: inject(),
    session: inject(),
    notify: inject(),
    socket: inject(),

    _refreshAccessToken(expiresIn, refreshToken) {

        const data = {
            'grant_type': 'refresh_token',
            'refresh_token': refreshToken
        };

        return new Promise((resolve, reject) => {
            const refreshSuccess = response => {
                expiresIn = response.token['expires_in'] || expiresIn;
                refreshToken = response.token['refresh_token'] || refreshToken;
                const expiresAt = this._absolutizeExpirationTime(expiresIn);
                const data = assign(response.token, { 'expires_in': expiresIn, 'expires_at': expiresAt, 'refresh_token': refreshToken });
                this._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);
                this.trigger('sessionDataUpdated', data);
                resolve(data);
            };

            if (this.get('socket.isConnected')) {
                this.socket
                    .invoke('RefreshToken', refreshToken)
                    .then(refreshSuccess)
                    .catch(reject);
                return;
            }

            this.socket
                .connect(data)
                .then(refreshSuccess)
                .catch(reject);
        });
    },

    authenticate({ code, state } = {}) {
        const redirect_uri = `${window.location.protocol}//${window.location.host}${configuration.rootURL}authorize`;

        // Callback from auth provider
        // Exchange authorization code for access token and finally athenticate the session
        if (isPresent(code)) {
            if (isBlank(state)) {
                return reject({ error: 'bad_request', error_description: 'State is missing' });
            }

            const randomState = state.split('~')[0];
            const savedRandomState = localStorage.getItem(randomState);
            localStorage.removeItem(randomState);
            if (savedRandomState !== randomState) {
                return reject({ error: 'bad_request', error_description: 'Invalid state value' });
            }

            return new Promise((resolve, reject) => {
                const data = {
                    'grant_type': 'auth_code',
                    'auth_code': code,
                    'redirect_url': redirect_uri
                };
                this.socket
                    .connect(data)
                    .then(response => {
                        run(() => {
                            const expiresAt = this._absolutizeExpirationTime(response.token['expires_in']);
                            this._scheduleAccessTokenRefresh(response.token['expires_in'], expiresAt, response.token['refresh_token']);
                            if (!isEmpty(expiresAt)) {
                                response.token = assign(response.token, { 'expires_at': expiresAt });
                            }
                            resolve(response.token);
                        });
                    })
                    .catch(response => {
                        if (!response || !response.success) {
                            if (response.message ==='invalid_grant')
                                return this.authenticate();
                            reject(response);
                        }
                    });
            });
        }

        // Initial step in user authentication
        // Redirect to auth provider
        return new Promise((resolve, reject) =>
            this.getOpenIdConfiguration()
                .then(c => {
                    const randomState = this.getRandomState(20);
                    localStorage.setItem(randomState, randomState);

                    const url = c.authorization_endpoint +
                        '?client_id=' + c.client_id +
                        '&response_type=code' +
                        '&redirect_uri=' + redirect_uri +
                        '&response_mode=query' +
                        '&scope=openid' +
                        '&state=' + randomState + '~' + (isPresent(state) && btoa(state) || 'index');
                    window.location.replace(url);
                }, reject));
    },

    invalidate() {
        return this._super(...arguments)
            .then(() => this.getOpenIdConfiguration()
                .then(c => next(null, () => {
                    const url = `${c.end_session_endpoint}?post_logout_redirect_uri=${c.post_logout_redirect_uri}`;
                    window.location.replace(url);
                })));
    },

    getOpenIdConfiguration() {
        return this.ajax.request(`${configuration.rootURL}.well-known/openid-configuration`);
    },

    getRandomState(length) {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const state = [];
        for (var i = 0; i < length; i++) {
            state.push(chars.charAt(Math.floor(Math.random() * chars.length)));
        }
        return state.join('');
    }
});
