import { DiscreteNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';
import measureDepth, { IMeasureDepthSample, MeasureDepthDataset, MeasureDepthDatasetParser, MeasureDepthDatasetJsonFormatter } from 'ava-saturation/classes/dimensions/measure-depth';
import IMoniker from 'ava-saturation/interfaces/moniker';
import { IWellLogDataset } from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';

const faciesCodeKey = 'facies';

class Facies extends DiscreteNumericDimension {
    /**
     * Describes geological facies
     * @ref  https://www.glossary.oilfield.slb.com/Terms/f/facies.aspx
     */
    constructor() {
        super();

        this.displayName = 'Facies';
        this.name = this.shortName = faciesCodeKey;
        this.valueField = 'code';
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.Facies;
}

const facies = new Facies();

export interface IFaciesSample extends IMeasureDepthSample {
    [faciesCodeKey]: number;
}

export class FaciesDataset extends MeasureDepthDataset<IFaciesSample> implements IWellLogDataset<IFaciesSample> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super([facies], wellMoniker);

        this.primaryDimension = facies;
        this.wellLogMoniker = wellLogMoniker;
    }

    wellLogMoniker: IMoniker;
}

const faciesDatasetCreator = (wellMoniker: IMoniker, wellLogMoniker: IMoniker) => {
    return () => new FaciesDataset(wellMoniker, wellLogMoniker);
};

export class FaciesDatasetParser extends MeasureDepthDatasetParser<IFaciesSample, FaciesDataset> {
    constructor(wellMoniker: IMoniker, wellLogMoniker: IMoniker) {
        super(wellMoniker, faciesDatasetCreator(wellMoniker, wellLogMoniker));
    }

    protected parseSample(sample: Record<string, any>): IFaciesSample {
        return { ...sample } as IFaciesSample;
    }
}

export const faciesDatasetSettings = {
    samplesAlias: 'Samples',
    dimension: facies.toPlainObject(),
    targetDimensions: [facies, measureDepth].map(d => d.toPlainObject()),
    valueMap: {
        [measureDepth.shortName]: 'MD',
        [facies.shortName]: 'Value'
    }
};

export class FaciesDatasetJsonFormatter extends MeasureDepthDatasetJsonFormatter {
    constructor() {
        super(faciesDatasetSettings);
    }

    test(dimension: IDimension, monikerDictionary: { [key: string]: IMoniker }) {
        if (dimension.shortName !== facies.shortName)
            return false;

        if (!monikerDictionary.wellMoniker)
            return false;

        if (!monikerDictionary.wellLogMoniker)
            return false;

        return true;
    }

    formatMonikers(monikerDictionary: { [key: string]: IMoniker }): string {
        return `
            wellMoniker: ${JSON.stringify(monikerDictionary.wellMoniker)},
            wellLogMoniker: ${JSON.stringify(monikerDictionary.wellLogMoniker)}
        `;
    }

    // @ts-ignore
    formatProviderString(provider: string, monikerDictionary: { [key: string]: IMoniker }, dimensionIndexMap: Record<string, string>) {
        return `${provider}("${monikerDictionary.wellLogMoniker.identifier}", ${JSON.stringify(faciesDatasetSettings)})`;
    }

    getBlankDataset(monikerDictionary: { [key: string]: IMoniker }): MeasureDepthDataset<IFaciesSample> | null {
        return new FaciesDataset(monikerDictionary.wellMoniker, monikerDictionary.wellLogMoniker);
    }

    getSampleFormat(samplePrefix: string): string {
        return `
            ${super.getSampleFormat(samplePrefix)},
            ${facies.shortName}: ${samplePrefix}.${this.datasetSettings.valueMap[facies.shortName]}
        `;
    }
}

export default facies;