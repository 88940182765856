import Controller, { inject as controller } from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import SaturationConceptsController from 'ava-saturation/controllers/saturation-concepts';
import IContact from 'ava-saturation/interfaces/contact-reference';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import { IWidgetInstance } from 'ava-saturation/interfaces/widget';

export default class ExploreIndexController extends Controller.extend({
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    @controller saturationConcepts: SaturationConceptsController;
    @service screenSensor: any;

    menuOpen: boolean = true;
    modalOpen: boolean = false;
    transition: any;
    transitionConfirmed: boolean = false;

    @computed('screenSensor.xsScreenSize', 'screenSensor.smScreenSize')
    get isTabletMode() {
        return this.screenSensor.xsScreenSize || this.screenSensor.smScreenSize;
    }

    @computed('isTabletMode')
    get isSelectionToggable() {
        return this.isTabletMode;
    }

    @action
    createConcept(grid: IGrid, contact: IContact, faciesProperty: IFaciesProperty, widgets: Partial<IWidgetInstance>[]) {
        this.saturationConcepts.currentlyLoading = true;

        this.transitionToRoute('saturation-concepts.new', {
            queryParams:
            {
                grid: grid.moniker.string,
                contact: contact.moniker.string,
                facies: faciesProperty ? faciesProperty.moniker.string : undefined,
                widgets: JSON.stringify(widgets)
            }
        });
    }

    @action
    onToggleMenu() {
        this.toggleProperty('menuOpen');
    }

    @action
    closePanel() {
        return true;
    }

    @action
    closePromptDialog(result: string) {
        set(this, 'modalOpen', false);
        if (result === 'ok') {
            set(this, 'transitionConfirmed', true);
            this.transition.retry();
        }
    }
}

declare module '@ember/controller' {
    interface Registry {
        'explore.index': ExploreIndexController;
    }
}
