import isInZeroOneRange from 'ava-saturation/classes/calculations/dependency-injection/filters/is-in-zero-one-range';

export const dependencies = {
    functions: {
        isInZeroOneRange
    },
    dimensionsIndex: {
    }
};

export default function isWaterSaturationPointValid(deps, point) {
    if(!point)
        return false;

    if(isNaN(Number(point.sw)))
        return false;

    return deps.functions.isInZeroOneRange(deps, point.sw);
}