import Mixin from '@ember/object/mixin';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';

export default Mixin.create(UnauthenticatedRouteMixin, {
    model() {
        const return_url = this.get('session.attemptedTransition.intent.url') || 'index';

        return this.session.authenticate('authenticator:auth-code', { state: return_url });
    }
});
