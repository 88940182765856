import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { fromPairs, map, filter } from 'lodash';
import {
    SaturationConcept,
    SaturationConceptsState,
} from './types';
import * as saturationConceptActions from './actions';
import * as modelActions from 'ava-saturation/store/entities-v1/saturation-concept-state/model/actions';
import * as tracking from 'ava-saturation/store/entities-v1/saturation-concept-actions/actions';
// Create a tagged union type
export type SaturationConceptAction = ActionType<typeof saturationConceptActions> | ActionType<typeof modelActions> | ActionType<typeof tracking>;

export function saturationConceptsReducer(
    state: Record<string, SaturationConcept> = {},
    action: SaturationConceptAction
): Record<string, SaturationConcept> {
    switch (action.type) {
        // fetched all from DB
        case getType(saturationConceptActions.addConcepts):
            // action.saturationConcepts should be renamed to action.payload
            const concepts = fromPairs(map(action.payload, concept => [concept.id, concept]));
            return {
                ...state,
                ...concepts
            };

        // create from UI
        case getType(saturationConceptActions.createConcept):
            // NOTE: [TT] incomig concept must have isFresh = true
            return {
                ...state,
                [action.payload.id]: action.payload
            };

        // deleted in DB
        case getType(saturationConceptActions.deleteConcept):
            const { [action.payload.id]: value, ...newState } = state;
            return newState;

        // general information update from UI
        case getType(saturationConceptActions.updateGeneralInformation):
            // action.saturationConceptId & generalInformation should be renamed to action.payload[]
            return {
                ...state,
                [action.payload.sid]: {
                    ...state[action.payload.sid],
                    isDraft: true,
                    generalInformation: {
                        ...action.payload.generalInformation
                    }
                }
            };
        case getType(tracking.createSaturationConceptActionAsync.success):
            return {
                ...state,
                [action.payload.saturationConceptId]: {
                    ...state[action.payload.saturationConceptId],
                    isDraft: true
                }
            };
        case getType(saturationConceptActions.updateConceptPatch): {
            let result = {
                ...state[action.payload.id],
                patchId: action.payload.patchId,
                isDraft: true,
                isFresh: false
            };

            return {
                ...state,
                [action.payload.id]: result
            };
        }
        case getType(saturationConceptActions.updateConceptVersion): {
            let result = {
                ...state[action.payload.id],
                versionId: action.payload.versionId,
                isDraft: false
            };

            return {
                ...state,
                [action.payload.id]: result
            };
        }
        case getType(modelActions.setModel):
            return {
                ...state,
                [action.payload.sid]: {
                    ...state[action.payload.sid],
                    metadata: {
                        sourceMonikerString: action.payload.moniker,
                        modelId: action.payload.id
                    }
                }
            };
        case getType(saturationConceptActions.resetConcepts):
            return action.payload;

        default:
            return state;
    }
}

export function localSaturationConceptsReducer(
    state: Record<string, SaturationConcept> = {},
    action: SaturationConceptAction
): Record<string, SaturationConcept> {
    switch (action.type) {
        case getType(saturationConceptActions.addConcepts):
            const concepts = fromPairs(map(filter(action.payload, s => !state[s.id]), concept => [concept.id, { ...concept, patchId: '', versionId: '' }]));
            return {
                ...state,
                ...concepts
            };
        case getType(saturationConceptActions.createConcept):
            return {
                ...state,
                [action.payload.id]: action.payload
            };

        case getType(saturationConceptActions.deleteConcept):
            const { [action.payload.id]: value, ...newState } = state;
            return newState;

        case getType(saturationConceptActions.updateGeneralInformation):
            // action.saturationConceptId & generalInformation should be renamed to action.payload[]
            return {
                ...state,
                [action.payload.sid]: {
                    ...state[action.payload.sid],
                    generalInformation: action.payload.generalInformation
                }
            };
        case getType(modelActions.setModel):
            return {
                ...state,
                [action.payload.sid]: {
                    ...state[action.payload.sid],
                    metadata: {
                        sourceMonikerString: action.payload.moniker,
                        modelId: action.payload.id
                    }
                }
            };
        case getType(saturationConceptActions.updateConceptPatch): {
                let result = {
                    ...state[action.payload.id],
                    patchId: action.payload.patchId
                };

                return {
                    ...state,
                    [action.payload.id]: result
                };
            }
        case getType(saturationConceptActions.updateConceptVersion): {
                let result = {
                    ...state[action.payload.id],
                    versionId: action.payload.versionId
                };

                return {
                    ...state,
                    [action.payload.id]: result
                };
            }
        case getType(saturationConceptActions.applyPatch):
            return {
                ...state,
                [action.payload.sid]: {
                    ...state[action.payload.sid],
                    patchId: action.payload.patchId,
                    versionId: action.payload.versionId ? action.payload.versionId : state[action.payload.sid].versionId
                }
            };
        default:
            return state;
    }
}

export var saturationConcepts = combineReducers<SaturationConceptsState>({
    byId: saturationConceptsReducer
});

export var localSaturationConcepts = combineReducers<SaturationConceptsState>({
    byId: localSaturationConceptsReducer
});