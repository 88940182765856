import ReferenceObjectType from '../classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from '../interfaces/reference-parser';
import IContact from 'ava-saturation/interfaces/contact-reference';

export default class ContactReferenceType extends ReferenceObjectType {
    readonly id = 110;
    readonly name = 'contact';
    readonly typeAliases = ['PetrelContact', 'PetrelContactUpdate'];
    subTypeAliases = [];
}

export const contactReferenceType = new ContactReferenceType();

export class ContactReferenceParser extends SingleReferenceParser<IContact> {
    parse(object: any): IContact {
        let reference = super.parse(object);
        return {
            ...reference,
            type: contactReferenceType,
        };
    }
}

export class ContactCollectionReferenceParser extends ReferenceParser<Array<IContact>> {
    parse(object: any): Array<IContact> {
        return referenceCollectionParser<IContact>(this.appendGridMoniker(object, 'Contacts'), new ContactReferenceParser(this.origin, this.version));
    }
}