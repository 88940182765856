import { computed, set } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import { DataSourceInstance } from 'ava-saturation/classes/data-source';
import pointWellLog from 'ava-saturation/classes/dimensions/point-well-log';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import { AxisSettings, fromDimension, fromGroup } from 'ava-saturation/classes/widgets/axis';
import { ProportionalChartBase } from 'ava-saturation/components/charting/proportional-chart-base';
import IDimension from 'ava-saturation/interfaces/dimension';
import IPlot, { AbstractAxisOption, ILegendSettings, IPlotSettings, PlotDefinition, PlotSettingsType } from 'ava-saturation/interfaces/plot';
import { IGroup, IReferenceGroup, PlotPresentationalType } from 'ava-saturation/interfaces/presenter';
import IReference from 'ava-saturation/interfaces/reference';
import d3 from 'd3';

class ScatterPlotDefinition extends PlotDefinition {
    name: string = 'scatter-plot';
    type: PlotPresentationalType = PlotPresentationalType.Scatter;
    icon: string = 'bubble_chart';
}

class RegressionLineScatterPlotDefinition extends PlotDefinition {
    name: string = 'regression-line-scatter-plot';
    type: PlotPresentationalType = PlotPresentationalType.ScatterRegression;
    icon: string = 'bubble_chart';
}

class HistogramPlotDefinition extends PlotDefinition {
    name: string = 'histogram-plot';
    type: PlotPresentationalType = PlotPresentationalType.Histogram;
    icon: string = 'bar_chart';
}

export function plotDefinitionFactory(definition?: Partial<PlotDefinition>) {
    if (!definition)
        return scatterPlotDefinition;

    switch (definition.type) {
        case PlotPresentationalType.Scatter:
            return scatterPlotDefinition;

        case PlotPresentationalType.ScatterRegression:
            return regressionLineScatterPlotDefinition;

        case PlotPresentationalType.Histogram:
            return histogramPlotDefinition;

        default:
            return scatterPlotDefinition;
    }
}

export const scatterPlotDefinition = new ScatterPlotDefinition();
export const regressionLineScatterPlotDefinition = new RegressionLineScatterPlotDefinition();
export const histogramPlotDefinition = new HistogramPlotDefinition();

export const plotDefinitions = [scatterPlotDefinition, histogramPlotDefinition];

export abstract class PlotSettings implements IPlotSettings {
    abstract readonly type: PlotSettingsType;
    abstract legendSettings: LegendSettings;
    abstract isPopulated: boolean;

    /**
     * Populates the X & Y axes options array
     * @param dimensions
     */
    abstract withDimensions(dimensions: IDimension[]): PlotSettings;

    /**
     * Populates the Z axis options array
     * @param groups
     */
    abstract withGroups(groups: IReferenceGroup[]): PlotSettings;

    /**
     * Sets the default option for all axes
     * @param dimensionsByLog
     */
    abstract withDefaultDimensions(dimensionsByLog: IDimension[]): PlotSettings;

    /**
     *
     * @returns POJO ready for serilization
     */
    abstract deflate(): Partial<PlotSettings>;

    addAndSortAxisOptions(axisOptions: AbstractAxisOption[], dimensionsForAdditon: IDimension[], asCategorical?: boolean) {
        // pass 'true' to fromDimension to convert the settigns to categorial so the proper settings component is rendered
        return axisOptions.pushObjects(dimensionsForAdditon.map(d => fromDimension(d, asCategorical))).sortBy('dimension.axisPriority');
    }

    addAndSortGroupAxisOptions(axisOptions: AbstractAxisOption[], groupsForAdditon: IGroup<IReference>[]) {
        return axisOptions.pushObjects(groupsForAdditon.map(g => fromGroup(g))).sortBy('dimension.axisPriority');
    }

    withAxisDimensions(axisSettings: AxisSettings, whitelistedDimensions: IDimension[], cb: Function) {
        const dimensionsForAdditon = this.findDimensionsForAddition(axisSettings.options, whitelistedDimensions);
        const axisOptionsForRemoval = this.findAxisOptionsForRemoval(axisSettings.options, whitelistedDimensions);

        if (dimensionsForAdditon.length > 0)
            this.setAxisSettingOptions(axisSettings, dimensionsForAdditon, cb);
        if (axisOptionsForRemoval.length > 0)
            axisSettings.options.removeObjects(axisOptionsForRemoval);
    }

    withAxisGroups(axisSettings: AxisSettings, groups: IGroup<IReference>[], cb: Function) {
        const newGroupDimensions = groups.map(o => o.dimension);

        this.findAndUpdateExistingGroups(axisSettings.options, groups);
        const groupsForAddition = this.findGroupsForAddtion(axisSettings.options, groups);
        const axisOptionsForRemoval = this.findAxisOptionsForRemoval(axisSettings.options, newGroupDimensions);

        if (groupsForAddition.length > 0)
            this.setAxisSettingOptions(axisSettings, groupsForAddition, cb);
        if (axisOptionsForRemoval.length > 0)
            axisSettings.options.removeObjects(axisOptionsForRemoval);
    }

    findOption(dimension: IDimension | undefined, axisSettings: AxisSettings) {
        let targetOption;

        if (dimension) {
            if (dimension.shortName === pointWellLog.shortName) {
                // for point logs return Sw for X axis dimension
                targetOption = axisSettings.options.find(o => {
                    return o.dimension.shortName === waterSaturation.shortName;
                });
            } else {
                // for general logs lookup the axis dimension
                targetOption = axisSettings.options.find(o => {
                    return o.dimension.shortName === dimension.shortName;
                });
            }
        }

        return targetOption;
    }

    findDimensionsForAddition(currentAxisOptions: AbstractAxisOption[], newDimensions: IDimension[]) {
        let availableDimensions = currentAxisOptions.map(o => o.dimension);
        return newDimensions.reject(nd => availableDimensions.find(ad => nd.name === ad.name));
    }

    findAndUpdateExistingGroups(currentAxisOptions: AbstractAxisOption[], newGroups: IGroup<IReference>[]) {
        currentAxisOptions.forEach(option => {
            const correspondingGroup = newGroups.find(g => g.dimension.name === option.dimension.name);

            if (correspondingGroup)
                option.updateWith!(fromGroup(correspondingGroup));
        });
    }

    findGroupsForAddtion(currentAxisOptions: AbstractAxisOption[], newGroups: IGroup<IReference>[]) {
        let availableDimensions = currentAxisOptions.map(o => o.dimension);
        return newGroups.reject(g => availableDimensions.find(ad => ad.name === g.dimension.name));
    }

    findAxisOptionsForRemoval(currentAxisOptions: AbstractAxisOption[], newDimensions: IDimension[]) {
        return currentAxisOptions.reject(o => newDimensions.find(nd => nd.name === o.dimension.name));
    }

    setAxisSettingOptions(axisSettings: AxisSettings, elementsForAdditon: IDimension[] | IGroup<IReference>[], cb: Function): void {
        if (cb) {
            const options = cb.call(this, axisSettings.options, elementsForAdditon);
            set(axisSettings, 'options', options);
        }
    }
}

export class LegendSettings implements ILegendSettings {
    title: string;

    /**
     *
     */
    constructor(inflatable?: Partial<LegendSettings>) {
        this.title = inflatable ? inflatable.title! : '';
    }

    static inflate(inflatable: Object): LegendSettings {
        return new LegendSettings(inflatable);
    }

    deflate(): Object {
        return {
            title: this.title
        };
    }
}

export default abstract class Plot extends ProportionalChartBase implements IPlot {
    dataSourceInstance: DataSourceInstance;

    // containers
    svg: d3.Selection<Element, {}, HTMLElement | null, any | undefined>;
    chartWrapper: d3.Selection<Element, {}, HTMLElement | null, any | undefined>;
    _renderTimer: any | null = null;

    constructor(properties?: object | undefined) {
        super(properties);

        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        set(this, 'displayLegend', this.displayLegend == undefined ? true : this.displayLegend);
    }

    abstract definition: PlotDefinition;
    abstract settings: PlotSettings;
    displayLegend: boolean;

    didInsertElement() {
        // can add a one time computed property for the container change
        // @ts-ignore
        const container = d3.select(this.element);
        // @ts-ignore
        set(this, 'container', container.node());
        // @ts-ignore
        set(this, 'chartWrapper', container.select('#chart'));
    }

    willDestroy() {
        cancel(this._renderTimer);
    }

    doLater(action: () => void): void {
        this._renderTimer = later(this, () => {
            if (!this.isDestroyed && action) {
                action();
            }
        }, 0);
    }

    applyFloatingPointPrecision(value: number, precision: number = 2) {
        // (+) cast to number
        return +parseFloat(value.toString()).toFixed(precision);
    }

    @computed('ratio', 'displayLegend')
    get verticalLegend(): boolean {
        // @ts-ignore
        // set(this, 'settings.legendSettings.right', this.ratio <= 1);
        if (!this.displayLegend)
            return false;

        return this.ratio <= 0.8;
    }

    @computed('width', 'margin.left', 'margin.right')
    get svgChartWidth() {
        return this.width - this.margin.left - this.margin.right;
    }

    @computed('height', 'margin.top', 'margin.bottom')
    get svgChartHeight() {
        return this.height - this.margin.top - this.margin.bottom;
    }

    abstract get legendTitle(): string;

    abstract get legendValues(): IReference[]; // TODO: Shouldn't have direct dependency here
}
