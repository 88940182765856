import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as calculationSetActions from '../calculation-set/actions';
import * as bandActions from '../function-band/actions';
import * as functionActions from '../model-function/actions';
import * as modelInputActions from '../model-inputs/actions';
import * as calculationConstantActions from './actions';
import { CalculationConstant, CalculationConstantsState } from './types';
// Create a tagged union type
export type CalculationConceptAction = ActionType<typeof calculationConstantActions> |
    ActionType<typeof functionActions> |
    ActionType<typeof bandActions> |
    ActionType<typeof saturationConceptActions> |
    ActionType<typeof modelInputActions> |
    ActionType<typeof calculationSetActions>;

function calculationConstants(state: CalculationConstant[] = [], action: CalculationConceptAction) {
    switch (action.type) {

        case getType(calculationConstantActions.addCalculationConstants):
            return [
                ...state, {
                    ...action.payload
                }
            ];
        case getType(calculationConstantActions.updateCalculationConstants):
            return state.map(c => c.bandId === action.payload.bandId ? { ...c, constants: { ...action.payload.constants } } : c);

        case getType(functionActions.deleteFunction): {
            return state.filter(b => b.functionId !== action.payload.id);
        }

        case getType(calculationSetActions.addCalculationSet):
        case getType(calculationSetActions.deleteCalculationSet): {
            return state.map(c => c.functionId === action.payload.functionId && c.bandId === action.payload.bandId ? { ...c, constants: {} } : c);
        }

        case getType(functionActions.updateBandType): {
            const id = `${action.payload.id}/${action.payload.bandType}`;
            if (state.find(c => c.bandId === id)) {
                return state.map(c => c.bandId === id ? { ...c, constants: {} } : c);
            } else {
                return [
                    ...state,
                    {
                        sid: action.payload.sid,
                        bandId: id,
                        functionId: action.payload.id,
                        constants: {}
                    }
                ];
            }
        }

        case getType(functionActions.addFunction): {
            const id = `${action.payload.id}/${action.payload.bandType}`;
            return [
                ...state,
                {
                    sid: action.payload.sid,
                    bandId: id,
                    functionId: action.payload.id,
                    constants: {}
                }
            ];
        }

        case getType(bandActions.addFunctionBand):
            return [
                ...state,
                {
                    sid: action.payload.sid,
                    bandId: action.payload.id,
                    functionId: action.payload.functionId,
                    constants: {}
                }
            ];

        case getType(bandActions.updateCalculationType):
            return state.map(c => c.bandId === action.payload.id ? { ...c, constants: {} } : c);

        case getType(modelInputActions.addModelInput): {
            if (action.payload.type === 'contact') {
                return state.map(c => ({ ...c, constants: {} }));
            }

            return state; // << original state
        }

        case getType(calculationConstantActions.deleteCalculationConstants): {
            return state.filter(c => c.bandId !== action.payload.bandId);
        }

        default:
            return state;
    }
}

export function calculationConstantsReducerBySid(
    state: Record<string, CalculationConstant[]> = {},
    action: CalculationConceptAction
): Record<string, CalculationConstant[]> {
    switch (action.type) {

        case getType(functionActions.addFunction):
        case getType(functionActions.updateBandType):
        case getType(bandActions.addFunctionBand):
        case getType(bandActions.updateCalculationType):
        case getType(modelInputActions.addModelInput):
        case getType(calculationConstantActions.deleteCalculationConstants):
        case getType(functionActions.deleteFunction):
        case getType(calculationConstantActions.addCalculationConstants):
        case getType(calculationConstantActions.updateCalculationConstants):
        case getType(calculationSetActions.addCalculationSet):
        case getType(calculationSetActions.deleteCalculationSet):
            return {
                ...state,
                [action.payload.sid]: calculationConstants(state[action.payload.sid], action)
            };

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        default:
            return state;
    }
}

export default combineReducers<CalculationConstantsState>({
    bySid: calculationConstantsReducerBySid
});
