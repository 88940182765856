import ObjectPromiseProxy from './object-promise-proxy';
import RSVP from 'rsvp';
import IReferenceParser from 'ava-saturation/interfaces/reference-parser';
import { IReferenceBase } from 'ava-saturation/interfaces/reference';

export default class ReferenceObjectPromiseProxy<TReturnValue extends IReferenceBase | IReferenceBase[]> extends ObjectPromiseProxy<TReturnValue> {
    // Property '[Symbol.toStringTag]' is missing in type 'Fix<ReferenceObjectPromiseProxy<IReference | IReference[]> & { promise: Promise<T>; }>'
    // There should be a work-around. Still seeing the error on my side. Surprisingly it works
    // Attempted to install @types/es6-promise & @types/es6-collections - didn't do the job
    // See ObjectPromiseProxy for the "FIX"

    public static createAndParse<T extends IReferenceBase | Array<IReferenceBase>>(promise: RSVP.Promise<any>, parser: IReferenceParser<T>, settings: { autoParse: boolean } = { autoParse: true }): ReferenceObjectPromiseProxy<T> {
        return ReferenceObjectPromiseProxy.create({
            promise: promise.then((result: string) => {
                var fromString = settings.autoParse ? JSON.parse(result) : result;
                return parser.parse(fromString);
            })
        }) as ReferenceObjectPromiseProxy<T>;
    }

    public static createPromise<T>(promise: RSVP.Promise<any>): ReferenceObjectPromiseProxy<T> {
        return ReferenceObjectPromiseProxy.create({
            promise
        }) as ReferenceObjectPromiseProxy<T>;
    }

    public static empty<T extends IReferenceBase | Array<IReferenceBase>>(returnValue: T): ReferenceObjectPromiseProxy<T> {
        return ReferenceObjectPromiseProxy.create({
            promise: RSVP.Promise.resolve<T>(<T> returnValue)
        }) as ReferenceObjectPromiseProxy<T>;
    }

    public next<TResult1 = TReturnValue, TResult2 = never>(
        onFulfilled?: ((value: TReturnValue) => TResult1 | PromiseLike<TResult1>) | undefined | null,
        onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null,
        label?: string
    ): ReferenceObjectPromiseProxy<TResult1 | TResult2> {
        return ReferenceObjectPromiseProxy.createPromise<TResult1 | TResult2>(
            this.then(onFulfilled, onRejected, label)
        );
    }
}