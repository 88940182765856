define("ava-saturation/initializers/mock-dataset-store", ["exports", "mock-store/initializers/mock-dataset-store"], function (_exports, _mockDatasetStore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mockDatasetStore.default;
    }
  });
});
