import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IZone from 'ava-saturation/interfaces/zone-reference';

export default class ZoneReferenceType extends ReferenceObjectType {
    readonly id = 120;
    readonly name = 'zone';
    readonly typeAliases = ['PetrelZone', 'PetrelZoneUpdate'];
    subTypeAliases = [];
}

export const zoneReferenceType = new ZoneReferenceType();

export class ZoneReferenceParser extends SingleReferenceParser<IZone> {

    parse(object: any): IZone {
        let reference = super.parse(object);
        return {
            ...reference,
            type: zoneReferenceType,
            code: object.Index
        };
    }
}

export class ZoneCollectionReferenceParser extends ReferenceParser<Array<IZone>> {
    parse(object: any): Array<IZone> {
        return referenceCollectionParser<IZone>(this.appendGridMoniker(object, 'Zones'), new ZoneReferenceParser(this.origin, this.version));
    }
}