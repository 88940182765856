export default function DatasetCalculator(producedDimension, requiredDimensions, calculator) {
    this.producedDimension = producedDimension;
    this.requiredDimensions = requiredDimensions;
    this.calculator = calculator;

    return {
        producedDimension: this.producedDimension,
        requiredDimensions: this.requiredDimensions,
        calculator: this.calculator,
        meetsExpectations: function (dimensions) {
            return this.requiredDimensions.filter(d => dimensions.indexOf(d) != -1).length ===
                this.requiredDimensions.length;
        },
        apply: function (value, dataset) {
            if (this.meetsExpectations(dataset.dimensions) === false)
                return;

            // const nonSanitizedValue = this.calculator.calculate(value)
            // const calculatedValue = nonSanitizedValue === null ? NaN : Number(nonSanitizedValue);

            const calculatedValue = Number(this.calculator.calculate(value));

            if (isNaN(calculatedValue) == false) {
                value[this.producedDimension.shortName] = calculatedValue;

                const isDatasetUpdated = dataset.dimensions.indexOf(this.producedDimension) != -1;
                const action = isDatasetUpdated ? () => { } : dataset.dimensions.push;

                action.call(dataset.dimensions, this.producedDimension);
            }
        }
    };
}