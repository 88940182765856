import { DiscreteNumericDimension } from 'ava-saturation/classes/dimension';

const contactDepthKey = 'contactDepth';

const contactDepth = new DiscreteNumericDimension();
contactDepth.displayName = 'Contact Depth';
contactDepth.name = contactDepth.shortName = contactDepthKey;
contactDepth.unitAlias = 'Standard_Depth_Index';

export interface IContactDepthSample {
    [contactDepthKey]: number;
}

export default contactDepth;