import isPorosityPointValid from 'ava-saturation/classes/calculations/dependency-injection/filters/is-porosity-point-valid';
import isWaterSaturationPointValid from 'ava-saturation/classes/calculations/dependency-injection/filters/is-water-saturation-point-valid';

export const dependencies = {
    functions: {
        isPorosityPointValid,
        isWaterSaturationPointValid
    },
    dimensionsIndex: {}
};

export function BVWCalculator(deps) {
    this.isPorosityPointValid = deps.functions.isPorosityPointValid;
    this.isWaterSaturationPointValid = deps.functions.isWaterSaturationPointValid;

    return {
        calculate: function(value) {
            if (!this.isPorosityPointValid(deps, value))
                return null;

            if (!this.isWaterSaturationPointValid(deps, value))
                return null;

            return value.por * value.sw;
        }.bind(this)
    };
}