export const avaDefault = {

    primaryColor: {
        '50': '#ebeaea',
        '100': '#cccccb',
        '200': '#aaaaa9',
        '300': '#888786',
        '400': '#6f6e6c',
        '500': '#555452',
        '600': '#4e4d4b',
        '700': '#444341',
        '800': '#3b3a38',
        '900': '#2a2928',
        'A100': '#D3D2D2',
        'A200': '#9F9E9E',
        'A400': '#555452',
        'A700': '#302F2F',
    },

    accentColor: {
        '50': '#e7eaf3',
        '100': '#c4cbe0',
        '200': '#9da8cc',
        '300': '#7585b8',
        '400': '#586ba8',
        '500': '#3a5199',
        '600': '#344a91',
        '700': '#2c4086',
        '800': '#25377c',
        '900': '#18276b',
        'A100': '#a5b3ff',
        'A200': '#7288ff',
        'A400': '#3f5cff',
        'A700': '#2547ff',
    }
};

export const fontSize = {
    small: 10,
    normal: 12,
    h1: 18,
    h2: 16,
    h3: 14,
    h4: 14
};
