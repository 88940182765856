import Component from '@ember/component';
import ColorMixin from 'ember-paper/mixins/color-mixin';
import { htmlSafe } from '@ember/string';
import { computed } from '@ember/object';
import $ from 'jquery';

const AvaIconComponent = Component.extend(ColorMixin, {
    tagName: 'md-icon',
    classNames: ['ava-icon md-default-theme'],
    attributeBindings: ['containerStyle:style', 'title'],

    size: 20,
    containerStyle: computed('size', function () {
        const size = this.get('size');
        return htmlSafe(`height: ${size}px; min-height: ${size}px; width: ${size}px; min-width: ${size}px; transform: scale(0.95);`);
    }),

    sizeStyle: computed('size', function () {
        const size = this.get('size');
        return htmlSafe(`height: ${size}px; min-height: ${size}px; width: ${size}px; min-width: ${size}px; font-size: ${size}px; line-height: ${size}px; transform: scale(0.95); ${this.get('style')}`);
    }),

    didRender() {
        this._super(...arguments);
        $(this.element.querySelector('svg')).attr('style', this.get('sizeStyle'));
    }
});

AvaIconComponent.reopenClass({
    positionalParams: ['icon']
});

export default AvaIconComponent;
