// note to self this exists  { QCLogPair } from 'ava-saturation/services/qc-workflow';
import uuid from 'ava-saturation/utils/uuid';
import IWaterSaturationProperty from './water-saturation-property-reference';

export default class QCGenPair {
    constructor(property: any, log: any) {
        this.id = uuid();
        this.property = property;
        this.log = log;
    }

    id: string;
    log: any; // TODO: [TT] Refer to qc-workflow.ts line 97
    property: IWaterSaturationProperty;
}
