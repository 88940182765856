import { isBlank } from '@ember/utils';
import _ from 'lodash';
import escape from 'ava-saturation/utils/escape-regex-string';

export default function (arrayToFilter, itemProperty, filter) {
    if (isBlank(filter)) {
        return arrayToFilter;
    }

    if (isBlank(itemProperty)) {
        return arrayToFilter;
    }

    let escFilter = escape(filter);
    let searchRegExp = new RegExp(escFilter, 'i'); // ignore case

    return _.filter(arrayToFilter, (item) => {
        var propertyValue = itemProperty(item);
        return propertyValue.match(searchRegExp);
    });
}
