import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as functionActions from '../model-function/actions';
import * as functionBandActions from '../function-band/actions';
import * as actions from './actions';
import { CalculationParameter, CalculationParameterState } from './types';
import { generateBandId } from '../function-band/reducers';
import { cuddy, skelt, leverettJ } from 'ava-saturation/classes/calculation-types';

export type CalculationParameterActionType = ActionType<typeof actions> | ActionType<typeof functionActions> | ActionType<typeof functionBandActions> | ActionType<typeof saturationConceptActions>;

const resolveParameterType = (calculationMethodId: string) => {
    switch (calculationMethodId) {
        case cuddy.id:
            return cuddy.mappingParameters;
        case skelt.id:
            return skelt.mappingParameters;
        case leverettJ.id:
            return leverettJ.mappingParameters;
        default:
            throw 'Unsupported calculation type';
    }
};

const calculationParameters = (state: CalculationParameter[] = [], action: CalculationParameterActionType): CalculationParameter[] => {
    switch (action.type) {
        case getType(functionActions.addFunction): {
            const bandId = generateBandId(action.payload);

            return [
                ...state, {
                    id: `${bandId}_parameters`,
                    sid: action.payload.sid,
                    bandId: bandId,
                    functionId: action.payload.id,
                    byDimensionKey: { ...resolveParameterType(cuddy.id) }
                }
            ];
        }

        case getType(functionActions.updateBandType): {
            const bandId = generateBandId(action.payload);

            const bandParameters = state.find(p => p.id === `${bandId}_parameters`);

            if (bandParameters)
                return state;

            return [
                ...state, {
                    id: `${bandId}_parameters`,
                    sid: action.payload.sid,
                    bandId: bandId,
                    functionId: action.payload.id,
                    byDimensionKey: { ...resolveParameterType(cuddy.id) }
                }
            ];
        }

        case getType(functionBandActions.addFunctionBand): {
            return [
                ...state, {
                    id: `${action.payload.id}_parameters`,
                    sid: action.payload.sid,
                    bandId: action.payload.id,
                    functionId: action.payload.id,
                    byDimensionKey: { ...resolveParameterType(action.payload.calculationType) }
                }
            ];
        }

        case getType(functionBandActions.updateCalculationType): {
            return state.map(p => p.id === `${action.payload.id}_parameters` ? {
                ...p,
                byDimensionKey: { ...resolveParameterType(action.payload.calculationType) }
            } : p);
        }

        case getType(actions.updateCalculationParameter): {
            return state.map(p => p.id === action.payload.id ? {
                ...p,
                byDimensionKey: {
                    ...p.byDimensionKey,
                    ...action.payload.byDimensionKey
                }
            } : p);
        }

        case getType(functionBandActions.deleteFunctionBand): {
            return state.filter(b => b.bandId !== action.payload.id);
        }

        case getType(functionActions.deleteFunction): {
            return state.filter(b => b.functionId !== action.payload.id);
        }

        default:
            return state;
    }
};

export function modelCalculationParameterBySid(state: Record<string, CalculationParameter[]> = {}, action: CalculationParameterActionType) {
    switch (action.type) {
        case getType(functionActions.addFunction):
        case getType(functionActions.updateBandType):
        case getType(functionBandActions.addFunctionBand):
        case getType(functionBandActions.updateCalculationType):
        case getType(actions.updateCalculationParameter):
        case getType(functionBandActions.deleteFunctionBand):
        case getType(functionActions.deleteFunction): {
            return {
                ...state,
                [action.payload.sid]: calculationParameters(state[action.payload.sid], action)
            };
        }

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        default:
            return state;
    }
}

export default combineReducers<CalculationParameterState>({
    bySid: modelCalculationParameterBySid
});
