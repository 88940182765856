import { createSelector, ParametricSelector } from 'reselect';
import { CalculationParameter } from './types';

export interface SearchProps {
    sid: string;
}

const getCalculationParameters: ParametricSelector<any, SearchProps, CalculationParameter[]> = (state: any, props: SearchProps) =>
    state.entitiesV1.saturationConceptState.calculationParameter.bySid[props.sid] || [];

export const getCalculationParametersBySid = createSelector([getCalculationParameters], (cs) => cs);