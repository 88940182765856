import Component from '@ember/component';
import { computed } from '@ember/object';

/**
 * @class PaperBottomNavigation
 * @extends Ember.Component
 */
export default Component.extend({
    tagName: 'md-bottom-navigation',
    anchor: 'bottom',
    docked: true,
    classNameBindings: ['whiteframe:md-whiteframe-z2', 'docked:md-docked', 'anchorClass', 'dockedClass', 'minimizedClass'],

    anchorClass: computed('anchor', function () {
        return `md-anchor-${this.anchor}`;
    }),

    dockedClass: computed('docked', 'anchor', function () {
        return this.docked ? `md-anchor-docked-${this.anchor}` : '';
    }),

    minimizedClass: computed('minimized', function () {
        return this.minimized ? 'minimized' : '';
    })
});