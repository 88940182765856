import {
    JValueCalculator,
    dependencies as calculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/j-value-calculator-pure';
import DatasetCalculator from 'ava-saturation/classes/calculations/dependency-injection/calculators/dataset-calculator-pure';

import { capillaryPressureRes } from 'ava-import/classes/dimensions/capillary-pressure';
import { iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import permeability from 'ava-import/classes/dimensions/permeability';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import jValue from 'ava-import/classes/dimensions/j-value';

export const dependencies = {
    functions: {
        ...calculatorDependencies.functions,
        JValueCalculator,
        DatasetCalculator
    },
    dimensionsIndex: {
        ...calculatorDependencies.dimensionsIndex,
        jValue,
        capillaryPressureRes,
        iftReservoir,
        contactAngleRes,
        permeability,
        porosity
    }
};

export default function JValueDatasetCalculator(deps) {
    this.datasetCalculator = new deps.functions.DatasetCalculator(
        deps.dimensionsIndex.jValue,
        [deps.dimensionsIndex.capillaryPressureRes, deps.dimensionsIndex.iftReservoir, deps.dimensionsIndex.contactAngleRes, deps.dimensionsIndex.permeability, deps.dimensionsIndex.porosity],
        new deps.functions.JValueCalculator(deps)
    );

    return {
        producedDimension: this.datasetCalculator.producedDimension,
        datasetCalculator: this.datasetCalculator,
        meetsExpectations: function (dimensions) {
            return this.datasetCalculator.meetsExpectations(dimensions);
        },
        apply: function (value, dataset) {
            this.datasetCalculator.apply(value, dataset);
        }
    };
}