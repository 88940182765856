import isInRange from 'ava-saturation/calculations/filters/is-in-range';

export const dependencies = {
    functions: {
        isInRange
    },
    dimensionsIndex: {
    }
};

export default function isInZeroOneRange(deps, value) {
    return deps.functions.isInRange(0, 1, value);
}