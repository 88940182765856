import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IWellLog, { IWellLogReferenceType } from 'ava-saturation/interfaces/well-log-reference';
import IDimension from 'ava-saturation/interfaces/dimension';
import measureDepth from 'ava-saturation/classes/dimensions/measure-depth';

export default abstract class WellLogReferenceType extends ReferenceObjectType implements IWellLogReferenceType {
    constructor(primaryDimension: IDimension, dimensions: Array<IDimension>) {
        super();

        this.primaryDimension = primaryDimension;
        this.dimensions = dimensions;

        this.dimensions.push(measureDepth);
    }

    abstract id = 210;
    abstract name = 'well-log';
    readonly typeAliases = ['PetrelWellLog', 'PetrelWellLogUpdate', 'PetrelDictionaryWellLog', 'PetrelDictionaryWellLogUpdate'];
    abstract subTypeAliases: Array<string>;

    primaryDimension: IDimension;
    dimensions: Array<IDimension>;

    toPlainObject() {
        return {
            id: this.id,
            name: this.name
        };
    }
}

export class WellLogReferenceParser extends SingleReferenceParser<IWellLog> {
    private _type: WellLogReferenceType;

    constructor(origin: string, version: string, logType: WellLogReferenceType) {
        super(origin, version);

        this._type = logType;
    }

    parse(object: any): IWellLog {
        let reference = super.parse(object);
        // @ts-ignore
        return {
            ...reference,
            // @ts-ignore
            isSynthetic: reference.IsSynthetic,
            type: this._type
        };
    }
}

export class WellLogCollectionReferenceParser extends ReferenceParser<Array<IWellLog>> {
    private _wellLogParser: WellLogReferenceParser;

    constructor(origin: string, version: string, logType: WellLogReferenceType) {
        super(origin, version);

        this._wellLogParser = new WellLogReferenceParser(origin, version, logType);
    }

    parse(object: any): Array<IWellLog> {
        return referenceCollectionParser<IWellLog>(this.appendWellMoniker(object, 'Logs'), this._wellLogParser);
    }
}
