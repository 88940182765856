import { createCustomAction } from 'typesafe-actions';
import constants from './constants';
import { GeneralInformation, SaturationConcept, DeleteConceptPayload, Patch } from './types';

export const addConcepts = createCustomAction(constants.GET_ALL_SUCCESS, type => {
    return (concepts: SaturationConcept[]) => ({ type, payload: concepts });
});

export const resetConcepts = createCustomAction(constants.GET_ALL_FAILURE, type => {
    return () => ({ type, payload: {} });
});

export const createConcept = createCustomAction(constants.PUT_SUCCESS, type => {
    return (concept: SaturationConcept) => ({ type, payload: concept });
});

export const applyPatch = createCustomAction(constants.APPLY_PATCH, type => {
    return (payload: Patch) => ({ type, payload: payload });
});

export const deleteConcept = createCustomAction(constants.DELETE_SUCCESS, type => {
    return (concept: DeleteConceptPayload) => ({ type, payload: concept });
});

export const updateGeneralInformation = createCustomAction(constants.UPDATE_GENERAL_INFO, type => {
    return ({ sid, generalInformation }: { sid: string, generalInformation: GeneralInformation }) => ({ type, payload: { sid, generalInformation }, trackable: true });
});

export const updateConceptVersion = createCustomAction(constants.UPDATE_VERSION, type => {
    return (id: string, versionId: string) => ({ type, payload: { id, versionId } });
});

export const updateConceptPatch = createCustomAction(constants.UPDATE_PATCH, type => {
    return (id: string, patchId: string) => ({ type, payload: { id, patchId } });
});

export const sanitizeState = createCustomAction(constants.SANITIZE, type => {
    return (payload: DeleteConceptPayload) => ({ type, payload: payload });
});