export function initialize(instance) {
    const session = instance.lookup('service:session');
    session.addObserver('isAuthenticated', session, function () {
        if (window.appInsights && typeof window.appInsights.setAuthenticatedUserContext === 'function') {
            const userId = this.get('data.authenticated.email') || this.get('data.authenticated.sub') || 'unauthenticated';
            window.appInsights.setAuthenticatedUserContext(userId);
            window.appInsights.trackPageView();
        }
    });
}

export default {
    initialize,
    name: 'ava-application-insights'
};