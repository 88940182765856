import Component from '@ember/component';
import { computed } from '@ember/object';
import IContact from 'ava-saturation/interfaces/contact-reference';
import ContactProviderMixin from 'ava-saturation/mixins/providers/contact-provider';

export default class ContactContextProvider extends Component.extend(ContactProviderMixin, {
    contactContext: computed('filteredContacts.[]', 'contact', function() {
        return this.filteredContacts.find((c: IContact) => (this.contact ? this.contact.moniker.string : '') === c.moniker.string) || this.filteredContacts[0];
    })
}) {
    contact: IContact;
}