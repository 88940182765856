import { NonNumericDimension } from 'ava-saturation/classes/dimension';

const permeabilityPropertyKey = 'permeabilityProperty';

export class PermeabilityProperty extends NonNumericDimension {
    static id: string = permeabilityPropertyKey;

    constructor() {
        super();

        this.displayName = 'PermeabilityProperty';
        this.name = this.shortName = permeabilityPropertyKey;
    }
}

export const permeabilityProperty = new PermeabilityProperty();
