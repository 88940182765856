import Component from '@ember/component';
import { observer } from '@ember/object';
import { isBlank, isNone } from '@ember/utils';
import { once } from '@ember/runloop';

// Renders math expression
// see https://en.wikibooks.org/wiki/LaTeX/Mathematics for expression format
const MathExpression = Component.extend({
    tagName: 'span',
    classNames: ['ava-math-expression'],

    didInsertElement() {
        this.renderExpression();
    },

    expressionObserver: observer('expression', function() {
        once(this, 'renderExpression');
    }),

    renderExpression() {
        const expression = this.expression;
        const element = this.element;
        if (isBlank(expression) || isNone(element)) {
            return;
        }
        
        window.katex.render(expression, element);
    }
});

MathExpression.reopenClass({
    positionalParams: ['expression']
});

export default MathExpression;

