import RSVP from 'rsvp';
import merge from 'lodash/merge';

export function getSaturationConceptVersions(conceptVersionStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptVersionStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);

        let versions = [];
        cursor.onsuccess = function (successEvent) {
            let openedCursor = successEvent.target.result;

            if (openedCursor) {
                versions.push(openedCursor.value);
                openedCursor.continue();
            } else {
                resolve(versions);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function addSaturationConceptVersion(versionStore, saturationConceptId, version) {
    return new RSVP.Promise((resolve, reject) => {
        version = merge(version, { saturationConceptId });

        var addRequest = versionStore.put(version);

        addRequest.onsuccess = function (successEvent) {
            resolve(successEvent.target.result);
        };

        addRequest.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function addSaturationConceptVersions(conceptVersionStore, saturationConceptId, versions) {
    return new RSVP.Promise((resolve, reject) => {
        let i = 0, versionCount = versions.length;
        let putNext = () => {
            if (i < versionCount) {
                let version = versions[i];
                let putRequest = conceptVersionStore.put({
                    id: version.id,
                    saturationConceptId,
                    created: version.created,
                    patches: version.patches,
                    versionNo: version.versionNo
                });

                i++;

                putRequest.onsuccess = putNext;
                putRequest.onerror = function (errorEvent) {
                    reject(errorEvent.target.error);
                };
            } else {
                resolve();
            }
        };

        putNext();
    });
}

export function removeSaturationConceptVersionsByConceptId(conceptVersionStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptVersionStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.openCursor(forConceptId);
        let removedVersions = [];

        cursor.onsuccess = function (successEvent) {
            var cursor = successEvent.target.result;

            if (cursor) {
                removedVersions.push(cursor.value);
                cursor.delete();
                cursor.continue();
            } else {
                resolve(removedVersions);
            }
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

export function getSaturationConceptVersionsCount(conceptVersionStore, saturationConceptId) {
    return new RSVP.Promise((resolve, reject) => {
        var index = conceptVersionStore.index('saturationConceptId');

        var forConceptId = IDBKeyRange.only(saturationConceptId);
        var cursor = index.count(forConceptId);

        cursor.onsuccess = function (successEvent) {
            var count = successEvent.target.result;
            resolve(count);
        };

        cursor.onerror = function (errorEvent) {
            reject(errorEvent.target.error);
        };
    });
}

