import { get, set } from '@ember/object';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IWellLog, { IWellLogFilter, toPlainObject } from 'ava-saturation/interfaces/well-log-reference';
import IWell, { IWellFilter } from 'ava-saturation/interfaces/well-reference';
import IWaterSaturationProperty from 'ava-saturation/interfaces/water-saturation-property-reference';

export interface IMonikerCollection {
    allMonikers: IMoniker[];
}

export interface IInputDictionary {
    byDimensionKey: {
        [key: string]: IMonikerCollection
    };
}

export default interface IWellModelingContextCollection {
    byMoniker: {
        [key: string]: IInputDictionary;
    };
}

export interface IWellContextCollection {
    wells: IWell[];
    porosityLogs: IWellLog[];
    waterSaturationLogs: IWellLog[];
    faciesLogs: IWellLog[];
    wellPointLogs: IWellLog[];
    wellFilters: IWellFilter[];
    faciesLogFilters: IWellLogFilter[];
    waterSaturationProperties: IWaterSaturationProperty[];

    toPlainObject(): Record<string, any>;
}

export class WellContextCollection implements IWellContextCollection {
    constructor(contextCollection: IWellContextCollection) {
        this.wells = contextCollection.wells;
        this.porosityLogs = contextCollection.porosityLogs;
        this.waterSaturationLogs = contextCollection.waterSaturationLogs;
        this.faciesLogs = contextCollection.faciesLogs;
        this.wellPointLogs = contextCollection.wellPointLogs;
        this.wellFilters = contextCollection.wellFilters;
        this.faciesLogFilters = contextCollection.faciesLogFilters;
        this.waterSaturationProperties = contextCollection.waterSaturationProperties;
    }

    wells: IWell[];
    porosityLogs: IWellLog[];
    waterSaturationLogs: IWellLog[];
    faciesLogs: IWellLog[];
    wellPointLogs: IWellLog[];
    wellFilters: IWellFilter[];
    faciesLogFilters: IWellLogFilter[];
    waterSaturationProperties: IWaterSaturationProperty[];

    toPlainObject() {
        return {
            wells: this.wells,
            porosityLogs: this.porosityLogs.map(log => toPlainObject(log)),
            waterSaturationLogs: this.waterSaturationLogs.map(log => toPlainObject(log)),
            wellPointLogs: this.wellPointLogs.map(log => toPlainObject(log))
        };
    }
}

export class WellModelingContextCollection implements IWellModelingContextCollection {

    constructor(inflatable?: Partial<WellModelingContextCollection>) {
        const inputDictionary = inflatable ? inflatable.byMoniker! : {};

        const copyInputDictionary = Object.keys(inputDictionary).reduce((aggregate: any, key) => {
            aggregate[key] = new InputDictionary(inputDictionary[key]);
            return aggregate;
        }, {});

        this.byMoniker = { ...copyInputDictionary };
    }

    byMoniker: {
        [key: string]: InputDictionary;
    };

    tryAdd(well: IWell) {
        if (this.byMoniker[well.moniker.string])
            return;

        set(this, 'byMoniker', {
            [well.moniker.string]: new InputDictionary(),
            ...this.byMoniker
        });
    }

    delete(well: IWell) {
        delete this.byMoniker[well.moniker.string];
    }

    static inflate(inflatable: Object): WellModelingContextCollection {
        return new WellModelingContextCollection(inflatable);
    }

    deflate(): Object {
        return {
            byMoniker: this.byMoniker
        };
    }
}

export class InputDictionary implements IInputDictionary {
    constructor(dictionary?: InputDictionary) {
        const monikerCollection = dictionary ? dictionary.byDimensionKey : {};
        const copyMonikerCollection = Object.keys(monikerCollection).reduce((aggregate: any, key) => {
            aggregate[key] = new MonikerCollection(monikerCollection[key]);
            return aggregate;

        }, {});

        this.byDimensionKey = dictionary ? { ...copyMonikerCollection } : {};
    }

    byDimensionKey: {
        [key: string]: MonikerCollection
    };

    tryAdd(dimension: IDimension, force: boolean = false): number {
        // @ts-ignore
        const inputDictionary = get(get(this, 'byDimensionKey'), dimension.shortName);
        if (inputDictionary && force === false)
            return inputDictionary.allMonikers.length as number;

        set(this, 'byDimensionKey', {
            ...this.byDimensionKey,
            [dimension.shortName]: new MonikerCollection()
        });

        return 0;
    }

    delete(dimension: IDimension) {
        delete this.byDimensionKey[dimension.shortName];
    }
}

export class MonikerCollection implements IMonikerCollection {
    constructor(collection?: MonikerCollection) {
        // NOTE: In cloned charts thease monikers are POJO
        this.allMonikers = collection ? [...collection.allMonikers] : [];
    }

    allMonikers: IMoniker[];

    tryAdd(wellLogs: IWellLog[]) {
        this.allMonikers.addObjects(wellLogs.map((log: IWellLog) => log.moniker));

        return this.allMonikers.length;
    }

    delete(wellLogs: IWellLog[]) {
        this.allMonikers.removeObjects(wellLogs.map((log: IWellLog) => log.moniker));

        return this.allMonikers.length;
    }
}
