import Component from '@ember/component';
import { action, computed, set } from '@ember/object';
import { allTypes as allBandTypes, allTypesById, BandType } from 'ava-saturation/classes/band-types';
import { availableCalculationMethods } from 'ava-saturation/classes/calculation-types';
import facies from 'ava-saturation/classes/dimensions/facies';
import segment from 'ava-saturation/classes/dimensions/segment';
import zone from 'ava-saturation/classes/dimensions/zone';
import { IWellContextCollection } from 'ava-saturation/classes/well-modeling-context';
import IDimension from 'ava-saturation/interfaces/dimension';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { WellIntersectionFilter } from 'ava-saturation/mixins/providers/grid-dataset-provider';
import { getBands, getRanges } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/selectors';
import { IBand, IRange } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { deleteFunction, updateBandType, updateFunctionName } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/actions';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import isEnterPressed from 'ava-saturation/utils/is-enter-pressed';
import isEscapePressed from 'ava-saturation/utils/is-escape-pressed';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

const stateToComputed = function (this: FunctionModel, state: any) {
    const ranges = getRanges(state, { sid: this.instance.sid, functionId: this.instance.id });
    const bands = getBands(state, { sid: this.instance.sid, functionId: this.instance.id });

    return {
        allRanges: ranges,
        allBands: bands
    };
};

const dispatchToActions = function (this: FunctionModel, dispatch: Dispatch) {
    return {
        toggleEdit(this: FunctionModel) {
            this.toggleProperty('isEdited');
            set(this, 'titlePreview', this.instance.name);
        },
        onRemove(this: FunctionModel) {
            dispatch(deleteFunction(
                this.instance
            ));
        },
        bandTypeChange(this: FunctionModel, type: BandType) {
            dispatch(updateBandType({
                id: this.instance.id,
                sid: this.instance.sid,
                bandType: type.id
            }));
            if (this.onFunctionChange)
                this.onFunctionChange();
        },
        onCompleteEdit(this: FunctionModel) {
            dispatch(updateFunctionName({
                id: this.instance.id,
                sid: this.instance.sid,
                name: this.titlePreview || ''
            }));
            this.toggleProperty('isEdited');
        }
    };
};

export class FunctionModel extends Component {
    // inputs
    instance: ModelFunction;
    areas: ModelArea[];
    gridContext: GridContext;
    wellContext: IWellContextCollection;

    // state
    allBands: IBand[];
    allRanges: IRange[];

    // edit
    isEdited: boolean = false;
    titlePreview: string | null;

    onFunctionChange: () => void; // [ATS] Extend with needed parameters .

    findAreaByType(areas: ModelArea[], type: IDimension) {
        return areas.filter(a => a.type === type.name).find(a => this.instance.areaIds.includes(a.id));
    }

    @action
    calculationMethodChanged() {
        if (this.onFunctionChange)
            this.onFunctionChange();
    }

    @action
    constantChanged() {
        if (this.onFunctionChange)
            this.onFunctionChange();
    }

    @action
    calculationSetChanged() {
        if (this.onFunctionChange)
            this.onFunctionChange();
    }

    @action
    bandChanged() {
        if (this.onFunctionChange)
            this.onFunctionChange();
    }

    @computed('instance')
    get navigationId() {
        return `function_${this.instance.id}`;
    }

    // will need to add one more for the regions when we get there
    // the instace areaIds should never change so no point in observing them
    @computed('areas.[]')
    get zoneArea() {
        return this.findAreaByType(this.areas, zone) as ModelArea;
    }

    @computed('areas.[]')
    get segmentArea() {
        return this.findAreaByType(this.areas, segment);
    }

    @computed('areas.[]')
    get faciesArea() {
        return this.findAreaByType(this.areas, facies);
    }

    @computed('zoneArea', 'gridContext.zones.[]')
    get zoneElements() {
        if (!this.zoneArea)
            return [];

        return this.gridContext.zones.filter(z => this.zoneArea.monikers.includes(z.moniker.string));
    }

    @computed('segmentArea', 'gridContext.segments.[]')
    get segmentElements() {

        if (!this.segmentArea)
            return [];

        const monikers = this.segmentArea.monikers;
        return this.gridContext.segments.filter(s => monikers.includes(s.moniker.string));
    }

    @computed('faciesArea', 'gridContext.faciesCodes.[]')
    get faciesElements() {
        if (!this.faciesArea)
            return [];

        const monikers = this.faciesArea.monikers;
        return this.gridContext.faciesCodes.filter(f => monikers.includes(f.moniker.string));
    }

    @computed('zoneArea.name', 'zoneElements.[]')
    get zoneDisplayName() {
        if (this.zoneArea.name)
            return this.zoneArea.name;

        return this.zoneElements[0].name;
    }

    @computed('segmentArea.name', 'segmentElements.[]')
    get segmentDisplayName() {
        if (!this.segmentArea)
            return null;

        if (this.segmentArea.name)
            return this.segmentArea.name;

        return this.segmentElements[0].name;
    }

    @computed('faciesArea.name', 'faciesElements.[]')
    get faciesDisplayName() {
        if (!this.faciesArea)
            return null;

        if (this.faciesArea.name)
            return this.faciesArea.name;

        return this.faciesElements[0].name;
    }

    @computed('instance.name')
    get displayName() {
        return this.instance.name;
    }

    keyPress(event: Event) {
        if (isEnterPressed(event)) {
            this.actions.onCompleteEdit.call(this);
        }
    }

    keyDown(event: Event) {
        if (isEscapePressed(event) && this.isEdited) {
            this.actions.toggleEdit.call(this);
        }
    }

    @computed('zoneElements', 'segmentElements', 'faciesElements')
    get elementInfo() {
        const names = [
            `<div><span class="layout-font-weight-600">Zones</span>: ${this.zoneElements.map(z => z.name).join(', ')}</div>`,
            this.segmentElements.length === 0 ? null : `<div><span class="layout-font-weight-600">Segments</span>: ${this.segmentElements.map(s => s.name).join(', ')}</div>`,
            this.faciesElements.length === 0 ? null : `<div><span class="layout-font-weight-600">Facies</span>: ${this.faciesElements.map(s => s.name).join(', ')}</div>`,
        ].compact();

        return `${names.join('')}`;
    }

    @computed('gridContext.applicableIntersections.[]', 'zoneArea', 'segmentArea')
    get applicableIntersections() {
        let intersections = this.gridContext.applicableIntersections.filter(i => this.zoneArea.monikers.includes(i.zoneMoniker.string));

        if (this.segmentArea !== undefined) {
            const monikers = this.segmentArea.monikers;
            intersections = intersections.filter(i => monikers.includes(i.segmentMoniker.string));
        }

        return intersections;
    }

    @computed('applicableIntersections.[]')
    get wellFilter(): WellIntersectionFilter {
        return new WellIntersectionFilter(this.applicableIntersections);
    }

    @computed('gridContext', 'applicableIntersections.[]')
    get functionGridContext() {
        const context = new GridContext(this.gridContext);

        context.applicableIntersections = context.intersections = this.applicableIntersections;

        return context;
    }

    @computed('wellFilter', 'wellContext.wells')
    get applicableWells() {
        return this.wellContext.wells.filter(w => this.wellFilter.predicate(w));
    }

    @computed('instance.bandType')
    get bandType(): BandType {
        return allTypesById[this.instance.bandType];
    }

    @computed('bandType')
    get availableCalculationMethods() {
        return availableCalculationMethods(this.bandType.id);
    }

    @computed('availableCalculationMethods.[]')
    get calculationMethodsDisplay() {
        // return `${this.availableCalculationMethods.map(m => `<div class="layout-font-weight-600">${m.name} method</div>`).join('')}`;
        return this.availableCalculationMethods;
    }

    @computed('allBands.[]', 'bandType')
    get bands(): Array<IBand> {
        return this.allBands.filter(b => b.type === this.bandType.id && b.functionId === this.instance.id);
    }

    @computed('allRanges.[]', 'bandType')
    get ranges(): Array<IRange> {
        return this.allRanges.filter(r => r.bandType === this.bandType.id && r.functionId === this.instance.id);
    }

    @computed('gridContext.{porosityProperty,permeabilityProperty}')
    get availableBandTypes(): BandType[] {
        let result: BandType[] = [allBandTypes.general];
        if (this.gridContext.porosityProperty) {
            result.push(allBandTypes.porosity);
        }
        if (this.gridContext.permeabilityProperty) {
            result.push(allBandTypes.permeability);
        }
        return result;
    }

    @computed('gridContext.{porosityStatistics,permeabilityStatistics}', 'bandType')
    get propertyStatistics() {
        switch (this.bandType) {
            case allBandTypes.porosity: {
                const porosityStatistics = this.gridContext.porosityStatistics;
                return { min: Number(porosityStatistics.min), max: Number(porosityStatistics.max), editable: true };
            }
            case allBandTypes.permeability: {
                const permeabilityStatistics = this.gridContext.permeabilityStatistics;
                return { min: Number(permeabilityStatistics.min), max: Number(permeabilityStatistics.max), editable: true };
            }
            default:
            case allBandTypes.general: {
                return { min: NaN, max: NaN, editable: false };
            }
        }
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionModel);
