export default function () {
    this.transition(
        this.toValue((toValue, fromValue) => {
            return toValue.tab > fromValue.tab;
        }),
        this.use('toLeft'),
        this.reverse('toRight')
    );
    this.transition(
        this.hasClass('cross-fade'),
        this.use('fade', {duration: 'fast'})
    );
    this.transition(
        this.hasClass('collapse-expand'),
        this.use('toUp'),
    );
}