import Ember from 'ember';
import { inject } from '@ember/service';
import Notify from 'ember-notify';

const { Logger } = Ember;

export default Notify.extend({
    intl: inject(),

    logAndAlert(error, translationKey, translationKeyParams) {
        if (error) {
            Logger.error(error);
        }
        
        this.alert(this.intl.t(translationKey, translationKeyParams), {
            closeAfter: 20000
        });
    }
});