import IWell from 'ava-saturation/interfaces/well-reference';
import { referenceCollectionParser, ReferenceParser, SingleReferenceParser } from '../interfaces/reference-parser';
import ReferenceObjectType from './reference-object-type';

export default class WellReferenceType extends ReferenceObjectType {
    readonly id = 200;
    readonly name = 'well';
    readonly typeAliases = ['PetrelBorehole'];
    subTypeAliases = [];
}

export const wellReferenceType = new WellReferenceType();

export class WellReferenceParser extends SingleReferenceParser<IWell> {
    parse(object: any): IWell {
        let reference = super.parse(object);
        const identifierSelector = (object: any) => object._instance;
        return {
            ...reference,
            trajectoryMoniker: this.parseMoniker(object.TrajectoryId, identifierSelector),
            type: wellReferenceType,
        };
    }
}

export class WellCollectionReferenceParser extends ReferenceParser<Array<IWell>> {
    parse(object: any): Array<IWell> {
        return referenceCollectionParser<IWell>(object, new WellReferenceParser(this.origin, this.version));
    }
}
