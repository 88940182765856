/* istanbul ignore file */
// GK: It is a one line straightforward code.a Writing explicite tests will be overkill.
import { helper } from '@ember/component/helper';

export default helper(function (index) {
    const A = 'A'.charCodeAt(0);
    let remainder = +index;
    let columnIndex = '';

    while (remainder >= 0) {
        columnIndex = String.fromCharCode(A + remainder % 26) + columnIndex;
        remainder = Math.floor(remainder / 26) - 1;
    }

    return columnIndex;
});