import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { getOwner } from '@ember/application';

import RSVP from 'rsvp';

import isValidNumber from 'ava-saturation/utils/is-valid-number';
import isNumbernNegative from 'ava-saturation/utils/is-number-negative';
import IWell, { IWellFilter } from 'ava-saturation/interfaces/well-reference';
import ObjectPromiseProxy from 'ava-saturation/classes/object-promise-proxy';
import IIntersection from 'ava-saturation/interfaces/intersection-reference';
import IContactDepth from 'ava-saturation/interfaces/contact-depth-reference';

export default Mixin.create({
    datasetStore: computed({
        get() {
            return getOwner(this).lookup('service:dataset-store');
        }
    }),

    // context
    grid: null,
    contact: null,
    faciesProperty: null,
    permeabilityProperty: null,
    porosityProperty: null,

    asyncFilteredIntersections: computed('grid', function () {
        return this.datasetStore.getIntersections(this.grid.moniker);
    }),

    asyncFilteredContactDepths: computed('grid', 'contact', function () {
        if (this.contact)
            return this.datasetStore.getContactDepths(this.grid.moniker, this.contact.moniker);

        return ObjectPromiseProxy.createPromise(new RSVP.Promise(resolve => resolve([])));
    }),

    asyncFilteredFaciesCodes: computed('grid', 'faciesProperty', function () {
        if (this.faciesProperty)
            return this.datasetStore.getFaciesCodes(this.grid.moniker, this.faciesProperty.moniker, this.faciesProperty.templateMoniker);

        return ObjectPromiseProxy.createPromise(new RSVP.Promise(resolve => resolve([])));
    }),

    asyncPermeabilityPropertyStatistics: computed('grid', 'permeabilityProperty', function () {
        if (this.permeabilityProperty)
            return this.datasetStore.getPropertyStatistics(this.grid.moniker, this.permeabilityProperty.moniker);

        return ObjectPromiseProxy.createPromise(new RSVP.Promise(resolve => resolve({})));
    }),

    asyncPorosityPropertyStatistics: computed('grid', 'porosityProperty', function () {
        if (this.porosityProperty)
            return this.datasetStore.getPropertyStatistics(this.grid.moniker, this.porosityProperty.moniker);

        return ObjectPromiseProxy.createPromise(new RSVP.Promise(resolve => resolve({})));
    }),

    isLoading: computed(
        'asyncFilteredIntersections.isPending',
        'asyncFilteredContactDepths.isPending',
        'asyncFilteredFaciesCodes.isPending',
        'asyncPermeabilityPropertyStatistics.isPending',
        'asyncPorosityPropertyStatistics.isPending',
        'wellFilter.isPending', function () {
        return this.asyncFilteredIntersections.isPending ||
            this.asyncFilteredContactDepths.isPending ||
            this.asyncFilteredFaciesCodes.isPending ||
            this.asyncPermeabilityPropertyStatistics.isPending ||
            this.asyncPorosityPropertyStatistics.isPending ||
            this.wellFilter.isPending;
    }),

    contactDetails: computed('asyncFilteredContactDepths.content.[]', function () {
        return ObjectPromiseProxy.createPromise(
            this.asyncFilteredContactDepths.then((contactDepths: IContactDepth[]) => {

                const numericContactDepths = contactDepths.filter((cd: IContactDepth) => {
                    return isValidNumber(cd.depth) && cd.depth !== null;
                });

                // valid depths
                const negativeContactDepthNumbers = numericContactDepths.filter((cd: IContactDepth) => {
                    return isNumbernNegative(cd.depth);
                });

                let wronglyDefinedDepthsCount = numericContactDepths.length - negativeContactDepthNumbers.length;
                let emptyDepthsCount = contactDepths.length - numericContactDepths.length;

                return {
                    usableDepthsCount: negativeContactDepthNumbers.length,
                    wronglyDefinedDepthsCount: wronglyDefinedDepthsCount,
                    emptyDepthsCount: emptyDepthsCount
                };
            })
        );
    }),

    asyncApplicableIntersections: computed('asyncFilteredIntersections.content.[]', 'asyncFilteredContactDepths.content.[]', function () {
        let promiseHash = {
            intersections: this.asyncFilteredIntersections,
            contactDepths: this.asyncFilteredContactDepths
        };

        return ObjectPromiseProxy.createPromise(
            RSVP.hash(promiseHash)
                .then(({ intersections, contactDepths }: { intersections: IIntersection[], contactDepths: IContactDepth[] }) => {
                    const applicableIntersections = intersections.filter((i: IIntersection) => {
                        return contactDepths.find((cd: IContactDepth) =>
                            cd.segmentMoniker.string === i.segmentMoniker.string &&
                            cd.zoneMoniker.string === i.zoneMoniker.string);
                    });

                    return applicableIntersections;
                })
        );
    }),

    wellFilter: computed('asyncApplicableIntersections.content.[]', function () {
        return ObjectPromiseProxy.createPromise(
            this.asyncApplicableIntersections
                .then((intersections: IIntersection[]) => {
                    return new WellIntersectionFilter(intersections);
                })
        );
    })
});

export class WellIntersectionFilter implements IWellFilter {
    private _intersections: IIntersection[];

    constructor(intersections: IIntersection[]) {
        this._intersections = intersections;
    }

    predicate(well: IWell): boolean {
        return this._intersections.find(i => i.wellMoniker.string === well.moniker.string) !== undefined;
    }
}
