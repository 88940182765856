import IDataset from 'ava-saturation/interfaces/dataset';

export default interface IDatasetParser<TValue, TDataset extends IDataset<TValue>> {
    parse(samples: Array<Record<string, any>>): TDataset;
}

export abstract class DatasetParserBase<TValue, TDataset extends IDataset<TValue>> implements IDatasetParser<TValue, TDataset> {
    private _datasetCreator: () => TDataset;

    constructor(datasetCreator: () => TDataset) {
        this._datasetCreator = datasetCreator;
    }

    parse(samples: Array<Record<string, any>>): TDataset {
        const dataset = this._datasetCreator();
        dataset.values = samples.map(this.parseSample);

        return dataset;
    }

    protected abstract parseSample(sample: Record<string, any>): TValue;
}