export default {
    time: {
        hhmmss: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    },
    date: {
        hhmmss: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        },
        short: {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        },
        long: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        },
        'short-hhmmss': {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    },
    number: {
        EUR: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        },
        USD: {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    }
};
