import { computed } from '@ember/object';
import Component from '@ember/component';
import facies from 'ava-saturation/classes/dimensions/facies';
import segment from 'ava-saturation/classes/dimensions/segment';
import IDimension from 'ava-saturation/interfaces/dimension';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { Dispatch } from 'redux';

import { cuddy, leverettJ } from 'ava-saturation/classes/calculation-types';
import { getCalculationSetsBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/selectors';
import { CalculationSet } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/types';
import { connect } from 'ember-redux';
import { uniqBy } from 'lodash';

export const stateToComputed = function (this: FunctionItem, _state: any) {
    const calculationSetsBySid = getCalculationSetsBySid(_state, {
        sid: this.function.sid
    });
    return {
        calculationSetsBySid
    };
};

export const dispatchToActions = function (this: FunctionItem, _dispatch: Dispatch) {
    return {
    };
};

class FunctionItem extends Component {
    // inputs
    gridContext: GridContext;

    function: ModelFunction;
    bands: IBand[];
    constants: CalculationConstant[];
    parameters: CalculationParameter[];
    areas: ModelArea[];

    // state
    calculationSetsBySid: CalculationSet[];

    onFunctionSelected: (area: ModelArea, modelFunction: ModelFunction) => void;

    private findAreaByType(areas: ModelArea[], type: IDimension) {
        return areas.filter(a => a.type === type.name).find(a => this.function.areaIds.includes(a.id));
    }

    // copied exactly the same from function-model.ts
    @computed('areas.[]')
    get segmentArea() {
        return this.findAreaByType(this.areas, segment);
    }

    @computed('areas.[]')
    get faciesArea() {
        return this.findAreaByType(this.areas, facies);
    }

    @computed('segmentArea', 'gridContext.segments.[]')
    get segmentElements() {
        if (!this.segmentArea)
            return [];

        return this.gridContext.segments.filter(s => this.segmentArea!.monikers.includes(s.moniker.string));
    }

    @computed('faciesArea', 'gridContext.faciesCodes.[]')
    get faciesElements() {
        if (!this.faciesArea)
            return [];

        return this.gridContext.faciesCodes.filter(f => this.faciesArea!.monikers.includes(f.moniker.string));
    }

    @computed('segmentArea.name', 'segmentElements.[]')
    get segmentDisplayName() {
        if (!this.segmentArea)
            return null;

        if (this.segmentArea.name)
            return this.segmentArea.name;

        return this.segmentElements[0].name;
    }

    @computed('faciesArea.name', 'faciesElements.[]')
    get faciesDisplayName() {
        if (!this.faciesArea)
            return null;

        if (this.faciesArea.name)
            return this.faciesArea.name;

        return this.faciesElements[0].name;
    }

    @computed('segmentDisplayName', 'faciesDisplayName')
    get displayName() {
        const names = [
            this.segmentDisplayName,
            this.faciesDisplayName
        ].compact();

        return names.length !== 0 ? `${names.join(' - ')}` : 'Standalone';
    }

    @computed('function.name')
    get userGivenName() {
        return this.function.name;
    }

    @computed('bands.[]')
    get relatedBands() {
        return this.bands.filter(b => b.functionId === this.function.id && b.type === this.function.bandType);
    }

    @computed('relatedBands.[]')
    get bandIds() {
        return this.relatedBands.map(b => b.id);
    }

    @computed('bandIds.[]', 'constants.[]')
    get bandConstants() {
        return this.constants.filter(c => this.bandIds.includes(c.bandId));
    }

    @computed('bandIds.[]', 'parameters.[]')
    get bandParameters() {
        return this.parameters.filter(c => this.bandIds.includes(c.bandId));
    }

    @computed('bandConstants.[]', 'validationMessages')
    get isValid() {
        return this.bandConstants.length === this.bandIds.length && this.bandConstants.every(c =>
            Object.keys(c.constants).length > 0 &&
            Object.keys(c.constants).reduce((result, key) => result && isNaN(Number(c.constants[key])) === false, true) &&
            this.validationMessages.length === 0
        );
    }

    @computed('relatedBands.[]', 'gridContext.porosityProperty', 'bandConstants.[]', 'calculationSetsBySid')
    get validationMessages() {
        let messages: string[] = [];
        if (this.relatedBands.any(b => b.calculationType === cuddy.id) && !this.gridContext.porosityProperty) {
            messages.push('To apply this function select Porosity property first or change the calculation method');
        }

        let calculationSetsPerFn = this.calculationSetsBySid.filter(cs => cs.functionId === this.function.id && this.bandIds.includes(cs.bandId));
        const enabledInputsCount = uniqBy(calculationSetsPerFn, c => c.bandId).length;

        if (enabledInputsCount !== this.relatedBands.length) {
            messages.push('To apply this function turn on Inputs for each band');
        }

        const leverettJBandIds = this.relatedBands.filter(b => b.calculationType === leverettJ.id).map(b => b.id);

        if (leverettJBandIds.length !== 0 && (!this.gridContext.porosityProperty || !this.gridContext.permeabilityProperty)) {
            messages.push('To apply this function select Porosity and Permeability properties first or change the calculation method');
        }

        const leverettJBandParameters = this.bandParameters.filter(p => leverettJBandIds.includes(p.bandId));
        const parameterValidationPredicate = (parameterDictionary: Record<string, number | undefined>) =>
            Object.keys(parameterDictionary).every(key => isNaN(Number(parameterDictionary[key])) === false);

        if (leverettJBandIds.length !== 0 && (leverettJBandParameters.length === 0 || leverettJBandParameters.every(p => parameterValidationPredicate(p.byDimensionKey)) === false)) {
            messages.push('To apply this function enter the appropriate values in the Mapping Parameters for each band');
        }

        const areConstantsAccepted = this.bandConstants.every(bc => Object.keys(bc.constants).length !== 0);
        if (!areConstantsAccepted) {
            messages.push('To apply this function accept constants first');
        }

        return messages;
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionItem);
