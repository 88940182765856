import { helper } from '@ember/component/helper';
import { isBlank } from '@ember/utils';

import _ from 'lodash';

export function findGridByMoniker(params) {
    let [gridModels, monikerString] = params;

    if (isBlank(monikerString)) {
        return undefined;
    }

    if (isBlank(gridModels)) {
        return undefined;
    }

    let result =  _.find(gridModels, gm => {
        return gm.grid && gm.grid.moniker && gm.grid.moniker.string === monikerString;
    });
    return result;
}

export default helper(findGridByMoniker);