import { createCustomAction } from 'typesafe-actions';
import constants from './constants';
import { Model } from './types';

export const addModel = createCustomAction(constants.ADD, type => {
    return (model: Model) => ({ type, payload: model, recordable: true });
});

export const setModel = createCustomAction(constants.SET_MODEL, type => {
    return (model: Model) => ({ type, payload: model, recordable: true, trackable: true });
});

export const deleteModel = createCustomAction(constants.DELETE, type => {
    return (model: Model) => ({ type, payload: model, recordable: true });
});
