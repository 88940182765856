import Component from '@ember/component';
import { getOwner } from '@ember/application';

export default Component.extend({
    classNames: ['ava-full-page-message', 'layout', 'layout-align-center-center', 'flex'],
    actions: {
        transitionTo(route) {
            getOwner(this).lookup('service:router').transitionTo(route);
        }
    }
});