import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import contactDepth from 'ava-saturation/classes/dimensions/contact-depth';
import {
    HAFWLCalculator,
    dependencies as calculatorDependencies
} from 'ava-saturation/classes/calculations/dependency-injection/calculators/hafwl-calculator-pure';
import DatasetCalculator from 'ava-saturation/classes/calculations/dependency-injection/calculators/dataset-calculator-pure';


export const dependencies = {
    functions: {
        ...calculatorDependencies.functions,
        HAFWLCalculator,
        DatasetCalculator
    },
    dimensionsIndex: {
        ...calculatorDependencies.dimensionsIndex,
        tvdss,
        hafwl,
        contactDepth
    }
};

export default function HAFWLDatasetCalculator(deps) {
    this.datasetCalculator = new deps.functions.DatasetCalculator(
        deps.dimensionsIndex.hafwl,
        [deps.dimensionsIndex.contactDepth, deps.dimensionsIndex.tvdss],
        new deps.functions.HAFWLCalculator(deps)
    );

    return {
        producedDimension: this.datasetCalculator.producedDimension,
        datasetCalculator: this.datasetCalculator,
        meetsExpectations: function (dimensions) {
            return this.datasetCalculator.meetsExpectations(dimensions);
        },
        apply: function (value, dataset) {
            this.datasetCalculator.apply(value, dataset);
        }
    };
}