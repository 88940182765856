import zone from 'ava-saturation/classes/dimensions/zone';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import segment from 'ava-saturation/classes/dimensions/segment';
import contactDepth from 'ava-saturation/classes/dimensions/contact-depth';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import DatasetExtender from 'ava-saturation/classes/calculations/dependency-injection/extenders/dataset-extender-pure';

export const dependencies = {
    functions: {
        isInRange,
        DatasetExtender
    },
    dimensionsIndex: {
        tvdss,
        contactDepth,
        zone,
        segment
    }
};

export default function ContactDepthDatasetExtender(deps, intersections, contactDepths, bypassContactValidation) {
    this.datasetExtender = new deps.functions.DatasetExtender(
        deps.dimensionsIndex.contactDepth.shortName,
        [deps.dimensionsIndex.tvdss],
    );

    return {
        name: this.datasetExtender.name,
        datasetExtender: this.datasetExtender,
        isInRange: deps.functions.isInRange,
        dimensionsIndex: deps.dimensionsIndex,
        _intersections: intersections,
        _contactDepths: contactDepths,
        _bypassContactValidation: bypassContactValidation,
        meetsDimensionExpectations: function (dimensions) {
            return this.datasetExtender.meetsDimensionExpectations(dimensions);
        },
        isApplicable: function () {
            return this._intersections.length !== 0 && this._contactDepths !== 0;
        },
        findExtendingRecord: function (value, dataset) {
            if (!dataset)
                return null;

            const targetIntersection = this._intersections.find(i =>
                i.wellMoniker.string === dataset.wellMoniker.string &&
                this.isInRange(i.startDepth, i.endDepth, value.tvdss)
            );

            if (!targetIntersection)
                return null;

            const targetContact = this._contactDepths ? this._contactDepths.find(cd =>
                isNaN(Number(cd.depth)) == false &&
                cd.zoneMoniker.string === targetIntersection.zoneMoniker.string &&
                cd.segmentMoniker.string === targetIntersection.segmentMoniker.string
            ) : undefined;

            if (this._bypassContactValidation || !this._contactDepths) {
                if (targetIntersection.endDepth == null || this.isInRange(targetIntersection.startDepth, targetIntersection.endDepth, value.tvdss) === false) {
                    return null;
                }
            } else {
                if (!targetContact || this.isInRange(targetIntersection.startDepth, targetContact.depth, value.tvdss) === false) {
                    return null;
                }
            }

            const target = Object.keys(targetIntersection).reduce((aggregate, key) => {
                aggregate[key] = targetIntersection[key];

                return aggregate;
            }, {});

            if (!this._bypassContactValidation && this._contactDepths) {
                Object.keys(targetContact).forEach(key => target[key] = targetContact[key]);
            }

            return target;
        },
        applyExtendingRecord: function (value, extendingRecord, dataset) {
            if (!this._bypassContactValidation || isNaN(Number(extendingRecord.depth)) == false) {
                value[this.dimensionsIndex.contactDepth.shortName] = extendingRecord.depth;

                const isDatasetUpdated = dataset.dimensions.indexOf(this.dimensionsIndex.contactDepth) != -1;
                const action = isDatasetUpdated ? () => { } : dataset.dimensions.push;

                action.call(dataset.dimensions, this.dimensionsIndex.contactDepth);
            }

            //metadata
            value[this.dimensionsIndex.zone.shortName] = extendingRecord.zoneName;
            value[this.dimensionsIndex.segment.shortName] = extendingRecord.segmentName;
        },
        extend: function (value, dataset) {
            if (this.datasetExtender.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }
    };
}
