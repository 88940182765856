import { action, computed } from '@ember/object';
import Component from '@ember/component';
import { FaciesLogContextFilter } from 'ava-saturation/classes/facies-log-reference-type';

export default class ConceptChartExplorer extends Component {
    isExpanded = false;
    documentInputs: any;

    @computed('documentInputs')
    get faciesLogFilter(): FaciesLogContextFilter {
        return new FaciesLogContextFilter(this.documentInputs.facies.reference);
    }

    @action
    toggle() {
        this.toggleProperty('isExpanded');
    }
}
