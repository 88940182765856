import { createSelector, ParametricSelector } from 'reselect';
import { CalculationConstant } from './types';
export interface BasicSearchProps {
    sid: string;
}

export interface SearchProps extends BasicSearchProps {
}

const getCalculationConstantsBySid: ParametricSelector<any, BasicSearchProps, CalculationConstant[]> = (state: any, props: BasicSearchProps) =>
    state.entitiesV1.saturationConceptState.calculationConstant.bySid[props.sid];

// [NV]: This is handled in the component where it's used using a computed property to monitor for changes it can be disposed.
export const getCalculationConstants: ParametricSelector<any, SearchProps, CalculationConstant[]> = createSelector([getCalculationConstantsBySid], (constants) => constants);