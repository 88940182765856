import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import IProject from 'ava-saturation/interfaces/project-reference';

export default class DataSourceProvider extends Component {
    @service objectStore: IObjectStore;

    @computed('objectStore.availableStores.[]', 'objectStore.availableStores.@each.id')
    get availableStoreNames(this: any): string[] {
        return this.objectStore.availableStores.map((s: IObjectStore) => s.id);
    }

    @computed('objectStore.availableStores.[]', 'objectStore.availableStores.@each.displayName')
    get availableStoreDisplayNames(this: any): string[] {
        return this.objectStore.availableStores.map((s: IObjectStore) => s.displayName);
    }

    @computed('objectStore.project.isFulfilled')
    get projectName(this: any) {
        return this.objectStore.project.next((p: IProject) => {
            return p.name;
        });
    }
}