import Component from '@ember/component';
import { action } from '@ember/object';
import IZone from 'ava-saturation/interfaces/zone-reference';
import ISegment from 'ava-saturation/interfaces/segment-reference';

export default class FilterItem extends Component {
    filter: IZone | ISegment;
    isSelected: boolean;

    onFilterAdd: (filter: IZone | ISegment, filterType: string) => void;

    @action
    onFilterSelected(filter: IZone | ISegment, filterType: string) {
        this.onFilterAdd(filter, filterType);
    }
}
