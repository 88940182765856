import { computed, set } from '@ember/object';
import { NumericDimension } from 'ava-saturation/classes/dimension';
import { dimensionIndexLookup } from 'ava-saturation/classes/dimensions/all';
import IDimension, { DimensionType, NumericDimensionCategory } from 'ava-saturation/interfaces/dimension';
import { AbstractAxisOption, ContinuousNumericScaleType, IAxisOptionSettings, IAxisSettings, INumericAxisOptionSettings, IScalableAxisOptionSettings } from 'ava-saturation/interfaces/plot';
import { IGroup, IReferenceGroup } from 'ava-saturation/interfaces/presenter';
import IReference from 'ava-saturation/interfaces/reference';
import { NumericAxisScaleResolver } from 'ava-saturation/classes/charting/numeric-axis-scale-resolver'

export class AxisSettings implements IAxisSettings {
    options: AbstractAxisOption[] = [];
    selectedOption: AbstractAxisOption | undefined;

    constructor(inflatable?: Partial<AxisSettings>) {
        if (inflatable) {
            // selectedOption must be able to handle undefined
            set(this, 'selectedOption', AxisOption.inflate(inflatable.selectedOption!));
        }
    }

    @computed('option')
    get title(): string {
        if (this.option)
            return this.option.dimension!.displayName;

        return '';
    }

    @computed('selectedOption')
    get option(): AbstractAxisOption | undefined {
        return this.selectedOption;
    }

    static inflate(inflatable: Partial<AxisSettings>): AxisSettings {
        return new AxisSettings(inflatable);
    }

    deflate(): Object {
        return {
            selectedOption: this.selectedOption ? this.selectedOption.deflate() : undefined
        };
    }
}

export class GroupableAxisSettings extends AxisSettings {
    groups: IGroup<IReference>[];

    constructor(inflatable?: Partial<GroupableAxisSettings>) {
        super(...arguments);

        if (inflatable) {
            set(this, 'selectedOption', ReferenceGroupAxisOption.inflate(inflatable.selectedOption!));
        }
    }

    @computed('options.[]', 'selectedOption')
    get option(): AbstractAxisOption | undefined {

        let option = (this.options || []).find(o => this.selectedOption && o.dimension.name === this.selectedOption.dimension.name);

        if (!option && this.options.length > 0) option = this.options[0];

        return option;
    }

    static inflate(inflatable: Object): GroupableAxisSettings {
        return new GroupableAxisSettings(inflatable);
    }
}

export class AxisOption extends AbstractAxisOption {
    /**
     *
     */
    constructor(inflatable?: Partial<AxisOption>) {
        super();

        if (inflatable) {
            set(this, 'dimension', dimensionIndexLookup(inflatable.dimension!.shortName));
            set(this, 'settings', axisOptionSettingsFactory(inflatable.settings!));
        }
    }

    dimension: IDimension;
    settings: IAxisOptionSettings;

    static inflate(inflatable: Object): AxisOption | undefined {
        return inflatable ? new AxisOption(inflatable) : undefined;
    }

    deflate(): Object {
        return {
            dimension: { shortName: this.dimension.shortName },
            settings: this.settings.deflate()
        };
    }
}

export class ReferenceGroupAxisOption extends AxisOption {
    /**
     *
     */
    constructor(inflatable?: Partial<ReferenceGroupAxisOption>) {
        super(inflatable);
    }

    values: IReference[];
    selectedValues: IReference[];

    updateWith(option: ReferenceGroupAxisOption) {
        if (!this.values)
            set(this, 'values', []);

        if (!this.selectedValues)
            set(this, 'selectedValues', []);

        if (this.values.reject(v => option.values.indexOf(v) !== -1).length !== 0)
            this.values.setObjects(option.values);

        if (this.selectedValues.reject(v => option.selectedValues.indexOf(v) !== -1).length !== 0)
            this.selectedValues.setObjects(option.selectedValues);
    }

    static inflate(o: Object): ReferenceGroupAxisOption | undefined {
        return o ? new ReferenceGroupAxisOption(o) : undefined;
    }

    deflate(): Object {
        return super.deflate();
    }
}

export enum AxisOptionSettingsType {
    Categorial = 'categorial-axis-settings',
    Groupable = 'groupable-axis-settings',
    Numeric = 'numeric-axis-settings',
    NumericScalable = 'numeric-scalable-axis-settings',
}

export class NumericAxisOptionSettings implements INumericAxisOptionSettings {
    name: string = AxisOptionSettingsType.Numeric;

    _min: string;
    _max: string;

    scaleResolver: NumericAxisScaleResolver;

    /**
     *
     */
    constructor(inflatable?: Partial<NumericAxisOptionSettings>) {

        if (inflatable) {
            if (inflatable.min) {
                set(this, 'min', inflatable.min.toString());
            } else {
                set(this, 'min', '');
            }
            if (inflatable.max) {
                set(this, 'max', inflatable.max.toString());
            } else {
                set(this, 'max', '');
            }
        } else {
            set(this, 'min', '');
            set(this, 'max', '');
        }

        this.scaleResolver = new NumericAxisScaleResolver();
    }

    @computed()
    get min(): string {
        return this._min;
    }
    set min(value: string) {
        this._min = value;
        this.validateRange();
    }

    @computed()
    get max(): string {
        return this._max;
    }
    set max(value: string) {
        this._max = value;
        this.validateRange();
    }

    validateRange() {
        if (this.scaleResolver) {
            let calculatedMin = this._min != '' ? Number(this._min) : NaN;
            if (isNaN(calculatedMin)) calculatedMin = this.scaleResolver.minData;
            let calculatedMax = this._max != '' ? Number(this._max) : NaN;
            if (isNaN(calculatedMax)) calculatedMax = this.scaleResolver.maxData;
            let isValid = calculatedMin <= calculatedMax;
            if (isValid && this.scaleResolver.scaleType == ContinuousNumericScaleType.Logarithmic) {
                isValid = (calculatedMax + calculatedMin) > calculatedMax; // should not be positive and negative
            }
            if (isValid) {
                const min = this._min != '' ? Number(this._min) : NaN;
                set(this.scaleResolver, 'minUserDefined', min);
                const max = this._max != '' ? Number(this._max) : NaN;
                set(this.scaleResolver, 'maxUserDefined', max);
            } else {
                set(this.scaleResolver, 'minUserDefined', NaN);
                set(this.scaleResolver, 'maxUserDefined', NaN);
            }
        }
    }

    deflate(): Object {
        return {
            name: this.name,
            min: this.min,
            max: this.max
        };
    }
}

export class NumericScalableAxisOptionSettings extends NumericAxisOptionSettings implements IScalableAxisOptionSettings {
    name: string = AxisOptionSettingsType.NumericScalable;

    _scale: ContinuousNumericScaleType = ContinuousNumericScaleType.Logarithmic;

    constructor(inflatable?: Partial<NumericScalableAxisOptionSettings>) {
        super(inflatable);

        if (inflatable) {
            set(this, 'scale', inflatable.scale!);
        }
    }

    get scales() {
        let scales = {};

        for (var scale in ContinuousNumericScaleType) {
            if (typeof ContinuousNumericScaleType[scale] !== 'number') {
                scales = { [scale]: ContinuousNumericScaleType[scale], ...scales };
            }
        }

        return scales;
    }

    deflate(): Object {
        const inflatable = super.deflate();
        // @ts-ignore
        inflatable.name = this.name;
        // @ts-ignore
        inflatable.scale = this.scale;

        return inflatable;
    }

    get scale(): ContinuousNumericScaleType {
        return this._scale;
    }
    set scale(value: ContinuousNumericScaleType) {
        this._scale = value;
        if (this.scaleResolver)
            set(this.scaleResolver, 'scaleType', this._scale);
    }
}

export class CategorialAxisOptionSettings implements IAxisOptionSettings {
    readonly name: string = AxisOptionSettingsType.Categorial;
    binCount: number;

    /**
     *
     */
    constructor(inflatable?: Partial<CategorialAxisOptionSettings>) {
        set(this, 'binCount', inflatable ? inflatable.binCount! : 10);
    }

    deflate(): Partial<CategorialAxisOptionSettings> {
        return {
            name: this.name,
            binCount: this.binCount
        };
    }
}

export class GroupableAxisOptionSettings implements IAxisOptionSettings {
    readonly name: string = AxisOptionSettingsType.Groupable;

    deflate(): Partial<GroupableAxisOptionSettings> {
        return {
            name: this.name
        };
    }
}

export function axisOptionSettingsFactory(inflatable: Partial<IAxisOptionSettings>) {
    switch (inflatable.name) {
        case AxisOptionSettingsType.Numeric:
            return new NumericAxisOptionSettings(inflatable);

        case AxisOptionSettingsType.NumericScalable:
            return new NumericScalableAxisOptionSettings(inflatable);

        case AxisOptionSettingsType.Categorial:
            return new CategorialAxisOptionSettings(inflatable);

        case AxisOptionSettingsType.Groupable:
            return new GroupableAxisOptionSettings();

        default:
            throw new TypeError('Unknown axis option settings: ' + inflatable.name);
    }
}

export function fromDimension(dimension: IDimension, asCategorial: boolean = false): AxisOption {
    const axisOption = new AxisOption();
    axisOption.dimension = dimension;
    // will resolve additionally the scaling type based on the domain of the dimension if its numeric
    if (dimension.type === DimensionType.Categorical || asCategorial) {
        const categorial = new CategorialAxisOptionSettings();
        categorial.binCount = 10;
        axisOption.settings = categorial;

        return axisOption;
    }

    const numericDimension = dimension as NumericDimension;

    if (numericDimension.category === NumericDimensionCategory.Discrete)
        axisOption.settings = new NumericAxisOptionSettings();

    if (numericDimension.category === NumericDimensionCategory.Continuous) {
        const settings = new NumericScalableAxisOptionSettings();
        // default scales set to Linear.
        // code preserved for future reference.
        // const dimensionRange = (dimension as ContinuosNumericDimension)
        //    .domain(ContinuousNumericScaleType.Linear, [], x => NaN);

        // const range = Math.abs(dimensionRange[0] - dimensionRange[1]);
        // settings.scale = range <= 1 ? ContinuousNumericScaleType.Logarithmic : ContinuousNumericScaleType.Linear;
        settings.scale = numericDimension.scaleType;
        axisOption.settings = settings;
    }

    return axisOption;
}

export function fromGroup(group: IReferenceGroup): ReferenceGroupAxisOption {
    const axisOption = new ReferenceGroupAxisOption();
    axisOption.dimension = group.dimension;
    axisOption.values = group.values;
    axisOption.selectedValues = group.selectedValues;
    axisOption.settings = new GroupableAxisOptionSettings();

    return axisOption;
}
