import d3 from 'd3';
import Service from '@ember/service';
import { Promise } from 'rsvp';
import { later, cancel } from '@ember/runloop';
import { isNone } from '@ember/utils';
import signalR from 'signalr';
import configuration from 'ava-saturation/config/environment';

export default class Socket extends Service {
    transport = signalR.HttpTransportType.WebSockets;
    connection;

    get isConnected() {
        return this.get('connection.connection.connectionState') === 1;
    }

    connect(options) {
        if (isNone(this.connection)) {
            const queryString = d3.entries(options)
                .map((x) => encodeURIComponent(x.key) + '=' + encodeURIComponent(x.value))
                .join('&');

            this.set('connection',
                new signalR.HubConnectionBuilder()
                    .withUrl(`/${configuration.APP.apiNamespace}/trackingHub?${queryString}`, this.transport)
                    .configureLogging(signalR.LogLevel.None)
                    .build());
        }

        return new Promise((resolve, reject) => {
            const rejectTimer = later(() => {
                this.disconnect();
                reject({ error: 'connection_timeout' });
            }, 30000);

            this.connection
                .on('OnConnect', response => {
                    if (!response || !response.success) {
                        this.disconnect();
                        return reject(response);
                    }
                    else {
                        cancel(rejectTimer);
                        resolve(response);
                    }
                });

            this.connection
                .start()
                .catch(reject);
        });
    }

    disconnect() {
        return this.connection
            .stop();
    }

    invoke() {
        return this.connection
            .invoke(...arguments);
    }
}
