import Component from '@ember/component';
import { action, computed, defineProperty, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { contactAngleLab, contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import { iftLab, iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { gasGradient, oilGradient, waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import contactDepth from 'ava-saturation/classes/dimensions/contact-depth';
import facies from 'ava-saturation/classes/dimensions/facies';
import { AxisSettings, GroupableAxisSettings, NumericAxisOptionSettings, NumericScalableAxisOptionSettings, ReferenceGroupAxisOption } from 'ava-saturation/classes/widgets/axis';
import SettingsFactory from 'ava-saturation/classes/widgets/plot';
import Plot, { LegendSettings, PlotSettings, scatterPlotDefinition } from 'ava-saturation/components/widgets-new/plot';
import IDataset from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { ContinuousNumericScaleType, AbstractAxisOption, PlotDefinition, PlotSettingsType } from 'ava-saturation/interfaces/plot';
import { IGroup } from 'ava-saturation/interfaces/presenter';
import IReference from 'ava-saturation/interfaces/reference';
import { trimTrailingZeros } from 'ava-saturation/utils/model-helpers';
import d3, { ScaleContinuousNumeric } from 'd3';
// @ts-ignore
import d3tip from 'd3-tip';
import { isArray, isEmpty } from 'lodash';

import { NumericAxisScaleResolver } from 'ava-saturation/classes/charting/numeric-axis-scale-resolver';
import { IDatasetSample } from 'ava-saturation/interfaces/dataset';

export class ScatterPlotSettings extends PlotSettings {
    readonly type = PlotSettingsType.Scatter;

    legendSettings: LegendSettings;
    xAxisSettings: AxisSettings;
    yAxisSettings: AxisSettings;
    zAxisSettings: GroupableAxisSettings;
    readonly dimensionBlacklist: IDimension[] = [contactDepth, facies, iftLab, iftReservoir, contactAngleLab, contactAngleRes, gasGradient, oilGradient, waterGradient];

    constructor(inflatable?: Partial<ScatterPlotSettings>) {
        super();

        if (inflatable) {
            set(this, 'legendSettings', LegendSettings.inflate(inflatable.legendSettings!));
            set(this, 'xAxisSettings', AxisSettings.inflate(inflatable.xAxisSettings!));
            set(this, 'yAxisSettings', AxisSettings.inflate(inflatable.yAxisSettings!));
            set(this, 'zAxisSettings', GroupableAxisSettings.inflate(inflatable.zAxisSettings!));
        } else {
            set(this, 'legendSettings', this.legendSettings || new LegendSettings());
            set(this, 'xAxisSettings', this.xAxisSettings || new AxisSettings());
            set(this, 'yAxisSettings', this.yAxisSettings || new AxisSettings());
            set(this, 'zAxisSettings', this.zAxisSettings || new GroupableAxisSettings());
        }
    }

    isPopulated: boolean;

    static inflate(inflatable: Partial<ScatterPlotSettings>): ScatterPlotSettings {
        return new ScatterPlotSettings(inflatable);
    }

    deflate(): Object {
        return {
            type: this.type,
            legendSettings: this.legendSettings.deflate(),
            xAxisSettings: this.xAxisSettings.deflate(),
            yAxisSettings: this.yAxisSettings.deflate(),
            zAxisSettings: this.zAxisSettings.deflate()
        };
    }

    withDimensions(dimensions: IDimension[]): ScatterPlotSettings {
        // TODO: (1)This check is here because onload the dimensions array is empty
        // thus overriding the axis options & their settings
        if (dimensions.length > 0) {
            const whitelistedDimensions = dimensions
                .reject(d => this.dimensionBlacklist.find(bd => bd.name === d.name));

            this.withAxisDimensions(this.xAxisSettings, whitelistedDimensions, this.xAxisSortingOption);
            this.withAxisDimensions(this.yAxisSettings, whitelistedDimensions, this.yAxisSortingOption);
        }

        // @ts-ignore
        set(this, 'isPopulated', dimensions.length > 0);

        return this;
    }

    withGroups(groups: IGroup<IReference>[]): ScatterPlotSettings {
        // TODO: (1) Doesn't happen /w groups Zones and Segments; but with Facies why?
        if (groups.length > 0) {
            this.withAxisGroups(this.zAxisSettings, groups, this.zAxisSortingOption);
        }

        return this;
    }

    withDefaultDimensions(dimensions: IDimension[]) {
        if (dimensions && dimensions.length) {
            // if there is a selected option already dont' override it
            // if axes are swaped after first log selection, we have Y must set X to the last memeber of the array
            // if needed decouple from *dimensions by cloning

            // set the xAxis selectedOption
            if (this.yAxisSettings.selectedOption) {
                // yAxis is set and xAxis is NOT set get the LAST dimension
                !this.xAxisSettings.selectedOption && set(this.xAxisSettings, 'selectedOption', this.findOption(dimensions[1], this.xAxisSettings));
            } else {
                // yAxis is NOT set and xAxis is NOT set get the FIRST dimension
                !this.xAxisSettings.selectedOption && set(this.xAxisSettings, 'selectedOption', this.findOption(dimensions[0], this.xAxisSettings));
                // set the yAxis selectedOption
                !this.yAxisSettings.selectedOption && set(this.yAxisSettings, 'selectedOption', this.findOption(dimensions[1], this.yAxisSettings));
            }
            // set the zAxis selectedOption
            !this.zAxisSettings.selectedOption && set(this.zAxisSettings, 'selectedOption', this.zAxisSettings.options[0]);
        } else {
            // everything is deselectd reset
            set(this.xAxisSettings, 'selectedOption', undefined);
            set(this.yAxisSettings, 'selectedOption', undefined);
            set(this.zAxisSettings, 'selectedOption', undefined);
        }

        return this;
    }

    xAxisSortingOption(options: AbstractAxisOption[], dimensionsForAdditon: IDimension[]) {
        return this.addAndSortAxisOptions(options, dimensionsForAdditon);
    }

    yAxisSortingOption(options: AbstractAxisOption[], dimensionsForAdditon: IDimension[]) {
        return this.addAndSortAxisOptions(options, dimensionsForAdditon).reverseObjects();
    }

    zAxisSortingOption(options: AbstractAxisOption[], groupsForAdditon: IGroup<IReference>[]) {
        return this.addAndSortGroupAxisOptions(options, groupsForAdditon);
    }
}

export default class ScatterPlot extends Plot {
    /**
     * @param properties - arguments passed from classes extending ScatterPlot
     */
    constructor(properties?: object | undefined) {
        super(properties);
        // @ts-ignore
        set(this, 'definition', scatterPlotDefinition);
        // @ts-ignore
        set(this, 'settings', this.settings || SettingsFactory.create<ScatterPlotSettings>(scatterPlotDefinition));
        // @ts-ignore
        set(this, 'settings.legendSettings.title', 'Scatter Plot Legend');
    }

    definition: PlotDefinition;
    settings: ScatterPlotSettings;
    datasets: IDataset<Record<string, any>>[];

    getScaleByType(scaleType: ContinuousNumericScaleType): ScaleContinuousNumeric<number, number> {
        switch (+scaleType) {
            case ContinuousNumericScaleType.Logarithmic:
                return d3.scaleLog();
            case ContinuousNumericScaleType.Linear:
                return d3.scaleLinear();
            default:
                throw new Error('Unsupported scale.');
        }
    }

    colorSchema = (value: string, opacity = 1) => {
        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        const item = this.legendValues.find(v => v[this.zAxisValueField] == value);

        if (!item)
            return d3.color('#3a5199');

        if (item.color.startsWith('#'))
            return d3.rgb(item.color).darker();

        return d3.rgb(`rgba(${item.color}, ${opacity})`).darker();
    };

    @computed('settings.xAxisSettings.option')
    get xAxisOption() {
        return this.settings.xAxisSettings.option;
    }

    @computed('settings.yAxisSettings.option')
    get yAxisOption() {
        return this.settings.yAxisSettings.option;
    }

    @computed('settings.zAxisSettings.option')
    get zAxisOption() {
        return this.settings.zAxisSettings.option as ReferenceGroupAxisOption;
    }

    @computed('xAxisOption.dimension')
    get xIndex() {
        if (this.xAxisOption) {
            return this.xAxisOption.dimension.shortName;
        }

        return 'missing';
    }

    @computed('xAxisOption.dimension')
    get xUnit() {
        var unitName;
        if (this.xAxisOption)
            unitName = this.xAxisOption.dimension.unitName;

        if (!unitName || !/\S/.test(unitName))
            return null;

        return unitName;
    }

    @computed('yAxisOption.dimension')
    get yIndex() {
        if (this.yAxisOption) {
            return this.yAxisOption.dimension.shortName;
        }

        return 'missing';
    }

    @computed('yAxisOption.dimension')
    get yUnit() {
        var unitName;
        if (this.yAxisOption)
            unitName = this.yAxisOption.dimension.unitName;

        if (!unitName || !/\S/.test(unitName))
            return null;

        return unitName;
    }

    @computed('zAxisOption.dimension')
    get zIndex() {
        return this.zAxisOption.dimension.shortName;
    }

    @computed('datasets.[]')
    get plotValues(): Record<string, any>[] {
        if (isEmpty(this.datasets)) {
            return [];
        }

        return this.datasets
            .reduce((flattened: Record<string, any>[], dataset) => flattened.concat(dataset.values), []);
    }

    xValueSelector(value: IDatasetSample): any { return value.x; }
    yValueSelector(value: IDatasetSample): any { return value.y; }

    @computed('plotValues', 'xIndex', 'yIndex', 'zIndex')
    get data(): Record<string, any>[] {
        const values = this.plotValues;
        const xIndex = this.xIndex;
        const yIndex = this.yIndex;
        const zIndex = this.zIndex;

        const data = values
            .reduce((aggr: Array<Record<string, any>>, v: Record<string, any>) => {
                const x = v[xIndex];
                const y = v[yIndex];
                const z = v[zIndex];

                if (!x || !isFinite(x) || !y || !isFinite(y) || (!z && z !== 0)) {
                    return aggr;
                }

                if (isArray(z)) {
                    z.filter(zz => zz).forEach(zz => aggr.push({ x, y, z: zz }));
                    return aggr;
                }

                aggr.push({ x, y, z: z.toString() });

                return aggr;
            }, []);

        this.doLater(() => {
            let resolver = this.xAxisScaleResolver;
            if (resolver != null && this.xAxisOption && this.xAxisOption.settings) {
                set(resolver, 'scaleType', (this.xAxisOption.settings as NumericScalableAxisOptionSettings) ? (this.xAxisOption.settings as NumericScalableAxisOptionSettings).scale : ContinuousNumericScaleType.Linear);
                set(resolver, 'chartingPreferences', this.xAxisOption.dimension.chartingPreferences);
                set(resolver, 'valueSelector', this.xValueSelector);
                set(resolver, 'series', [data as Record<string, any>[]]);
            }
            resolver = this.yAxisScaleResolver;
            if (resolver != null && this.yAxisOption && this.yAxisOption.settings) {
                set(resolver, 'scaleType', (this.yAxisOption.settings as NumericScalableAxisOptionSettings) ? (this.yAxisOption.settings as NumericScalableAxisOptionSettings).scale : ContinuousNumericScaleType.Linear);
                set(resolver, 'chartingPreferences', this.yAxisOption.dimension.chartingPreferences);
                set(resolver, 'valueSelector', this.yValueSelector);
                set(resolver, 'series', [data as Record<string, any>[]]);
            }
        });

        return data as Record<string, any>[];
    }

    @computed('xAxisOption.dimension')
    get xAxisScaleResolver(): NumericAxisScaleResolver | null {
        if (this.xAxisOption && this.xAxisOption.settings) {
            let axisSettings = this.xAxisOption.settings as NumericAxisOptionSettings;
            let resolver = axisSettings.scaleResolver;
            return resolver;
        }
        return null;
    }

    @computed('data', 'yAxisOption.dimension')
    get yAxisScaleResolver(): NumericAxisScaleResolver | null {
        if (this.yAxisOption && this.yAxisOption.settings) {
            let axisSettings = this.yAxisOption.settings as NumericAxisOptionSettings;
            let resolver = axisSettings.scaleResolver;
            return resolver;
        }
        return null;
    }

    @computed('data', 'zAxisOption.selectedValues.[]', 'xAxisScaleResolver.effectiveMin', 'xAxisScaleResolver.effectiveMax', 'yAxisScaleResolver.effectiveMin', 'yAxisScaleResolver.effectiveMax')
    get filteredData(): Record<string, any>[] {
        let data = this.data.filter(v =>
            // @ts-ignore
            // tslint:disable-next-line:triple-equals
            !!this.zAxisOption.selectedValues.find(x => x[this.zAxisValueField] == v.z));
        if (this.xAxisScaleResolver != null) {
            const min = this.xAxisScaleResolver.effectiveMin;
            const max = this.xAxisScaleResolver.effectiveMax;
            data = data.filter(v => (v.x >= min) && (v.x <= max));
        }
        if (this.yAxisScaleResolver != null) {
            const min = this.yAxisScaleResolver.effectiveMin;
            const max = this.yAxisScaleResolver.effectiveMax;
            data = data.filter(v => (v.y >= min) && (v.y <= max));
        }
        return data;
    }

    @computed('data.[]')
    get zAxisData(): string[] {
        return this.data
            .map(v => v.z)
            .uniq();
    }

    @computed('zAxisOption.dimension')
    get legendTitle(): string {
        return this.zAxisOption.dimension.shortName;
    }

    @computed('zAxisOption.values.[]')
    get allLegendValues() {
        return this.zAxisOption.values;
    }

    @computed('allLegendValues.[]', 'zAxisData.[]')
    get legendValues(): IReference[] {
        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        return this.allLegendValues.filter(v => !!this.zAxisData.find(x => x == v[this.zAxisValueField]));
    }

    @computed('zAxisOption.dimension')
    get zAxisValueField(): string {
        return this.zAxisOption.dimension.valueField;
    }

    @computed('data', 'xAxisOption.dimension', 'xAxisOption.settings', 'svgChartWidth', 'xAxisScaleResolver.effectiveMin', 'xAxisScaleResolver.effectiveMax')
    get xScale() {
        if (this.xAxisOption && this.xAxisScaleResolver) {
            return this.getScaleByType(this.xAxisScaleResolver.scaleType)
                .domain(this.xAxisScaleResolver.scale)
                .range([0, this.svgChartWidth]);
        }

        return undefined;
    }

    @computed('data', 'yAxisOption.dimension', 'yAxisOption.settings', 'svgChartHeight', 'yAxisScaleResolver.effectiveMin', 'yAxisScaleResolver.effectiveMax')
    get yScale() {
        if (this.yAxisOption && this.yAxisScaleResolver) {
            return this.getScaleByType(this.yAxisScaleResolver.scaleType)
                .domain(this.yAxisScaleResolver.scale)
                .range([this.svgChartHeight, 0]);
        }

        return undefined;
    }

    @computed('xScale', 'svgChartHeight')
    get xAxis() {
        if (this.xScale) {
            const xScale = this.xScale;

            return d3.axisBottom(xScale)
                .ticks(5, 'r')
                .tickSizeInner(-this.svgChartHeight)
                .tickSizeOuter(0);
        }

        return undefined;
    }

    @computed('yScale', 'svgChartWidth')
    get yAxis() {
        if (this.yScale) {
            const yScale = this.yScale;

            return d3.axisLeft(yScale)
                .ticks(5, 'r')
                .tickSizeInner(-this.svgChartWidth)
                .tickSizeOuter(0);
        }

        return undefined;
    }

    @computed('chartWrapper')
    get chart() {
        return this.chartWrapper.select('svg > g');
    }
    getZValue = (value: string) => {
        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        const found = this.allLegendValues.find(x => x[this.zAxisValueField] == value);
        return (found && found.name) || value;
    };

    @computed()
    get tip() {
        return d3tip()
            .attr('class', 'd3-tip')
            .offset([-5, 0])
            .html((d: any) => `<div class="d3-tip-inner" style="background-color:${this.colorSchema(d.z, 0.75)};">
    ${this.getZValue(d.z)}
    <br/> (${this.applyFloatingPointPrecision(d.x)}, ${this.applyFloatingPointPrecision(d.y)})
</div>`);

    }

    @action
    onLegendItemSelected(item: any) {
        let selectedItems = this.zAxisOption.selectedValues;
        const currentItem = selectedItems.find(l => l === item);

        const actionToCall = currentItem ? selectedItems.removeObject : selectedItems.pushObject;

        actionToCall.call(selectedItems, currentItem || item);
    }

    didRender() {
        this.doLater(() => {
            const data = this.filteredData,
                xScale = this.xScale,
                yScale = this.yScale,
                chart = this.chart,
                svg = this.chartWrapper.select('svg'),
                message = svg.select('text.message');

            const createAxis = (axisKey: string, axis: any) => {
                if (axis) {
                    message.text('');

                    chart.select(`g.${axisKey}.axis`)
                        .call(axis)
                        .attr('font-size', '14')
                        .selectAll('.tick > line')
                        .attr('opacity', 0.1);
                }

                if (!xScale || !yScale) {
                    message
                        .text('Choose a second variable')
                        .attr('dominant-baseline', 'center');
                }
            };

            createAxis('x', this.xAxis);
            createAxis('y', this.yAxis);

            // adjust bottom left labels so that they do not overlap
            chart.select('g.y.axis').select('.tick > text').attr('dy', '-1px');
            chart.select('g.x.axis').selectAll('.tick > text').attr('dy', '0.9em');

            // remove tick trailing zeros
            chart.select('g.y.axis').selectAll('.tick > text').each(function () {
                if (d3.select(this).text()) {
                    d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
                }
            });
            chart.select('g.x.axis').selectAll('.tick > text').each(function () {
                if (d3.select(this).text()) {
                    d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
                }
            });

            const circle = chart.selectAll('circle')
                .data(data);

            // create the tooltips
            chart.call(this.tip);

            circle.exit().remove();
            // @ts-ignore
            circle.enter().append('circle').merge(circle)
                // @ts-ignore
                .attr('cx', d => xScale(d.x))
                // @ts-ignore
                .attr('cy', d => yScale(d.y))
                .attr('r', 4.5)
                // @ts-ignore
                .attr('fill', d => this.colorSchema(d.z))
                .on('mouseover', this.tip.show)
                .on('mouseout', this.tip.hide); // colors(d.z))
        });
    }
    willDestroy() {
        this.tip.destroy();
    }
}

export class ScatterPlotSettingsComponent extends Component {
    settings: ScatterPlotSettings;

    constructor() {
        super(...arguments);

        defineProperty(this, 'xAxisSettings', alias('settings.xAxisSettings'));
        defineProperty(this, 'yAxisSettings', alias('settings.yAxisSettings'));
        defineProperty(this, 'zAxisSettings', alias('settings.zAxisSettings'));
    }

    @action
    onAxisOptionChanged(axisKey: string, option: AbstractAxisOption) {
        // @ts-ignore
        set(this, `settings.${axisKey}AxisSettings.selectedOption`, option);

        this.notifyPropertyChange(`settings.${axisKey}AxisSettings.options`);
    }

    @action
    switchXYAxisSettings() {
        const xAxisSettings = this.settings.xAxisSettings;
        const yAxisSettings = this.settings.yAxisSettings;

        // @ts-ignore
        set(this, 'settings.xAxisSettings', yAxisSettings);

        // @ts-ignore
        set(this, 'settings.yAxisSettings', xAxisSettings);
    }
}
