import d3 from 'd3';
import isValidValue from 'ava-saturation/utils/is-valid-value';

const log10 = Math.log10 || function (x: number) { return Math.log(x) / Math.LN10; };

export default function (values: Record<string, any>[], accessor: (object: Record<string, any>) => number, userDefined: any) {

    if (isValidValue(userDefined.min) && userDefined.min != 0 && isValidValue(userDefined.max) && userDefined.max != 0) {
        return [userDefined.min, userDefined.max];
    }

    const extent = d3.extent(values, accessor);
    const min = extent[0];
    const max = extent[1];
    const absoluteMin = min && Math.abs(min);
    const absoluteMax = max && Math.abs(max);
    const signMin = (min && absoluteMin && min / absoluteMin) || 1;
    const signMax = (max && absoluteMax && max / absoluteMax) || 1;

    const resultMin = Math.pow(10, Math.floor(log10(absoluteMin || 0))) * signMin || 1;
    const resultMax = Math.pow(10, Math.ceil(log10(absoluteMax || 1))) * signMax || 100;

    // depending on the signs mirroring the min and max might be required.
    let range = resultMin < resultMax ? [resultMin, resultMax] : [resultMax, resultMin];

    if (isValidValue(userDefined.min) && userDefined.min != 0) {
        range[0] = userDefined.min;
    }

    if (isValidValue(userDefined.max) && userDefined.max != 0) {
        range[1] = userDefined.max;
    }

    return range;
}
