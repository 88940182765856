import { createAsyncAction } from 'typesafe-actions';
import constants from './constants';
import { SaturationConceptAction } from './types';
// TODO: Proper Typing
export const createSaturationConceptActionAsync = createAsyncAction(
    constants.CREATE_REQUEST,
    constants.CREATE_SUCCESS,
    constants.CREATE_FAILURE
)<undefined, any, any>();

export const fetchSaturationConceptActionAsync = createAsyncAction(
    constants.FETCH_REQUEST,
    constants.FETCH_SUCCESS,
    constants.FETCH_FAILURE
)<undefined, any, any>();

// TODO: Move this or rename it.
export const removeSaturationConceptActionCacheAsync = createAsyncAction(
    constants.REMOVE_REQUEST,
    constants.REMOVE_SUCCESS,
    constants.REMOVE_FAILURE
)<undefined, SaturationConceptAction[], any>();
