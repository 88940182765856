import { isArray } from '@ember/array';
import { computed, get } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { isNone } from '@ember/utils';

export function buildModelChanges(attributes, options = {}) {
    const values = attributes.filter(x => get(options, `${x}.value`));
    const models = attributes.filter(x => get(options, `${x}.model`));
    const items = attributes.filter(x => get(options, `${x}.items`));
    const itemModels = attributes.filter(x => get(options, `${x}.itemModel`));

    const dependencies = values
        .concat(models.map(x => `${x}.modelChanges`))
        .concat(items.map(x => `${x}.[]`))
        .concat(itemModels.map(x => `${x}.@each.modelChanges`));

    return Mixin.create({
        modelChanges: computed.apply(this, dependencies.concat(['hasDirtyAttributes', function () {
            if (!this.hasDirtyAttributes) {
                return [];
            }

            // get dependency values so that ember will track their changes
            values.forEach(x => this.get(x));
            models.forEach(x => this.get(`${x}.modelChanges`));
            items.forEach(x => this.get(`${x}.length`));
            itemModels.forEach(x => this.get(`${x}.firstObject.modelChanges`));

            const changedAttributes = this.changedAttributes();
            const intl = this.intl;

            return attributes
                .filter(x => changedAttributes[x])
                .reduce((p, y) => {
                    if (get(options, `${y}.value`)) {
                        const formatter = get(options, `${y}.valueFormatter`) || (x => x);
                        p.push({
                            'title': y,
                            'attribute': y,
                            'old-value': formatter(changedAttributes[y][0], intl),
                            'new-value': formatter(changedAttributes[y][1], intl)
                        });
                    }

                    if (isNone(this.get(y))) {
                        return p;
                    }

                    if (get(options, `${y}.model`)) {
                        const modelChanges = this.get(`${y}.modelChanges`);
                        if (isArray(modelChanges)) {
                            p = p.concat(modelChanges.map(y => {
                                return {
                                    'title': `${y}.${y['title']}`,
                                    'attribute': `${y}.${y['attribute']}`,
                                    'old-value': y['old-value'],
                                    'new-value': y['new-value']
                                };
                            }));
                        }
                    }

                    if (!isArray(this.get(y))) {
                        return p;
                    }

                    if (get(options, `${y}.items`)) {
                        const formatter = get(options, `${y}.itemValueFormatter`) || (j => j);
                        const oldItems = this.get(y)._originalState;
                        const newItems = this.get(y).toArray();
                        if (oldItems.length !== newItems.length || oldItems.any((z, i) => z !== newItems[i])) {
                            p.push({
                                'title': `${y}.items`,
                                'attribute': `${y}.items`,
                                'old-value': oldItems.map((o, i) => formatter(o, i, intl)).join(', '),
                                'new-value': newItems.map((n, i) => formatter(n, i, intl)).join(', ')
                            });
                        }
                    }

                    if (get(options, `${y}.itemModel`)) {
                        this.get(y).forEach(y => {
                            const itemId = get(y, 'object-id');
                            const modelChanges = get(y, 'modelChanges');
                            if (isArray(modelChanges)) {
                                p = p.concat(modelChanges.map(z => {
                                    return {
                                        'title': `${y}.${z['title']}`,
                                        'attribute': `${y}.${itemId}.${z['attribute']}`,
                                        'old-value': z['old-value'],
                                        'new-value': z['new-value']
                                    };
                                }));
                            }
                        });
                    }

                    return p;
                }, []);
        }]))
    });
}

export function trimTrailingZeros(x) {
    return x.replace(/(.+)\.0+$|(.+\..*[^0])0+$/, '$1$2');
}

export function computedHash(name, key, value) {
    return computed(`${name}.[]`, function () {
        const getKey = key ? (v) => get(v, key) : (v) => v;
        const getValue = value ? (v) => get(v, value) : (v) => v;
        return (get(this, name) || [])
            .reduce((p, v) => {
                p[getKey(v)] = getValue(v);
                return p;
            }, {});
    });
}

export default {
    computedHash,
    buildModelChanges,
    trimTrailingZeros
};
