import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import { iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';
import { waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import permeability from 'ava-saturation/classes/dimensions/permeability';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import IReference from 'ava-saturation/interfaces/reference';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { IsParameterZeroStatement } from 'ava-saturation/utils/calculator-parameters';
import { StatementBuilder } from 'ava-saturation/interfaces/statement-builder';

export class LeverettJCalculationStatement implements StatementBuilder<CalculationConstant> {
    private constants: CalculationConstant;
    private parameters: CalculationParameter | undefined;
    // private thenStatementBuilder: (constants: CalculationConstant) => string;
    // private otherwiseStatementBuilder: () => string;

    constructor(constants: CalculationConstant, parameters: CalculationParameter | undefined) {
        this.constants = constants;
        this.parameters = parameters;
    }
    then(_statementBilder: (band: CalculationConstant) => string): StatementBuilder<CalculationConstant> {
        // this.thenStatementBuilder = statementBilder;
        return this;
    }
    otherwise(_statementBilder: () => string): StatementBuilder<CalculationConstant> {
        // this.otherwiseStatementBuilder = statementBilder;
        return this;
    }
    build(propertyMap: {
        [dimensionKey: string]: string;
    }, _elementMap: {
        [dimensionKey: string]: IReference[];
    }) {
        const hafwlPropertyName = propertyMap[hafwl.shortName];
        const porosityPropertyName = propertyMap[porosity.shortName];
        const permeabilityPropertyName = propertyMap[permeability.shortName];
        if (!this.parameters)
            throw 'Mapping Parameters need to be provided in order to perform a Leverett J function mapping.';
        const iftValue = this.parameters.byDimensionKey[iftReservoir.shortName];
        const caValue = this.parameters.byDimensionKey[contactAngleRes.shortName];
        const wGradientValue = this.parameters.byDimensionKey[waterGradient.shortName];
        const hydrocarbonGradientValue = this.parameters.byDimensionKey.hydrocarbonGradient;
        const PC_H = `${hafwlPropertyName}*(${wGradientValue}-${hydrocarbonGradientValue})`;
        const PC_H_DIVISOR = `${iftValue}*cos(${caValue})`;
        const PROPERTY_CORRELATION = `sqrt(${permeabilityPropertyName}/${porosityPropertyName})`;
        return `if(${IsParameterZeroStatement(hafwlPropertyName)}, U, (${this.constants.constants.a}*POW(0.2166*(${PC_H}/${PC_H_DIVISOR})*${PROPERTY_CORRELATION},${this.constants.constants.b})))`;
    }
}
