import Controller from '@ember/controller';
import { oneWay } from '@ember/object/computed';
import { inject } from '@ember/service';
import DeleteConceptMixin from 'ava-saturation/mixins/delete-concept-mixin';

export default Controller.extend(DeleteConceptMixin, {
    router: inject(),
    redux: inject(),
    screenSensor: inject(),
    synchronization: inject(),
    store: inject(),
    conceptService: inject('concept'),
    sideNavigationOpen: true,
    isSaving: false,
    queueName: 'importConcept',
    exportManifestEnabled: oneWay('features.ExportManifest'),

    notifySaving(state) {
        this.set('isSaving', state);
    },

    async save(concept) {
        this.notifySaving(true);
        this.synchronization.disableSync();

        await this.synchronization.version(concept);

        this.synchronization.enableSync();
        this.notifySaving(false);
    },

    actions: {
        async saveVersion(concept) {
            await this.save(concept);
        },

        toggleNavPanel() {
            this.toggleProperty('sideNavigationOpen');
        },

        async exportConceptManifest(concept) {
            if (concept.isDraft) {
                await this.save(concept);
            }
            this.conceptService.exportManifest(concept);
        },

        exportConceptToPDF(concept) {
            this.conceptService.exportToPdf(concept);
        }
    }
});
