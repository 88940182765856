import faciesPropertyValidations from './facies-property';
import porosityPropertyValidations from './porosity-property';
import permeabilityPropertyValidations from './permeability-property';
import gridValidations from './grid';
import contactValidations from './contact';
import { faciesProperty } from 'ava-saturation/classes/dimensions/facies-property';
import { porosityProperty } from 'ava-saturation/classes/dimensions/porosity-property';
import { permeabilityProperty } from 'ava-saturation/classes/dimensions/permeability-property';
import { contact } from 'ava-saturation/classes/dimensions/contact';

// export default (conceptId: any, state: any) => {
export default () => {
    // const connector = connectValidationRules(conceptId, state);
    return {
        [faciesProperty.shortName]: faciesPropertyValidations,
        [porosityProperty.shortName]: porosityPropertyValidations,
        [permeabilityProperty.shortName]: permeabilityPropertyValidations,
        [contact.shortName]: contactValidations,
        grid: gridValidations,
    };
};

// function connectValidationRules(conceptId: string, state: any) {
//     return (rules: Record<string, ((conceptId: string, state: any) => (value: any) => boolean)>[]) => {
//         // new
//         // return Object.keys(rules).forEach(k => {
//         //     ...rules[k]
//         // })
//     };
// }