import Route from '@ember/routing/route';
import { inject } from '@ember/service';
import * as actions from 'ava-saturation/store/entities-v1/saturation-concept/actions';
import LicencedAuthenticatedRouteMixin from 'ava-saturation/mixins/auth/licensed-authenticated-route-mixin';
import ReferenceAvailabilityMixin from 'ava-saturation/mixins/reference-availability';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';

export default Route.extend(LicencedAuthenticatedRouteMixin, ReferenceAvailabilityMixin, {
    store: inject('store'),
    redux: inject(),

    queryParams: {

    },
    // we need the model and after model hooks to wire up the loading
    async model(params: { saturation_concept_id: string }) {
        let conceptId = params.saturation_concept_id;

        const record = await this.store.findRecord('saturation-concept', conceptId, { reload: true });
        const result = await record.destroyRecord();

        let saturationConcept = result.toJSON();
        saturationConcept.id = result.get('id');

        return saturationConcept;
    },

    afterModel(concept: SaturationConcept) {
        this.redux.dispatch(actions.deleteConcept(concept));

        return this.transitionTo('saturation-concepts.index');
    }
});
