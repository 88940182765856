import { observer } from '@ember/object';
import Route from '@ember/routing/route';

export default Route.extend({
    isAuthenticatedObserver: observer('session.isAuthenticated', function () {
        if (this.get('session.isAuthenticated')) {
            this.transitionTo('index');
        }
    })
});
