import { NonNumericDimension } from 'ava-saturation/classes/dimension';
import { DimensionAxisDisplayPriority } from 'ava-saturation/interfaces/dimension';

const zoneKey = 'zone';

class Zone extends NonNumericDimension {
    /**
     * Describes a geological zone
     * @ref https://www.glossary.oilfield.slb.com/Terms/z/zone.aspx
     */
    constructor() {
        super();

        this.displayName = 'Zone';
        this.name = this.shortName = zoneKey;
    }

    readonly axisPriority: number = DimensionAxisDisplayPriority.Zone;
}

const zone = new Zone();

export default zone;