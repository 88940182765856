import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import IIntersection from 'ava-saturation/interfaces/intersection-reference';
import { ReferenceParserBase } from 'ava-saturation/interfaces/reference-parser';

export default class IntersectionReferenceType extends ReferenceObjectType {
    readonly id = 310;
    readonly name = 'intersection';
    readonly typeAliases = ['BoreholeGridIntersection'];
    subTypeAliases = [];
}

export const intersectionReferenceType = new IntersectionReferenceType();

export class IntersectionReferenceParser<T extends IIntersection> extends ReferenceParserBase<T> {
    parse(object: any): T {
        const identifierSelector = (object: any) => object._instance;
        return {
            ...object,
            gridMoniker: this.parseMoniker(object.GridId, identifierSelector),
            zoneMoniker: this.parseMoniker(object.ZoneId, identifierSelector),
            segmentMoniker: this.parseMoniker(object.SegmentId, identifierSelector),
            wellMoniker: this.parseMoniker(object.BoreholeId, identifierSelector),
            zoneName: object.ZoneName,
            segmentName: object.SegmentName,
            startDepth: object.EntryZ,
            endDepth: object.ExitZ
        };
    }
}

export class IntersectionCollectionReferenceParser extends ReferenceParserBase<Array<IIntersection>> {
    parse(object: any): Array<IIntersection> {
        return referenceCollectionParser<IIntersection>(object, new IntersectionReferenceParser(this.origin, this.version));
    }
}

function referenceCollectionParser<T extends IIntersection>(object: any, singleReferenceParser: ReferenceParserBase<T>): Array<T> {
    return (object as Array<any>).map(o => singleReferenceParser.parse(o));
}