import Component from '@ember/component';
import { action, set } from '@ember/object';

export class ChartLegendItem {
    id: string;
    name: string;
    selectable: boolean;
    selected: boolean;
    color: string = '#000000';
}

export class ChartLegendItemsGroup {
    name: string;
    items: ChartLegendItem[];
}

export enum ChartLegendPosition {
    hidden = 'hidden',
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom',
}

export default class ChartLegend extends Component {
    groups: ChartLegendItemsGroup[];

    onItemClick: (item: any) => void;

    @action
    onClick(item: any) {
        set(item, 'selected', !item.selected);
        if (this.onItemClick)
            this.onItemClick(item);
    }

}
