import Component from '@ember/component';
import { action, computed, defineProperty, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { capillaryPressureLab, capillaryPressureRes } from 'ava-import/classes/dimensions/capillary-pressure';
import { pcHeightGas, pcHeightOil } from 'ava-import/classes/dimensions/capillary-pressure-height';
import JValue from 'ava-import/classes/dimensions/j-value';
import { relativePermeabilityGas, relativePermeabilityOil, relativePermeabilityWater } from 'ava-import/classes/dimensions/relative-permeability';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import bvw from 'ava-saturation/classes/dimensions/bvw';
import permeability from 'ava-saturation/classes/dimensions/permeability';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import { AxisSettings, CategorialAxisOptionSettings, GroupableAxisSettings, ReferenceGroupAxisOption } from 'ava-saturation/classes/widgets/axis';
import SettingsFactory from 'ava-saturation/classes/widgets/plot';
import Plot, { histogramPlotDefinition, LegendSettings, PlotSettings } from 'ava-saturation/components/widgets-new/plot';
import IDataset from 'ava-saturation/interfaces/dataset';
import IDimension from 'ava-saturation/interfaces/dimension';
import { AbstractAxisOption, PlotDefinition, PlotSettingsType } from 'ava-saturation/interfaces/plot';
import { IGroup } from 'ava-saturation/interfaces/presenter';
import IReference from 'ava-saturation/interfaces/reference';
import isValidValue from 'ava-saturation/utils/is-valid-value';
import d3 from 'd3';
// @ts-ignore
import d3tip from 'd3-tip';
import { ceil, groupBy, head, isEmpty, last, sortBy } from 'lodash';

export class HistogramPlotSettings extends PlotSettings {
    readonly type = PlotSettingsType.Histogram;

    legendSettings: LegendSettings;
    xAxisSettings: AxisSettings;
    zAxisSettings: GroupableAxisSettings;
    dimensionWhitelist: IDimension[] = [porosity, waterSaturation, capillaryPressureLab, permeability, bvw, capillaryPressureRes, pcHeightGas, pcHeightOil, relativePermeabilityGas, relativePermeabilityOil, relativePermeabilityWater, JValue];

    constructor(inflatable?: Partial<HistogramPlotSettings>) {
        super();

        if (inflatable) {
            set(this, 'legendSettings', LegendSettings.inflate(inflatable.legendSettings!));
            set(this, 'xAxisSettings', AxisSettings.inflate(inflatable.xAxisSettings!));
            set(this, 'zAxisSettings', GroupableAxisSettings.inflate(inflatable.zAxisSettings!));
        } else {
            set(this, 'legendSettings', this.legendSettings || new LegendSettings());
            set(this, 'xAxisSettings', this.xAxisSettings || new AxisSettings());
            set(this, 'zAxisSettings', this.zAxisSettings || new GroupableAxisSettings());
        }
    }

    isPopulated: boolean;

    static inflate(inflatable: Partial<HistogramPlotSettings>): HistogramPlotSettings {
        return new HistogramPlotSettings(inflatable);
    }

    deflate(): Object {
        return {
            type: this.type,
            legendSettings: this.legendSettings.deflate(),
            xAxisSettings: this.xAxisSettings.deflate(),
            zAxisSettings: this.zAxisSettings.deflate()
        };
    }

    withDimensions(dimensions: IDimension[]): HistogramPlotSettings {
        if (dimensions.length > 0) {
            const whitelistedDimensions = dimensions
                .filter(d => this.dimensionWhitelist.find(wd => wd.name === d.name));

            this.withAxisDimensions(this.xAxisSettings, whitelistedDimensions, this.xAxisSortingOption);
        }

        set(this, 'isPopulated', dimensions.length > 0);

        return this;
    }

    withDefaultDimensions(dimensions: IDimension[]) {
        // if there is a selected option already dont' override it
        if (dimensions && dimensions.length) {
            !this.xAxisSettings.selectedOption && set(this.xAxisSettings, 'selectedOption', this.findOption(dimensions[0], this.xAxisSettings));
            !this.zAxisSettings.selectedOption && set(this.zAxisSettings, 'selectedOption', this.zAxisSettings.options[0]);
        }

        return this;
    }

    withGroups(groups: IGroup<IReference>[]): HistogramPlotSettings {
        if (groups.length > 0) {
            this.withAxisGroups(this.zAxisSettings, groups, this.zAxisSortingOption);
        }

        return this;
    }

    xAxisSortingOption(options: AbstractAxisOption[], dimensionsForAdditon: IDimension[]) {
        return this.addAndSortAxisOptions(options, dimensionsForAdditon, true);
    }

    zAxisSortingOption(options: AbstractAxisOption[], groupsForAdditon: IGroup<IReference>[]) {
        return this.addAndSortGroupAxisOptions(options, groupsForAdditon);
    }
}

export default class HistogramPlot extends Plot {
    @service screenSensor: any;

    /**
     *  This can be refactored following
     *  @ref - http://bl.ocks.org/KatiRG/f7d064cd9c3efbc3c360
     *  The original implementation seems to follow
     *  @ref - https://github.com/mydea/ember-d3-workshop
     */
    constructor() {
        super(...arguments);

        set(this, 'definition', histogramPlotDefinition);

        set(this, 'settings', this.settings || SettingsFactory.create<HistogramPlotSettings>(histogramPlotDefinition));
        // @ts-ignore Setting a deeply nested property SMELL!
        set(this, 'settings.legendSettings.title', 'Histogram Plot Legend');
    }

    definition: PlotDefinition;
    settings: HistogramPlotSettings;
    datasets: IDataset<Record<string, any>>[];

    @computed('settings.xAxisSettings.option')
    get xAxisOption() {
        return this.settings.xAxisSettings.option;
    }

    @computed('settings.zAxisSettings.option')
    get zAxisOption() {
        return this.settings.zAxisSettings.option as ReferenceGroupAxisOption;
    }

    @computed('xAxisOption.dimension')
    get xIndex() {
        if (this.xAxisOption)
            return this.xAxisOption.dimension.shortName;

        return 'missing';
    }

    @computed('xAxisOption.dimension')
    get xUnit() {
        const unitName = this.xAxisOption!.dimension.unitName;

        if (!unitName || !/\S/.test(unitName))
            return null;

        return unitName;
    }

    @computed('zAxisOption.dimension')
    get zIndex() {
        return this.zAxisOption.dimension.shortName;
    }

    @computed('xAxisOption.settings.binCount', 'svgChartWidth')
    get binSizeRatio() {
        const binCount = (this.xAxisOption!.settings as CategorialAxisOptionSettings).binCount;
        const standardBinRatio = 110;

        return standardBinRatio / (this.svgChartWidth / (binCount || 10));
    }

    @computed('datasets.[]')
    get plotValues(): Record<string, any>[] {
        if (isEmpty(this.datasets)) {
            return [];
        }

        return this.datasets
            .reduce((flattened: Record<string, any>[], dataset) => flattened.concat(dataset.values), []);
    }

    @computed('plotValues', 'zAxisOption.selectedValues.[]', 'xIndex', 'zIndex')
    get filteredValues(): Record<string, any>[] {
        const zIndex = this.zIndex;
        if (isEmpty(this.zAxisOption.selectedValues))
            return this.plotValues.filter(v => isValidValue(v[this.xIndex]));

        // @ts-ignore
        // tslint:disable-next-line:triple-equals
        return this.plotValues.filter(v => isValidValue(v[this.xIndex]) && !!this.zAxisOption.selectedValues.find(x => v[zIndex] == x[this.zAxisValueField]));
    }

    @computed('filteredValues', 'xIndex')
    get orderedValues() {
        return sortBy(this.filteredValues, this.xIndex);
    }

    @computed('orderedValues', 'xIndex')
    get min(): Record<string, any> {
        const min = {
            [this.xIndex]: 0
        };

        return this.orderedValues.length > 1 ? head(this.orderedValues) || min : min;
    }

    @computed('orderedValues')
    get max() {
        return last(this.orderedValues) || { [this.xIndex]: 0 };
    }

    @computed('xAxisOption.settings.binCount')
    get binCount() {
        return (this.xAxisOption!.settings as CategorialAxisOptionSettings).binCount || 10;
    }

    @computed('min', 'max', 'xIndex', 'binCount')
    get step() {
        const binCount = this.binCount;
        if (binCount === 0) return 0;

        return Math.abs(this.max[this.xIndex] - this.min[this.xIndex]) / binCount;
    }

    @computed('step')
    get displayStep() {
        return this.applyFloatingPointPrecision(this.step, 3);
    }

    @computed('step', 'xIndex', 'min', 'max', 'binCount')
    get binRanges(): Array<any> {
        var result: any = [];
        if (this.binCount === 0) return result;
        var start = Math.floor(this.min[this.xIndex] * 100000) / 100000;
        for (var i = 0; i < this.binCount; i++) {
            var end = Math.floor((start + this.step) * 100000) / 100000;
            result.push({
                start: start,
                end: end,
                exclusiveStart: false,
                exclusiveEnd: true,
            });
            start = end;
        }
        result[this.binCount - 1].end = Math.ceil(this.max[this.xIndex] * 100000) / 100000;
        result[this.binCount - 1].exclusiveEnd = false;
        return result;
    }

    @computed('orderedValues', 'xIndex', 'zIndex', 'zAxisOption.values.[]', 'min', 'max', 'step', 'binSizeRatio', 'binRanges')
    get data(): Record<string, any>[] {
        const orderedValues = this.orderedValues,
            xIndex = this.xIndex,
            zIndex = this.zIndex;
        // @ts-ignore
        const keys = this.zAxisOption.values.map(v => v[this.zAxisValueField]).concat(['undefined']);

        const result: Record<string, any>[] = [];
        const shouldScale = (index: number) => {
            if (this.binSizeRatio > 1.5)
                return true;

            return index % ceil(this.binSizeRatio) !== 0;
        };

        // TODO: [TT] this needs to be refactored
        // 1. Best withe pure D3
        // 2. or https://www.refactoring.com/catalog/replaceIterationWithRecursion.html
        this.binRanges.forEach((b, i) => {
            let bin: Record<string, any> = {};

            bin.name = `${this.applyFloatingPointPrecision(b.start, 3)} - ${this.applyFloatingPointPrecision(b.end, 3)}`;

            // labels on the xAxis
            bin.tickLabel = shouldScale(i) ?
                `${this.applyFloatingPointPrecision(b.end, 3)}` :
                `${this.applyFloatingPointPrecision(b.start, 3)} - ${this.applyFloatingPointPrecision(b.end, 3)}`;

            const valuesInRange = orderedValues.filter((v: Record<string, any>) => isInRange(b.start, b.end, v[xIndex], b.exclusiveStart, b.exclusiveEnd));

            bin.total = valuesInRange.length;

            let grouped = groupBy(valuesInRange, zIndex);
            let index = 0;
            const metadata: Array<{ title: string, from: number, to: number }> = [];

            const zWithCount = keys.reduce((aggr: Record<string, number>, key) => {
                if (key !== 'undefined')
                    metadata.push({
                        // @ts-ignore
                        title: this.zAxisOption.values.find(v => v[this.zAxisValueField] === key).name,
                        from: index,
                        to: index + (<[]> <any> grouped[key] || []).length
                    });

                index += (<[]> <any> grouped[key] || []).length;

                aggr[key] = (grouped[key] || []).length;
                return aggr;
            }, {});

            bin = {
                ...bin,
                ...zWithCount,
                metadata
            };

            result.push(bin);
        });

        return result;
    }

    @computed('data', 'xAxisOption.settings.binCount', 'svgChartWidth')
    get visibleXAxisLabels(): string[] {
        const limit = 50;
        const binCount = (this.xAxisOption!.settings as CategorialAxisOptionSettings).binCount;
        const binWidth = (this.svgChartWidth / (binCount || 10));

        const filter = (data: Record<string, any>, step: number) => {
            if (data.length < step)
                return [this.data[0].tickLabel, this.data[this.data.length - 1].tickLabel];

            var filtered = [];
            for (var i = 0; i < data.length; i = i + step) {
                filtered.push(data[i].tickLabel);
            }
            return filtered;
        };

        if (binWidth > limit) return this.data.map(d => d.tickLabel);
        if (binWidth > limit / 2) return filter(this.data, 2);
        if (binWidth > limit / 3) return filter(this.data, 3);
        if (binWidth > limit / 4) return filter(this.data, 4);
        return [this.data[0].tickLabel, this.data[this.data.length - 1].tickLabel];
    }

    @computed('values.[]', 'zIndex')
    get zAxisData(): string[] {
        return this.plotValues.map(v => v[this.zIndex]).uniq();
    }

    @computed('zAxisOption.dimension')
    get zAxisValueField(): string {
        return this.zAxisOption.dimension.valueField;
    }

    @computed('zAxisOption.dimension')
    get zAxisTitleField(): string {
        return this.zAxisOption.dimension.shortName;
    }

    @computed('zAxisOption.dimension')
    get legendTitle(): string {
        return this.zAxisOption.dimension.shortName;
    }

    @computed('zAxisOption.values.[]')
    get allLegendValues() {
        // const obj = this.zAxisOption.values[0]; //hacky
        return this.zAxisOption.values;
    }

    @computed('allLegendValues.[]', 'zAxisData.[]')
    get legendValues(): IReference[] {
        return this.allLegendValues.filter(v => {
            // @ts-ignore
            // tslint:disable-next-line
            const zAxisValue = this.zAxisData.find(x => x == v[this.zAxisValueField]);

            // Zero values are going to be caught otherwise
            return zAxisValue != null;
        });
    }

    @computed('data', 'svgChartWidth')
    get xScale() {
        return d3.scaleBand()
            .domain(this.data.map(d => d.tickLabel))
            .rangeRound([0, this.svgChartWidth]);
    }

    @computed('data', 'svgChartHeight')
    get yScale() {
        return d3.scaleLinear()
            .domain([0, d3.max(this.data, d => d.total)])
            .nice()
            .rangeRound([this.svgChartHeight, 0]);
    }

    @computed('xScale', 'svgChartHeight', 'visibleXAxisLabels')
    get xAxis() {
        const xScale = this.xScale;

        return d3.axisBottom(xScale).tickFormat(n => this.visibleXAxisLabels.includes(n) ? n : '');
    }

    @computed('yScale', 'svgChartWidth')
    get yAxis() {
        const yScale = this.yScale;

        return d3.axisLeft(yScale);
    }

    @computed('xScale', 'svgChartWidth')
    get bandWidth() {
        let width = this.xScale.bandwidth() * 95 / 100;
        if (this.xScale.bandwidth() - width > 10) width = this.xScale.bandwidth() - 10;
        if (width <= 0) width = this.xScale.bandwidth();
        return width;
    }

    @computed('chartWrapper')
    get chart() {
        return this.chartWrapper.select('svg > g');
    }

    @action
    onLegendItemSelected(item: any) {
        let selectedItems = this.zAxisOption.selectedValues;
        const currentItem = selectedItems.find(l => l === item);

        const actionToCall = currentItem ? selectedItems.removeObject : selectedItems.pushObject;

        actionToCall.call(selectedItems, currentItem || item);
    }

    @computed()
    get tip() {
        return d3tip()
            .attr('class', 'd3-tip')
            .offset([-5, 0])
            .html((d: any) => {
                // @ts-ignore
                // tslint:disable-next-line
                const title = d.data.metadata.find(z => z.from == d[0] && z.to == d[1]).title;
                return `<div class="d3-tip-inner" style="background:${this.colorSchema(d.key, 0.75)};">
            ${title} [${d.data.name}] <br/>Samples: ${d[1] - d[0]}
</div>`;
            });

    }

    colorSchema = (value: string, opacity = 1) => {
        // @ts-ignore
        const item = this.legendValues.find(v => v[this.zAxisValueField] === value);

        if (!item)
            return d3.color('#3a5199');

        if (item.color.startsWith('#'))
            return d3.rgb(item.color).darker();

        return d3.rgb(`rgba(${item.color}, ${opacity})`).darker();
    };

    didRender() {
        const data = this.data,
            xScale = this.xScale,
            yScale = this.yScale,
            chart = this.chart;

        // @ts-ignore
        const keys = this.allLegendValues.map(lv => lv[this.zAxisValueField]);

        const createAxis = (axisKey: string, axis: any) => {
            chart.select(`g.${axisKey}.axis`)
                .call(axis)
                .attr('font-size', '14')
                .selectAll('.tick > line')
                .attr('opacity', 0.1);
        };

        createAxis('x', this.xAxis);
        createAxis('y', this.yAxis);

        // adjust bottom left labels so that they do not overlap
        // chart.select('g.y.axis').select('.tick > text').attr('dy', '-1px');
        // chart.select('g.x.axis').selectAll('.tick > text').attr('dy', '0.9em');

        // // remove tick trailing zeros
        // chart.select('g.y.axis').selectAll('.tick > text').each(function () {
        //     if (d3.select(this).text()) {
        //         d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
        //     }
        // });
        // chart.select('g.x.axis').selectAll('.tick > text').each(function () {
        //     if (d3.select(this).text()) {
        //         d3.select(this).text(trimTrailingZeros(d3.select(this).text()));
        //     }
        // });

        // create the tooltips
        chart.call(this.tip);

        chart.selectAll('g.content').remove();
        // @ts-ignore
        chart.selectAll('g.content')
            .data(d3.stack().keys(keys)(data))
            .enter().append('g')
            .attr('class', 'content')
            // @ts-ignore
            .attr('fill', d => this.colorSchema(d.key))
            .selectAll('rect')
            // @ts-ignore
            .data(d => d)
            .enter().append('rect')
            // @ts-ignore
            .attr('x', (d) => xScale(d.data.tickLabel))
            // @ts-ignore
            .attr('y', (d) => yScale(d[1]))
            // @ts-ignore
            .attr('height', (d) => yScale(d[0]) > yScale(d[1]) ? yScale(d[0]) - yScale(d[1]) : 0)
            .attr('width', this.bandWidth)
            // @ts-ignore
            // tslint:disable-next-line
            .on('mouseover', this.tip.show)
            .on('mouseout', this.tip.hide);
    }
}

export class HistogramPlotSettingsComponent extends Component {
    settings: HistogramPlotSettings;

    constructor() {
        super(...arguments);

        defineProperty(this, 'xAxisSettings', alias('settings.xAxisSettings'));
        defineProperty(this, 'zAxisSettings', alias('settings.zAxisSettings'));
    }

    @action
    onAxisOptionChanged(axisKey: string, option: AbstractAxisOption) {
        // @ts-ignore
        set(this, `settings.${axisKey}AxisSettings.selectedOption`, option);

        this.notifyPropertyChange(`settings.${axisKey}AxisSettings.options`);
    }
}
