import uuid from 'ava-saturation/utils/uuid';
import d3 from 'd3';
import { computed } from '@ember/object';
import isValidNumber from 'ava-saturation/utils/is-valid-number';

export class ChartSeries2DPoint {
    x: number;
    y: number;
}

export abstract class ChartSeries2D {
    id: string;
    title: string;
    points: Array<any>;
    xAccessor: (point: any) => number;
    yAccessor: (point: any) => number;
    color: string;

    tipData: (point: any) => string;

    hidden: boolean;

    constructor() {
        // uuids often strats with a digit, which breaks d3 class selectors
        // we add a letter prefix to fix this
        this.id = 'c' + uuid();
        this.xAccessor = (point: any) => point.x;
        this.yAccessor = (point: any) => point.y;
    }

    abstract draw(
        chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>,
        xScale: d3.ScaleContinuousNumeric<number, number>,
        yScale: d3.ScaleContinuousNumeric<number, number>,
        tooltip: any): void;

    @computed('points', 'xAccessor', 'yAccessor')
    get validPoints(): Array<any> {
        return this.points.filter(d => isValidNumber(this.xAccessor(d)) && isValidNumber(this.yAccessor(d)));
    }

    get domainX(): { min: number, max: number } {
        const extent = d3.extent(this.points, this.xAccessor);
        if (!extent[0]) return { min: NaN, max: NaN };
        return { min: extent[0], max: extent[1] };
    }

    get domainY(): { min: number, max: number } {
        const extent = d3.extent(this.points, this.yAccessor);
        if (!extent[0]) return { min: NaN, max: NaN };
        return { min: extent[0], max: extent[1] };
    }

    @computed('validPoints')
    get pointsWithTip() {
        const tipData = this.tipData;
        return this.validPoints.map((p: any) => ({ ...p, tipData }));
    }

    dummyMouseEventHander() {
        //
    }
}

export class PointSeries extends ChartSeries2D {

    constructor() {
        super();

        this.tipData = (point: any) => `<div class="d3-tip-inner" style="background-color:${this.color};">
                ${this.title} <br/>
                (${this.xAccessor(point)} : ${this.yAccessor(point)})
            </div>`;
    }

    draw(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>,
        xScale: d3.ScaleContinuousNumeric<number, number>,
        yScale: d3.ScaleContinuousNumeric<number, number>,
        tooltip: any): void {
        const circle = chart.selectAll('.' + this.id)
            .data(this.pointsWithTip);
        circle.exit().remove();

        if (this.hidden) return;

        // @ts-ignore
        circle.enter().append('circle').merge(circle)
            .classed(this.id, true)
            .attr('cx', (d: any) => xScale(this.xAccessor(d)))
            .attr('cy', (d: any) => yScale(this.yAccessor(d)))
            .attr('r', 4.5)
            .attr('fill', () => d3.rgb(this.color))
            .on('mouseover', this.tipData ? tooltip.show : this.dummyMouseEventHander)
            .on('mouseout', this.tipData ? tooltip.hide : this.dummyMouseEventHander);
    }

    static clearAll(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>) {
        chart.selectAll('circle').remove();
    }
}

export class LineSeries extends ChartSeries2D {

    draw(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>,
        xScale: d3.ScaleContinuousNumeric<number, number>,
        yScale: d3.ScaleContinuousNumeric<number, number>,
        tooltip: any): void {
        chart.selectAll('.' + this.id).remove();

        if (this.hidden) return;
        if (this.pointsWithTip.length === 0) return;

        // @ts-ignore
        chart.append('path')
            .datum(this.pointsWithTip)
            .classed(this.id, true)
            .attr('fill', 'none')
            .attr('stroke', this.color)
            .attr('stroke-width', 1.5)
            .attr('d', d3.line()
                // @ts-ignore
                .x((d: ChartSeries2DPoint) => xScale(this.xAccessor(d)))
                .y((d: ChartSeries2DPoint) => yScale(this.yAccessor(d)))
            )
            .on('mouseover', this.tipData ? tooltip.show : this.dummyMouseEventHander)
            .on('mouseout', this.tipData ? tooltip.hide : this.dummyMouseEventHander);
    }

    static clearAll(chart: d3.Selection<Element, unknown, HTMLElement | null, any | undefined>) {
        chart.selectAll('path').remove();
    }
}
