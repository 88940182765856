import { all } from 'redux-saga/effects';
// import saturationConceptSagas from './saturation-concept/index';
// import saturationConceptVersionSagas from './saturation-concept-version/index';
// import saturationConceptActionSagas from './saturation-concept-action/index';
// import saturationConceptReferenceSagas from './saturation-concept-reference/index';
// import postFunctionMappingParameters from './saturation-concept-function-mapping/post-function-mapping-parameters';

// //master
// import cleanSagas from './master/clean/index';
// import createSagas from './master/create/index';
// import fetchSagas from './master/fetch/index';
// import loaderSagas from './master/load/index';
// import removeSagas from './master/remove/index';
// import selectSagas from './master/select/index';
// import storeSagas from './master/store/index';
// import updateSagas from './master/update/index';

// import referenceMonitor from './master/reference-monitor';
// import synchronizationHandler from './master/synchronization-handler';
// import exceptionHandler from './master/exception-handler';

import _ from 'lodash';

// single entry point to start all Sagas at once
export default function constructRootSaga() {//{ store, database, referenceStore }) {
    let sagas = _.flatMapDeep([
    // saturationConceptSagas({ store, database }),
    // saturationConceptVersionSagas({ store, database }),
    // saturationConceptActionSagas({ database }),
    // saturationConceptReferenceSagas({ referenceStore }),
    // /* Master sagas */
    // loaderSagas({ store, database, referenceStore }),
    // cleanSagas({ store, database, referenceStore }),
    // selectSagas({ store, database, referenceStore }),
    // createSagas({ store, database, referenceStore }),
    // removeSagas({ store, database, referenceStore }),
    // fetchSagas({ store, database, referenceStore }),
    // storeSagas({ store, database, referenceStore }),
    // updateSagas({ store, database, referenceStore }),

    // postFunctionMappingParameters({referenceStore}),
    // referenceMonitor({ referenceStore }),
    // synchronizationHandler({ store, database }),
    // exceptionHandler()
    ]);

    return function* rootSaga() {
        yield all(sagas);
    };
}