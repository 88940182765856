import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import ReferenceAvailability from 'ava-saturation/mixins/reference-availability';
import { computedHash } from 'ava-saturation/utils/model-helpers';
// TODO: [TT] Proper typing
// @ts-ignore - error TS2417
export default class SaturationController extends Controller.extend(ReferenceAvailability, {
    // anything which *must* be merged to prototype here
}) {
    // normal class body definition here
    @service redux: any;
    @service resourceManager: any;
    @service screenSensor: any;
    @service intl: any;
    applicationNames: Array<string> = ['saturation'];
    applicationNameHash: any = computedHash('applicationNames');
    applicationName: string = 'saturation';

    @computed('session.data.authenticated.{ec_token,access_token}')
    get tokenParams(): string {
        // @ts-ignore
        return `ectoken=${this.get('session.data.authenticated.ec_token')}&token=${this.get('session.data.authenticated.access_token')}`;
    }

    clickLink({ link, download, target, authenticate }: any, event: any) {
        this.resourceManager.getResource({ link, download, target, authenticate }, event);
    }

    getGuide(event: MouseEvent) {
        this.resourceManager.getQuickStartGuide(event);
    }

    async downloadPlugin() {
        return this.resourceManager.getPlugin();
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'index': SaturationController;
    }
}
