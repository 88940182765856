import { createCustomAction } from 'typesafe-actions';
import { BandFilter, DeleteBandFilterPayload, UpdateDimensionKeysPayload } from './types';
import constants from './constants';

export const addBandFilter = createCustomAction(constants.ADD, type => {
    return (payload: BandFilter) => ({ type, payload: payload, recordable: true });
});

export const deleteBandFilter = createCustomAction(constants.DELETE, type => {
    return (payload: DeleteBandFilterPayload) => ({ type, payload: payload, recordable: true });
});

export const updateBandFilterDimensionKeys = createCustomAction(constants.UPDATE_DIMENSTION_KEYS, type => {
    return (payload: UpdateDimensionKeysPayload) => ({ type, payload: payload, recordable: true });
});
