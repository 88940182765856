import UomClassValidator from 'ava-import/classes/validators/uom-class-validator';
import IValidator from 'ava-import/interfaces/validator';
import IDimension, { DimensionAxisDisplayPriority, DimensionType, IDiscreteNumericDimension, INumericDimension, NumericDimensionCategory } from 'ava-saturation/interfaces/dimension';
import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';

export class Dimension implements IDimension {
    constructor() {
        this.validators = {};
    }

    name: string;
    shortName: string;
    displayName: string;

    unitAlias: string;
    unitName: string;
    private _displayUnitSymbols: string[];

    get displayUnitSymbols() {
        return this._displayUnitSymbols;
    }
    set displayUnitSymbols(values: string[]) {
        if (values && values.length > 0) {
            this._displayUnitSymbols = values;

            this.validators.uom = { bootstrap: () => new UomClassValidator(this._displayUnitSymbols, this.displayName) };
        }
    }

    type: DimensionType;
    valueField: string = 'name';
    axisPriority: DimensionAxisDisplayPriority = DimensionAxisDisplayPriority.None;

    chartingPreferences: ChartingAxisPreferences = new ChartingAxisPreferences({value: 0, forced: false}, {value: 1, forced: false}, false);

    toPlainObject() {
        return {
            name: this.name,
            shortName: this.shortName,
            displayName: this.displayName,
            unitName: this.unitName,
            unitAlias: this.unitAlias
        };
    }

    serialize(): string {
        return JSON.stringify(this.toPlainObject());
    }

    validators: {
        [key: string]: {
            bootstrap: (...args: any[]) => IValidator<any>
        };
    };
}

export class NonNumericDimension extends Dimension {
    type: DimensionType.Categorical = DimensionType.Categorical;
}

export abstract class NumericDimension extends Dimension implements INumericDimension {
    type: DimensionType.Numeric = DimensionType.Numeric;
    scaleType: ContinuousNumericScaleType = ContinuousNumericScaleType.Linear;
    abstract category: NumericDimensionCategory;
    abstract unitAlias: string;
    abstract unitName: string;
}

export class DiscreteNumericDimension extends NumericDimension implements IDiscreteNumericDimension {
    category: NumericDimensionCategory.Discrete = NumericDimensionCategory.Discrete;
    unitAlias: string;
    unitName: string;
}

export class ContinuosNumericDimension extends NumericDimension implements INumericDimension {
    category: NumericDimensionCategory.Continuous = NumericDimensionCategory.Continuous;
    forcedScaleOverride: ContinuousNumericScaleType;
    unitAlias: string;
    unitName: string;
}