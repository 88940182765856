/* istanbul ignore file */
// TT: untestable unsed in petrel-script context
import toFixedNumber from './to-fixed-number';

export const lerpWithoutCoefficient = function lerpWithoutCoefficient(deps, xA, xB, yA, yB, x) {
    let rangeLength = deps.functions.toFixedNumber(xB - xA);
    let interpolationDistanceLength = deps.functions.toFixedNumber(xB - x);
    var coef = rangeLength == 0 ? 1 : (interpolationDistanceLength / rangeLength);
    return deps.functions.lerp(deps, yA, yB, coef);
};

export const lerp = function lerp(deps, yA, yB, coef) {
    return deps.functions.toFixedNumber(deps.functions.toFixedNumber(1 - coef) * yB + coef * yA);
};

export const dependencies = {
    functions: {
        toFixedNumber,
        lerp
    },
    dimensionsIndex: {
    }
};
