import { allTypesById } from 'ava-saturation/classes/band-types';
import IReference from 'ava-saturation/interfaces/reference';
import { StatementBuilder } from 'ava-saturation/interfaces/statement-builder';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';

export class FunctionBandStatement implements StatementBuilder<IBand> {
    private band: IBand;
    private thenStatementBuilder: (band: IBand) => string;
    private otherwiseStatementBuilder: () => string;
    constructor(band: IBand) {
        this.band = band;
    }
    then(statementBilder: (band: IBand) => string): StatementBuilder<IBand> {
        this.thenStatementBuilder = statementBilder;
        return this;
    }
    otherwise(statementBilder: () => string): StatementBuilder<IBand> {
        this.otherwiseStatementBuilder = statementBilder;
        return this;
    }
    build(propertyMap: {
        [dimensionKey: string]: string;
    }, _elementMap: {
        [dimensionKey: string]: IReference[];
    }) {
        const propertyName = propertyMap[allTypesById[this.band.type].shortName];
        if (!propertyName || this.band.ranges.length === 0)
            return `${this.thenStatementBuilder(this.band)}`;
        const conditional = `(${this.band.ranges.map(range => `(${propertyName}>=${range.from} AND ${propertyName}<${range.to})`).join(' OR ')})`;
        return `if(${conditional}, ${this.thenStatementBuilder(this.band)}, ${this.otherwiseStatementBuilder()})`;
    }
}
