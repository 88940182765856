import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';

export class ChartAxisOptions {
    type: ContinuousNumericScaleType = ContinuousNumericScaleType.Linear;
    title: string = '';
    pinMin: number = NaN;
    pinMax: number = NaN;
    inverted: boolean = false;
    allowZoom: boolean = true;
    zones: { from: number, to: number, color: string, name: string }[];
}