import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import IReference from 'ava-saturation/interfaces/reference';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { IsParameterZeroStatement } from 'ava-saturation/utils/calculator-parameters';
import { StatementBuilder } from 'ava-saturation/interfaces/statement-builder';

export class CuddyCalculationStatement implements StatementBuilder<CalculationConstant> {
    private constants: CalculationConstant;
    // private parameters: CalculationParameter | undefined;
    // private thenStatementBuilder: (constants: CalculationConstant) => string;
    // private otherwiseStatementBuilder: () => string;
    constructor(constants: CalculationConstant, _parameters: CalculationParameter | undefined) {
        this.constants = constants;
        // this.parameters = parameters;
    }
    then(_statementBilder: (band: CalculationConstant) => string): StatementBuilder<CalculationConstant> {
        // this.thenStatementBuilder = statementBilder;
        return this;
    }
    otherwise(_statementBilder: () => string): StatementBuilder<CalculationConstant> {
        // this.otherwiseStatementBuilder = statementBilder;
        return this;
    }
    build(propertyMap: {
        [dimensionKey: string]: string;
    }, _elementMap: {
        [dimensionKey: string]: IReference[];
    }) {
        const hafwlPropertyName = propertyMap[hafwl.shortName];
        const porosityPropertyName = propertyMap[porosity.shortName];
        return `if(${porosityPropertyName}=U or ${IsParameterZeroStatement(porosityPropertyName)} or ${IsParameterZeroStatement(hafwlPropertyName)}, U, (${this.constants.constants.a}*POW(${hafwlPropertyName},${this.constants.constants.b}))/${porosityPropertyName})`;
    }
}
