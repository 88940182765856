import IReference, { IReferenceBase } from 'ava-saturation/interfaces/reference';
import { isEmpty } from '@ember/utils';
import Moniker from 'ava-saturation/classes/moniker';

export default interface IReferenceParser<T extends IReferenceBase | Array<IReferenceBase>> {
    origin: string;
    version: string;
    parse(object: any): T;
}

export abstract class ReferenceParserBase<T> implements IReferenceParser<T> {
    constructor(origin: string, version: string) {
        this.origin = origin;
        this.version = version;
    }

    origin: string;
    version: string;

    abstract parse(object: any): T;

    protected parseMoniker(object: any, identifierSelector: (object: any) => string = (object: any) => object.ObjectId._instance) {
        return new Moniker({
            origin: this.origin,
            version: this.version,
            identifier: identifierSelector(object)
        });
    }

    protected parseTemplateMoniker(object: any, identifierSelector: (object: any) => string = (object: any) => object.TemplateId._instance) {
        return new Moniker({
            origin: this.origin,
            version: this.version,
            identifier: identifierSelector(object)
        });
    }

    protected parseModificationDate(object: any) {
        let history = object.History;
        return isEmpty(history) ? null : history[0].EndDateTimeOffset;
    }
}

export abstract class ReferenceParser<T extends IReference | Array<IReference>> extends ReferenceParserBase<T> {
    constructor(origin: string, version: string) {
        super(origin, version);
    }

    /**
    * Appends the corresponding gridMoniker to a GridObjectColletion recieved from the plugin
    * @param object  Array<{IGrid,  [key: string]: T}>
    * @param prop    the key string
    * @returns       Array<T with appended gridMoniker>
    */
    appendGridMoniker(object: Array<any>, prop: string): Array<Record<string, any>> {
        const result: Array<Record<string, any>> = [];
        object.forEach(x => {
            x[prop].forEach((o: Record<string, any>) => {
                o.gridMoniker = this.parseMoniker(x.Grid);
                result.push(o);
            });
            return x;
        });
        return result;
    }

    /**
    * Appends the corresponding wellMoniker to a WellObjectColletion recieved from the plugin
    * @param object  Array<{IWell,  [key: string]: T}>
    * @param prop    the key string
    * @returns       Array<T with appended wellMoniker>
    */
    appendWellMoniker(object: Array<any>, prop: string): Array<Record<string, any>> {
        const result: Array<Record<string, any>> = [];
        object.forEach(x => {
            x[prop].forEach((o: Record<string, any>) => {
                o.wellMoniker = this.parseMoniker(x.Well);
                result.push(o);
            });
            return x;
        });
        return result;
    }
}

export class SingleReferenceParser<T extends IReference> extends ReferenceParser<T> {
    parse(object: any): T {
        return {
            ...object,
            name: object.Name,
            icon: object.Icon,
            color: object.Color,
            moniker: this.parseMoniker(object),
            modified: this.parseModificationDate(object),
            versionEntry: {
                modified: this.parseModificationDate(object),
            }
        };
    }
}

export function referenceCollectionParser<T extends IReference>(object: any, singleReferenceParser: SingleReferenceParser<T>): Array<T> {
    return (object as Array<any>).map(o => singleReferenceParser.parse(o));
}