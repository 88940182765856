import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as functionActions from '../model-function/actions';
import * as actions from './actions';
import { CalculationSet, CalculationSetState } from './types';

export type CalculationSetActionType = ActionType<typeof actions> | ActionType<typeof functionActions> | ActionType<typeof saturationConceptActions>;

const calculationSets = (state: CalculationSet[] = [], action: CalculationSetActionType): CalculationSet[] => {
    switch (action.type) {
        case getType(actions.addCalculationSet):
            return [
                ...state, {
                    ...action.payload
                }
            ];

        case getType(functionActions.deleteFunction): {
            return state.filter(b => b.functionId !== action.payload.id);
        }

        case getType(actions.deleteCalculationSet): {
            return state.filter(c => c.id !== action.payload.id);
        }

        default:
            return state;
    }
};

export function modelCalculationSetBySid(state: Record<string, CalculationSet[]> = {}, action: CalculationSetActionType) {
    switch (action.type) {
        case getType(functionActions.deleteFunction):
        case getType(actions.deleteCalculationSet):
        case getType(actions.addCalculationSet): {
            return {
                ...state,
                [action.payload.sid]: calculationSets(state[action.payload.sid], action)
            };

        }

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        default:
            return state;
    }
}

export default combineReducers<CalculationSetState>({
    bySid: modelCalculationSetBySid
});
