import { computed } from '@ember/object';
import Component from '@ember/component';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IReference from 'ava-saturation/interfaces/reference';
import { getModelAreas } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/selectors';
import { ModelArea, ModelAreaStub } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';

const stateToComputed = function (this: ElementStateContextProvider, state: any) {
    const areasBySid = getModelAreas(state, {
        sid: this.conceptId,
    });

    return {
        areasBySid
    };
};

const dispatchToActions = function (this: ElementStateContextProvider) {
    return {
    };
};

export interface IGridReference extends IReference {
    gridMoniker: IMoniker;
}

export class ElementStateContextProvider extends Component {
    conceptId: string;
    model: Model;
    type: IDimension;
    elements: Array<IGridReference>;
    areasBySid: Array<ModelArea>;

    @computed('elements')
    get filteredElements(): Array<IGridReference> {
        return this.elements.filter(p => p.gridMoniker.string === this.model.moniker);
    }

    @computed('areasBySid', 'model.moniker', 'type')
    get existingAreas(): Array<ModelArea> {
        return this.areasBySid.filter(a => a.modelMoniker === this.model.moniker && a.type === this.type.name);
    }

    @computed('filteredElements.[]', 'existingAreas.[]')
    get areas() {
        return this.filteredElements.map((element) => {
            let area = this.existingAreas.find(m => m.monikers.includes(element.moniker.string)) as ModelAreaStub;

            let target = area || {
                id: uuid(),
                sid: this.conceptId,
                modelMoniker: this.model.moniker,
                monikers: [element.moniker.string],
                name: null,
                type: this.type.shortName,
                isStub: true,
            };

            return target;
        }).uniq().sort((a1, a2) => a2.monikers.length - a1.monikers.length);
    }

    @computed('areas')
    get standaloneAreas() {
        return this.areas.filter(a => a.monikers.length === 1);
    }

    @computed('areas')
    get aggregateAreas() {
        return this.areas.filter(a => a.monikers.length > 1);
    }
}

export default connect(stateToComputed, dispatchToActions)(ElementStateContextProvider);