import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as modelActions from './actions';
import { Model, ModelState } from './types';

export type ModelActionType = ActionType<typeof modelActions> | ActionType<typeof saturationConceptActions>;

export function modelsById(state: Record<string, Model> = {}, action: ModelActionType): Record<string, Model> {

    switch (action.type) {
        case getType(modelActions.addModel):
        case getType(modelActions.setModel):
            return {
                ...state,
                [action.payload.id]: {
                    ...action.payload
                }
            };

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            const keys = Object.keys(state).filter(k => newState[k].sid === action.payload.id);
            keys.forEach(k => delete newState[k]);
            return newState;
        }

        case getType(modelActions.deleteModel): {
            const { [action.payload.id]: value, ...newState } = state;
            return newState;
        }

        case getType(saturationConceptActions.createConcept): {
            if (action.payload.metadata.modelId)
                return {
                    ...state,
                    [action.payload.metadata.modelId]: {
                        id: action.payload.metadata.modelId,
                        sid: action.payload.id,
                        moniker: action.payload.metadata.sourceMonikerString
                    }
                };
            return state;
        }

        default:
            return state;
    }
}

export default combineReducers<ModelState>({
    byId: modelsById
});