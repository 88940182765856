import { observes } from '@ember-decorators/object';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { IBand, IRange } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import d3 from 'd3';
import { isNull, isString, trim } from 'lodash';

export interface IAssociation {
    id: string;
    name: string;
}

export default class D3Range extends Component {
    @service screenSensor: any;
    @service intl: any;

    // inputs
    container: Element | null;
    rangesContainer: Element | null;
    axisContainer: Element | null;

    min: number;
    max: number;

    ranges: Array<IRange>;
    bands: Array<IBand>;

    onRangeAccepted: (newRange: { from: number; to: number; name: string; }) => void;

    // parameters
    bandName: string | null = null;
    resetName: boolean = true;
    from: number | null = null;
    to: number | null = null;

    init() {
        super.init();
        assert('d3-range.min is required.', this.min != null);
        assert('d3-range.max is required.', this.max != null);
        assert('d3-range.ragnes is required.', this.ranges != null);
        assert('d3-range.onRangeAccepted is required.', this.onRangeAccepted != null);
        this.invalidateRender();
    }

    didInsertElement() {
        // can add a one time computed property for the container change
        const container = d3.select(this.element);
        const rangesContainer = d3.select(this.element.querySelector('svg > .ranges > g.content'));
        const axisContainer = d3.select(this.element.querySelector('svg > .ranges > g.axis'));

        set(this, 'container', container.node());
        set(this, 'rangesContainer', rangesContainer.node());
        set(this, 'axisContainer', axisContainer.node());
    }

    didRender() {
        this.renderRanges();
    }

    // methods
    _calculateStep(value: number) {
        const step = Math.abs(Number(value)) / 10;
        return step.toFixed(3);
    }

    @computed('from', 'min')
    get dynamicFromStep() {
        return this._calculateStep(Number(this.min) > 0.1 ? this.min : 0.1);
    }

    @computed('to', 'max')
    get dynamicToStep() {
        return this._calculateStep(this.max);
    }

    @computed('screenSensor.width', 'container', 'forceResize')
    public get width(): number {
        if (this.container == null || this.screenSensor.width === 0)
            return 0;

        return this.container.clientWidth;
    }

    @computed('min', 'max', 'width')
    get xScale() {
        return d3.scaleLinear()
            .domain([this.min, this.max])
            .rangeRound([0, this.width]);
    }

    @computed('xScale', 'from')
    get editRangeX() {
        return this.xScale(Number(this.from));
    }

    @computed('xScale', 'to', 'from')
    get editRangeWidth() {
        if (!isNull(this.to) && !isNull(this.from) && Number(this.to) > Number(this.from)) {
            return this.xScale(Number(this.to) - Number(this.from));
        }

        return this.xScale(0);
    }

    @computed('min', 'max', 'ranges.[]', 'to')
    get fromValidationRules() {
        return [
            {
                id: 'atLeast',
                message: this.intl.t('band-range.validation.atLeast', {min: this.min}),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return this.min <= Number(value) && value != null;
                }
            },
            {
                id: 'intersects',
                message: this.intl.t('band-range.validation.invalidFrom'),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return this.ranges.filter(r => r.parentId !== 'global').any(r => r.from < value && r.to > value) === false;
                }
            },
            {
                id: 'lessThen',
                message: this.intl.t('band-range.validation.lessThan', {max: Math.min(this.max, Number(this.to || this.max))}),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return Math.min(this.max, Number(this.to || this.max)) >= Number(value);
                }
            },
            {
                id: 'overlapped',
                message: this.intl.t('band-range.validation.overlapped'),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;
                    if (this.to === null)
                        return true;

                    // @ts-ignore
                    return this.ranges.filter(r => r.parentId !== 'global').any(r => r.from >= value && r.to <= this.to) === false;
                }
            }
        ];
    }

    @computed('min', 'max', 'ranges.[]', 'from')
    get toValidationRules() {
        return [
            {
                id: 'atLeast',
                message: this.intl.t('band-range.validation.atLeast', {min: Math.max(this.min, Number(this.from))}),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return Math.max(this.min, Number(this.from)) <= Number(value);
                }
            },
            {
                id: 'intersects',
                message: this.intl.t('band-range.validation.invalidTo'),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return this.ranges.filter(r => r.parentId !== 'global').any(r => r.from < value && r.to > value) === false;
                }
            },
            {
                id: 'lessThen',
                message: this.intl.t('band-range.validation.lessThan', {max: this.max}),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;

                    return this.max >= Number(value) && value != null;
                }
            },
            {
                id: 'overlapped',
                message: this.intl.t('band-range.validation.overlapped'),
                validate: (value: number | null) => {
                    if (value === null)
                        return true;
                    if (this.from === null)
                        return true;

                    // @ts-ignore
                    return this.ranges.filter(r => r.parentId !== 'global').any(r => r.from >= this.from && r.to <= value) === false;
                }
            }
        ];
    }

    @computed('bandName')
    get isBandNameValid() {
        return isString(this.bandName) ? !!trim(this.bandName) : false;
    }

    @computed('from', 'to', 'fromValidationRules.[]', 'toValidationRules.[]', 'isBandNameValid')
    get isRangeValid() {
        return this.isBandNameValid && this.from != null && this.to != null &&
            this.fromValidationRules.every(r => r.validate(this.from)) &&
            this.toValidationRules.every(r => r.validate(this.to));
    }

    renderInvalidated: boolean = true;
    @observes('ranges.[]')
    invalidateRender() {
        this.renderInvalidated = true;
    }

    renderRanges() {
        if (this.renderInvalidated) {
            const container = d3.select(this.rangesContainer);
            const axisContainer = d3.select(this.axisContainer);

            container.selectAll('rect').remove();

            container.selectAll('rect')
                .data(this.ranges)
                .enter()
                .append('rect')
                .attr('x', (d: any) => {
                    return this.xScale(d.from);
                })
                .attr('height', 30)
                .attr('width', (d) => {
                    return this.xScale(d.to - d.from);
                })
                .attr('class', (d) => {
                    return d.color;
                })
                .style('cursor', 'pointer')
                .on('click', (d: any) => {
                    if (d.parentId === 'global') {
                        set(this, 'from', d.from);
                        set(this, 'to', d.to);
                    }
                })
                .on('mouseover', function () {
                    let element = d3.select(this);
                    element.style('opacity', 0.6);
                })
                .on('mouseout', function () {
                    let element = d3.select(this);
                    element.style('opacity', 1);
                });

            // @ts-ignore
            d3.axisBottom(this.xScale)(axisContainer);
            // .tickValues(flatMap(this.ranges, r => [r.from, r.to]).uniq());
            this.renderInvalidated = false;
        }
    }

    @action
    createRange() {
        this.onRangeAccepted({
            name: this.bandName ? this.bandName : '',
            from: Number(this.from),
            to: Number(this.to)
        });

        this.actions.resetRange.call(this);
    }

    @action
    resetRange() {
        set(this, 'from', null);
        set(this, 'to', null);
        set(this, 'bandName', '');
        set(this, 'resetName', true);
    }
}