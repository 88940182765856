import { helper } from '@ember/component/helper';

/**
 * Checks if value<IMoniker> is in collection<IMoniker>
 * @param Tuple<Array<IMoniker>, IMoniker>
 * @returns boolean
 */
export function includesMoniker(params) {
    const [items, value] = params;
    return items && items.length && items.some(item => item['string'] === value['string']);
}

export default helper(includesMoniker);
