export function initialize(application) {
    application.inject('route', 'features', 'service:features');
    application.inject('controller', 'features', 'service:features');
    application.inject('component', 'features', 'service:features');
}

export default {
    name: 'features',
    initialize
};
