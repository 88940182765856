import { flatMap } from 'lodash';
import regression from 'regression';
import RegressionConstantsCalculator from './calculator';

export default class SkeltRegressionConstantsCalculator extends RegressionConstantsCalculator {

    constructor() {
        super('hafwl', 'sw');
    }

    calculate(dataSets) {
        let combinedDataSets = flatMap(dataSets, ds => ds.values);
        let xKey = this.xKey,
            yKey = this.yKey;

        let points = combinedDataSets
            .filter(p =>
                isNaN(Number(p[xKey])) == false &&
                isNaN(Number(p[yKey])) == false &&
                p[xKey] !== 0
            )
            .map(point => {
                let xKeyValue = point[xKey],
                    yKeyValue = point[yKey];

                return [(-1 / xKeyValue), 1 - yKeyValue];
            });

        var result = regression.exponential(points, {
            precision: 4
        });

        return {
            a: result.equation[0],
            b: result.equation[1],
            string: result.string
        };
    }
}
