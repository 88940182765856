import Component from '@ember/component';
import { inject } from '@ember/service';
import { connect } from 'ember-redux';
import {
    CLEAR_SATURATION_ERROR
} from 'ava-saturation/actions/definitions/general';
//selectors
import { selectGeneralError } from 'ava-saturation/selectors/errors';
// TODO: Refactor
var stateToComputed = (state) => {
    let error = selectGeneralError(state);

    return {
        text: error.origin ? `An unexpected error occured while executing ${error.origin}` : undefined
    };
};

var ErrorNotification = Component.extend({
    paperToaster: inject()
});

var dispatchToActions = (dispatch) => {
    return {
        onClose() {
            dispatch({type: CLEAR_SATURATION_ERROR});
        },
        close() {
            this.actions.onClose();
        }
    };
};

export default connect(stateToComputed, dispatchToActions)(ErrorNotification);
