import { orderBy } from 'lodash';

export const parsePatch = (p) => {
    let conceptId = p.get('saturationConceptId');
    let recordedActions = p.get('recordedActions').map(a => {
        return {
            id: a.get('id'),
            type: a.get('action-type'),
            time: a.get('time'),
            patchId: p.get('id'),
            versionId: p.get('version-id'),
            ...a.get('parameters')
        };
    });

    let patch = p.toJSON();
    patch.id = p.get('id');
    patch.saturationConceptId = conceptId;
    patch.recordedActions = recordedActions;

    return patch;
};

export const parsePatches = (patches, conceptId) => {
    return orderBy(patches.map(v => parsePatch(v, conceptId)), a => new Date(a.time));
};

export const parseVersions = (versions, conceptId) => {
    return orderBy(versions.map(v => parseVersion(v, conceptId)), a => new Date(a.time));
};

export const parseVersion = (v) => {
    let conceptId = v.get('saturationConceptId');
    let patches = v.get('patches').map(p => {
        return parsePatch(p);
    });

    let version = v.toJSON();
    version.id = v.get('id');
    version.versionNo = v.get('version-no');
    version.saturationConceptId = conceptId;
    version.patches = patches;

    return version;
};

export const extractActions = (versions, patches) => {
    let actions = [];
    for (let version of versions) {
        for (let patch of version.patches) {
            actions.push(...patch.recordedActions);
        }
    }

    for (let patch of patches) {
        actions.push(...patch.recordedActions);
    }

    return actions;
};

export const extractCachedActions = (actions) => {
    return actions.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
    }).map(a => {
        return {
            type: a['action-type'],
            payload: a.parameters.payload,
            patchId: a.anchorPatchId,
            versionId: a.anchorVersionId,
            conceptId: a.saturationConceptId
        };
    });
};

export const missingVersions = (concept, versions) => {
    versions.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
    });

    if (concept) {
        const ids = versions.map(v => v.id);
        const index = ids.indexOf(concept.versionId);
        if (index > -1) {
            return versions.slice(index + 1);
        }
    }
    return versions;
};

export const missingPatches = (concept, patches) => {
    patches.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
    });
    if (concept) {
        const ids = patches.map(v => v.id);
        const index = ids.indexOf(concept.patchId);
        if (index > -1) {
            return patches.slice(index + 1);
        }
    }
    return patches;
};
