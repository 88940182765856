import isInZeroOneRange from 'ava-saturation/classes/calculations/dependency-injection/filters/is-in-zero-one-range';

export const dependencies = {
    functions: {
        isInZeroOneRange
    },
    dimensionsIndex: {
    }
};

export default function isPorosityPointValid(deps, point) {
    if (!point)
        return false;

    if (isNaN(Number(point.por)))
        return false;

    return deps.functions.isInZeroOneRange(deps, point.por);
}