import $ from 'jquery';
import Service from '@ember/service';

import { inject } from '@ember/service';
import { computed } from '@ember/object';

import configuration from 'ava-saturation/config/environment';

const click = (link, { download, target } = {}) => {
    const a = window.document.createElement('a');

    a.href = link;

    if (download) {
        a.download = download;
    }

    if (target) {
        a.target = target;
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

const appenUrlParams = (url, params) => `${url}${url.indexOf('?') >= 0 ? '&' : '?'}${params}`;

export default Service.extend({
    session: inject(),

    tokenParams: computed('session.data.authenticated.{ec_token,access_token}', function () {
        return `ectoken=${this.session.get('data.authenticated.ec_token')}&token=${this.session.get('data.authenticated.access_token')}`;
    }),

    getResource({ link, download, target = '_blank', authenticate }, event) {
        if (event) {
            event.preventDefault();
        }

        if (!authenticate) {
            click(link, { download, target });
            return;
        }

        if (!this.get('session.isAccessTokenExpired')) {
            click(appenUrlParams(link, this.tokenParams), { download, target });
            return;
        }

        this.session
            .refreshAccessToken()
            .then(() => click(appenUrlParams(link, this.tokenParams), { download, target }))
            .catch((/* error */) => {
                // this.get('notify').logAndAlert(error, 'request-failed-message');
            });
    },
    getQuickStartGuide(event) {
        try {
            const download = 'Ava_Saturation_Quick_Start_Guide.pdf';
            const link = `${configuration.APP.cdnEndpoint}assets/eval/${download}`;
            this.getResource({ link, download, target: undefined, authenticate: true }, event);
        } catch (error) {
            // this.get('notify').logAndAlert(error, 'request-failed-message');
        }
    },
    getCPImportTemplate() {
        try {
            const download = `Ava_Saturation_CP_import_Template_${configuration.APP.version}.xlsx`;
            const link = `${configuration.APP.cdnEndpoint}assets/eval/${download}`;
            this.getResource({ link, download, target: null, authenticate: true });
        }
        catch (error) {
            // this.get('notify').logAndAlert(error, 'request-failed-message');
        }
    },

    async getPlugin() {
        try {
            const pluginVersion = (await $.get(`${configuration.rootURL}.plugin-version`)).trim();
            const download = `Ava_${pluginVersion}_Petrel.zip`;
            const link = `${configuration.APP.cdnEndpoint}downloads/${download}`;
            this.getResource({ link, download, target: null, authenticate: true });
        }
        catch (error) {
            // this.get('notify').logAndAlert(error, 'request-failed-message');
        }
    }
});


// import Service from '@ember/service';

// import { computed } from '@ember/object';
// import { inject as service } from '@ember/service';

// import configuration from 'ava-saturation/config/environment';

// const click = (link: string, download: string) => {
//     const a = window.document.createElement("a");
//     a.href = link;
//     a.target = "_blank";
//     if (download) {
//         a.download = download;
//     }
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
// };

// const appenUrlParams = (url: string, params: string) => `${url}${url.indexOf('?') >= 0 ? '&' : '?'}${params}`;

// export default class ResourceManager extends Service {
//     @service session: any;

//     @computed('session.data.authenticated.{ec_token,access_token}')
//     get tokenParams() {
//         return `ectoken=${this.session.get('data.authenticated.ec_token')}&token=${this.session.get('data.authenticated.access_token')}`;
//     }

//     getResource({ link, download, authenticate }: { link: string, download: string, authenticate: boolean }, event: Event | null = null) {
//         if (event) {
//             event.preventDefault();
//         }

//         if (!authenticate) {
//             click(link, download);
//             return;
//         }

//         if (!this.session.get('isAccessTokenExpired')) {
//             click(appenUrlParams(link, this.get('tokenParams')), download);
//             return;
//         }

//         this.get('session')
//             .refreshAccessToken()
//             .then(() => click(appenUrlParams(link, this.get('tokenParams')), download))
//             .catch((/* error */) => {
//                 //this.get('notify').logAndAlert(error, 'request-failed-message');
//             });
//     }

//     async getPlugin() {
//         try {
//             const pluginVersion = await $.get(`${configuration.rootURL}.plugin-version`);
//             const download = `Ava_${pluginVersion}_Petrel.zip`;
//             const link = `${configuration.APP.cdnEndpoint}downloads/${download}`;
//             this.getResource({ link, download, authenticate: true });
//         }
//         catch (error) {
//             //this.get('notify').logAndAlert(error, 'request-failed-message');
//         }
//     }
// }
