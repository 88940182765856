import { computed } from '@ember/object';
import Component from '@ember/component';
import { BandType } from 'ava-saturation/classes/band-types';
import { availableCalculationMethods } from 'ava-saturation/classes/calculation-types';
import { addFunctionBand, deleteFunctionBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/actions';
import { bandTheme } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/selectors';
import { IBand, IRange } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';
import { maxBy } from 'lodash';
import extendRanges from 'ava-saturation/utils/extend-ranges';

const GLOBAL_PARENT = 'global';

const stateToComputed = function (this: BandManagerComponent) {
    return {
    };
};

const dispatchToActions = function (this: BandManagerComponent, dispatch: (action: any) => void) {

    return {
        RangeAccepted(this: BandManagerComponent, newRange: IRange) {
            // let oldRange = this.extendedRanges.find(r => r.id === newRange.id);

            // if (oldRange !== undefined) {
            //     if (newRange.parentId !== GLOBAL_PARENT) {
            //         let rangesToUpdate = this.extendedRanges.filter(r => r.parentId === newRange.parentId).reduce((acc, r) => {
            //             return {
            //                 ...acc,
            //                 [r.id]: {
            //                     ...r
            //                 }
            //             };
            //         }, {} as { [key: string]: IRange });
            //         rangesToUpdate = { ...rangesToUpdate, [newRange.id]: { ...newRange } };

            //         dispatch(updateRanges({
            //             id: newRange.parentId,
            //             sid: this.conceptId,
            //             ranges: Object.keys(rangesToUpdate).map(k => ({ from: rangesToUpdate[k].from, to: rangesToUpdate[k].to }))
            //         }));
            //     }

            //     if (newRange.parentId !== oldRange.parentId && oldRange.parentId !== GLOBAL_PARENT) {
            //         let rangesToUpdate = this.extendedRanges.filter(r => r.parentId === oldRange.parentId).reduce((acc, r) => {
            //             return {
            //                 ...acc,
            //                 [r.id]: {
            //                     ...r
            //                 }
            //             };
            //         }, {} as { [key: string]: IRange });
            //         const { [oldRange.id]: value, ...oldRangesToUpdate } = rangesToUpdate;
            //         dispatch(updateRanges({
            //             id: oldRange.parentId,
            //             sid: this.conceptId,
            //             ranges: Object.keys(oldRangesToUpdate).map(k => ({ from: oldRangesToUpdate[k].from, to: oldRangesToUpdate[k].to }))
            //         }));
            //     }
            // }

            if (newRange.parentId == null) {
                dispatch(addFunctionBand({
                    id: uuid(),
                    calculationSetIds: [],
                    functionId: this.functionId,
                    modelMoniker: '',
                    name: newRange.name,
                    ranges: [{ from: newRange.from, to: newRange.to }],
                    calculationType: availableCalculationMethods(this.bandType.id)[0].id,
                    sid: this.conceptId,
                    type: this.bandType.id,
                    // @ts-ignore
                    colorIndex: maxBy(this.bands, b => b.colorIndex).colorIndex + 1 % this.colors.length
                }));
                if (this.onBandChange)
                    this.onBandChange();
            }
        },

        deleteBand(this: BandManagerComponent, band: IBand) {
            dispatch(deleteFunctionBand({
                id: band.id,
                sid: this.conceptId,
            }));
            if (this.onBandChange)
                    this.onBandChange();
        }
    };
};

export class BandManagerComponent extends Component {
    functionId: string;
    bands: IBand[];
    ranges: IRange[];
    conceptId: string;
    bandType: BandType;
    statistics: {
        min: number;
        max: number;
        editable: boolean;
    };

    colors = bandTheme;

    onBandChange: () => void;

    @computed('statistics')
    get min(): number {
        return this.statistics.min;
    }

    @computed('statistics')
    get max(): number {
        return this.statistics.max;
    }

    @computed('ranges.[]')
    get extendedRanges(): Array<IRange> {
        return extendRanges(this.ranges, this.min, this.max, (from: number, to: number, id: string): IRange => {
            return {
                id,
                name: '',
                functionId: this.functionId,
                parentId: GLOBAL_PARENT,
                from,
                to,
                color: this.colors[0],
                bandType: this.bandType.id
            };
        });
    }

    @computed('bandType')
    get bandingName(): string {
        return `concept/${this.bandType.name.toLowerCase()}-banding`;
    }
}

export default connect(stateToComputed, dispatchToActions)(BandManagerComponent);