import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { getOwner } from '@ember/application';
import configuration from 'ava-saturation/configuration';
// import BrowserDetector from 'ava-saturation/utils/browser-detector';

export default Mixin.create({
    features: computed(function () {
        return getOwner(this).lookup('service:features');
    }),

    beforeModel() {
        if(!featuresManager.hasFullFeaturesSet) {
            window.location.replace(configuration.routeIfNotSupported);
        }
        this._super(...arguments);
    }
});
