import { computed } from '@ember/object';
import Component from '@ember/component';
import { BandType, general } from 'ava-saturation/classes/band-types';
import { availableCalculationMethods, CalculationType, leverettJ } from 'ava-saturation/classes/calculation-types';
import { DataSourceInstance } from 'ava-saturation/classes/data-source';
import facies from 'ava-saturation/classes/dimensions/facies';
import pointLog from 'ava-saturation/classes/dimensions/point-well-log';
import porosity from 'ava-saturation/classes/dimensions/porosity';
// dimensions
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import Moniker from 'ava-saturation/classes/moniker';
import { CalculationSet as BandCalculationSet } from 'ava-saturation/interfaces/calculation-store';
import IWell from 'ava-saturation/interfaces/well-reference';
import { getCalculationParametersBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/selectors';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { getCalculationSetsBySid } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/selectors';
import { CalculationSet } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-set/types';
import { updateCalculationType } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/actions';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

const dimensions = [porosity, waterSaturation, pointLog];
// @ts-ignore
const supplementaryDimensions = [facies];

const stateToComputed = function (this: FunctionBand, state: any) {
    const calculationSetsBySid = getCalculationSetsBySid(state, {
        sid: this.instance.sid,
    });

    const calculationParametersBySid = getCalculationParametersBySid(state, {
        sid: this.instance.sid,
    });

    return {
        calculationSetsBySid,
        calculationParametersBySid
    };
};

const dispatchToActions = function (this: FunctionBand, dispatch: Dispatch) {
    return {
        changeCalculationMethod(this: FunctionBand, calculationType: CalculationType) {
            dispatch(updateCalculationType({
                id: this.instance.id,
                sid: this.instance.sid,
                calculationType: calculationType.id
            }));
            if (this.onCalculationMethodChange) {
                this.onCalculationMethodChange();
            }
        }
    };
};

export class FunctionBand extends Component {
    instance: IBand;
    bandType: BandType;
    applicableWells: IWell[];
    onCalculationMethodChange: () => void;

    // state
    calculationSetsBySid: CalculationSet[];
    calculationParametersBySid: CalculationParameter[];

    @computed('instance.ranges')
    get rangeDisplay() {
        return this.bandType === general || this.instance.id.indexOf('/') !== -1 ?
            '' : `${this.instance.ranges.map(r => `<span class="layout-font-weight-600">${r.from}</span>&nbsp; to &nbsp;<span class="layout-font-weight-600">${r.to}</span>`)}`;
    }

    @computed('bandType')
    get availableCalculationMethods() {
        return availableCalculationMethods(this.bandType.id);
    }

    @computed('instance.calculationType', 'availableCalculationMethods')
    get calculationMethod() {
        return this.availableCalculationMethods.find(cm => cm.id === this.instance.calculationType);
    }

    @computed('calculationMethod')
    get isAvailable() {
        return this.calculationMethod !== undefined && this.applicableWells.length !== 0;
    }

    @computed('calculationMethod', 'bandType')
    get inputComponent() {
        if (!this.calculationMethod)
            throw 'A band must have a calculation type set';

        return `concept/function-inputs/${this.bandType.id}-${this.calculationMethod.id}`;
    }

    @computed('calculationMethod', 'bandType')
    get resultComponent() {
        if (!this.calculationMethod)
            throw 'A band must have a calculation type set';

        return `concept/function-results/${this.bandType.id}-${this.calculationMethod.id}`;
    }

    @computed('calculationMethod', 'bandType')
    get parameterComponent() {
        if (!this.calculationMethod)
            throw 'A band must have a calculation type set';

        if (this.calculationMethod === leverettJ)
            return `concept/function-parameters/${this.bandType.id}-${this.calculationMethod.id}`;

        return null;
    }

    @computed('calculationSetsBySid')
    get calculationSets() {
        return this.calculationSetsBySid.filter(cs => cs.bandId === this.instance.id && cs.calculationType === this.instance.calculationType);
    }

    @computed('calculationParametersBySid')
    get calculationParameters() {
        // currently support only one parameter configuration for a band
        // switch to filter, add a dropdown, extend the function-band with the selected parameter configuration id and you're done
        return this.calculationParametersBySid.find(cs => cs.bandId === this.instance.id);
    }

    @computed('calculationMethod', 'calculationSetsBySid')
    get dataSourceInstance() {
        if (!this.calculationMethod)
            return null;

        const result = new DataSourceInstance({ definition: this.calculationMethod.dataSourceDefinition }, this.applicableWells);
        result.calculationSets = this.calculationSets
            .map(s => {
                const wellMoniker = new Moniker({ string: s.wellMoniker });

                const sets: BandCalculationSet[] = Object.keys(s.byDimensionKey).map(key => {
                    const calculableDimension = dimensions.find(d => d.shortName === key);
                    const wellLogMoniker = new Moniker({ string: s.byDimensionKey[key].moniker });

                    if (calculableDimension)
                        return {
                            monikerDictionary: { wellMoniker, wellLogMoniker },
                            dimension: calculableDimension,
                            isSupplementary: false
                        };

                    return {
                        monikerDictionary: { wellMoniker, wellLogMoniker },
                        dimension: facies,
                        isSupplementary: true
                    };
                });

                // @ts-ignore
                sets.push({
                    monikerDictionary: { wellMoniker, trajectoryMoniker: new Moniker({ string: '' }) },
                    dimension: tvdss,
                    isSupplementary: false
                });

                return sets;
            });
        return result;
    }

    @computed('instance')
    get referenceId(): string {
        let instance = this.get('instance');
        let id = instance.id.replace(/\//g, '-');
        return id;
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionBand);
