import { combineReducers } from 'redux';
import { saturationConcepts, localSaturationConcepts } from './saturation-concept/reducers';
import saturationConceptActionReducer from './saturation-concept-actions/reducers';
import saturationConceptStateReducer from './saturation-concept-state';

export default combineReducers({
    'saturationConcept': saturationConcepts,
    'localSaturationConcepts': localSaturationConcepts,
    'saturationConceptActions': saturationConceptActionReducer,
    'saturationConceptState': saturationConceptStateReducer
});