import { IRange } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';

export default (ranges: IRange[], min: number, max: number, rangeFactory: (from: number, to: number, id: string) => IRange) => {
    if (!ranges)
        return [];

    let indexes = ranges.reduce((aggregate: Array<number>, range) => {
        aggregate.push(range.from);
        aggregate.push(range.to);
        return aggregate;
    }, []);

    if (!indexes.includes(min)) {
        indexes.push(min);
    }
    if (!indexes.includes(max)) {
        indexes.push(max);
    }

    indexes = indexes.sort((a, b) => a - b).uniq().filter(e => e >= min || e <= max);

    let extendedRanges: Array<IRange> = [];
    indexes.reduce((from, to, index) => {
        let id = index.toString();
        let range = ranges.find(r => from == r.from && to == r.to);
        extendedRanges.push(range != undefined ? { ...range, from, to,  id } : rangeFactory(from, to, id));
        return to;
    });
    return extendedRanges.sortBy('from');
};
