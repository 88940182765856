import objectName from 'ava-import/classes/dimensions/object-name';

import facies from 'ava-saturation/classes/dimensions/facies';
import IDimension from 'ava-saturation/interfaces/dimension';
import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import zone from 'ava-saturation/classes/dimensions/zone';
import bvw from 'ava-saturation/classes/dimensions/bvw';
import well from 'ava-saturation/classes/dimensions/well';
import segment from 'ava-saturation/classes/dimensions/segment';
import pointWellLog from 'ava-saturation/classes/dimensions/point-well-log';

import permeability from 'ava-import/classes/dimensions/permeability';

import porosity from 'ava-saturation/classes/dimensions/porosity';
import wellLog from 'ava-saturation/classes/dimensions/well-log';

import { contactAngleLab, contactAngleRes } from 'ava-import/classes/dimensions/contact-angle';

import measureDepth from 'ava-saturation/classes/dimensions/measure-depth';
import contactDepth from 'ava-saturation/classes/dimensions/contact-depth';

import { iftLab, iftReservoir } from 'ava-import/classes/dimensions/interfacial-tension';
import { gasGradient, oilGradient, waterGradient } from 'ava-import/classes/dimensions/pressure-gradient';
import { pcHeightGas, pcHeightOil } from 'ava-import/classes/dimensions/capillary-pressure-height';
import { capillaryPressureRes, capillaryPressureLab } from 'ava-import/classes/dimensions/capillary-pressure';
import { relativePermeabilityWater, relativePermeabilityGas, relativePermeabilityOil } from 'ava-import/classes/dimensions/relative-permeability';

import jValue from 'ava-import/classes/dimensions/j-value';

import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';

export const dimensionsArray: IDimension[] = [
    objectName,
    capillaryPressureRes,
    capillaryPressureLab,
    bvw,
    contactDepth,
    hafwl,
    measureDepth,
    porosity,
    tvdss,
    waterSaturation,
    facies,
    zone,
    segment,
    well,
    wellLog,
    permeability,
    contactAngleLab,
    contactAngleRes,
    iftLab,
    iftReservoir,
    waterGradient,
    gasGradient,
    oilGradient,
    relativePermeabilityWater,
    relativePermeabilityGas,
    relativePermeabilityOil,
    jValue
];

const dimensionsIndex: Record<string, IDimension> = {
    measureDepth,
    tvdss,
    porosity,
    hafwl,
    waterSaturation,
    bvw,
    facies,
    contactDepth,
    zone,
    segment,
    well,
    capillaryPressureRes,
    capillaryPressureLab,
    wellLog,
    objectName,
    permeability,
    contactAngleLab,
    contactAngleRes,
    iftLab,
    iftReservoir,
    gasGradient,
    oilGradient,
    waterGradient,
    pcHeightGas,
    pcHeightOil,
    relativePermeabilityWater,
    relativePermeabilityGas,
    relativePermeabilityOil,
    jValue,
    pointWellLog
};

export default dimensionsIndex;

export const dimensionIndexMap = Object.keys(dimensionsIndex).reduce((aggregate, key: string) => {
    let dimension = dimensionsIndex[key];

    aggregate[dimension.shortName] = key;

    return aggregate;
}, {} as Record<string, string>);

export function dimensionIndexLookup(dimensionName: string) {
    return dimensionsIndex[dimensionIndexMap[dimensionName]];
}