import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    'title': validator('presence', {
        presence: true,
        ignoreBlank: true
    })
});

const ModelChanges = buildModelChanges(
    ['title', 'description', 'asset-team', 'field', 'reservoir'],
    { 
        'title': { value: true }, 
        'description': { value: true }, 
        'asset-team': { value: true }, 
        'field': { value: true }, 
        'reservoir': { value: true } 
    });

export default Fragment.extend(Validations, ModelChanges, {
    'title': DS.attr('string', { defaultValue: '' }), 
    'description': DS.attr('string', { defaultValue: '' }),
    'asset-team': DS.attr('string', { defaultValue: '' }),
    'field': DS.attr('string', { defaultValue: '' }),
    'reservoir': DS.attr('string', { defaultValue: '' })
});
