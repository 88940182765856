import { createCustomAction } from 'typesafe-actions';
import { FunctionBand, UpdateCalculationTypePayload, UpdateRangesPayload, DeleteFunctionBandPayload, UpdateCalculationSetsPayload } from './types';
import constants from './constants';

export const addFunctionBand = createCustomAction(constants.ADD, type => {
    return (payload: FunctionBand) => ({ type, payload: payload, recordable: true });
});

export const deleteFunctionBand = createCustomAction(constants.DELETE, type => {
    return (payload: DeleteFunctionBandPayload) => ({ type, payload: payload, recordable: true });
});

export const updateCalculationType = createCustomAction(constants.UPDATE_CALCULATION_TYPE, type => {
    return (payload: UpdateCalculationTypePayload) => ({ type, payload: payload, recordable: true });
});

export const updateRanges = createCustomAction(constants.UPDATE_RANGES, type => {
    return (payload: UpdateRangesPayload) => ({ type, payload: payload, recordable: true });
});

export const updateCalculationSets = createCustomAction(constants.UPDATE_CALCULATION_SETS, type => {
    return (payload: UpdateCalculationSetsPayload) => ({ type, payload: payload, recordable: true });
});
