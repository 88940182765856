import { observes } from '@ember-decorators/object';
import { action, computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import jValue from 'ava-import/classes/dimensions/j-value';
import RegressionConstantsCalculator from 'ava-saturation/calculations/regression-constants/calculator';
import isInRange from 'ava-saturation/calculations/filters/is-in-range';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import well from 'ava-saturation/classes/dimensions/well';
import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';
import { updateCalculationConstants } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/actions';
import { getCalculationConstants } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/selectors';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import copyToClipboard from 'ava-saturation/utils/copy-to-clipboard';
import isEnterPressed from 'ava-saturation/utils/is-enter-pressed';
import { connect } from 'ember-redux';
import { cloneDeep, flatMap, maxBy, minBy } from 'lodash';
import { Dispatch } from 'redux';
import ResultsBase from './results-base';

export const stateToComputed = function (this: GeneralLeverettResults, state: any) {
    const calculationConstantsBySid = getCalculationConstants(state, {
        sid: this.band.sid
    });

    return {
        calculationConstantsBySid
    };
};

export const dispatchToActions = function (this: GeneralLeverettResults, dispatch: Dispatch) {
    return {
        restoreConstant(this: GeneralLeverettResults, constantKey: string) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {
                    a: constantKey === 'A' ? this.regression.a : this.regressionConstantA,
                    b: constantKey === 'B' ? this.regression.b : this.regressionConstantB
                }
            }));

            // @ts-ignore
            set(this, `regressionConstant${constantKey}`, NaN);
        },
        resetConstants(this: GeneralLeverettResults) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {}
            }));
        },
        acceptChanges(this: GeneralLeverettResults) {
            dispatch(updateCalculationConstants({
                sid: this.band.sid,
                bandId: this.band.id,
                functionId: this.band.functionId,
                constants: {
                    a: this.regressionConstantA,
                    b: this.regressionConstantB
                }
            }));

            set(this, `regressionConstantA`, NaN);
            set(this, `regressionConstantB`, NaN);
        }
    };
};

export class GeneralLeverettResults extends ResultsBase {
    @service intl: any;

    _regressionConstantA: number;  // TODO: Fix type => string
    _regressionConstantB: number;  // TODO: Fix type => string

    // state
    calculationConstantsBySid: CalculationConstant[];

    didReceiveAttrs() {
        if (isNaN(this._regressionConstantA))
            set(this, '_regressionConstantA', this.calculationConstants.constants.a || this.regression.a);

        if (isNaN(this._regressionConstantB))
            set(this, '_regressionConstantB', this.calculationConstants.constants.b || this.regression.b);
    }

    get primaryDimension() {
        const _jValue = cloneDeep(jValue);
        // change the resulting function hafwl scale to be Logarithmic
        _jValue.scaleType = ContinuousNumericScaleType.Logarithmic;

        return _jValue;
    }

    get secondaryDimension() {
        const _sw = cloneDeep(waterSaturation);
        // change the resulting function _bvw scale to be Logarithmic
        _sw.scaleType = ContinuousNumericScaleType.Logarithmic;

        return _sw;
    }

    @computed('calculationConstantsBySid')
    get calculationConstants(): CalculationConstant {
        return this.calculationConstantsBySid.filter(c => c.bandId === this.band.id)[0];
    }

    @observes('filteredDatasets.[]')
    onDatasetUpdate() {
        if (this.areCalculationConstantsDirty) {
            set(this, '_regressionConstantA', this.regression.a);
            set(this, '_regressionConstantB', this.regression.b);
        }
    }

    @computed('filteredDatasets.[]')
    get regression() {
        // probably it's the other way around, not sure
        const regressionCalculator = new RegressionConstantsCalculator(
            this.primaryDimension.shortName,
            this.secondaryDimension.shortName
        );

        return regressionCalculator.calculate(this.filteredDatasets.map(ds => ds.dataset));
    }

    @computed('_regressionConstantA', 'regression.a', 'calculationConstants.constants.a')
    get regressionConstantA() {
        return this._regressionConstantA || this.calculationConstants.constants.a || this.regression.a;
    }
    set regressionConstantA(this: GeneralLeverettResults, value: number) {
        // @ts-ignore
        set(this, '_regressionConstantA', value);
    }

    @computed('_regressionConstantA', 'regression.a', 'calculationConstants.constants.a')
    get regressionConstantB() {
        return this._regressionConstantB || this.calculationConstants.constants.b || this.regression.b;
    }
    set regressionConstantB(value: number) {
        // @ts-ignore
        set(this, '_regressionConstantB', value);
    }

    @computed('calculationConstants.constants.a', '_regressionConstantA', 'calculationConstants.constants.b', '_regressionConstantB')
    get areCalculationConstantsDirty() {
        // @ts-ignore
        if (this._regressionConstantA === '' || this._regressionConstantB === '')
            return false;

        return this._regressionConstantA !== this.calculationConstants.constants.a ||
            this._regressionConstantB !== this.calculationConstants.constants.b;
    }

    @computed('regressionConstantA')
    get dynamicConstantAStep() {
        return this._calculateStep(this.regressionConstantA);
    }

    @computed('regressionConstantB')
    get dynamicConstantBStep() {
        return this._calculateStep(this.regressionConstantB);
    }

    @computed('gridContext.applicableIntersections.[]', 'datasets.[]')
    get intersectionsFilter() {
        if (this.gridContext.applicableIntersections.length === 0)
            return () => false;

        // find the intersection for the specific well
        const intersections = this.gridContext.applicableIntersections.filter(a => {
            return this.datasets.some(d => d.calculationSets.some(cs => cs.monikerDictionary.wellMoniker.string === a.wellMoniker.string));
        });

        return (point: any) => intersections.some(i => isInRange(i.startDepth, i.endDepth, point.tvdss, false, true));
    }

    @computed('bandFilter', 'faciesFilter', 'intersectionsFilter')
    get pointFilters() {
        return [this.bandFilter, this.faciesFilter, this.intersectionsFilter];
    }

    @computed('filteredDatasets.[]', 'regressionConstantA', 'regressionConstantB')
    get regressionLineValues() {
        const plotValues = flatMap(this.filteredDatasets.map(ds => ds.dataset.values)),
            a = this.regressionConstantA,
            b = this.regressionConstantB;

        const dimensionShortName = jValue.shortName;
        const min = get(minBy(plotValues, dimensionShortName), dimensionShortName),
            max = get(maxBy(plotValues, dimensionShortName), dimensionShortName);

        let increment = Math.abs(max - min) / 1000;
        let lineValues = [];

        for (var i = min; i < max; i += increment) {
            lineValues.push({
                [waterSaturation.shortName]: a * Math.pow(i, b),
                [jValue.shortName]: i,
                [well.shortName]: this.applicableWells[0]
            });
        }

        return lineValues;
    }

    @action
    updateConstant(constantKey: string, value: number) {
        // @ts-ignore
        set(this, `regressionConstant${constantKey}`, value);
    }

    @action
    onConstantKeyPress(constantKey: string, value: string, event: KeyboardEvent) {
        if (isEnterPressed(event)) {
            event.stopPropagation();
            this.actions.updateConstant.call(this, constantKey, value);
        }
    }

    @action
    copyResultingFunction(key: string) {
        const formula = this.intl.t(`sections.saturation-height-function.function-modeling.leverett-j-method.functions-formula.${key}`, {
            regressionA: this.regressionConstantA,
            regressionB: this.regressionConstantB
        });
        copyToClipboard(formula);
    }

    @action
    exportDataset() {
        this.generateDataset();
    }
}

export default connect(stateToComputed, dispatchToActions)(GeneralLeverettResults);
