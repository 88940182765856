import facies from 'ava-saturation/classes/dimensions/facies';
import measureDepth from 'ava-saturation/classes/dimensions/measure-depth';
import DatasetExtender from 'ava-saturation/classes/calculations/dependency-injection/extenders/dataset-extender-pure';

export const dependencies = {
    functions: {
        DatasetExtender
    },
    dimensionsIndex: {
        facies,
        measureDepth
    }
};

export default function FaciesCodeDatasetExtender(deps, faciesCodeDataset) {
    this.datasetExtender = new deps.functions.DatasetExtender(
        deps.dimensionsIndex.facies.shortName,
        [deps.dimensionsIndex.measureDepth],
    );

    return {
        name: this.datasetExtender.name,
        datasetExtender: this.datasetExtender,
        dimensionsIndex: deps.dimensionsIndex,
        _dataset: faciesCodeDataset,
        meetsDimensionExpectations: function(dimensions) {
            return this.datasetExtender.meetsDimensionExpectations(dimensions);
        },
        isApplicable: function() {
            return this._dataset != null && Array.isArray(this._dataset.values) && this._dataset.values.length != 0;
        },
        findExtendingRecord: function(value, /* dataset */) {
            return this._dataset.values.filter(v => v.md <= value.md).sort((a, b) => b[this.dimensionsIndex.measureDepth.shortName] - a[this.dimensionsIndex.measureDepth.shortName])[0];
        },
        applyExtendingRecord: function(value, extendingRecord, dataset) {
            value[this.dimensionsIndex.facies.shortName] = extendingRecord[this.dimensionsIndex.facies.shortName];

            const isDatasetUpdated = dataset.dimensions.indexOf(this.dimensionsIndex.facies) != -1;
            const action = isDatasetUpdated ? () => { } : dataset.dimensions.push;

            action.call(dataset.dimensions, this.dimensionsIndex.facies);
        },
        extend: function(value, dataset) {
            if (this.datasetExtender.meetsDimensionExpectations(dataset.dimensions) === false)
                return;

            const extendingRecord = this.findExtendingRecord(value, dataset);

            if (extendingRecord)
                this.applyExtendingRecord(value, extendingRecord, dataset);
        }
    };
}
