import DS from 'ember-data';
import Fragment from 'ember-data-model-fragments/fragment';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    'action-type': validator('presence', {
        presence: true,
        ignoreBlank: false
    })
});

const ModelChanges = buildModelChanges(
    ['action-type', 'performed-by', 'time', 'parameters'],
    { 
        'action-type': { value: true },
        'performed-by': { value: true },
        'time': { value: true },
        'parameters': { value: true }
    });

export default Fragment.extend(Validations, ModelChanges, {
    'action-type': DS.attr('string', { defaultValue: '' }),
    'performed-by': DS.attr('string', { defaultValue: '' }),
    'time': DS.attr('date', { defaultValue: '' }),
    'parameters': DS.attr()
});
