import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ScrollableSensor extends Component {
    @service screenSensor: any;
    offset: number;
    _height: number;

    didInsertElement() {
        super.init();
        set(this, '_height', this.screenSensor.height);
    }

    @computed('screenSensor.size', 'screenSensor.height', 'offset', '_height')
    get height() {
        if (this.screenSensor.xsScreenSize == null || !this.element)
            return 0;

        var offset = this.offset || 0;

        return this.screenSensor.height - (this.element.getBoundingClientRect().top + offset);
    }

    @computed('height')
    get heightStyle() {
        return `height: ${this.height}px; max-height: ${this.height}px;`;
    }
}