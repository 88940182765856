import { combineReducers } from 'redux';
import bandFilter from './band-filter/reducers';
import calculationConstant from './calculation-constants/reducers';
import calculationSet from './calculation-set/reducers';
import calculationParameter from './calculation-parameter/reducers';
import functionBand from './function-band/reducers';
import modelArea from './model-area/reducers';
import modelInputs from './model-inputs/reducers';
import modelFunction from './model-function/reducers';
import model from './model/reducers';
import referenceVersion from './reference-version/reducers';
import widget from './widget/reducers';

export default combineReducers({
    bandFilter,
    calculationConstant,
    calculationSet,
    calculationParameter,
    functionBand,
    model,
    modelArea,
    modelFunction,
    modelInputs,
    referenceVersion,
    widget
});