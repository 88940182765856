import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IPorosityProperty from 'ava-saturation/interfaces/porosity-property-reference';

export default class PorosityPropertyReferenceType extends ReferenceObjectType {
    readonly id = 142;
    readonly name = 'Porosity';
    readonly typeAliases = ['PetrelPorosityProperty'];
    subTypeAliases = ['PetrelPorosityTemplate'];
}

export const porosityPropertyReferenceType = new PorosityPropertyReferenceType();

export class PorosityPropertyReferenceParser extends SingleReferenceParser<IPorosityProperty> {
    parse(object: any): IPorosityProperty {
        let reference = super.parse(object);
        const templateMoniker = this.parseTemplateMoniker(object);
        return {
            ...reference,
            type: porosityPropertyReferenceType,
            templateMoniker
        };
    }
}

export class PorosityPropertyCollectionParser extends ReferenceParser<Array<IPorosityProperty>> {
    parse(object: any): Array<IPorosityProperty> {
        return referenceCollectionParser<IPorosityProperty>(this.appendGridMoniker(object, 'Properties'), new PorosityPropertyReferenceParser(this.origin, this.version));
    }
}
