import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import DS from 'ember-data';
import { fragment } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { buildModelChanges } from 'ava-saturation/utils/model-helpers';

const Validations = buildValidations({
    generalInformation: validator('belongs-to'),
    metadata: validator('belongs-to')
});

const ModelChanges = buildModelChanges(
    ['generalInformation', 'metadata'],
    {
        'generalInformation': { model: true },
        'metadata': { model: true }
    });

export default DS.Model.extend(Validations, ModelChanges, {
    'documentVersion': DS.attr('string'),
    'createdBy': DS.attr('string'),
    'created': DS.attr('date'),
    'modifiedBy': DS.attr('string'),
    'modified': DS.attr('date'),
    'versionId': DS.attr('string'),
    'patchId': DS.attr('string'),
    'isDraft': DS.attr('boolean'),

    'generalInformation': fragment('saturation-concept-info', { defaultValue: {} }),
    'metadata': fragment('saturation-concept-metadata', { defaultValue: {} }),

    displayName: computed('id', 'generalInformation.title', function () {
        let title = this.get('generalInformation.title');
        if (isPresent(title)) {
            return title;
        }

        return `${this.intl.t('saturation-concept.title')} ${this.id}`;
    })
});
