import Component from '@ember/component';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { assert } from '@ember/debug';

import TransitionMixin from 'ember-css-transitions/mixins/transition-mixin';

const oppositeDirection = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top',
};

export function isHorizontal(anchor) {
    return ['down', 'up'].indexOf(anchor) !== -1;
}

export function getAnchor(anchor) {
    return isHorizontal(anchor)
        ? oppositeDirection[anchor]
        : anchor;
}

/**
 * @class PaperDrawer
 * @extends Ember.Component
 */
export default Component.extend(TransitionMixin, {
    //controls - public
    minimize: false,
    inlay: false,
    persistent: true,
    position: 'left',
    zIndex: null,

    //private bindings
    _open: false,
    tagName: 'div',
    classNameBindings: ['docked:md-docked',],//'closed:md-width-0'
    closed: not('open'),
    onToggle: function () { /* must be overwritten */ },

    init: function () {
        assert('paper-drawer.screenSize is required.', this.screenSize != null);
        assert('paper-drawer.smallerScreenSizes[] is required.', this.smallerScreenSizes != null);
        this._super(...arguments);
    },

    open: computed('_open', {
        get(/* key */) {
            return this._open;
        },
        set(key, value) {
            this.set(`_${key}`, value);
            this.onToggle();
            return value;
        }
    }),

    //positioning attributes
    anchor: computed('position', function () {
        return getAnchor(this.position);
    }),

    //behavioral attributes
    variant: computed('persistent', function () {
        return this.persistent ? 'persistent' : 'temporary';
    }),

    docked: computed('variant', function () {
        return this.variant !== 'temporary';
    }),

    //positioning classes
    anchorClass: computed('anchor', function () {
        return `md-anchor-${this.anchor}`;
    }),

    //behavioral classes
    variantClass: computed('variant', function () {
        return `md-drawer-${this.variant}`;
    }),

    dockedClass: computed('docked', 'anchor', function () {
        return this.docked ? `md-anchor-docked-${this.anchor}` : '';
    }),

    inlayClass: computed('inlay', function () {
        return this.inlay ? 'md-inlay' : '';
    }),

    minimizedClass: computed('minimize', 'open', function () {
        return (this.minimize && !this.open) ? 'md-minimized' : '';
    }),

    closedClass: computed('closed', 'minimizedClass', function () {
        return this.closed && !this.minimizedClass ? 'md-closed' : '';
    }),

    zIndexClass: computed('zIndex', function () {
        return `layout-z-index-${this.zIndex || ''}`;
    }),

    backdropZIndexClass: computed('zIndex', function () {
        return `layout-z-index-${this.zIndex - 1 || ''}`;
    }),

    //media classes
    screenSizeClass: computed('screenSize', 'smallerScreenSizes', function () {
        return `layout-${this.screenSize} ${this.smallerScreenSizes.map(ss => `layout-gt-${ss}`).join(' ')}`;
    }),

    //combined classes
    combinedClass: computed('anchorClass', 'variantClass', 'dockedClass', 'inlayClass', 'minimizedClass', 'closedClass', 'screenSizeClass', 'zIndexClass', function () {
        return `${this.anchorClass} ${this.variantClass} ${this.dockedClass} ${this.inlayClass} ${this.minimizedClass} ${this.closedClass} ${this.screenSizeClass} ${this.zIndexClass}`;
    }),

    actions: {
        onBackdropTap() {
            this.toggleProperty('open');
        }
    }
});
