import { computed } from '@ember/object';
import Component from '@ember/component';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import { setModel } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/actions';
import { getModelById, getModels } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/selectors';
import { Model } from 'ava-saturation/store/entities-v1/saturation-concept-state/model/types';
import { getConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import uuid from 'ava-saturation/utils/uuid';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';
import { flatMap, get } from 'lodash';

const stateToComputed = function (this: GridStateContextProvider, state: any) {
    const concept = getConceptById(state, { sid: this.conceptId });
    const modelId = concept && concept.metadata ? concept.metadata.modelId : null;
    const model = getModelById(state, { modelId: modelId });

    return {
        models: getModels(state, { sid: get(concept, 'id') }),
        modelContext: model
    };
};

const dispatchToActions = function (this: GridStateContextProvider, dispatch: Dispatch) {
    return {
        onGridChanged(this: GridStateContextProvider, gridModel: { grid: IGrid, model: Model }) {
            let { model, grid } = gridModel;

            model = model || {
                id: uuid(),
                sid: this.conceptId,
                moniker: grid.moniker.string
            };

            dispatch(setModel(model));
        }
    };
};

export class GridStateContextProvider extends Component {
    conceptId: string;
    grids: IGrid[];
    modelContext: Model | undefined;
    models: Record<string, Model>;
    rules: Array<any>;

    @computed('grids', 'modelContext')
    get gridContext(): IGrid | undefined {
        return this.grids.find((g: IGrid) => (this.modelContext ? this.modelContext.moniker : '') === g.moniker.string);
    }

    @computed('grids', 'models')
    get gridModels(): Array<{ grid: IGrid, model: Model | undefined }> {
        let models = Object.values(this.models);
        // one concept has may models && one model has may concepts
        // serving the concept list &&  the concep edit
        return flatMap(this.grids, g => {
            const result = models.filter(m => m.moniker === g.moniker.string).map(m => {
                return {
                    grid: g,
                    model: m
                } as { grid: IGrid, model: Model | undefined };
            });

            if (result.length === 0)
                result.push({
                    grid: g,
                    model: undefined
                });

            return result;
        });
    }
}

export default connect(stateToComputed, dispatchToActions)(GridStateContextProvider);