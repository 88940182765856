import Component from '@ember/component';
import { action, computed, set } from '@ember/object';
import { debounce, join } from '@ember/runloop';
import { IWellContextCollection, WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import IDimension from 'ava-saturation/interfaces/dimension';
import IWell from 'ava-saturation/interfaces/well-reference';

export default class WidgetInputs extends Component {
    // inputs
    wells: IWell[];
    wellContextCollection: IWellContextCollection;
    wellModelingContextCollection: WellModelingContextCollection;

    wellContext: IWell | null = null;
    exploredDimension: IDimension | null = null;
    filter: boolean;

    onCalculationSetsChanged: (args: any) => void;

    @computed('wells.[]', 'wellModelingContextCollection.byMoniker')
    get targetWells() {
        return this.wells.filter((w: IWell) => {
            // tslint:disable-next-line:triple-equals
            return !this.filter || this.wellModelingContextCollection.byMoniker[w.moniker.string] != undefined;
        });
    }

    @action
    onWellSelected(well: IWell, dimension: IDimension | null) {
        join(() => {
            set(this, 'exploredDimension', null);

            let shouldResetSelection = this.filter ?
                this.wellContext === well :
                this.wellContext === well && dimension == null;

            set(this, 'wellContext', shouldResetSelection ? null : well);
        });
    }

    @action
    exploreDimension(dimension: IDimension) {
        join(() => {
            set(this, 'exploredDimension', this.exploredDimension === dimension ? null : dimension);
        });
    }

    @action
    calculationSetsChanged(args: any) {
        debounce(() => this.onCalculationSetsChanged(args), this.filter ? 0 : 250);
    }

    @action
    // tslint:disable-next-line
    noop() { }
}