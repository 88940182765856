import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import ReferenceObjectPromiseProxy from 'ava-saturation/classes/reference-object-promise-proxy';

export default class GridProvider extends Component {
    @service objectStore: IObjectStore;

    dataSourceProviders!: string[];

    @computed('objectStore.grids.isFulfilled', 'objectStore.grids.content.[]')
    get asyncGrids(): ReferenceObjectPromiseProxy<IGrid[]> {
        return this.objectStore.grids.next((grids: Array<IGrid>) => {
            return grids.filter((g: IGrid) => this.dataSourceProviders.indexOf(g.moniker.origin) !== -1);
        });
    }
}