import { computed } from '@ember/object';
import { ChartingAxisPreferences } from 'ava-saturation/classes/charting/chart-axis-preferences';
import { IDatasetSample } from 'ava-saturation/interfaces/dataset';
import { ContinuousNumericScaleType } from 'ava-saturation/interfaces/plot';
import d3 from 'd3';

export class NumericAxisScaleResolver {

    scaleType: ContinuousNumericScaleType = ContinuousNumericScaleType.Linear;

    series: IDatasetSample[][];

    valueSelector: (valueSource: IDatasetSample) => number;

    minUserDefined: number;
    maxUserDefined: number;

    extendRange: boolean;

    chartingPreferences: ChartingAxisPreferences;

    constructor(valueSelector?: (valueSource: IDatasetSample) => number, extendRange: boolean = true) {

        if (valueSelector)
            this.valueSelector = valueSelector;
        this.extendRange = extendRange;
    }

    @computed('series', 'valueSelector', 'scaleType', 'chartingPreferences', 'extendRange')
    get dataRange(): number[] {
        if (this.series == undefined) return [NaN, NaN];
        let all: IDatasetSample[] = [];
        this.series.forEach((s) => all = all.concat(s));
        const extent = d3.extent(all, this.valueSelector);
        let minData = extent[0] ? extent[0] : NaN;
        let maxData = extent[1] ? extent[1] : NaN;
        if (this.scaleType == ContinuousNumericScaleType.Logarithmic) {
            let range = NumericAxisScaleResolver.toLogarithmic([minData, maxData]);
            minData = range[0];
            maxData = range[1];
        } else {
            if (this.chartingPreferences) {
                if (isNaN(minData) || this.chartingPreferences.min.forced)
                    minData = this.chartingPreferences.min.value;
                if (isNaN(maxData) || this.chartingPreferences.max.forced)
                    maxData = this.chartingPreferences.max.value;
            }
            if (isNaN(minData) && isNaN(maxData)) {
                minData = 0;
                maxData = 1;
            }
        }
        return [minData, maxData];
    }

    @computed('dataRange')
    get minData(): number {
        return this.dataRange[0];
    }

    @computed('dataRange')
    get maxData(): number {
        return this.dataRange[1];
    }

    @computed('minUserDefined', 'minData')
    get effectiveMin(): number {
        return isNaN(this.minUserDefined) ? this.minData : this.minUserDefined;
    }

    @computed('maxUserDefined', 'maxData')
    get effectiveMax(): number {
        return isNaN(this.maxUserDefined) ? this.maxData : this.maxUserDefined;
    }

    @computed('effectiveMin', 'effectiveMax', 'scaleType', 'extendRange', 'chartingPreferences')
    get scale(): number[] {
        let range = [this.effectiveMin, this.effectiveMax];
        if (this.scaleType == ContinuousNumericScaleType.Linear && this.extendRange) {
            range = NumericAxisScaleResolver.extendRange(
                [this.effectiveMin, this.effectiveMax],
                this.effectiveMin == this.minUserDefined,
                this.effectiveMax == this.maxUserDefined);
        }
        if (this.chartingPreferences && this.chartingPreferences.inverted)
            return range.reverse();
        return range;
    }

    @computed('effectiveMin', 'effectiveMax', 'scaleType', 'extendRange', 'chartingPreferences')
    get allowedMinRange(): { from: number, to: number } {
        if (this.scaleType == ContinuousNumericScaleType.Logarithmic && this.effectiveMax > 0) {
            return { from: 0, to: this.effectiveMax };
        }
        return { from: -Infinity, to: this.effectiveMax };
    }

    @computed('effectiveMin', 'effectiveMax', 'scaleType', 'extendRange', 'chartingPreferences')
    get allowedMaxRange(): { from: number, to: number } {
        if (this.scaleType == ContinuousNumericScaleType.Logarithmic && this.effectiveMin < 0) {
            return { from: this.effectiveMin, to: 0 };
        }
        return { from: this.effectiveMin, to: Infinity };
    }


    static toLogarithmic(range: number[]): number[] {
        const log10 = Math.log10 || function (x: number) { return Math.log(x) / Math.LN10; };

        const min = range[0];
        const max = range[1];
        const absoluteMin = min && Math.abs(min);
        const absoluteMax = max && Math.abs(max);
        const signMin = (min && absoluteMin && min / absoluteMin) || 1;
        const signMax = (max && absoluteMax && max / absoluteMax) || 1;

        if (min > 0 && max > 0) {
            const resultMin = Math.pow(10, Math.floor(log10(absoluteMin || 0))) * signMin || 1;
            const resultMax = Math.pow(10, Math.ceil(log10(absoluteMax || 1))) * signMax || 100;

            return [resultMin, resultMax];
        }
        else if (min < 0 && max < 0)
        {
            const resultMin = Math.pow(10, Math.ceil(log10(absoluteMin || 1))) * signMin || 100;
            const resultMax = Math.pow(10, Math.floor(log10(absoluteMax || 0))) * signMax || 1;

            return [resultMin, resultMax];
        }
        else
        {
            // do not support logarithmic from negative to positive
            return [min, max];
        }
    }

    static extendRange(range: number[], minFixed: boolean, maxFixed: boolean): number[] {
        const distance = range[1] - range[0];
        const extra = distance / 50;
        let min = range[0];
        if (!minFixed) {
            if (min >= 0) min = min - Math.min(extra, min);
            else min = min - extra;
        }
        let max = range[1];
        if (!maxFixed) {
            if (max <= 0) max = max + Math.min(extra, Math.abs(max));
            else max = max + extra;
        }
        return [min, max];
    }
}
