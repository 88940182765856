import { availableCalculationMethods, cuddy } from 'ava-saturation/classes/calculation-types';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as saturationConceptActions from '../../saturation-concept/actions';
import * as functionActions from '../model-function/actions';
import { ModelFunction, UpdateBandTypePayload } from '../model-function/types';
import * as actions from './actions';
import { FunctionBand, FunctionBandState } from './types';

export type FunctionBandActionType = ActionType<typeof actions> | ActionType<typeof functionActions> | ActionType<typeof saturationConceptActions>;
export const generateBandId = (payload: ModelFunction | UpdateBandTypePayload) => `${payload.id}/${payload.bandType}`;

const functionBands = (state: FunctionBand[] = [], action: FunctionBandActionType) => {
    switch (action.type) {
        case getType(actions.addFunctionBand): {
            return [
                ...state, {
                    ...action.payload
                }
            ];
        }

        case getType(functionActions.addFunction): {
            const id = generateBandId(action.payload);
            return [
                ...state,
                {
                    id,
                    name: 'Global',
                    type: action.payload.bandType,
                    sid: action.payload.sid,
                    calculationType: cuddy.id,
                    ranges: [],
                    functionId: action.payload.id,
                    calculationSetIds: [],
                    modelMoniker: action.payload.modelMoniker,
                    colorIndex: 0
                }
            ];
        }
        case getType(actions.updateCalculationType): {
            return state.map(b => b.id === action.payload.id ? { ...b, calculationType: action.payload.calculationType } : b);
        }
        case getType(actions.updateRanges): {
            return state.map(b => b.id === action.payload.id ? { ...b, ranges: [...action.payload.ranges] } : b);
        }
        case getType(actions.updateCalculationSets): {
            return state.map(b => b.id === action.payload.id ? { ...b, calculationSetIds: [...action.payload.calculationSetIds] } : b);
        }

        case getType(actions.deleteFunctionBand): {
            return state.filter(b => b.id !== action.payload.id);
        }

        case getType(functionActions.deleteFunction): {
            return state.filter(b => b.functionId !== action.payload.id);
        }

        case getType(functionActions.updateBandType): {
            const band = state.find(b => b.functionId === action.payload.id);

            if (!band || state.some(b => b.functionId === action.payload.id && b.type === action.payload.bandType)) {
                return state;
            }

            const id = generateBandId(action.payload);
            return [
                ...state,
                {
                    ...band, // copy all settings from already existing band
                    id,
                    name: 'Global',
                    type: action.payload.bandType,
                    calculationType: availableCalculationMethods(action.payload.bandType)[0].id,
                    ranges: [],
                    calculationSetIds: []
                }
            ];

        }

        default:
            return state;
    }
};

export function functionBandsBySid(state: Record<string, FunctionBand[]> = {}, action: FunctionBandActionType) {
    switch (action.type) {
        case getType(actions.addFunctionBand):
        case getType(actions.updateCalculationType):
        case getType(actions.updateRanges):
        case getType(actions.deleteFunctionBand):
        case getType(actions.updateCalculationSets):
        case getType(functionActions.updateBandType):
        case getType(functionActions.deleteFunction):
        case getType(functionActions.addFunction): {
            return {
                ...state,
                [action.payload.sid]: functionBands(state[action.payload.sid], action)
            };
        }

        case getType(saturationConceptActions.sanitizeState):
        case getType(saturationConceptActions.deleteConcept): {
            let newState = { ...state };
            delete newState[action.payload.id];
            return newState;
        }

        default:
            return state;
    }
}

export default combineReducers<FunctionBandState>({
    bySid: functionBandsBySid
});
