
import Application from '@ember/application';
import Resolver from 'ava-saturation/resolver';
import loadInitializers from 'ember-load-initializers';
import configuration from 'ava-saturation/config/environment';

const App = Application.extend({
    modulePrefix: configuration.modulePrefix,
    podModulePrefix: configuration.podModulePrefix,
    Resolver
});

loadInitializers(App, configuration.modulePrefix);

export default App;
