import { computed } from '@ember/object';
import Component from '@ember/component';
import IDimension from 'ava-saturation/interfaces/dimension';
import IMoniker from 'ava-saturation/interfaces/moniker';
import IReference from 'ava-saturation/interfaces/reference';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

const stateToComputed = function (_state: any) {
    return {};
};

const dispatchToActions = function (this: StandaloneArea, _dispatch: Dispatch) {
    return {
        onAreaSelected(this: StandaloneArea, selected: boolean) {
            this.onAreaSelected({ selected, area: this.area });
        }
    };
};

export interface IGridReference extends IReference {
    gridMoniker: IMoniker;
}

export class StandaloneArea extends Component {
    elements: IGridReference[];
    type: IDimension;
    area: ModelArea;
    onAreaSelected: ({ selected, area }: { selected: boolean, area: ModelArea }) => void;
    selectedAreas: ModelArea[];

    @computed('elements', 'area')
    get targetElement() {
        return this.elements.find(e => this.area.monikers.includes(e.moniker.string));
    }

    @computed('element')
    get displayName() {
        if (!this.targetElement)
            return '';

        return this.targetElement.name;
    }

    @computed('selectedAreas.[]', 'area')
    get isSelected() {
        return this.selectedAreas.includes(this.area);
    }
}

export default connect(stateToComputed, dispatchToActions)(StandaloneArea);