define("ava-saturation/classes/ava-cp-pattern", ["exports", "ava-import/classes/ava-cp-pattern"], function (_exports, _avaCpPattern) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _avaCpPattern.default;
    }
  });
});
