import Component from '@ember/component';
import { action, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import facies from 'ava-saturation/classes/dimensions/facies';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { GridContext, IGridModelingContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { FaciesLogContextFilter } from 'ava-saturation/classes/facies-log-reference-type';

export default class DocumentExplorer extends Component {
    @service screenSensor: any;
    faciesType = facies;
    documentInputs: any;
    model: any;

    constructor() {
        super(...arguments);

        this.functionMap = {};
    }

    functionMap: {
        // areaId: functionId
        [areaId: string]: string;
    };
    // TODO: [TT] document-inputs needs to be refactored into a service
    @computed('model.{zones,segments}', 'documentInputs.{grid,contact,facies,porosity,dataset,permeability}')
    get gridContext() {
        return new GridContext({
            grid: this.documentInputs.grid.reference,
            contact: this.documentInputs.contact.reference,
            faciesProperty: this.documentInputs.facies.reference,
            porosityProperty: this.documentInputs.porosity.reference,
            porosityStatistics: this.documentInputs.dataset.porosityStatistics,
            permeabilityProperty: this.documentInputs.permeability.reference,
            permeabilityStatistics: this.documentInputs.dataset.permeabilityStatistics,
            zones: this.model.zones.items,
            segments: this.model.segments.items,
            intersections: this.documentInputs.dataset.intersections,
            applicableIntersections: this.documentInputs.dataset.applicableIntersections,
            contactDepths: this.documentInputs.dataset.contactDepths,
            faciesCodes: this.documentInputs.dataset.faciesCodes,
            isPending: this.documentInputs.dataset.isLoading
        } as IGridModelingContext);
    }

    @computed('gridContext')
    get faciesLogFilter(): FaciesLogContextFilter {
        return new FaciesLogContextFilter(this.gridContext.faciesProperty);
    }

    @action
    functionChanged() {
        set(this, 'functionMap', {});
    }

    @action
    functionSelected(area: ModelArea, modelFunction: ModelFunction) {
        // workaround https://github.com/emberjs/ember.js/issues/10661#issuecomment-83129670
        // notify property change does not work when set is used to set a property of an object.
        set(this, 'functionMap', {
            ...this.functionMap,
            [area.id]: modelFunction && modelFunction.id
        });

    }
}
