import ReferenceObjectType from '../classes/reference-object-type';
import { ReferenceParser, SingleReferenceParser, referenceCollectionParser } from '../interfaces/reference-parser';
import IProject from 'ava-saturation/interfaces/project-reference';

export default class ProjectReferenceType extends ReferenceObjectType {
    id = 1;
    name = 'project';
    typeAliases = ['PetrelProject'];
    subTypeAliases = [];
}

export const projectReferenceType = new ProjectReferenceType();

export class ProjectReferenceParser extends SingleReferenceParser<IProject> {
    parse(object: any): IProject {
        let reference = super.parse(object);
        return {
            ...reference,
            type: projectReferenceType,
        };
    }
}

export class ProjectCollectionReferenceParser extends ReferenceParser<Array<IProject>> {
    parse(object: any): Array<IProject> {
        return referenceCollectionParser<IProject>(object, new ProjectReferenceParser(this.origin, this.version));
    }
}