export default {
    version: 20180115,
    apply: function (db, event/*, transaction*/) {
        if (!db || !event)
            throw 'Database missing';

        if (event.oldVersion < this.version) {
            // TODO [NV]: expose
            let conceptStoreName = 'saturation-concepts', 
                versionStoreName = 'saturation-concept-version',
                versionsStoreName = `${versionStoreName}s`,
                stateStoreName = 'saturation-concept-state',
                statesStoreName = `${stateStoreName}s`,
                actionStoreName = 'saturation-concept-action',
                actionsStoreName = `${actionStoreName}s`,
                patchStoreName = 'saturation-concept-patch';

            if(db.objectStoreNames.contains(conceptStoreName))
                db.deleteObjectStore(conceptStoreName);
                
            if(db.objectStoreNames.contains(statesStoreName))
                db.deleteObjectStore(statesStoreName);
            
            if(db.objectStoreNames.contains(actionsStoreName))
                db.deleteObjectStore(actionsStoreName);

            if(db.objectStoreNames.contains(versionsStoreName))
                db.deleteObjectStore(versionsStoreName);

            let versionStore = db.createObjectStore(versionStoreName, { keyPath: 'id' });
            versionStore.createIndex('saturationConceptId', 'saturationConceptId', { unique: false });
            
            let stateStore = db.createObjectStore(stateStoreName, { keyPath: 'saturationConceptId' });
            stateStore.createIndex('versionId', 'versionId', { unique: false });
            stateStore.createIndex('patchId', 'patchId', { unique: true });
            
            let patchStore = db.createObjectStore(patchStoreName, { keyPath: 'id' });
            patchStore.createIndex('saturationConceptId', 'saturationConceptId', { unique: false });
            patchStore.createIndex('created', 'created', { unique: false });
            
            let actionStore = db.createObjectStore(actionStoreName, { keyPath: 'id' });
            actionStore.createIndex('saturationConceptId', 'saturationConceptId', { unique: false });
        }
    }
};