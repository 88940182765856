import { cuddy, leverettJ, skelt } from 'ava-saturation/classes/calculation-types';
import facies from 'ava-saturation/classes/dimensions/facies';
import hafwl from 'ava-saturation/classes/dimensions/hafwl';
import permeability from 'ava-saturation/classes/dimensions/permeability';
import porosity from 'ava-saturation/classes/dimensions/porosity';
import segment from 'ava-saturation/classes/dimensions/segment';
import zone from 'ava-saturation/classes/dimensions/zone';
import { CuddyCalculationStatement } from 'ava-saturation/classes/statements/cuddy-calculation-statement-builder';
import { StatementBuilder } from 'ava-saturation/interfaces/statement-builder';
import { FunctionBandStatement } from 'ava-saturation/classes/statements/function-band-statement-builder';
import { FunctionStatement } from 'ava-saturation/classes/statements/function-statement-builder';
import { LeverettJCalculationStatement } from 'ava-saturation/classes/statements/leverett-j-calculation-statement-builder';
import { SkeltHarrisonCalculationStatement } from 'ava-saturation/classes/statements/skelt-harrison-calculation-statement-builder';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { IBand } from 'ava-saturation/store/entities-v1/saturation-concept-state/function-band/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { values } from 'lodash';

export class StatementGenerator {

    private static get regexTransforms() {
        return [
            (v: string) => v.replace(new RegExp(/[\\\~\@\#\$\%\^\&\*\(\)\-\=\+\/\'\"\;\:\|\{\}\,\.\?\!\<\>\`\[\]]/, 'g'), ' ').trimRight(),
            (v: string) => v.replace(new RegExp(/\s+/, 'g'), '_'),
            (v: string) => {
                let regex = new RegExp(/^\d.*$/, 'g');

                return regex.test(v) ? `_${v}` : v;
            }
        ];
    }

    private static applyTransforms(value: string) {
        return this.regexTransforms.reduce((p, transform) => transform(p), value);
    }

    private static calculationStatementMap = (constants: CalculationConstant, parameters: CalculationParameter | undefined, calculationType: string) => {
        switch (calculationType) {
            case cuddy.id:
                return new CuddyCalculationStatement(constants, parameters);
            case skelt.id:
                return new SkeltHarrisonCalculationStatement(constants, parameters);
            case leverettJ.id:
                return new LeverettJCalculationStatement(constants, parameters);
            default:
                throw 'Unsupported calculation method';
        }
    };

    public static generateStatement(propertyNames: {
        aboveContactProperty: string;
        zonesProperty: string;
        segmentProperty: string;
        faciesProperty: string | null;
        porosityProperty: string | null;
        permeabilityProperty: string | null;
    },
        pathArray: string[],
        gridContext: GridContext,
        constants: CalculationConstant[],
        parameters: CalculationParameter[],
        bands: IBand[],
        functionMap: Record<string, string>,
        functions: ModelFunction[],
        areas: ModelArea[],
        treatUndefinedAsWater: boolean,
        swPropertyName: string
    ) {
        const fullPropertyPath = pathArray.map(unescapedPath => this.applyTransforms(unescapedPath)).join('\\');

        const propertyMap = {
            [hafwl.shortName]: `${fullPropertyPath}\\${this.applyTransforms(propertyNames.aboveContactProperty)}`,
            [zone.shortName]: `${fullPropertyPath}\\${this.applyTransforms(propertyNames.zonesProperty)}`,
            [segment.shortName]: `${fullPropertyPath}\\${this.applyTransforms(propertyNames.segmentProperty)}`,
        };

        if (propertyNames.porosityProperty)
            propertyMap[porosity.shortName] = this.applyTransforms(propertyNames.porosityProperty);

        if (propertyNames.permeabilityProperty)
            propertyMap[permeability.shortName] = this.applyTransforms(propertyNames.permeabilityProperty);

        if (propertyNames.faciesProperty)
            propertyMap[facies.shortName] = this.applyTransforms(propertyNames.faciesProperty);

        const elementMap = {
            [zone.shortName]: gridContext.zones,
            [segment.shortName]: gridContext.segments,
            [facies.shortName]: gridContext.faciesCodes
        };

        const calculationStatementBuilder = (band: IBand) => {
            let calculationConstants = constants.find(c => c.bandId === band.id) as CalculationConstant;
            let calculationParameters = parameters.find(c => c.bandId === band.id);

            return this.calculationStatementMap(calculationConstants, calculationParameters, band.calculationType).build(propertyMap, elementMap);
        };

        const bandStatementBuilder = (modelFunction: ModelFunction) => {
            let relatedBands = bands.filter(b => b.functionId === modelFunction.id && b.type === modelFunction.bandType);
            // will use the global to close the last statement - global will happen only as a fallback condition if none are met
            const globalBand = relatedBands.find(b => b.id.indexOf('/') !== -1) as IBand;
            relatedBands = relatedBands.without(globalBand);

            const globalBandStatement = new FunctionBandStatement(globalBand).then(calculationStatementBuilder);

            if (relatedBands.length === 0)
                return globalBandStatement.build(propertyMap, elementMap);

            const bandStatements = relatedBands.map(b =>
                new FunctionBandStatement(b).then(calculationStatementBuilder)
            ).concat([globalBandStatement]);

            let bandStatement: StatementBuilder<IBand> | null = null;
            bandStatements.forEach((item) => {
                if (bandStatement != null)
                    bandStatement.otherwise(() => item.build(propertyMap, elementMap));
                bandStatement = item;
            });

            return bandStatements[0].build(propertyMap, elementMap);
        };

        const selectedFunctions = values(functionMap);
        const functionStatements = functions.filter(f => selectedFunctions.includes(f.id)).map(f =>
            new FunctionStatement(f, areas).then(bandStatementBuilder) as FunctionStatement
        );

        functionStatements.forEach((s, i, a) => {
            if (i === a.length - 1) { // last element
                s.otherwise(() => `${treatUndefinedAsWater ? 1 : 'U'}`);
            } else {
                s.otherwise(() => a[i + 1].build(propertyMap, elementMap));
            }
        });

        const fullStatement = functionStatements[0].build(propertyMap, elementMap);

        const propertyName = this.applyTransforms(swPropertyName);

        return `${fullPropertyPath}\\${propertyName}=${fullStatement}`;
    }

    public static generateNormalizeStatement(pathArray: string[], swPropertyName: string) {
        const fullPropertyPath = pathArray.map(unescapedPath => this.applyTransforms(unescapedPath)).join('\\');
        const propertyName = this.applyTransforms(swPropertyName);
        const targetProperty = `${fullPropertyPath}\\${propertyName}`;

        return `${targetProperty}=if(${targetProperty}>1, 1, ${targetProperty})`;
    }

    public static generateFillGapStatement(pathArray: string[], swPropertyName: string) {
        const fullPropertyPath = pathArray.map(unescapedPath => this.applyTransforms(unescapedPath)).join('\\');
        const propertyName = this.applyTransforms(swPropertyName);
        const targetProperty = `${fullPropertyPath}\\${propertyName}`;

        return `${targetProperty}=if(${targetProperty}=U, 1, ${targetProperty})`;
    }
}