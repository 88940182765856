import { action, computed } from '@ember/object';
import Component from '@ember/component';
import IReference from 'ava-saturation/interfaces/reference';
import { ModelInput } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-inputs/types';

export default class PropertySelection extends Component {
    onPropertyChanged: (propertyModel: { item: IReference, model: ModelInput }) => void;
    brokenRules: {
        referenced: [
            {
                isInValid: boolean,
                message: string
            }
        ]
    };

    @action
    onPropertySelected(propertyModel: { item: IReference, model: ModelInput }): void {
        this.onPropertyChanged(propertyModel);
    }

    @computed('brokenRules.[]', 'isLocked.[]')
    get message(): string {
        return this.brokenRules.referenced[0].message;
    }

    @computed('brokenRules.[]')
    get isLocked(): boolean {
        return this.brokenRules && this.brokenRules.hasOwnProperty('referenced') && (this.brokenRules.referenced ? this.brokenRules.referenced.length > 0 : false);
    }
}