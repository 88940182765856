import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import QCWorkflow from 'ava-saturation/services/qc-workflow';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import StoreProxy from 'reference-store/classes/store-proxy';

export default class QualityControl extends Controller {
    @service qcWorkflow: QCWorkflow;
    @service objectStore: IObjectStore;

    grid: IGrid;
    model: any;

    @computed('qcWorkflow', 'qcWorkflow.context')
    get qcContext() {
        return this.qcWorkflow.context;
    }

    @action
    onGridSelected(grid: IGrid): void {
        this.qcWorkflow.applyGrid(grid);
    }

    @action
    async generateLogs(properties: string[]) {
        await this.objectStore.generateSyntheticLogs(this.qcContext.grid.moniker, properties, true);

        (this.objectStore as any as StoreProxy<IObjectStore>).refresh(['waterSaturationLogs']);
        const logs = await this.objectStore.waterSaturationLogs;
        set(this.model, 'waterSaturationLogs', logs);
        set(this.qcWorkflow.model, 'waterSaturationLogs', logs);
    }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
    interface Registry {
        'quality-control': QualityControl;
    }
}
