import QualityControlQcChart from 'ava-saturation/components/quality-control/qc-chart';
import { LineSeries } from 'ava-saturation/components/charting/chart-series';
import { computed } from '@ember/object';
import { ChartQuadrant } from 'ava-saturation/components/charting/proportional-chart-base';
import { ChartAxisOptions } from 'ava-saturation/components/charting/chart-axis-options';
import { ChartLegendItemsGroup, ChartLegendItem } from '../charting/chart-legend';
import { inject as service } from '@ember/service';
import QCWorkflow from 'ava-saturation/services/qc-workflow';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import waterSaturation from 'ava-saturation/classes/dimensions/water-saturation';
import d3 from 'd3';
export default class LineCharts extends QualityControlQcChart {
    @service qcWorkflow: QCWorkflow;

    quadrant: ChartQuadrant = ChartQuadrant.q4;

    filteredSeries(wellMonikerString: string): LineSeries[] {
        const byWell = this.qcWorkflow.logs
            .filter(l => l.wellMoniker.string === wellMonikerString);

        const byLog = this.qcWorkflow.extractUnique(byWell, (x: any) => x.logMoniker.string,
            (logs) => {
                let series = new LineSeries();
                series.title = logs[0].name;
                series.points = logs[0].log;
                series.xAccessor = (p) => p[waterSaturation.shortName];
                series.yAccessor = (p) => p[tvdss.shortName];
                series.color = logs[0].color;
                return series;
            }
        );

        return byLog;
    }

    filteredLegendData(series: LineSeries[]): ChartLegendItemsGroup[] {
        let group = new ChartLegendItemsGroup();
        group.name = 'Legend';
        group.items = series
            .map(s => {
                let item = new ChartLegendItem();
                item.id = s.id;
                item.name = s.title;
                item.selectable = true;
                item.selected = !s.hidden;
                item.color = s.color;
                return item;
            });

        return [group];
    }

    @computed('series', 'qcWorkflow._correlation')
    get wellsSeries(): any {
        return this.qcWorkflow.wells.map(w => {
            const series = this.filteredSeries(w.moniker.string);
            if (series.length < 2) return undefined;
            return {
                name: w.name,
                series,
                legendData: this.filteredLegendData(series),
                yAxisOptions: this.yAxisOptions(w.moniker.string),
                correlation: this.qcWorkflow._correlations.filter(c => c.wellMoniker === w.moniker.string)
            };
        })
            .filter(s => s);
    }

    @computed()
    get xAxisOptions(): ChartAxisOptions {
        let options = new ChartAxisOptions();
        options.title = waterSaturation.displayName;
        options.allowZoom = false;
        options.pinMin = 0;
        options.pinMax = 1;
        return options;
    }

    yAxisOptions(wellMonikerString: string): ChartAxisOptions {
        let options = new ChartAxisOptions();
        options.title = tvdss.displayName;
        options.zones = this.qcWorkflow.zoneAreas.filter((zone: any) => zone.wellMonikerString === wellMonikerString).map((zone: any) =>
            ({
                from: Number(zone.startDepth),
                to: Number(zone.endDepth),
                color: d3.rgb(`rgba(${zone.color}, ${0.5})`).hex(),
                name: zone.zone,
            })
        );
        return options;
    }
}
