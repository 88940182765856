import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import IObjectStore from 'ava-saturation/interfaces/object-store';
import IGrid from 'ava-saturation/interfaces/grid-reference';
import IFaciesProperty from 'ava-saturation/interfaces/facies-property-reference';
import ReferenceObjectPromiseProxy from 'ava-saturation/classes/reference-object-promise-proxy';

export default class GridPropertyProvider extends Component {
    @service objectStore: IObjectStore;

    grid: ReferenceObjectPromiseProxy<IGrid>;

    @computed('objectStore.faciesProperties.isFulfilled', 'objectStore.faciesProperties.content.[]', 'grid')
    get asyncFaciesProperties(): ReferenceObjectPromiseProxy<IFaciesProperty[]> {
        return this.grid.next((grid) => {
            return this.objectStore.faciesProperties.next((properties) => {
                return properties.filterBy('gridMoniker.string', grid.moniker.string);
            });
        });
    }
}