/**
 * @module ember-paper
 */
import PaperTabs from 'ember-paper/components/paper-tabs';

import ColorMixin from 'ava-saturation/mixins/overrides/color-mixin';

/**
 * @class PaperTabs
 * @extends Ember.Component
 * @uses ColorMixin
 */
export default PaperTabs.extend(ColorMixin, {
    classNameBindings: ['whiteframe:md-whiteframe-z2']
});