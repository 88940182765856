import { computed } from '@ember/object';
import Component from '@ember/component';
import { GridContext } from 'ava-saturation/interfaces/grid-modeling-context';
import { CalculationConstant } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-constants/types';
import { CalculationParameter } from 'ava-saturation/store/entities-v1/saturation-concept-state/calculation-parameter/types';
import { ModelArea } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-area/types';
import { ModelFunction } from 'ava-saturation/store/entities-v1/saturation-concept-state/model-function/types';
import { connect } from 'ember-redux';
import { Dispatch } from 'redux';

export const stateToComputed = function (this: FunctionGroup, _state: any) {
    return {
    };
};

export const dispatchToActions = function (this: FunctionGroup, _dispatch: Dispatch) {
    return {
        selectFunction(this: FunctionGroup, modelFunction: ModelFunction) {
            this.onFunctionSelected(this.area, modelFunction);
        }
    };
};

export class FunctionGroup extends Component {
    // inputs
    gridContext: GridContext;

    areaId: string;
    functions: ModelFunction[];
    areas: ModelArea[];
    constants: CalculationConstant[];
    parameters: CalculationParameter[];
    isEnabled: boolean;

    onFunctionSelected: (area?: ModelArea, modelFunction?: ModelFunction) => void;

    @computed('areaId', 'areas.[]')
    get area() {
        return this.areas.find(a => a.id === this.areaId);
    }

    @computed('area.name', 'gridContext.zones.[]')
    get areaName() {
        if (!this.area)
            return 'Unavailable';

        if (this.area.name)
            return this.area.name;

        const monikers = this.area.monikers;
        const zone = this.gridContext.zones.find(z => monikers.includes(z.moniker.string));

        if (!zone)
            return 'Unavailable';

        return zone.name;
    }
}

export default connect(stateToComputed, dispatchToActions)(FunctionGroup);