export const ptToPx = (x: number) => x * 96 / 72;

export const loadFont = (url: string): Promise<any> => new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'arraybuffer';
    req.onload = () => resolve(req.response);
    req.onerror = () => reject(new Error(`Cannot load font ${url}`));
    req.send();
});
