import facies from 'ava-saturation/classes/dimensions/facies';
import tvdss from 'ava-saturation/classes/dimensions/tvdss';
import { WellModelingContextCollection } from 'ava-saturation/classes/well-modeling-context';
import CalculationSetGenerator from 'ava-saturation/classes/widgets/calculation-set-generator';
import { HistogramPlotSettings } from 'ava-saturation/components/histogram-plot';
import { ScatterPlotSettings } from 'ava-saturation/components/scatter-plot';
import { histogramPlotDefinition, plotDefinitionFactory, scatterPlotDefinition } from 'ava-saturation/components/widgets-new/plot';
import { CalculationSet } from 'ava-saturation/interfaces/calculation-store';
import { PlotDefinition, PlotSettingsType } from 'ava-saturation/interfaces/plot';
import { IPresenterSettings, PlotPresentationalType } from 'ava-saturation/interfaces/presenter';
import IWell from 'ava-saturation/interfaces/well-reference';
import { flatten } from 'lodash';

export enum DataSourceType {
    GeneralLog,
    PointLog
}

export abstract class DataSourceDefinition {
    abstract type: DataSourceType;
    abstract name: string;
    abstract processorType: ProcessorType;

    /**
     * Displayed in the UI
     */
    get displayName(): string {
        return `${DataSourceType[this.type]}`;
    }

    abstract supportedPlotDefinitions: PlotDefinition[];

    deflate(): Partial<DataSourceDefinition> {
        return {
            type: this.type
        };
    }
}

export class DataSourceInstance {
    constructor(inflatable: Partial<DataSourceInstance>, wells: IWell[]) {
        this.definition = dataSourceDefinitionFactory(inflatable.definition!);
        // PlotDefinitionFactory must be able to handle undefined
        this.plotDefinition = plotDefinitionFactory(inflatable.plotDefinition);
        this.presenterSettingsDictionary = inflatable.presenterSettingsDictionary ? DataSourceInstance.inflatePresenterDictionary(inflatable.presenterSettingsDictionary) : {} as { [key in PlotPresentationalType]: IPresenterSettings };
        this.wellModelingContextCollection = inflatable.wellModelingContextCollection ? WellModelingContextCollection.inflate(inflatable.wellModelingContextCollection) : new WellModelingContextCollection();
        this.calculationSets = CalculationSetGenerator.generateDatasets(this.wellModelingContextCollection, wells);
    }

    definition: DataSourceDefinition;
    plotDefinition: PlotDefinition;
    wellModelingContextCollection: WellModelingContextCollection;
    calculationSets: CalculationSet[][];
    presenterSettingsDictionary: { [key in PlotPresentationalType]: IPresenterSettings };

    get calculationSetDimensions() {
        if (this.calculationSets && this.calculationSets.length) {
            // remove the unnecessary dimensions
            const blackList = [tvdss, facies];

            // retruns IDimension[][] where the dimensions are grouped by logs
            // this.calculationSets.map(cs => cs.reject(x => blackList.find(bl => bl.shortName === x.dimension.shortName)).map(x => x.dimension));
            return flatten(this.calculationSets)
                .mapBy('dimension')
                .uniq()
                .reject(dimension => blackList.find(blackListedDimension => blackListedDimension.shortName === dimension.shortName));
        }

        return [];
    }

    static inflate(inflatable: Partial<DataSourceInstance>, wells: IWell[]): DataSourceInstance {
        return new DataSourceInstance(inflatable, wells);
    }

    deflate(): Object {
        return {
            definition: this.definition.deflate(),
            plotDefinition: this.plotDefinition.deflate(),
            presenterSettingsDictionary: this.deflatePresenterDictionary(),
            wellModelingContextCollection: this.wellModelingContextCollection.deflate()
        };
    }

    static inflatePresenterDictionary(o: Object): { [key in PlotPresentationalType]: IPresenterSettings } {
        // do you need the whole dictionary for read only
        let dictionaryClone = {} as { [key in PlotPresentationalType]: IPresenterSettings };
        Object.keys(o).forEach(key => {
            // @ts-ignore
            if (o[key].type === PlotSettingsType.Scatter) {
                // @ts-ignore
                dictionaryClone[key] = ScatterPlotSettings.inflate(o[key]);
            } else {
                // @ts-ignore
                dictionaryClone[key] = HistogramPlotSettings.inflate(o[key]);
            }
        });

        return dictionaryClone;
    }

    deflatePresenterDictionary(): Object {
        // Copies the whole dictionary

        // let deflatedDictionary = {} as { [key in PlotPresentationalType]: IPresenterSettings };
        // Object.keys(this.presenterSettingsDictionary).forEach(key => {
        //     // @ts-ignore
        //     deflatedDictionary[key] = this.presenterSettingsDictionary[key].deflate();
        // });
        // return deflatedDictionary;

        // Copies only the rendered plotType settings
        return {
            // @ts-ignore
            [this.plotDefinition.type]: this.presenterSettingsDictionary[this.plotDefinition.type].deflate()
        };
    }
}

export enum ProcessorType {
    Interpolator = 'interpolating',
    Iterator = 'iterating'
}

export class GeneralLogSourceDefinition extends DataSourceDefinition {
    name: string = 'general-log';
    type: DataSourceType = DataSourceType.GeneralLog;
    processorType: ProcessorType = ProcessorType.Interpolator;

    supportedPlotDefinitions: PlotDefinition[] = [scatterPlotDefinition, histogramPlotDefinition];
}

export class PointLogSourceDefinition extends DataSourceDefinition {
    name: string = 'point-log';
    type: DataSourceType = DataSourceType.PointLog;
    processorType: ProcessorType = ProcessorType.Iterator;

    supportedPlotDefinitions: PlotDefinition[] = [scatterPlotDefinition, histogramPlotDefinition];
}

export function dataSourceDefinitionFactory(definition: Partial<DataSourceDefinition>) {
    switch (definition.type) {
        case DataSourceType.GeneralLog:
            return generalLogSourceDefinition;

        case DataSourceType.PointLog:
            return pointLogSourceDefinition;

        default:
            return generalLogSourceDefinition;
    }
}

export const generalLogSourceDefinition = new GeneralLogSourceDefinition();
export const pointLogSourceDefinition = new PointLogSourceDefinition();
