import Component from '@ember/component';
import { action, computed } from '@ember/object';
import IWell from 'ava-saturation/interfaces/well-reference';

export default class WellItem extends Component {
    selectedWells: IWell[] = [];
    well: IWell;

    onWellToggle: (well: IWell, isSelected: boolean) => void;

    @computed('selectedWells.@each', 'well')
    get isSelected() {
        return this.selectedWells.includes(this.well);
    }

    @action
    onWellSelected(well: IWell) {
        this.onWellToggle(well, this.isSelected);
    }
}
