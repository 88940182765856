import ReferenceObjectType from 'ava-saturation/classes/reference-object-type';
import { SingleReferenceParser, ReferenceParser, referenceCollectionParser } from 'ava-saturation/interfaces/reference-parser';
import IAboveContactProperty from 'ava-saturation/interfaces/above-contact-property-reference';

export default class AboveContactPropertyReferenceType extends ReferenceObjectType {
    readonly id = 144;
    readonly name = 'Above Contact';
    readonly typeAliases = [];
    subTypeAliases = [];
}

export const aboveContactPropertyReferenceType = new AboveContactPropertyReferenceType();

export class AboveContactPropertyReferenceParser extends SingleReferenceParser<IAboveContactProperty> {
    parse(object: any): IAboveContactProperty {
        let reference = super.parse(object);
        const templateMoniker = this.parseTemplateMoniker(object);
        return {
            ...reference,
            type: aboveContactPropertyReferenceType,
            templateMoniker
        };
    }
}

export class AboveContactPropertyCollectionParser extends ReferenceParser<Array<IAboveContactProperty>> {
    parse(object: any): Array<IAboveContactProperty> {
        return referenceCollectionParser<IAboveContactProperty>(this.appendGridMoniker(object, 'Properties'), new AboveContactPropertyReferenceParser(this.origin, this.version));
    }
}
