import { createCustomAction } from 'typesafe-actions';
import { ModelArea, AddModelsPayload, UpdateTypePayload, UpdateNamePayload, DeletePayload } from './types';
import constants from './constants';

export const add = createCustomAction(constants.ADD, type => {
    return (modelArea: ModelArea) => ({ type, payload: modelArea, recordable: true });
});

export const updateName = createCustomAction(constants.UPDATE_NAME, type => {
    return (modelArea: UpdateNamePayload) => ({ type, payload: modelArea, recordable: true });
});

export const updateType = createCustomAction(constants.UPDATE_TYPE, type => {
    return (modelArea: UpdateTypePayload) => ({ type, payload: modelArea, recordable: true });
});

export const addModels = createCustomAction(constants.ADD_MODELS, type => {
    return (modelArea: AddModelsPayload) => ({ type, payload: modelArea, recordable: true });
});

export const removeModels = createCustomAction(constants.REMOVE_MODELS, type => {
    return (modelArea: AddModelsPayload) => ({ type, payload: modelArea, recordable: true });
});

export const deleteArea = createCustomAction(constants.DELETE, type => {
    return (modelArea: DeletePayload) => ({ type, payload: modelArea, recordable: true });
});