import CalculationProvider from 'ava-saturation/components/calculation-provider';
import well from 'ava-saturation/classes/dimensions/well';
import zone from 'ava-saturation/classes/dimensions/zone';
import { calculate, dependencies } from 'ava-saturation/classes/calculations/dependency-injection/interpolator-pure';
import segment from 'ava-saturation/classes/dimensions/segment';
import IDataset from 'ava-saturation/interfaces/dataset';

import { porosityLog, waterSaturationLog, faciesLog } from 'ava-saturation/classes/dimensions/well-log';

export default class InterpolatingCalculationProvider extends CalculationProvider {
    get metadataDimensions() {
        return [zone, segment, well, porosityLog, waterSaturationLog, faciesLog];
    }

    processOutputDataset(dataset: IDataset<Record<string, number>>): IDataset<Record<string, number>> {
        return dataset;
    }

    get calculator() {
        return {
            executor: calculate,
            dependencies
        };
    }
}