import { set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { removeSaturationConceptActionCacheAsync } from 'ava-saturation/store/entities-v1/saturation-concept-actions/actions';
import { getActionsByConceptId } from 'ava-saturation/store/entities-v1/saturation-concept-actions/selectors';
import { SaturationConceptAction } from 'ava-saturation/store/entities-v1/saturation-concept-actions/types';
import { updateConceptPatch, updateConceptVersion } from 'ava-saturation/store/entities-v1/saturation-concept/actions';
import { getConceptById } from 'ava-saturation/store/entities-v1/saturation-concept/selectors';
import { SaturationConcept } from 'ava-saturation/store/entities-v1/saturation-concept/types';
import excludeProperties from 'ava-saturation/utils/exclude-props';
import _ from 'lodash';

export default class Synchronization extends Service {
    @service redux: any;
    @service store: any;
    autoSyncId: NodeJS.Timeout;
    isSyncEnabled: boolean = true;

    async version(concept: SaturationConcept) {
        const patchId = await this.patch(concept);

        const scv = this.store.createRecord('saturation-concept-version');
        set(scv, 'saturationConceptId', concept.id);
        set(scv, 'anchorVersionId', concept.versionId);
        set(scv, 'anchorPatchId', patchId || concept.patchId);

        const versionUpdate = await scv.save();
        const parsedVersion = this.parseVersion(versionUpdate, concept.id);

        this.redux.dispatch(updateConceptVersion(parsedVersion.saturationConceptId, parsedVersion.id));

        await this.redux.saveState();
    }

    async patch(concept: SaturationConcept) {
        const actions = _.orderBy(getActionsByConceptId(this.redux.getState(), { sid: concept.id }), 'time');
        const patch = this.getPatch(concept, actions);

        if (!patch)
            return null;

        const scp = this.store.createRecord('saturation-concept-patch');
        set(scp, 'generalInformation', patch.generalInformation);
        set(scp, 'metadata', patch.metadata);
        set(scp, 'recordedActions', patch.recordedActions);
        set(scp, 'saturationConceptId', patch.conceptId);
        set(scp, 'anchorVersionId', patch.anchorVersionId);
        set(scp, 'anchorPatchId', patch.anchorPatchId);

        const patchUpdate = await scp.save();
        const parsedPatch = this.parsePatch(patchUpdate, concept.id);

        this.redux.dispatch(updateConceptPatch(parsedPatch.saturationConceptId, parsedPatch.id));

        this.redux.dispatch(removeSaturationConceptActionCacheAsync.success(actions));

        await this.redux.saveState();

        return parsedPatch.id;
    }

    attachSync(conceptId: string) {
        if (this.autoSyncId)
            clearInterval(this.autoSyncId);

        this.autoSyncId = setInterval(async () => {
            if (this.isSyncEnabled) {
                const concept = getConceptById(this.redux.getState(), { sid: conceptId });
                await this.patch(concept);
            }
        }, 60000);
    }

    enableSync() {
        set(this, 'isSyncEnabled', true);
    }

    disableSync() {
        set(this, 'isSyncEnabled', false);
    }

    parseVersion(version: any, conceptId: string) {
        let versionUpdate = version.toJSON();
        versionUpdate.id = version.get('id');
        versionUpdate.saturationConceptId = conceptId;

        return versionUpdate;
    }

    parsePatch(patch: any, conceptId: string) {
        let patchUpdate = patch.toJSON();
        patchUpdate.id = patch.get('id');
        patchUpdate.saturationConceptId = conceptId;

        return patchUpdate;
    }

    getPatch(concept: SaturationConcept, actions: SaturationConceptAction[]) {
        let recordableActions = _.filter(actions, a => a.recordable),
            trackedActions = _.filter(actions, a => a.trackable),
            storableActions = _.map(recordableActions, a => excludeProperties(a, ['recordable', 'trackable', 'type']));

        if (_.isEmpty(trackedActions) === false || _.isEmpty(storableActions) === false) {
            return {
                conceptId: concept.id,
                recordedActions: storableActions,
                anchorVersionId: concept.versionId,
                anchorPatchId: concept.patchId,
                generalInformation: trackedActions.length > 0 ? {
                    ...concept.generalInformation,
                    'asset-team': concept.generalInformation.assetTeam
                } : null,
                metadata: trackedActions.length > 0 ? concept.metadata : null
            };
        }

        return null;
    }

    willDestroy() {
        if (this.autoSyncId)
            clearInterval(this.autoSyncId);
    }
}

declare module '@ember/service' {
    interface Registry {
        'synchronization': Synchronization;
    }
}
